import {useState, useContext} from "react";
import Button from "../../../../components/Button";
import Spinner from "../../../../components/Spinner";
import {Container, Header, Row, Content} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../../assets/icons/index';
import { UseEmployees } from "../../../../hooks/useEmployees"
import AppContext from "../../../../state/App.context";

export default function ModalRemovePartner({open, setSelectedItem, width, height, employeeName, employeeId}){
    const { updateOnDelete } = UseEmployees();
    const [loading, setLoading] = useState(false);
    const title = 'Remover sócio';
    const [, setSnack] = useContext(AppContext).snackState;

    function handleClose(){
      setSelectedItem({open: false, mode: '', employee_name: '', id_employee: null});
    }

    async function handleRemoveEmployee(event){ 
        try{
          setLoading(true);
          event.preventDefault();
          const response = await updateOnDelete(employeeId);
          if(response.success){
            handleClose();
            setSnack({
              open: true,
              severity: 'success', 
              message:response?.message,
            })
          } else {
            setSnack({
              open: true,
              severity: 'error', 
              message:'Ocorreu um erro ao deletar sócio:' + response?.message,
            })
          }

        } catch(err){
          console.log(err);
        } finally{
          setLoading(false);
        }

      
    }

    function renderContent(){
      if(loading){
        return (
          <Spinner width={40} fontSize={14}></Spinner>
        )
      } else {
        return (
            <Content>
              <Row>
                <h1>Deseja remover o sócio  <b>{employeeName}</b>? <b>Essa ação não poderá ser desfeita.</b></h1>
              </Row>
              <Row style={{marginBottom: 0}}>
                <Button
                  label="Cancelar"
                  background="#8E92BC"
                  color="white"
                  borderColor="#8E92BC"
                  disabled={false}
                  onClick={() => setSelectedItem({ open: false, mode: '', employee_name: '', id_employee: null })}
                ></Button>
                <Button
                  label="Remover o sócio"
                  background="#FF4D5E"
                  color="white"
                  borderColor="#FF4D5E"
                  disabled={false}
                  onClick={handleRemoveEmployee}
                ></Button>
              </Row>
            </Content>
        )
      }

    }

    return (
        <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
        <Header>
          <h1>{title}</h1>
          <img src={Close} onClick={() => setSelectedItem({ open: false, mode: '', employee_name: '', id_employee: null })} alt="Fechar"></img>
        </Header>
          {renderContent()}
        </Container>
      </Modal>
       )
  }
import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Header";
import { Container, Content, Row, RowPagination } from "./styles";
import { UseLogs } from "../../hooks/useLogs";
import Table from "../../components/Table";
import Spinner from "../../components/Spinner";
import Searchbar from "../../components/Searchbar";
import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import Datepicker from "../../components/Datepicker";
import AppContext from "../../state/App.context";
import PaginationComponent from "../../components/Pagination";

const columns = [
  {
    name: "Usuário",
    key: "e_mail",
    type: "string",
    unit: "",
  },
  {
    name: "Ação",
    key: "user_action",
    type: "string",
    unit: "",
  },
  {
    name: "Data",
    key: "created_at",
    type: "datetime",
    unit: "",
  },
];

const defaultFilters = {
  startDate: "''",
  endDate: "''",
  pages: 1, // implement
  limit: 10,
};

function Logs() {
  const { getFilteredLogs } = UseLogs();
  const [search, setSearch] = useState("");
  const [searchForId, setSearchForId] = useState("");
  const [logsToShow, setLogsToShow] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filters, setFilters] = useState({ ...defaultFilters });
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [, setSnack] = useContext(AppContext).snackState;

  function cleanFilters() {
    setFilters({ ...defaultFilters });
  }

  function adjustHours(array) {
    return array.map((item) => {
      const originalDate = new Date(item.created_at);
      if (isNaN(originalDate.getTime())) {
        console.error(`Data inválida para item com o id: ${item.id}`);
        return item;
      }
      const adjustedDate = new Date(
        originalDate.setHours(originalDate.getHours() - 3)
      );
      return { ...item, created_at: adjustedDate.toISOString() };
    });
  }

  async function getInfo() {
    try {
      let formattedFilters = {
        startDate: filters.startDate,
        endDate: filters.endDate,
        pages: filters?.pages,
        limit: filters?.limit,
        order: searchForId.length > 35 ? searchForId : "''",
        user: search.length > 0 ? search : "''",
      };
      let logsResponse = await getFilteredLogs(formattedFilters);
      if (logsResponse.success) {
        let response = logsResponse.data?.data;
        response = adjustHours(response);
        setLogsToShow(response);
        setTotalPages(logsResponse?.data?.pages);
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: logsResponse.message,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getInfo();
  }, [filters]);

  const handleChangePage = (event, value) => {
    setFilters({
      ...filters,
      pages: value,
    });
  };

  function Filters() {
    return (
      <div style={{ marginTop: 16 }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            label="Limpar filtros"
            background="transparent"
            color="#54577A"
            borderColor="#54577A"
            disabled={false}
            onClick={() => {
              cleanFilters();
            }}
          ></Button>
        </div>
        <Datepicker
          label="Histórico entre"
          width={200}
          disabled={false}
          value={filters?.startDate}
          fontSize={"0.7rem"}
          onChange={(element) => {
            let filtersCopy = { ...filters };
            filtersCopy.startDate = element;
            setFilters(filtersCopy);
          }}
        />
        <Datepicker
          label="e"
          width={200}
          disabled={false}
          value={filters?.endDate}
          fontSize={"0.7rem"}
          onChange={(element) => {
            let filtersCopy = { ...filters };
            filtersCopy.endDate = element;
            setFilters(filtersCopy);
          }}
        />
      </div>
    );
  }

  function renderContent() {
    if (loading) {
      return (
        <div style={{ width: "100%", height: 300 }}>
          <Spinner width={40} fontSize={14}></Spinner>
        </div>
      );
    } else {
      return (
        <Table
          columns={columns}
          rows={logsToShow || []}
          hasEditing={false}
          hasRemoving={false}
          setSelectedItem={() => {}}
          height="60vh"
        ></Table>
      );
    }
  }

  return (
    <Container>
      <Header
        title="Histórico"
        subtitle="Acesse o histórico de ações dos usuários no sistema"
      />
      <Content>
        <Drawer
          open={openDrawer}
          handleOpen={setOpenDrawer}
          Children={Filters}
        ></Drawer>
        <Row style={{ width: "100%" }}>
          <Searchbar
            value={searchForId}
            onChange={setSearchForId}
            disabled={false}
            width={300}
            placeholder="Buscar log por id do pedido..."
            fontSize={"0.7rem"}
          ></Searchbar>
          <Row>
            <Button
              label="Filtros"
              background="transparent"
              color="#54577A"
              borderColor="#54577A"
              disabled={false}
              onClick={() => setOpenDrawer(true)}
            ></Button>
            <Searchbar
              value={search}
              onChange={setSearch}
              disabled={false}
              width={300}
              placeholder="Buscar usuário..."
              fontSize={"0.7rem"}
            ></Searchbar>
          </Row>
        </Row>
        {renderContent()};
        <RowPagination>
          <PaginationComponent
            totalPages={totalPages}
            page={filters?.pages}
            handleChangePage={handleChangePage}
          />
        </RowPagination>
      </Content>
    </Container>
  );
}

export default React.createElement(Logs);

import styled from 'styled-components';

export const Container = styled.div`
    width:100%;
    height: 114px; ;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: flex-start;
    background: var(--white);
    border-radius: 10px;
    padding: 16px 30px;
    margin-top: 12px;
    ` ;

export const Title = styled.h1`
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 160%;
    color: var(--txt-title);
    text-align: center;
` ;

export const Value = styled.h1`
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 160%;
    color: var(--txt-title);
    text-align: center;
    margin-top: 0.5rem;` ;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
` ;

export const RowInfo = styled.h1`
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 2rem;
    color: var(--txt-subtitle);
    text-align: left;
` ;

export const Divider = styled.div`
    color: var(--txt-subtitle);
    width: 100%;
    height: 0px;
    border: 0.2px solid rgba(84, 87, 122, 0.4);
    margin-top: 12px;
    margin-bottom: 12px;
` ;




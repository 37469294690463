import React, {useState, useContext, useEffect} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UsePaymentTypes } from "../../../hooks/usePaymentTypes"
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import ModalAddPaymentType from './ModalAddPaymentType/index';
// import ModalEditPaymentType from './ModalEditPaymentType/index';
import ModalRemovePaymentType from './ModalRemovePaymentType/index';
import AppContext from "../../../state/App.context";

  const columns = [
    {
      name: 'Nome',
      key:'paymenttype_name',
      type: 'string', 
      unit:'',
    },
  ]

 function PaymentTypes(){
  const { paymentTypes } = UsePaymentTypes();
  const [search, setSearch] = useState('');
  const [openModalAddPaymentType, setOpenModalAddPaymentType] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState({open: false, mode: '', paymenttype_name: '', id_paymenttype: null});
  const [, setSnack] = useContext(AppContext).snackState;

  useEffect(()=>{
    if(paymentTypes.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:paymentTypes.error,
      })
    }
  },[paymentTypes])
  
  let paymentTypesToShow = paymentTypes.filter((type)=> type.isactive);
  if (search.length > 0) {
    paymentTypesToShow = paymentTypes.filter(a => a.paymenttype_name.toLowerCase().includes(search.toLowerCase()));
  }


    return (
      <Container>
        <Header title="Tipos de pagamento" subtitle="Gerencie os tipos de pagamento cadastrados" />
        <Content>
          <Row>
            <Button
              label="Adicionar tipos de pagamento" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddPaymentType(true)}
             ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar tipos de pagamento..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={paymentTypesToShow || []} 
            hasEditing={false} 
            hasRemoving={true}
            setSelectedItem={setSelectedPaymentType} 
          ></Table>
        </Content>
        <ModalAddPaymentType
         open={openModalAddPaymentType} 
         handleOpen={setOpenModalAddPaymentType} 
         width={700} 
         height={240} 
         ></ModalAddPaymentType>
         {/* <ModalEditPaymentType
          open={selectedPaymentType.open && selectedPaymentType.mode === 'edit'} 
          setSelectedItem={setSelectedPaymentType} 
          selectedItem={selectedPaymentType}
          width={700} 
          height={330} 
        ></ModalEditPaymentType> */}
        <ModalRemovePaymentType
          open={selectedPaymentType.open && selectedPaymentType.mode === 'remove'} 
          setSelectedItem={setSelectedPaymentType} 
          paymentTypeName={selectedPaymentType?.paymenttype_name}
          paymentTypeId={selectedPaymentType?.id_paymenttype}
          width={600} 
          height={160} 
        ></ModalRemovePaymentType>
      </Container>
    );
  };

  export default React.createElement(PaymentTypes);
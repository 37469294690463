import React from "react";
import { Container, Label } from "./styles";
import { Select, Space } from "antd";
import type { SelectProps } from "antd";

interface OptionInterface {
  label: string;
  value: string;
}

interface MultipleSelectInterface {
  label: string;
  width: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string | null | undefined;
  selectOptions: OptionInterface[];
  fontSize: string;
  handleChange: any;
}

export default function MultipleSelect({
  label,
  width,
  disabled,
  placeholder,
  defaultValue,
  selectOptions,
  fontSize,
  handleChange,
}: MultipleSelectInterface) {
  const options: SelectProps["options"] = [];

  for (let i = 0; i < selectOptions.length; i++) {
    options.push({
      label: selectOptions[i].label,
      value: selectOptions[i].value,
    });
  }

  return (
    <Space style={{ width: width || "100%" }} direction="vertical">
      <Label style={{ fontSize: fontSize }}>{label}:</Label>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={handleChange}
        options={options}
        disabled={disabled}
      />
    </Space>
  );
}

import { FormEvent, useEffect, useState, useContext} from "react";
import moment from 'moment';
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import Datepicker from "../../../../components/Datepicker";
import Numberfield from "../../../../components/Numberfield";
import {Container, Header, Row, Content, ListTitle, Divider} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import { UseTrips } from "../../../../hooks/useTrips";
import Autocomplete from "../../../../components/Autocomplete";
import { UseBoats } from "../../../../hooks/useBoats"
import { UseFishingTypes } from "../../../../hooks/useFishingTypes";
import { UseEmployees } from "../../../../hooks/useEmployees"; 
import AppContext from "../../../../state/App.context";
import Table from "../../../../components/Table";

const columns = [
  {
    name: 'Funcionário',
    key:'label',
    type: 'string', 
    unit: '',
  },
]

export default function ModalAddTrip({open, handleOpen, width, height}){
    const { createTrip } = UseTrips();
    const { boats } = UseBoats();
    const { fishingTypes } = UseFishingTypes();
    const {employees } = UseEmployees(); 
    const [trips_name, setName] = useState(0);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [boat, setBoat] = useState({id: 0});
    const [fishingType, setFishingType] = useState({id: 0});
    const [consumption, setConsumption] = useState(0);
    const [production, setProduction] = useState(0);
    const [notes, setNotes] = useState('');
    const [harvest, setHarvest] = useState(0);
    const [employeesList, setEmployeesList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');

    function cleanAllInputs(){
      try{
          setName(0);
          setStart('');
          setEnd('');
          setBoat({id: 0});
          setFishingType({id: 0});
          setConsumption(0);
          setProduction(0);
          setHarvest('');
          setNotes('');
          setEmployeesList([]);
      } catch(err){
           console.log(err);
      }
  }

    const fontSize = 14;
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;

    const title = 'Adicionar nova viagem';

    let boatsOptions = boats && !boats.error && boats.map((element)=> {
      return {
      id: element?.id_boat,
      label: element?.boats_name,
    }
  })

    let fishingTypesOptions = fishingTypes && !fishingTypes.error && fishingTypes.map((element)=> {return {
      id: element?.id_fishingtype,
      label: element?.fishingtypes_name,
    }});
    let employeesOptions = employees &&  !employees.error && employees.map((element)=> {return {
      id: element?.id_employee,
      label: element?.full_name,
    }})

    function addEmployeeToList(){
      try{
        let employeesListCopy = [... employeesList];
        employeesListCopy.push(selectedEmployee);
        setEmployeesList(employeesListCopy);
      } catch(err){
        console.log(err);
      }
     }
     function removeEmployeeFromList(selectedItem){
      try{
        let employeesListCopy = [... employeesList];
        let index = employeesListCopy.findIndex((element) => element.id === selectedItem.id);
        if(index > -1){
          employeesListCopy.splice(index,1);
        }
        setEmployeesList(employeesListCopy);
      } catch(err){
        console.log(err);
      }
     }


    function checkingRequiredFields() {
      if (!trips_name || !harvest || !start || !fishingType || !boat.id || employeesList.length === 0) {
        setSnack({
          open: true,
          severity: 'error', 
          message: 'Preencha todos os campos necessários!',
        });
        return false;
      }
      return true;
    }

    function transformDateFormat(dateString) {
      const originalDate = moment(dateString);
      const formattedDate = originalDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      return formattedDate;
  }

    const isEndAfterStart = () => start && (!end || moment(end)?.isAfter(moment(start)));
    function checkingStartAndEnd() {
      if (!isEndAfterStart()) {
        setSnack({
          open: true,
          severity: 'error', 
          message: 'A saída deve ser antes da chegada!',
        });
        return false;
      }
      return true;
    }

    function returnEmployees(){
      try{
        let list = [];
        employeesList.map((item)=> {
          list.push({employee: item?.id});
        });
        return list;
      } catch(err){
        console.log(err);
      }
    }

    async function handleCreateTrip(event){
      try{
        setLoading(true);
        event.preventDefault();
        if(checkingRequiredFields() && checkingStartAndEnd()){
          const formattedEmployees = returnEmployees();
          const response = await createTrip(
              {
                trips_name,
                begin_date: start ? transformDateFormat(start) : start,
                end_date: end ? transformDateFormat(end) : end,
                fk_boats_id_boat: boat?.id,
                fk_fishingtypes_id_fishingtype: fishingType?.id,
                oil_consumption_engine_1: consumption,
                oil_consumption_engine_2: 0,
                oil_consumption_engine_3: 0,
                production,
                notes,
                boatName: '',
                fishingTypeName: '',
                harvest,
                employees: formattedEmployees,
          }
          );
          if(response.success){
            cleanAllInputs();
              handleOpen(false);
              setSnack({
                open: true,
                severity: 'success', 
                message:response?.message,
              }) 
          } else {
            setSnack({
              open: true,
              severity: 'error', 
              message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
            })
          }
      }
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
        
     }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
            return(
<Content>
                <Row>
                    <Numberfield
                    label="Número da viagem*"
                    width={'95%'}
                    disabled={false} 
                    value={trips_name} 
                    fontSize={fontSize} 
                    onChange={setName}
                  ></Numberfield>
                    <Autocomplete
                    value={boat}
                    width={'95%'}
                    disabled={false}
                    options={boatsOptions}
                    fontSize={fontSize} 
                    label="Barco*"
                    onChange={setBoat}
                    ></Autocomplete>
                </Row>
                <Row>
                <Numberfield
                    label="Safra*"
                    width={'95%'}
                    disabled={false} 
                    value={harvest} 
                    fontSize={fontSize} 
                    onChange={setHarvest}
                  ></Numberfield>
                <Autocomplete
                  value={fishingType}
                  width={'95%'}
                  disabled={false}
                  options={fishingTypesOptions}
                  fontSize={fontSize} 
                  label="Espécie alvo*"
                  onChange={setFishingType}
                  ></Autocomplete>
                </Row>
                <Row>
                <Datepicker
                    label="Saída*"
                    width={'95%'}
                    disabled={false}
                    value={start} 
                    fontSize={fontSize} 
                    onChange={setStart}
                    type="datetime-local"
                    />
                <Datepicker
                    label="Chegada"
                    width={'95%'}
                    disabled={false}
                    value={end} 
                    fontSize={fontSize} 
                    onChange={setEnd}
                    type="datetime-local"
                    />
               
                </Row>
                <Row>
                <Numberfield
                    label="Produção (kg)"
                    width={'95%'}
                    disabled={false} 
                    value={production} 
                    fontSize={fontSize} 
                    onChange={setProduction}
                  ></Numberfield>
                <Numberfield
                    label="Consumo de óleo Motor"
                    width={'95%'}
                    disabled={false} 
                    value={consumption} 
                    fontSize={fontSize} 
                    onChange={setConsumption}
                    ></Numberfield>
                </Row>
                <Row>
                  <ListTitle>Lista de tripulantes*:</ListTitle>
                 </Row>
                 <Divider></Divider>
                 <Row style={{marginBottom: 16, alignItems: 'end'}}>
                  <Autocomplete
                    value={selectedEmployee}
                    width={'80%'}
                    disabled={false}
                    options={employeesOptions}
                    fontSize={fontSize} 
                    label="Tripulante"
                    onChange={setSelectedEmployee}
                  ></Autocomplete>
                  <Button
                    label="Adicionar" 
                    background="transparent" 
                    color="#256CE1" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={addEmployeeToList}
                  ></Button>
                </Row>
                <Table 
                    columns={columns} 
                    rows={employeesList || []} 
                    hasEditing={false} 
                    hasRemoving={true}
                    setSelectedItem={removeEmployeeFromList}
                    height="auto" 
                  ></Table>
                <Row>
                <Textfield
                    label="Observações"
                    width={'100%'}
                    disabled={false} 
                    value={notes} 
                    fontSize={fontSize} 
                    onChange={setNotes}
                    multiline={true}
                    ></Textfield>
                </Row>
                <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleOpen(false)}
                    ></Button>
                    <Button
                    label="Adicionar viagem" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={handleCreateTrip}
                    ></Button>
                </Row>
        </Content>
            )
            
        }}

    return (
        <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{minWidth: width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleOpen(false)} alt="Fechar"></img>
          </Header>
          {renderContent()}
 
        </Container>
      </Modal>
       )
  }
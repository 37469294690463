import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 24px;
    padding:16px 24px;
    max-height: 90vh;
    height: auto !important;
    width: auto !important;
    overflow-y: auto;
    max-height: 90vh;
    scrollbar-width: thin; 
    scrollbar-color: #989898 #FFFFFF; 
    &::-webkit-scrollbar {
    width: 6px; 
    }
    &::-webkit-scrollbar-thumb {
    background-color: #989898; 
    border-radius: 4px; 
    }
    &::-webkit-scrollbar-track {
    background-color: #FFFFFF;
    }
    ` ;

export const Header  = styled.div`
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DCE4FF;
    position: relative;
    padding-bottom: 8px;
    h1{
        font-weight: 600;
        font-size: 1rem !important;
        color: var(--txt-title);
        text-align: center;
    };
    img {
        height: 1.2rem;
        position: absolute;
        right: 0px;
        cursor: pointer;
        transition: transform .2s;
        &:hover {
            transform: scale(1.05);
          }
    }
` ;

export const Content = styled.div`
height: auto;
width: 100%;
padding: 9px 12px;
display: flex;
flex-direction: column;
// overflow-y: auto !important;
height: 100%;
// max-height: 90vh;
// align-items: end;
// justify-content: end;
`;

export const Row = styled.div`
width: auto;
// display: flex;
// flex-direction: row;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
column-gap: 0.5rem;
align-items: center;
justify-content: space-between;
margin-top: 16px;
padding-left: 16px;
h5{
    font-size: 0.7rem !important;
    font-weight: 300;
    width: 100%%;
    color: #54577A;
}
h4{
    font-size: 0.8rem !important;
    font-weight: 400;
    text-align: left;
    width: 100%;
    b{
        font-weight: 500;
    }
}
h3{
    font-size: 0.7rem !important;
    font-weight: 400;
    width: 100%%;
    b{
        font-weight: 500;
    }
}
`;

export const ListTitle  = styled.h1`
    font-weight: 600;
    font-size: 0.8rem !important;
    color: var(--txt-title);
    text-align: left;
` ;

export const Divider = styled.div`
color: var(--txt-subtitle);
width: 100%;
height: 0px;
border: 0.05px solid rgba(84, 87, 122, 0.4);
margin-top: 8px;
margin-bottom: 0px;
opacity:0.3;
`;


import React, {useState, useContext, useEffect} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UseProducts } from "../../../hooks/useProducts"
// import { NewBoatModal } from "../../components/Modals/NewBoatModal";
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import ModalAddSupply from './ModalAddSupply/index';
import ModalEditSupply from './ModalEditSupply/index';
import ModalRemoveSupply from './ModalRemoveSupply/index';
import Autocomplete from "../../../components/Autocomplete";
import { UseProviders } from '../../../hooks/useProviders';
import AppContext from "../../../state/App.context";

  const columns = [
    {
      name: 'Nome',
      key:'product_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Descrição',
      key:'description',
      type: 'string', 
      unit:'',
    },
  ]

function Supplies(){
  const { products } = UseProducts();
  const [search, setSearch] = useState('');
  const [openModalAddSupply, setOpenModalAddSupply] = useState(false);
  const [selectedSupply, setSelectedSupply] = useState({open: false, mode: '', product_name: '', id_product: null});
  const [, setSnack] = useContext(AppContext).snackState;


  useEffect(()=>{
    if(products.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:products.error,
      })
    }
  },[products])


  let suppliesToShow = products && !products.error &&  products.filter(a => a.type === 'Insumo' && a.isactive);
  if (search.length > 0) {
    suppliesToShow = suppliesToShow.filter(a => a.product_name.toLowerCase().includes(search.toLowerCase()));
  }

    return (
      <Container>
        <Header title="Insumos" subtitle="Gerencie os insumos cadastrados" />
        <Content>
          <Row>
            <Button
              label="Adicionar insumo" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddSupply(true)}
             ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar insumo..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={suppliesToShow || []} 
            hasEditing={true} 
            hasRemoving={true}
            setSelectedItem={setSelectedSupply} 
          ></Table>
        </Content>
        <ModalAddSupply 
         open={openModalAddSupply} 
         handleOpen={setOpenModalAddSupply} 
         width={700} 
         height={430} 
         ></ModalAddSupply>
         <ModalEditSupply
          open={selectedSupply.open && selectedSupply.mode === 'edit'} 
          setSelectedItem={setSelectedSupply} 
          selectedItem={selectedSupply}
          width={700} 
          height={330} 
        ></ModalEditSupply>
        <ModalRemoveSupply
          open={selectedSupply.open && selectedSupply.mode === 'remove'} 
          setSelectedItem={setSelectedSupply} 
          productName={selectedSupply?.product_name}
          id_product={selectedSupply?.id_product}
          id_association={selectedSupply?.association}
          width={600} 
          height={170} 
        ></ModalRemoveSupply>
      </Container>
    );
  };

  export default React.createElement(Supplies);
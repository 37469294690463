import React, {useState, useContext, useEffect} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UseProviders } from "../../../hooks/useProviders"
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import ModalAddBuyer from './ModalAddBuyer/index';
import ModalEditBuyer from './ModalEditBuyer/index';
import ModalRemoveBuyer from './ModalRemoveBuyer/index';
import AppContext from "../../../state/App.context";

  const columns = [
    {
      name: 'Nome',
      key:'providers_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Descrição',
      key:'description',
      type: 'string', 
      unit:'',
    },
  ]

function Buyers(){
  const { providers } = UseProviders();
  const [search, setSearch] = useState('');
  const [openModalAddBuyer, setOpenModalAddBuyer] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState({open: false, mode: '', providers_name: '', id_providers: null});
  const [, setSnack] = useContext(AppContext).snackState;

  let buyersToShow = providers && !providers.error && providers.filter(a => a.type === 'Comprador' && a.isactive);
  if (search.length > 0) {
    buyersToShow = buyersToShow.filter(a => a.providers_name.toLowerCase().includes(search.toLowerCase()));
  }

  useEffect(()=>{
    if(providers.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:providers.error,
      })
    }
  },[providers])



    return (
      <Container>
        <Header title="Compradores" subtitle="Gerencie os compradores cadastrados" />
        <Content>
          <Row>
            <Button
              label="Adicionar comprador" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddBuyer(true)}
             ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar comprador..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={buyersToShow || []} 
            hasEditing={true} 
            hasRemoving={true}
            setSelectedItem={setSelectedBuyer} 
          ></Table>
        </Content>
        <ModalAddBuyer 
         open={openModalAddBuyer} 
         handleOpen={setOpenModalAddBuyer} 
         width={700} 
         height={330} 
         ></ModalAddBuyer>
         <ModalEditBuyer
          open={selectedBuyer.open && selectedBuyer.mode === 'edit'} 
          setSelectedItem={setSelectedBuyer} 
          selectedItem={selectedBuyer}
          width={700} 
          height={330} 
        ></ModalEditBuyer>
        <ModalRemoveBuyer
          open={selectedBuyer.open && selectedBuyer.mode === 'remove'} 
          setSelectedItem={setSelectedBuyer} 
          boatName={selectedBuyer?.providers_name}
          boatId={selectedBuyer?.id_providers}
          width={600} 
          height={170} 
        ></ModalRemoveBuyer>
      </Container>
    );
  };
  export default React.createElement(Buyers);
import React, {useState, useContext, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Header from '../../components/Header';
import {
   Container,Content,Row, RowContent, RowFilters, RowPagination, Title
  } from './styles';
import Drawer from "../../components/Drawer";
import Button from "../../components/Button";
import Table from '../../components/Table';
import {UseOrders} from '../../hooks/useOrders';
import AppContext from "../../state/App.context";
import Spinner from '../../components/Spinner';
import { UseUsers } from '../../hooks/useUsers';
import LocalStorageService from '../../services/storage';
// import { findMinDate, verifyInterval } from '../../services/functions';
import ModalVisualizeOrder from './ModalVisualizeOrder';
import ModalRemoveOrder from './ModalRemoveOrder';
import OrdersFilters from './Filters';
import PaginationComponent from '../../components/Pagination';
import { columns, defaultFilters } from './options';
import ModalAddOrderSalary from './ModalAddOrderSalary';
import ModalEditOrderSalary from './ModalEditOrderSalary'; 
import Searchbar from '../../components/Searchbar';

function Salary(){
  const localStorageService = LocalStorageService();
  const {loading, getOrdersSalary, getOrderSalaryDetails} = UseOrders();
  const [openDrawer, setOpenDrawer] = useState(false); 
  const [ordersToShow, setOrdersToShow] = useState([]);
  const [columnsToShow, setColumnsToShow] = useState(columns);
  const [selectedOrder, setSelectedOrder] = useState('');
  const [, setSnack] = useContext(AppContext).snackState;
  const [openModalAddOrderSalary, setOpenModalAddOrderSalary] = useState(false);
  const [filters, setFilters] = useState({...defaultFilters});
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const location = useLocation();
  const { state } = location;
  const [isStateProcessed, setIsStateProcessed] = useState(false);

  function verifyFilterProps(obj) {
    let count = 0;
    for (let prop in obj) {
      if (Array.isArray(obj[prop])) {
        count++;
      }
      if (prop !== 'pages' && prop !== 'limit' && obj[prop] !== "''") {
        count++;
      }
    }
    return count;
  }

  let activeFilters = verifyFilterProps(filters);
  let filterButtonLabel = activeFilters ? "Filtros (" + activeFilters + ")" : "Filtros";

// function filterByUniqueOrderId(array) {
//   const seenIds = new Set();
//   const result = [];

//   array.forEach(object => {
//       const orderId = object.id_order;
//       if (!seenIds.has(orderId)) {
//           seenIds.add(orderId);
//           result.push({ ...object, hasMultiplesEmployees: false });
//       } else {
//           const existingObject = result.find(obj => obj.id_order === orderId);
//           if (!existingObject.hasMultiplesEmployees) {
//               existingObject.hasMultiplesEmployees = true;
//           }
//       }
//   });

//   return result;
// }


  function formatingOrders(allOrders){
    try{
      let ordersCopy = [...allOrders];
      let formattedOrders = [];
      ordersCopy && Array.isArray(ordersCopy) && ordersCopy.map((order)=>{
        let status = order.status ? order?.status.includes('Pendente') ? 'Pendente' : 'Executado' : '';
        let last_payment_date = order.payment_date ? order?.payment_date[order?.payment_date.length - 1] : '';
        if(status === 'Pendente' && order?.amount_parcels === 0){
          last_payment_date = null;
        }
        if(order?.amount_parcels > 0 && order?.qty_parcels_exec === '0.00'){
          last_payment_date = null;
        }
        const last_expiration_date = order.expiration_date ? order?.expiration_date[order?.expiration_date.length - 1] : '';
        const full_name = order?.hasMultiplesEmployees ? 'Remuneração Rateada' : order?.full_name;
        if(order.isactive){
          formattedOrders.push({
            ...order,
            full_name,
            status_name: status,
            payment_date_name: last_payment_date,
            expiration_date_name: last_expiration_date,
            total_order_value_with_discount: parseFloat(order?.total_order_value) - parseFloat(order?.discount),
          })
        }
      });
      return formattedOrders;
    } catch(err){
      console.log(err);
    }
  }

  async function getInfo(){
    try{
      let formattedFilters = {
        type: filters?.type?.id || "''",
        costCenter: filters?.costCenter?.id || "''",
        costCategory: filters?.costCategory?.id || "''",
        subsidiary: filters?.subsidiary?.id || "''",
        full_name: filters?.employee?.label || "''",
        trip: filters?.trip?.id || "''",
        paymentType: filters?.paymentType?.id || "''",
        boat: filters?.boat?.id || "''",
        status: filters?.status?.id || "''",
        startDate:filters.startDate,
        endDate:filters.endDate ,
        harvest: filters?.harvest?.id || "''",
        pages: filters?.pages,
        limit: filters?.limit,

      }
      let ordersResponse = await getOrdersSalary(formattedFilters);
      if(ordersResponse.success){
        let ordersCopy = [...ordersResponse?.data?.data];
        ordersCopy = formatingOrders(ordersCopy);
        setOrdersToShow(ordersCopy);
        setTotalPages(ordersResponse?.data?.pages)
      } else {
        setSnack({
          open: true,
          severity: 'error', 
          message: ordersResponse.message,
        });
      }
    } catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    if (search === '' && !openModalAddOrderSalary && !state) getInfo()
  },[ filters, search, openModalAddOrderSalary])

  async function getOrdeRById(){
    try{
      let response = await getOrderSalaryDetails(search);
      if(response.success){
        let formattedOrder = formatingOrders(response.data)
        setOrdersToShow(formattedOrder);
      } else {
        setSnack({
          open: true,
          severity: 'error', 
          message: 'Não existe pedido com esse id',
        });
      }

    } catch(err){
      console.log(err);
    }
  }
  
  useEffect(()=>{
    if(search.length > 35) getOrdeRById()
    if (search === '' && !openModalAddOrderSalary && isStateProcessed) getInfo()
  },[search])

    useEffect(() => {
      if (state && !isStateProcessed) {
          setSearch(state.id_order);
          setIsStateProcessed(true);
      }
  }, [state, isStateProcessed]);
  

  const handleChangePage = (event, value) => {
    setFilters(
      {
        ... filters,
        pages: value,
      }
    )
  };

  function renderTable(){
    if(loading){
      return (
        <div style={{width:'100%', height:200}}>
        <Spinner width={40} fontSize={14}></Spinner>
        </div>
      )
    } else {
      return (
        <Table 
        columns={columnsToShow.filter(element => element.show)} 
        rows={ordersToShow || []} 
        hasEditing={true} 
        hasRemoving={true}
        setSelectedItem={setSelectedOrder} 
        actionsInFirstLine={true}
        colorfulRows={true}
        fitWidth={true}
        height="700px"
      ></Table>
      )
    }
  }

  function Filters(){
    return (
      <OrdersFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters}></OrdersFilters>
    )
  }

    return (
      <Container>
        <Header title="Remuneração" subtitle="Visualize e gerencie os pedidos remuneração de todas as subsidiárias" />
        <Content>
          <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer>
          <RowFilters>
            <Searchbar
            value={search} 
            onChange={setSearch} 
            disabled={false}
             width={300} 
             placeholder="Buscar pedido por id..." 
             fontSize={'0.7rem'} 
            ></Searchbar>
            <Button
              label="Adicionar pedido remuneração" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> {setOpenModalAddOrderSalary(true)}}
             ></Button>
            <Button 
              label={filterButtonLabel}
              background="transparent" 
              color="#54577A" 
              borderColor="#54577A" 
              disabled={false} 
              onClick={()=> setOpenDrawer(true)}
            ></Button>
          </RowFilters>
          <Row>
            <div style={{height: 16}}></div>
          </Row>
          <RowContent>
            {renderTable()}
          </RowContent>
          <RowPagination>
          <PaginationComponent totalPages={totalPages} page={filters?.pages} handleChangePage={handleChangePage} />
          </RowPagination>
        </Content>
        {selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'visualize' && (
          <ModalVisualizeOrder
          open={selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'visualize'} 
          setSelectedItem={setSelectedOrder} 
          selectedItem={selectedOrder}
          width={1100} 
          height={900} 
        ></ModalVisualizeOrder>
        )}
        {openModalAddOrderSalary && (
            <ModalAddOrderSalary
            open={openModalAddOrderSalary} 
            handleOpen={setOpenModalAddOrderSalary} 
            width={1000} 
            height={900} 
        ></ModalAddOrderSalary>
        )}
        {selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'remove'  && !selectedOrder.original_order && (
          <ModalRemoveOrder
          open={selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'remove'  && !selectedOrder.original_order} 
          setSelectedItem={setSelectedOrder} 
          selectedItem={selectedOrder}
          width={600} 
          height={220} 
          getInfo={getInfo} 
        ></ModalRemoveOrder>
        )}
        {selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'edit' && selectedOrder.issalary && (
          <ModalEditOrderSalary
            open={selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'edit' && selectedOrder.issalary} 
            setSelectedItem={setSelectedOrder} 
            selectedItem={selectedOrder}
            width={1000} 
            height={900} 
        ></ModalEditOrderSalary> 
        )}
      </Container> 
    );
  };

  export default React.createElement(Salary);
import {
  Container,
  Header,
  Content,
  Divider,
  CategoryInfo,
  Row,
  RowBoat,
} from "./styles";
import Modal from "@mui/material/Modal";
import { Close, ArrowDown, ArrowUp } from "../../../assets/icons/index";
import { formatMoney } from "../../../services/functions";
import Tooltip from "@mui/material/Tooltip";

export default function ModalCategories({ open, handleOpen, data }) {
  function calculateTotalsByCategoryAndBoat() {
    const result = data.reduce((acc, curr) => {
      const { costs_categories_name, type, total_order_value, boats_name } =
        curr;

      const isOutput = type === "Saída";
      const isInput = type === "Entrada";

      let category = acc.find(
        (cat) => cat.costs_category_name === costs_categories_name
      );
      if (!category) {
        category = {
          costs_category_name: costs_categories_name,
          total_outputs: 0,
          total_inputs: 0,
          boats: [],
          expanded: false,
        };
        acc.push(category);
      }

      let boat = category.boats.find((b) => b.boats_name === boats_name);
      if (!boat) {
        boat = {
          boats_name: boats_name,
          total_outputs: 0,
          total_inputs: 0,
        };
        category.boats.push(boat);
      }

      const orderValue = parseFloat(total_order_value);

      if (isOutput) {
        category.total_outputs += orderValue;
        boat.total_outputs += orderValue;
      } else if (isInput) {
        category.total_inputs += orderValue;
        boat.total_inputs += orderValue;
      }

      return acc;
    }, []);

    result.sort((a, b) => b.total_outputs - a.total_outputs);

    result.forEach((category) => {
      category.boats.sort((a, b) => b.total_outputs - a.total_outputs);
    });

    return result;
  }

  let dataToShow = calculateTotalsByCategoryAndBoat();

  return (
    <Modal
      open={open}
      onClose={() => handleOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-type"
    >
      <Container>
        <Header>
          <h1>Resumo das categorias</h1>
          <img src={Close} onClick={() => handleOpen(false)} alt="Fechar"></img>
        </Header>
        <Content>
          {dataToShow.map((category) => {
            return (
              <CategoryInfo>
                <Row>
                  {/* <Tooltip title={category.expanded ? "Recolher" : "Expandir"}>
                    <img
                      src={category.expanded ? ArrowUp : ArrowDown}
                      onClick={() => setExpanded(!expanded)}
                    ></img>
                  </Tooltip> */}
                  <h2>{category?.costs_category_name}</h2>
                  <h3>
                    Saídas: <b>{formatMoney(category?.total_outputs)}</b>
                  </h3>
                  <h3 style={{ textAlign: "right" }}>
                    Entradas: <b>{formatMoney(category?.total_inputs)}</b>
                  </h3>
                </Row>
                <Divider></Divider>
                {category.boats.map((boat) => {
                  return (
                    <RowBoat>
                      <h3>{boat?.boats_name}</h3>
                      <h3 style={{ color: "var(--red)" }}>
                        {" "}
                        {formatMoney(boat?.total_outputs)}
                      </h3>
                      <h3 style={{ textAlign: "right", color: "var(--green)" }}>
                        {formatMoney(boat?.total_inputs)}
                      </h3>
                    </RowBoat>
                  );
                })}
              </CategoryInfo>
            );
          })}
        </Content>
      </Container>
    </Modal>
  );
}

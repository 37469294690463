import {useEffect, useState, useContext} from "react";
import Button from "../../../components/Button";
import Textfield from "../../../components/Textfield";
import Datepicker from "../../../components/Datepicker";
import Numberfield from "../../../components/Numberfield";
import Switch from "../../../components/Switch";
import {Container, Header, Row, Content, ListTitle, Divider, Explanation} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import { UseOrders } from "../../../hooks/useOrders";
import { UseCostCenters } from "../../../hooks/useCostCenters";
import { UseCostCategories } from "../../../hooks/useCostCategories";
import { UseSubsidiaries } from "../../../hooks/useSubsidiaries";
import { UseProviders } from "../../../hooks/useProviders";
import { UseProducts} from "../../../hooks/useProducts";
import { UseTrips} from "../../../hooks/useTrips";
import { UseBoats} from "../../../hooks/useBoats";
import { UsePaymentTypes} from "../../../hooks/usePaymentTypes"; 
import moment from "moment";
import Autocomplete from "../../../components/Autocomplete";
import AppContext from "../../../state/App.context";
import Table from '../../../components/Table'; 
import LocalStorageService from '../../../services/storage';
import { columns, columnsParcels, columnsApportionment, typeOptions, statusOptions} from "./options";
import { countExecutedParcels, formatMoney } from "../../../services/functions";
import { harvestOptions } from "../options";

export default function ModalAddOrderApportionment({open, handleOpen, width, height}){
    const localStorageService = LocalStorageService();
    const { createOrder } = UseOrders();
    const { costCenters } = UseCostCenters();
    const { costCategories } = UseCostCategories();
    const { providers } = UseProviders();
    const { products } = UseProducts();
    const { subsidiaries } = UseSubsidiaries();
    const { trips } = UseTrips();
    const { boats } = UseBoats();
    const { paymentTypes } = UsePaymentTypes();

    const [disableAddButton, setDisableAddButton] = useState(false);
    const [product_amount, setProduct_amount] = useState(0);
    const [unity_price, setUnity_price] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [type, setType] = useState(typeOptions[0]);
    const [costCenter, setCostCenter] = useState({id: 0});
    const [cost_category, setCost_category] = useState({id: 0});
    const [subsidiary, setSubsidiary] = useState({id: 0});
    const [provider, setProvider] = useState({id: 0});
    const [product, setProduct] = useState({id: 0});
    const [productsList, setProductsList] = useState([]);
    const [apportiomentList, setApportiomentList] = useState([]);
    const [trip, setTrip] = useState({id: 0});
    const [paymentType, setPaymentType] = useState({id: 0});
    const [amount_parcels, setamount_parcels] = useState(0);
    const [receipt, setReceipt] = useState('');
    const [parcel_value, setParcelValue] = useState(0);
    const [expiration_date, setExpirationDate] = useState('');
    const [payment_date, setPaymentDate] = useState('');
    const [status, setStatus] = useState(statusOptions[0]);
    const fontSize = 14;
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;
    const [parcels, setParcels] = useState([]);
    const [is_indirect_cost, setIsIndirectCost] = useState(false);
    const [harvest, setHarvest] = useState({id: 0});
    const [notes, setNotes] = useState('');

    const title = 'Adicionar novo pedido rateado';

    let parcelsInput = paymentType?.label && (paymentType.label.includes("Parcelado") || paymentType?.label && paymentType.label.includes("Cartão"));
    
    function calculateTotalValue() {
      return productsList.reduce((total, product) => total + (product.unity_price * product.product_amount), 0);
    }

    const total_order_value = productsList.length > 0  ? calculateTotalValue() : 0;

    function cleanAllInputs(){
      setProduct_amount(0);
      setUnity_price(0);
      setType(typeOptions[0]);
      setCostCenter({id: 0});
      setCost_category({id: 0});
      setSubsidiary({id: 0});
      setProvider({id: 0});
      setProduct({id: 0});
      setTrip({id: 0});
      setPaymentType({id: 0});
      setReceipt('');
      setParcelValue(0);
      setExpirationDate('');
      setPaymentDate('');
      setStatus(statusOptions[0]);
      setParcels([]);
      setamount_parcels(0);
      setProductsList([]);
      setApportiomentList([]);
      setDiscount(0);
      setIsIndirectCost(false);
      setHarvest({id: 0});
      setNotes('');
    }

    let costCentersOptions = costCenters && !costCenters.error && costCenters.filter((element)=> element.isactive).map((element)=> {return {
      id: element?.id_cost,
      label: element?.costs_center_name,
    }})

    let costCategoriesOptions = costCategories &&  !costCategories.error && costCategories.map((element)=> {return {
      id: element?.id_category,
      label: element?.costs_categories_name,
    }})

    let subsidiariesOptions = subsidiaries && !subsidiaries.error && subsidiaries.map((element)=> {return {
      id: element?.id_subsidiary,
      label: element?.subsidiary_name,
    }})

    let providerType = type.id === 0 ? 'Comprador' : 'Fornecedor';

    let providersToShow = providers && !providers.error && providers.filter(a => a.type === providerType);
    let providersOptions = providers && !providers.error &&  providersToShow.map((element)=> {return {
      id: element?.id_providers,
      label: element?.providers_name,
    }})

    let productType = type.id === 0 ? 'Produto' : 'Insumo';

    let productsToShow = products && !products.error && products.filter(a => a.type === productType);
    let productsOptions = [];
    productsToShow && productsToShow.map((element)=> {
      if(element.isactive)
        productsOptions.push({
          id: element?.id_product,
          label: element?.product_name,
        });
      })

    productsOptions.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
    
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
    
      return 0;
    });

    let parcels_sum = 0;

    for (let i = 0; i < parcels.length; i++) {
      if (parcels[i]?.value !== undefined && !isNaN(parcels[i]?.value)) {
        parcels_sum += parcels[i]?.value;
      }
    }
      

      function getBoatName(boatId){
        try{
          let index = boats.findIndex((boat) => boat.id_boat === boatId);
          if(index !== -1){
            return boats[index].boats_name;

          } else return '';

        } catch(err){
          console.log(err)
        }
      
      }

      
      let tripsOptions = trips && !trips?.error && trips.map((element)=> {return {
        id: element?.id_trip,
        label: element?.trips_name + ' - ' + getBoatName(element?.fk_boats_id_boat),
        harvest: element?.harvest,
      }})
  
      if(harvest.id){
        tripsOptions = tripsOptions.filter((element)=> element.harvest === harvest?.label);
      }

    let paymentTypesOptions = paymentTypes && !paymentTypes.error && paymentTypes.map((element)=> {return {
      id: element?.id_paymenttype,
      label: element?.paymenttype_name,
    }})


    function addTimestampToArray(newElement, index, array){
      newElement = newElement || new Date();
      if (index === 0 && parcels.length !== 0){
        return "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
      }
      if (index === 0 && parcels.length === 0){
        return "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
      }
      if (index === (parcels.length - 1)){
        return array + "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
      }
      return array +  "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
    }

    function addElementToArray(newElement, index, array){
      if (index === 0 && parcels.length !== 0){
        return newElement + ",";
      }
      if (index === 0 && parcels.length === 0){
        return newElement;
      }
      if (index === (parcels.length - 1)){
        return array + newElement;
      }
      return array + newElement + ",";
    }
    function addStringToArray(newElement, index, array){
      if (index === 0 && parcels.length !== 0){
        return "'"+ newElement + "',";
      }
      if (index === 0 && parcels.length === 0){
        return "'"+ newElement + "'";
      }
      if (index === (parcels.length - 1)){
        return array + "'" + newElement + "'";
      }
      return array + "'" + newElement + "',";
    }

    function addUuidToArray(newElement, index, array){
      newElement = newElement || new Date();
      if (index === 0 && apportiomentList.length !== 0){
        return "'"+ newElement + "'::uuid,";
      }
      if (index === 0 && apportiomentList.length === 0){
        return "'"+ newElement + "'::uuid";
      }
      if (index === (apportiomentList.length - 1)){
        return array + "'"+ newElement + "'::uuid";
      }
      return array +  "'"+ newElement + "'::uuid,";
    }

    function creatingApportionmentArrays(totalOrderValue, discount){
      try{
        let fk_trips_id_trip_array = [];
        let fk_costs_center_id_cost_array = [];
        let total_order_array = [];
        let apportionmentValue = (totalOrderValue - discount)/(apportiomentList.length);
        apportiomentList.map((element, index)=>{
          fk_trips_id_trip_array = addUuidToArray(element?.trip?.id,index, fk_trips_id_trip_array);
          fk_costs_center_id_cost_array = addUuidToArray(element?.cost_center?.id,index, fk_costs_center_id_cost_array);
          total_order_array.push(apportionmentValue);
        });
        return {
          fk_trips_id_trip_array,
          fk_costs_center_id_cost_array,
          total_order_array,
        }

      } catch(err){
        console.log(err);
      }
    }

    function checkingRequiredFields() {
      if ( !subsidiary.id || !paymentType.id || !provider.id) {
        return false;
      }
      return true;
    }
    function checkingApportionmentAmount() {
      if ( apportiomentList.length < 2 ) {
        return false;
      }
      return true;
    }

    
    async function handleCreateOrder(event){
      try{
        setLoading(true);
        setDisableAddButton(true);
        event.preventDefault();
        if(checkingApportionmentAmount()){
          if(checkingRequiredFields()){
            if(productsList.length){
              let error = false;
              let status_array = '';
              let expiration_date_array = '';
              let payment_date_array = '';
              let values_parcels_array = '';
              let total_order_value_with_discount = total_order_value - discount;
              let total_order_value_exec = 0;
              if(amount_parcels === 0){
                if(status?.label === 'Executado'){
                  total_order_value_exec = total_order_value_with_discount;
                }
                status_array = addStringToArray(status.label, 0,status_array);
                values_parcels_array = addElementToArray(total_order_value_with_discount, 0,values_parcels_array);
                expiration_date_array = addTimestampToArray(expiration_date, 0,expiration_date_array);
                payment_date_array = addTimestampToArray(payment_date, 0,payment_date_array);
              } else {
                parcels.map((parcel, index)=> {
                  if(parcel.status.label === 'Executado'){
                    total_order_value_exec = total_order_value_exec + parcel?.value;
                  }
                  status_array = addStringToArray(parcel.status.label, index,status_array);
                  values_parcels_array = addElementToArray(parcel?.value, index,values_parcels_array);
                  expiration_date_array = addTimestampToArray(parcel.expiration_date, index,expiration_date_array);
                  payment_date_array = addTimestampToArray(parcel.payment_date, index,payment_date_array);
                });
              };
              let transactions = [];
              productsList.map((product)=>{
                transactions.push({
                  product_amount: product?.product_amount,
                  unity_price: product?.unity_price,
                  total_value: product?.total_value,
                  fk_costs_categories_id_category: product?.cost_category?.id,
                  fk_products_id_product: product?.product?.id,
                })
              });
              const userInfo = localStorageService.getUserInfo();
              const {fk_trips_id_trip_array, fk_costs_center_id_cost_array, total_order_array} = creatingApportionmentArrays(total_order_value, discount);
              const qty_parcels_exec = countExecutedParcels(parcels);
              const orderToAdd = {
                  created_at: moment().format('YYYY-MM-DD'),
                  expiration_date: expiration_date_array,
                  payment_date: payment_date_array,
                  amount_parcels,
                  type: type?.label,
                  status: status_array,
                  values_parcels: values_parcels_array,
                  total_order_value,
                  approved: true,
                  approved_by: userInfo?.email ,
                  created_by:userInfo?.email ,
                  receipt,
                  discount: discount,
                  // fk_costs_center_id_cost: costCenter?.id,
                  fk_providers_id_provider: provider?.id,
                  // fk_trips_id_trip: trip?.id,
                  fk_subsidiary_id_subsidiary: subsidiary?.id,
                  fk_paymenttype_id_paymenttype: paymentType?.id,
                  transactions,
                  isstocked: false,
                  fk_trips_id_trip_array,
                  fk_costs_center_id_cost_array,
                  total_order_array,
                  isapportionment: true,
                  qty_parcels_exec,
                  total_order_value_exec,
                  is_indirect_cost,
                  notes,
              };
                const response = await createOrder(orderToAdd);
                if(!response.success){
                  error = true;
                }
              if(!error){
                  handleOpen(false);
                  cleanAllInputs()
                  setSnack({
                    open: true,
                    severity: 'success', 
                    message:"Operação realizada com sucesso!",
                  })
              } else {
                setSnack({
                  open: true,
                  severity: 'error', 
                  message:'Ocorreu um erro no cadastro:' + response?.message,
                })
              }
      
              } else {
                setSnack({
                  open: true,
                  severity: 'error', 
                  message:'É necessário adicionar ao menos um produto/insumo na lista de produtos/insumos',
                })
              }
  
          } else {
            setSnack({
              open: true,
              severity: 'error', 
              message: 'Preencha todos os campos necessários!',
            });
          }
        } else {
          setSnack({
            open: true,
            severity: 'error', 
            message: 'É necessário ter mais de um par (viagem e centro de custo) em um pedido rateado!',
          });
        }
     
 
        
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
        setDisableAddButton(false);
      }
       
     }

     function addProductToList(){
      try{
        if(product?.id && product_amount && unity_price && cost_category.id){
          let productsListCopy = [... productsList];
          let productInfo = {
            product: product,
            product_name: product?.label,
            product_amount: product_amount,
            unity_price: unity_price,
            total_value: product_amount*unity_price,
            cost_category: cost_category,
            cost_category_name: cost_category?.label,
          }
          productsListCopy.push(productInfo);
          setProductsList(productsListCopy);
        } else {
          setSnack({
            open: true,
            severity: 'error', 
            message: 'Preencha todos os campos necessários no produto!',
          });
        }
      
      } catch(err){
        console.log(err);
      }
     }

     function addApportionmentToList(){
      try{
        let apportionmentListCopy = [... apportiomentList];
        let apportionmentItem = {
          trip: trip,
          trip_name: trip?.label,
          cost_center: costCenter,
          cost_center_name: costCenter?.label,
          harvest_name: trip?.harvest,
        }
        apportionmentListCopy.push(apportionmentItem);
        setApportiomentList(apportionmentListCopy);
      } catch(err){
        console.log(err);
      }
     }

     function findRelatedCostCenter(boatName){
      try{
        const index = costCentersOptions.findIndex((element)=> element.label.toLowerCase() === boatName.toLowerCase())
        return index !== -1 ? costCentersOptions[index] : costCentersOptions[0];
      } catch(err){
        console.log(err);
      }

     }

     function addAllOpenTripsToList(){
      try{
        let apportionmentListCopy = [... apportiomentList];
        let openTrips = trips.filter((trip) => trip.end_date === null);
        openTrips.map((trip)=>{
          let boatName = getBoatName(trip?.fk_boats_id_boat);
        let tripOption = {
          id: trip?.id_trip,
          label: trip?.trips_name + ' - ' + boatName + ' (' + trip?.harvest + ')' ,
        }
        let costCenterRelated = findRelatedCostCenter(boatName);
        let apportionmentItem = {
          trip: tripOption,
          trip_name: tripOption?.label,
          cost_center: costCenterRelated,
          cost_center_name: costCenterRelated?.label,
          harvest_name: trip?.harvest,
        }
        apportionmentListCopy.push(apportionmentItem);
        })
        setApportiomentList(apportionmentListCopy);
      } catch(err){
        console.log(err);
      }
     }
     function removeProductFromList(selectedItem){
      try{
        let productsListCopy = [... productsList];
        let index = productsListCopy.findIndex((element) => element.product.id === selectedItem.product.id);
        if(index > -1){
          productsListCopy.splice(index,1);
        }
        setProductsList(productsListCopy);
      } catch(err){
        console.log(err);
      }
     }

     function removeApportionmentFromList(selectedItem){
      try{
        let apportionmentListCopy = [... apportiomentList];
        let index = apportionmentListCopy.findIndex((element) => element.trip.id === selectedItem.trip.id);
        if(index > -1){
          apportionmentListCopy.splice(index,1);
        }
        setApportiomentList(apportionmentListCopy);
      } catch(err){
        console.log(err);
      }
     }


     function addParcelToList(){
      try{
        let parcelsCopy = [... parcels];
        let parcelInfo = {
          name: parcelsCopy.length + 1,
          value: parcel_value,
          expiration_date: expiration_date,
          payment_date: payment_date,
          status: status,
          status_label: status.label,
        }
        parcelsCopy.push(parcelInfo);
        setParcels(parcelsCopy);
      } catch(err){
        console.log(err);
      }
     }

     function removeParcelFromList(selectedItem){
      try{
        let parcelsCopy = [... parcels];
        let index = parcelsCopy.findIndex((element) => element.name === selectedItem.name);
        if(index > -1){
          parcelsCopy.splice(index,1);
        }
        setParcels(parcelsCopy);
      } catch(err){
        console.log(err);
      }
     }


     function renderParcelArea(){
      if(amount_parcels > 1){
        return (
          <>
          <Row style={{marginBottom: 16}}>
          <Numberfield
            label="Valor*"
            width={180}
            disabled={false} 
            value={parcel_value} 
            fontSize={fontSize} 
            onChange={setParcelValue}
          ></Numberfield>
          <Datepicker
              label="Data de Vencimento*"
              width={180}
              disabled={false}
              value={expiration_date} 
              fontSize={fontSize} 
              onChange={setExpirationDate} />
          <Datepicker
              label="Data de Pagamento"
              width={180}
              disabled={false}
              value={payment_date} 
              fontSize={fontSize} 
              onChange={setPaymentDate} />
          <Autocomplete
              value={status}
              width={180}
              disabled={false}
              options={statusOptions}
              fontSize={fontSize} 
              label="Status*"
              onChange={setStatus}
              ></Autocomplete>
            <Button
                label="Adicionar" 
                background="transparent" 
                color="#256CE1" 
                borderColor="#256CE1" 
                disabled={parcels.length >= amount_parcels} 
                onClick={addParcelToList}
                ></Button>
          </Row> 
          <Table 
            columns={columnsParcels} 
            rows={parcels || []} 
            hasEditing={false} 
            hasRemoving={true}
            setSelectedItem={removeParcelFromList} 
            height="auto" 
          ></Table>
          <Row>
            <Explanation>
                  Valor total do pedido: {formatMoney(total_order_value - discount)}
                  <br></br>
                  Somatório das parcelas: <b>{formatMoney(parcels_sum)}</b>
            </Explanation>
          </Row>
          <Row style={{marginTop: 0}}>
          <Explanation style={{color: 'var(--red)'}}>
                  {total_order_value - discount !== parcels_sum ? 'O somátório das parcelas deve ser igual ao valor total do pedido!' : ''}
            </Explanation>
          </Row>
          </>
        )
      } else {
        return(
          <>
          <Row style={{marginBottom: 16}}> 
          <Datepicker
              label="Data de Vencimento*"
              width={280}
              disabled={false}
              value={expiration_date} 
              fontSize={fontSize} 
              onChange={setExpirationDate} />
          <Datepicker
              label="Data de Pagamento"
              width={280}
              disabled={false}
              value={payment_date} 
              fontSize={fontSize} 
              onChange={setPaymentDate} />
          <Autocomplete
              value={status}
              width={280}
              disabled={false}
              options={statusOptions}
              fontSize={fontSize} 
              label="Status*"
              onChange={setStatus}
              ></Autocomplete>
          </Row> 
           </>
        )
      }
     }
     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
            return(
            <Content>
              <Row>
              <ListTitle>Informações do pedido:</ListTitle>
              </Row>
              <Divider></Divider>
                <Row>
                  <Autocomplete
                      value={type}
                      width={280}
                      disabled={false}
                      options={typeOptions}
                      fontSize={fontSize} 
                      label="Tipo*"
                      onChange={setType}
                      ></Autocomplete>
                  <Autocomplete
                      value={subsidiary}
                      width={280}
                      disabled={false}
                      options={subsidiariesOptions}
                      fontSize={fontSize} 
                      label="Subsidiária*"
                      onChange={setSubsidiary}
                    ></Autocomplete>
                    <Autocomplete
                      value={provider}
                      width={280}
                      disabled={false}
                      options={providersOptions}
                      fontSize={fontSize} 
                      label={providerType + '*'}
                      onChange={setProvider}
                    ></Autocomplete>
                </Row> 
                <Row>
                      <Textfield
                      label="Link do recibo"
                      width={'100%'}
                      disabled={false} 
                      value={receipt} 
                      fontSize={fontSize} 
                      onChange={setReceipt}
                      multiline={false}
                    ></Textfield>
                 </Row>
                 <Row>
                  <ListTitle>Lista de rateio*:</ListTitle>
                 </Row>
                 <Divider></Divider>
                 <Row style={{marginBottom: 16}}>
                      <Button
                        label="Adicionar todas as viagem em aberto" 
                        background="transparent" 
                        color="#256CE1" 
                        borderColor="#256CE1" 
                        disabled={false} 
                        onClick={addAllOpenTripsToList}
                      ></Button>
                    </Row>
                    <Row style={{marginBottom: 16}}>
                    <Autocomplete
                    value={harvest}
                    width={230}
                    disabled={false}
                    options={harvestOptions}
                    fontSize={fontSize} 
                    label="Safra*"
                    onChange={setHarvest}
                  ></Autocomplete>
                    <Autocomplete
                      value={trip}
                      width={230}
                      disabled={false}
                      options={tripsOptions}
                      fontSize={fontSize} 
                      label="Viagem*"
                      onChange={setTrip}
                    ></Autocomplete>
                    <Autocomplete
                        value={costCenter}
                        width={230}
                        disabled={false}
                        options={costCentersOptions}
                        fontSize={fontSize} 
                        label="Centro de custo*"
                        onChange={setCostCenter}
                    ></Autocomplete>
                    <Button
                      label="Adicionar" 
                      background="transparent" 
                      color="#256CE1" 
                      borderColor="#256CE1" 
                      disabled={false} 
                      onClick={addApportionmentToList}
                    ></Button>
                  </Row>
                <Table 
                    columns={columnsApportionment} 
                    rows={apportiomentList || []} 
                    hasEditing={false} 
                    hasRemoving={true}
                    setSelectedItem={removeApportionmentFromList}
                    height="auto" 
                  ></Table>
                  <br></br>
                <Row>
                  <ListTitle>Lista de produtos*:</ListTitle>
                 </Row>
                 <Divider></Divider>
                 <Row style={{marginBottom: 16}}>
                <Autocomplete
                    value={product}
                    width={230}
                    disabled={false}
                    options={productsOptions}
                    fontSize={fontSize} 
                    label={productType}
                    onChange={setProduct}
                    ></Autocomplete>
                <Autocomplete
                  value={cost_category}
                  width={230}
                  disabled={false}
                  options={costCategoriesOptions}
                  fontSize={fontSize} 
                  label="Categoria"
                  onChange={setCost_category}
                  ></Autocomplete>
                    <div style={{display:'flex', flexDirection:'row', minWidth:'330px', justifyContent:'space-between'}}>
                      <Numberfield
                        label="Quantidade*"
                        width={145}
                        disabled={false} 
                        value={product_amount} 
                        fontSize={fontSize} 
                        onChange={setProduct_amount}
                      ></Numberfield>
                      <Numberfield
                        label="Preço*"
                        width={145}
                        disabled={false} 
                        value={unity_price} 
                        fontSize={fontSize} 
                        onChange={setUnity_price}
                      ></Numberfield>
                  </div>
                  <Button
                    label="Adicionar" 
                    background="transparent" 
                    color="#256CE1" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={addProductToList}
                    ></Button>
                 </Row>
                  <Table 
                    columns={columns} 
                    rows={productsList || []} 
                    hasEditing={false} 
                    hasRemoving={true}
                    setSelectedItem={removeProductFromList}
                    height="auto" 
                  ></Table>
                  <Row style={{marginBottom: 16}}></Row>
             <Row><ListTitle>Pagamento:</ListTitle></Row>
             <Divider></Divider>
              <Row>
                <Autocomplete
                  value={paymentType}
                  width={280}
                  disabled={false}
                  options={paymentTypesOptions}
                  fontSize={fontSize} 
                  label="Tipo de pagamento*"
                  onChange={setPaymentType}
                  ></Autocomplete>
                <Numberfield
                    label="Parcela"
                    width={280}
                    disabled={!parcelsInput} 
                    value={amount_parcels} 
                    fontSize={fontSize} 
                    onChange={setamount_parcels}
                  ></Numberfield>
                  <Numberfield
                    label="Desconto"
                    width={280}
                    disabled={false} 
                    value={discount} 
                    fontSize={fontSize} 
                    onChange={setDiscount}
                  ></Numberfield>
              
                </Row>
                {renderParcelArea()}
              <Row style={{marginTop: 0}}>
              <Switch 
                label="Custo Indireto" 
                disabled={false} 
                value={is_indirect_cost} 
                fontSize={fontSize} 
                onChange={setIsIndirectCost}
                multiline={false}
                color="black"
                ></Switch>
              </Row>
              <Row style={{ marginBottom: 16}}>
                <Textfield
                    label="Observações"
                    width={'100%'}
                    disabled={false} 
                    value={notes} 
                    fontSize={fontSize} 
                    onChange={setNotes}
                    multiline={true}
                    ></Textfield>
                </Row>
                <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleOpen(false)}
                    ></Button>
                    <Button
                    label="Adicionar pedido" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={disableAddButton} 
                    onClick={handleCreateOrder}
                    ></Button>
                </Row>
        </Content>
            )
            
        }}

    return (
        <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleOpen(false)} alt="Fechar"></img>
          </Header>
          {renderContent()}
 
        </Container>
      </Modal>
       )
  }
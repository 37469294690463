import { useEffect, useState, useContext } from "react";
import Button from "../../../components/Button";
import Datepicker from "../../../components/Datepicker";
import { Container, Header, Row, Content, ListTitle, Divider } from "./styles";
import Modal from "@mui/material/Modal";
import { Close } from "../../../assets/icons/index";
import Spinner from "../../../components/Spinner";
import { UseOrders } from "../../../hooks/useOrders";
import moment from "moment";
import AppContext from "../../../state/App.context";
import ButtonUploadFile from "../../../components/ButtonUpload";
import Table from "../../../components/Table";
import { UseFiles } from "../../../hooks/useFiles";

const columnsFiles = [
  {
    name: "Nome do arquivo",
    key: "name",
    type: "string",
    unit: "",
  },
];

export default function ModalChangeMultiplesPaymentDates({
  open,
  selectedItems,
  setSelectedItems,
  width,
  height,
  setRefreshData,
}) {
  const { shortcutEditOrder } = UseOrders();
  const { uploadFilesToOrder } = UseFiles();
  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;
  const [newPaymentDate, setNewPaymentDate] = useState(new Date());
  const [files, setFiles] = useState([]);

  const title = "Mudar data de pagamento";
  const fontSize = 14;

  function cleanAllInputs() {
    setSelectedItems([]);
    setNewPaymentDate(new Date());
  }

  function handleClose() {
    setSelectedItems([]);
    cleanAllInputs();
    setRefreshData((prevIsRefreshData) => !prevIsRefreshData);
  }

  function addTimestampToArray(newElement, index, array, amount_parcels) {
    newElement = newElement || new Date();
    if (index === 0 && amount_parcels !== 0) {
      return (
        "timestamp '" + moment(newElement).format("YYYY-MM-DD HH:mm:ss") + "',"
      );
    }
    if (index === 0 && amount_parcels === 0) {
      return (
        "timestamp '" + moment(newElement).format("YYYY-MM-DD HH:mm:ss") + "'"
      );
    }
    if (index === amount_parcels - 1) {
      return (
        array +
        "timestamp '" +
        moment(newElement).format("YYYY-MM-DD HH:mm:ss") +
        "'"
      );
    }
    return (
      array +
      "timestamp '" +
      moment(newElement).format("YYYY-MM-DD HH:mm:ss") +
      "',"
    );
  }

  function addStringToArray(newElement, index, array, amount_parcels) {
    if (index === 0 && amount_parcels !== 0) {
      return "'" + newElement + "',";
    }
    if (index === 0 && amount_parcels === 0) {
      return "'" + newElement + "'";
    }
    if (index === amount_parcels - 1) {
      return array + "'" + newElement + "'";
    }
    return array + "'" + newElement + "',";
  }

  async function handleChangeExpirationDate(event) {
    try {
      setLoading(true);
      event.preventDefault();
      let ids_array = [];
      let status_arrays = [];
      let payment_date_arrays = [];
      let expiration_date_arrays = [];
      let total_order_value_exec_arrays = [];
      selectedItems.map((row) => {
        let amount_parcels = row?.amount_parcels;
        let status_array = "";
        let expiration_date_array = "";
        let payment_date_array = "";
        let new_payment_date = moment(newPaymentDate).add(3, "hours");
        let current_parcel_index = row?.current_parcel_name.split("/")[0] - 1;
        let total_order_value_exec_array = 0;
        let total_order_value_with_discount =
          row?.total_order_value - row?.discount;
        const status = "Executado";
        const expiration_date = moment(
          row?.expiration_date[current_parcel_index]
        ).add(3, "hours");
        if (amount_parcels === 0) {
          total_order_value_exec_array = total_order_value_with_discount;
          status_array = addStringToArray(
            status,
            0,
            status_array,
            amount_parcels
          );
          expiration_date_array = addTimestampToArray(
            expiration_date,
            0,
            expiration_date_array,
            amount_parcels
          );
          payment_date_array = addTimestampToArray(
            new_payment_date,
            0,
            payment_date_array,
            amount_parcels
          );
        } else {
          row?.status.map((status_item, index) => {
            let parcel_new_payment_date =
              index === current_parcel_index
                ? new_payment_date
                : row?.payment_date[index];
            let parcel_new_status =
              index === current_parcel_index ? "Executado" : row?.status[index];
            if (
              status_item === "Executado" ||
              parcel_new_status === "Executado"
            ) {
              total_order_value_exec_array =
                total_order_value_exec_array + row?.values_parcels[index];
            }
            status_array = addStringToArray(
              parcel_new_status,
              index,
              status_array,
              amount_parcels
            );
            expiration_date_array = addTimestampToArray(
              moment(row?.expiration_date[index]).add(3, "hours"),
              index,
              expiration_date_array,
              amount_parcels
            );
            payment_date_array = addTimestampToArray(
              parcel_new_payment_date,
              index,
              payment_date_array,
              amount_parcels
            );
          });
        }
        ids_array.push(row?.id_order);
        status_arrays.push(status_array);
        payment_date_arrays.push(payment_date_array);
        expiration_date_arrays.push(expiration_date_array);
        total_order_value_exec_arrays.push(total_order_value_exec_array);
      });
      const updatedOrders = {
        id_array: ids_array,
        status_array: status_arrays,
        expiration_date_array: expiration_date_arrays,
        payment_date_array: payment_date_arrays,
        total_order_value_exec_array: total_order_value_exec_arrays,
      };
      const response = await shortcutEditOrder(updatedOrders);
      if (response.success) {
        let errorFiles = false;
        let onlyFiles = [];
        if (files.length > 0) {
          files.map((file) => {
            onlyFiles.push(file?.file);
          });
        }
        ids_array.map(async (id) => {
          if (onlyFiles.length > 0) {
            const responseUploadFiles = await uploadFilesToOrder(id, onlyFiles);
            if (!responseUploadFiles.success) {
              errorFiles = true;
            }
          }
        });
        if (errorFiles) {
          setSnack({
            open: true,
            severity: "error",
            message:
              "Os pedidos foram registrados como pagos, porém houve erro ao anexar os arquivos. Vá individualmente em cada pedido e anexe os arquivos desejados.",
          });
        }
        handleClose();
        cleanAllInputs();
        setSnack({
          open: true,
          severity: "success",
          message: "Operação realizada com sucesso!",
        });
      } else {
        setSnack({
          open: true,
          severity: "error",
          message:
            "Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function createIdentifyString() {
    let text = "";
    selectedItems.map((item, index) => {
      text =
        " " +
        text +
        item?.providers_name +
        " [R$" +
        item?.current_parcel_value +
        "],";
    });
    return text;
  }

  function addFileToList(event) {
    try {
      let filesCopy = [...files];
      let selectedFiles = [...event.target.files];
      selectedFiles &&
        selectedFiles.map((file) => {
          filesCopy.push({
            name: file.name,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            size: file.size,
            type: file.type,
            webkitRelativePath: file.webkitRelativePath,
            file: file,
          });
        });
      setFiles(filesCopy);
    } catch (err) {
      console.log(err);
    }
  }

  function removeFileFromList(selectedItem) {
    try {
      let filesCopy = [...files];
      let index = filesCopy.findIndex(
        (element) => element.name === selectedItem.name
      );
      if (index > -1) {
        filesCopy.splice(index, 1);
      }
      setFiles(filesCopy);
    } catch (err) {
      console.log(err);
    }
  }

  function renderContent() {
    if (loading) {
      return <Spinner width={40} fontSize={14}></Spinner>;
    } else {
      return (
        <Content>
          <Row>
            <h1>
              Deseja definir os {selectedItems.length} itens (
              {createIdentifyString()}) como 'Executado' com data de pagamento
              em{" "}
              <b>
                {moment(newPaymentDate).add(3, "hours").format("DD/MM/YYYY")}
              </b>
              ?
            </h1>
          </Row>
          <Datepicker
            label={"Data de Pagamento"}
            width="100%"
            disabled={false}
            value={newPaymentDate}
            fontSize={fontSize - 2}
            onChange={(item) => setNewPaymentDate(item)}
          />
          <Row style={{ marginBottom: "1rem", justifyContent: "center" }}>
            <ButtonUploadFile
              disabled={false}
              onChange={addFileToList}
              multiple
              accept="*/*"
            ></ButtonUploadFile>
          </Row>
          <Table
            columns={columnsFiles}
            rows={files || []}
            hasEditing={false}
            hasRemoving={true}
            setSelectedItem={removeFileFromList}
            height="auto"
          ></Table>
          <Row>
            <Button
              label="Cancelar"
              background="#8E92BC"
              color="white"
              borderColor="#8E92BC"
              disabled={false}
              onClick={() => handleClose()}
            ></Button>
            <Button
              label="Salvar alterações"
              background="#256CE1"
              color="white"
              borderColor="#256CE1"
              disabled={false}
              onClick={handleChangeExpirationDate}
            ></Button>
          </Row>
        </Content>
      );
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>{title}</h1>
          <img src={Close} onClick={() => handleClose()} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  );
}

import {Container, Header, Row, Content, ListTitle, Divider, Print, PrintHeader, Section, RowInfo, SectionTitle} from './styles';
import { formatMoney } from '../../../services/functions';
import moment from 'moment';
import Table from '../../../components/Table';
import { columns_inputs, columns_outputs } from './options';
import Subsidiary from '../../../components/SubsidiaryReport';

const columns = [
    {
        name: 'Subsidiária',
        key: 'subsidiary_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Tipo',
        key: 'type',
        type: 'string',
        unit: '',
    },
    {
        name: 'Comp./Forn.',
        key: 'providers_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'C.C',
        key: 'costs_center_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Pagamento',
        key: 'paymenttype_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Parcela',
        key: 'current_parcel_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Valor (R$)',
        key: 'current_parcel_value',
        type: 'money',
        unit: '',
    },
    {
        name: 'Vencimento',
        key: 'current_parcel_expiration_date',
        type: 'date',
        unit: '',
    },
    {
        name: 'Pagamento',
        key: 'current_parcel_payment_date',
        type: 'date',
        unit: '',
    },
]

export default function ReportToPrint({allOrders, startDate, endDate, sumsInfo}){

    const {total, cash, manager} = sumsInfo;

    return (
        <Print id="report_1">
            <PrintHeader>
                <h4>Programação financeira</h4>
                <h3>Periodo: {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')} </h3>
                </PrintHeader>
              <Divider></Divider>
              <Row style={{width:'100%', display: 'grid', paddingLeft:0, gridTemplateColumns: '1fr 1fr 1fr', columnGap: 16}}> 
                <Section>
                <SectionTitle>Espécie</SectionTitle>
                    <RowInfo>
                        <h1>Saldo:</h1>
                        <h1 style={{color: cash?.balance > 0 ? "#03A03E" : "#FF4D5E"}}>{formatMoney(cash?.balance)}</h1>
                    </RowInfo>
                    <RowInfo>
                        <h1>Pagamentos:</h1>
                        <h1 style={{color: "#FF4D5E"}}>-{formatMoney(cash?.sum_resolved_outputs + cash?.sum_pending_outputs)}</h1>
                    </RowInfo>
                    <RowInfo>
                        <h1>Recebimentos:</h1>
                        <h1 style={{color: "#03A03E"}}>+{formatMoney(cash?.sum_resolved_inputs + cash?.sum_pending_inputs)}</h1>
                    </RowInfo>
                    <RowInfo>
                        <h1><b>Resultado:</b></h1>
                        <h1 style={{color: cash?.result > 0 ? "#03A03E" : "#FF4D5E"}}><b>{formatMoney(cash?.result)}</b></h1>
                    </RowInfo>
                </Section>
                <Section>
                    <SectionTitle>Gerenciador</SectionTitle>
                    <RowInfo>
                            <h1>Saldo:</h1>
                            <h1 style={{color: manager?.balance > 0 ? "#03A03E" : "#FF4D5E"}}>{formatMoney(manager?.balance)}</h1>
                        </RowInfo>
                        <RowInfo>
                            <h1>Pagamentos:</h1>
                            <h1 style={{color: "#FF4D5E"}}>-{formatMoney(manager?.sum_resolved_outputs + manager?.sum_pending_outputs)}</h1>
                        </RowInfo>
                        <RowInfo>
                            <h1>Recebimentos:</h1>
                            <h1 style={{color: "#03A03E"}}>+{formatMoney(manager?.sum_resolved_inputs + manager?.sum_pending_inputs)}</h1>
                        </RowInfo>
                        <RowInfo>
                            <h1><b>Resultado:</b></h1>
                            <h1 style={{color: manager?.result > 0 ? "#03A03E" : "#FF4D5E"}}><b>{formatMoney(manager?.result)}</b></h1>
                        </RowInfo>
                </Section>
                <Section>
            <SectionTitle>Total</SectionTitle>
            <RowInfo>
                    <h1>Saldo:</h1>
                    <h1 style={{color: total?.balance > 0 ? "#03A03E" : "#FF4D5E"}}>{formatMoney(total?.balance)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1>Pagamentos:</h1>
                    <h1 style={{color: "#FF4D5E"}}>-{formatMoney(total?.sum_resolved_outputs + total?.sum_pending_outputs)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1>Recebimentos:</h1>
                    <h1 style={{color: "#03A03E"}}>+{formatMoney(total?.sum_resolved_inputs + total?.sum_pending_inputs)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1><b>Resultado:</b></h1>
                    <h1 style={{color: total?.result > 0 ? "#03A03E" : "#FF4D5E"}}><b>{formatMoney(total?.result)}</b></h1>
                </RowInfo>
                </Section>
              </Row> 
              {/* <SectionTitle style={{width: '100%', marginTop: 16}}>Subsidiárias</SectionTitle> */}
              <br></br>
              {/* {
                subsidiaries.map((subsidiary, index) => {
                    return (
                        <Subsidiary key={index} subsidiary={subsidiary}></Subsidiary>
                    )
                })
              } */}
              <Table 
                  columns={columns} 
                  rows={allOrders} 
                  hasEditing={false} 
                  hasRemoving={false}
                  setSelectedItem={()=>{}} 
                  actionsInFirstLine={false}
                  height="auto"
                  fitWidth={false}
                ></Table>  
        </Print>
    )

}

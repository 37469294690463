

import { Container, Title, RowInfo, Divider, Content} from './styles'
import Spinner from '../Spinner';
import { formatMoney } from '../../services/functions';

export default function ResultsBoc({  trips , loading }) {
    let totalOutflow = 0;
    let totalInflow = 0;

    trips && trips.map((trip)=>{
        totalInflow = totalInflow + trip?.totalInflow;
        totalOutflow = totalOutflow + trip?.totalOutflow;
    })

    let result = totalInflow - totalOutflow;

    if(loading) {
        return (
            <Container>
                <Title>Balanço financeiro</Title>
                <Content>
                    <Spinner width={40} fontSize={0}></Spinner>
                </Content>
            </Container>
        )
        }
    return (
        <Container>
            <Title>Balanço financeiro</Title>
            <Content>
            <RowInfo style={{color:'#03A03E'}}>Receitas: {formatMoney(totalInflow)}</RowInfo>
            <RowInfo style={{color:'#FF4D5E'}}>Despesas: {formatMoney(totalOutflow)}</RowInfo>
            <Divider></Divider>
            <RowInfo style={{color: result > 0 ? '#03A03E' : '#FF4D5E'}} >Saldo: {formatMoney(result)}</RowInfo>
            </Content>
        </Container>
    )
}
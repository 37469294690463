import React, { useState } from "react";
import {
  TableContainer,
  TableHeader,
  TableRow,
  Icons,
  OrderIcon,
} from "./styles";
import { Remove, Edit, ArrowUp, ArrowDown } from "../../assets/icons/index"; // Renomeei os ícones
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { formatNumber, formatMoney } from "../../services/functions";

export default function Table({
  columns,
  rows,
  hasEditing,
  hasRemoving,
  setSelectedItem,
  height,
  actionsInFirstLine,
  fitWidth,
  defaultOrderKey,
  direction,
  onRowClick,
}) {
  const [sortConfig, setSortConfig] = useState({
    key: defaultOrderKey,
    direction: direction || "ascending",
  });

  function sortData(columnKey) {
    let direction = "ascending";
    if (sortConfig.key === columnKey && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: columnKey, direction });
  }

  function compareValues(key, order = "ascending") {
    return function (a, b) {
      const column = columns.find((col) => col?.key === key);
      const type = column?.type;
      let valueA = a[key];
      let valueB = b[key];
      if (type === "date") {
        valueA = moment(valueA);
        valueB = moment(valueB);
        if (valueA.isBefore(valueB)) {
          return order === "ascending" ? 1 : -1;
        } else if (valueB.isBefore(valueA)) {
          return order === "ascending" ? -1 : 1;
        } else {
          return 0;
        }
      }

      if (type === "string") {
        if (valueA > valueB) {
          return order === "ascending" ? 1 : -1;
        } else if (valueA < valueB) {
          return order === "ascending" ? -1 : 1;
        }
        return 0;
      } else if (type === "number" || type === "float" || type === "money") {
        return order === "ascending" ? valueA - valueB : valueB - valueA;
      } else {
        return 0;
      }
    };
  }

  function createDynamicStyle() {
    try {
      let elementToAdd = fitWidth ? "150px " : "1fr ";
      let styleString = "";
      columns.map((item) => {
        styleString = styleString + elementToAdd;
      });
      if (hasEditing || hasRemoving) {
        styleString = styleString + elementToAdd;
      }
      return styleString;
    } catch (err) {
      console.log(err);
    }
  }

  let columnsWidths = createDynamicStyle();

  function RenderActions({ rowIndex }) {
    if (hasEditing || hasRemoving) {
      return (
        <Icons key={rowIndex + "i"}>
          <Tooltip title="Editar" key="edit">
            <img
              src={Edit}
              alt="Editar"
              onClick={() => {
                setSelectedItem({
                  open: true,
                  mode: "edit",
                  ...rows[rowIndex],
                });
              }}
              style={{ display: hasEditing ? "flex" : "none" }}
            />
          </Tooltip>
          <Tooltip title="Remover" key="remove">
            <img
              src={Remove}
              alt="Remover"
              onClick={() => {
                setSelectedItem({
                  open: true,
                  mode: "remove",
                  ...rows[rowIndex],
                });
              }}
            />
          </Tooltip>
        </Icons>
      );
    } else {
      return <></>;
    }
  }

  function getElementColor(row, column, key) {
    if (column?.isColorful) {
      if (row[key] > 0) {
        return "#03A03E";
      } else {
        return "#FF4D5E";
      }
    } else {
      return "#54577A";
    }
  }

  function renderActionsRow(rowIndex) {
    return <RenderActions rowIndex={rowIndex}></RenderActions>;
  }

  function RenderRow({ rowIndex }) {
    const sortedRows = rows.sort(
      compareValues(sortConfig.key, sortConfig.direction)
    );
    return (
      <TableRow style={{ gridTemplateColumns: columnsWidths }} key={rowIndex}>
        {actionsInFirstLine && renderActionsRow(rowIndex)}
        {columns.map((column, index) => {
          const key = column.key;
          let value = rows[rowIndex][key];
          const unit = column.unit;
          if (value && column.formatter) {
            value = column.formatter(value);
          }
          if (column.type === "date") {
            if (value) {
              value = moment(value).add(3, "hours").format("DD/MM/YYYY");
            }
          }
          if (column.type === "dateWithoutYear") {
            if (value) {
              value = moment(value).add(3, "hours").format("DD/MM");
            }
          }
          if (column.type === "datetime") {
            if (value) {
              value = moment(value).format("DD/MM/YYYY [às] HH:mm");
            }
          }
          if (column.type === "boolean") {
            value = value ? "Sim" : "Não";
          }
          if (column.type === "money") {
            value = value ? formatMoney(value) : "R$ 0,00";
          }
          if (column.type === "float") {
            value = formatNumber(value);
          }
          if (column.type === "percent") {
            value = formatNumber(value * 100);
          }
          let valueToShow = value || value === 0 ? value + unit : "-";

          return (
            <Tooltip title={value + unit || "-"} key={index + "-" + rowIndex}>
              <h3
                key={index + "-" + rowIndex}
                style={{ color: getElementColor(rows[rowIndex], column, key) }}
                onClick={() => {
                  setSelectedItem({
                    open: true,
                    mode: "visualize",
                    item: { ...rows[rowIndex] },
                  });
                  if (onRowClick) {
                    onRowClick(rows[rowIndex]);
                  }
                }}
              >
                {valueToShow}
              </h3>
            </Tooltip>
          );
        })}
        {!actionsInFirstLine && renderActionsRow(rowIndex)}
      </TableRow>
    );
  }

  function RenderHeaderColumn(column) {
    const isSortable = column.sortable;
    return (
      <h3
        key={column.key}
        onClick={() => isSortable && sortData(column.key)}
        style={{ cursor: "pointer" }}
      >
        {sortConfig.key === column.key &&
          (sortConfig.direction === "ascending" ? (
            <OrderIcon src={ArrowUp} />
          ) : (
            <OrderIcon src={ArrowDown} />
          ))}
        {column.name}
      </h3>
    );
  }

  function renderActionsHeader() {
    if (hasEditing || hasRemoving) {
      return <h3>Ações</h3>;
    }
  }

  return (
    <TableContainer
      style={{
        width: fitWidth ? "fit-content" : "100%",
        height: height || "500px",
      }}
    >
      <TableHeader style={{ gridTemplateColumns: columnsWidths }}>
        {actionsInFirstLine && renderActionsHeader()}
        {columns && columns.map((column, index) => RenderHeaderColumn(column))}
        {!actionsInFirstLine && renderActionsHeader()}
      </TableHeader>
      {rows &&
        rows.length > 0 &&
        rows.map((row, index) => (
          <RenderRow key={index} rowIndex={index}></RenderRow>
        ))}
    </TableContainer>
  );
}

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const theme = createTheme({
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif',
    fontSize: 12,
    fontWeightRegular: 400,
  },
});

function PaginationComponent({page, totalPages, handleChangePage}) {
  return (
    <ThemeProvider theme={theme}>
      <Pagination count={totalPages} page={page} onChange={handleChangePage} />
    </ThemeProvider>
  );
}

export default PaginationComponent;



import { Container, Title, Bar, Progress, Value, TitleRow} from './styles'
import Tooltip from '@mui/material/Tooltip';

export default function ProgressBar({ title, percent, backgroundColor, barColor }) {
    return (
        <Container>
            <TitleRow>
                <Tooltip title="Porcentagem de transações executadas no período selecionado">
                    <Title>{title}</Title>
                </Tooltip>
            <Value>{percent}</Value>
            </TitleRow>
            <Bar style={{background:backgroundColor||'var(--light-blue)'}}>
                <Progress style={{width: percent, background:barColor||'var(--blue)'}}></Progress>
            </Bar>
        </Container>
    )
}
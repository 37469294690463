import { useEffect, useState, useContext} from "react";
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import { UseAccounts } from "../../../../hooks/useAccounts"
import {Container, Header, Row, Content} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import AppContext from "../../../../state/App.context";

export default function ModalEditAccount({open, setSelectedItem, width, height, selectedItem}){
    const { accounts, updateAccount } = UseAccounts();
    const [account_name, setName] = useState('');
    const [type, setType] = useState('');
    const [, setSnack] = useContext(AppContext).snackState;
    const fontSize = 14;
    const [loading, setLoading] = useState(true);

    function handleClose(){
        setSelectedItem({open: false, mode: '', account_name: '', id_account: null});
      }

     function getInfo(){
      try{
        const account = accounts.filter((element)=> element.id_account === selectedItem?.id_account)[0];
        setName(account.account_name);
        setType(account.type);
      }catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
    }

    useEffect(()=>{
      if (open) getInfo();
    },[open])

    const title = 'Editar conta';

    function checkingRequiredFields() {
      if (!account_name) {
        setSnack({
          open: true,
          severity: 'error', 
          message: 'Preencha todos os campos necessários!',
        });
        return false;
      }
      return true;
    }

    async function handleEditAccount(event){
      try{
        setLoading(true);
        event.preventDefault();
        if(checkingRequiredFields()){
        const response = await updateAccount(
            {
            id_account: selectedItem.id_account,
            account_name,
            type,
        }
        );
        if(response.success){
            handleClose();
            setSnack({
              open: true,
              severity: 'success', 
              message:response?.message,
            })
        } else {
          setSnack({
            open: true,
            severity: 'error', 
            message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
          })
        }
      }
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
       

     }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
          return (
            <Content>
             <Row>
                    <Textfield
                    label="Nome da conta*"
                    width={600}
                    disabled={false} 
                    value={account_name} 
                    fontSize={fontSize} 
                    onChange={setName}
                    multiline={false}
                    ></Textfield>
                </Row>
                <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=>handleClose()}
                    ></Button>
                    <Button
                    label="Salvar alterações" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={handleEditAccount}
                    ></Button>
                </Row>
        </Content>
          )
        }
  
      }
  
    return (
        <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-type"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleClose()} alt="Fechar"></img>
          </Header>
          {renderContent()}
   
        </Container>
      </Modal>
       )
  }
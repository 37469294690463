import React, {useState, useContext, useEffect} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UseTrips } from "../../../hooks/useTrips"
import { UseBoats } from "../../../hooks/useBoats"
import { UseFishingTypes } from "../../../hooks/useFishingTypes"
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import ModalAddTrip from './ModalAddTrip/index';
import ModalEditTrip from './ModalEditTrip/index';
import ModalRemoveTrip from './ModalRemoveTrip/index';
import ModalTripDetails from './ModalTripDetails';
import  Autocomplete  from  "../../../components/Autocomplete";
import moment from 'moment';
import AppContext from "../../../state/App.context";

  const columns = [
      {
      name: 'Status',
      key:'status',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Número',
      key:'trips_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Barco',
      key:'boats_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Safra',
      key:'harvest',
      type: 'number', 
      unit:'',
    },
    {
      name: 'Espécie alvo',
      key:'fishingtypes_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Saída',
      key:'begin_date',
      type: 'datetime', 
      unit:'',
    },
    {
      name: 'Chegada',
      key:'end_date',
      type: 'datetime', 
      unit:'',
    },
    {
      name: 'Qtd. dias',
      key:'qty_days',
      type: 'number', 
      unit:'',
    },
    {
      name: 'Produção',
      key:'production',
      type: 'float', 
      unit:'kg',
    },
    {
      name: 'Cons. M1',
      key:'oil_consumption_engine_1',
      type: 'float', 
      unit:'',
    },
    {
      name: 'Obs.',
      key:'notes',
      type: 'string', 
      unit:'',
    },
  ]

function Trips(){
  const { trips } = UseTrips();
  const {boats} = UseBoats();
  const {fishingTypes} = UseFishingTypes();
  const [search, setSearch] = useState('');
  const [openModalAddTrip, setOpenModalAddTrip] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState({open: false, mode: '', trips_name: '', id_trip: null});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;
  const [statusFilter, setStatusFilter] = useState({
    label: 'Todos',
    id: 0,
  });
  const [boatFilter, setBoatFilter] = useState({
    label: 'Todos',
    id: 0,
  });
  const [fishingTypeFilter, setFishingTypeFilter] = useState({
    label: 'Todos',
    id: 0,
  });

  useEffect(()=>{
    if(trips.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:trips.error,
      })
    }
  },[trips])

// Applying search
  let tripsToShow = trips.filter((trip)=> trip.isactive);

  if (search.length > 0) {
    tripsToShow = trips.filter(a => a.trips_name.toLowerCase().includes(search.toLowerCase()));
  }

  tripsToShow = tripsToShow.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
});

  tripsToShow =!tripsToShow.error && tripsToShow.map((trip)=>{
    let tripCopy = {... trip};
    let status = 'Fechada';
    let qty_days = 0;
    if(moment(tripCopy.begin_date).isAfter(tripCopy.end_date) || !tripCopy.end_date){
      delete tripCopy.end_date;
      status = 'Aberta';
    } else {
      qty_days = moment(tripCopy.end_date).diff(tripCopy.begin_date, 'days');
    }
    return {...tripCopy, status, qty_days};
  })


  // Apllying filters
  if (boatFilter.label !== 'Todos') {
    tripsToShow = tripsToShow.filter(a => a?.boats_name === boatFilter.label);
  }
  if (fishingTypeFilter.label !== 'Todos') {
    tripsToShow = tripsToShow.filter(a => a?.fishingtypes_name === fishingTypeFilter.label);
  }
  if (statusFilter.label !== 'Todos') {
    tripsToShow = tripsToShow.filter(a => a?.status === statusFilter.label);
  }
 

  let statusOptions = [
    {
      id: 1,
      label: 'Aberta',
    },
    {
      id: 2,
      label: 'Fechada',
    },
  ]

  let boatsOptions = [];
  boats && !boats.error && boats.map((element)=> {
    if(element.isactive){
      boatsOptions.push(
        {
          id: element?.id_boat,
          label: element?.boats_name,
        }
      )
    }
})

  let fishingTypesOptions = [];
   fishingTypes && !fishingTypes.error && fishingTypes.map((element)=> {
    if(element.isactive){
      fishingTypesOptions.push(
        {
          id: element?.id_fishingtype,
          label: element?.fishingtypes_name,
        }
      )
    }
})

function cleanFilters(){
  const default_1 = {
    label: 'Todos',
    id: 0,
  }
  setStatusFilter(default_1)
  setBoatFilter(default_1)
  setFishingTypeFilter(default_1)
}

  function Filters(){
    return(
      <div style={{marginTop: 16}}>
         <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Button 
            label="Limpar filtros" 
            background="transparent" 
            color="#54577A" 
            borderColor="#54577A" 
            disabled={false} 
            onClick={()=> {cleanFilters()}}
            ></Button>
          </div>
          <Autocomplete
          value={statusFilter}
          width={200}
          disabled={false}
          options={[{label: 'Todos', id: 0}, ... statusOptions]}
          fontSize={'0.7rem'} 
          label="Status"
          onChange={setStatusFilter}
          ></Autocomplete>
         <br></br>
        <Autocomplete
          value={boatFilter}
          width={200}
          disabled={false}
          options={[{label: 'Todos', id: 0}, ... boatsOptions]}
          fontSize={'0.7rem'} 
          label="Barco"
          onChange={setBoatFilter}
          ></Autocomplete>
          <br></br>
           <Autocomplete
          value={fishingTypeFilter}
          width={200}
          disabled={false}
          options={[{label: 'Todos', id: 0}, ... fishingTypesOptions]}
          fontSize={'0.7rem'} 
          label="Espécie Alvo"
          onChange={setFishingTypeFilter}
          ></Autocomplete>
      </div>
    )
  }

    return (
      <Container>
        <Header title="Viagens" subtitle="Visualize e gerencie todas as viagens realizadas" />
        <Content>
        <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer>
          <Row>
            <Button
              label="Adicionar viagem" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddTrip(true)}
             ></Button>
              <Button 
              label="Filtros" 
              background="transparent" 
              color="#54577A" 
              borderColor="#54577A" 
              disabled={false} 
              onClick={()=> setOpenDrawer(true)}
              ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar número da viagem..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={tripsToShow || []} 
            hasEditing={true} 
            hasRemoving={true}
            setSelectedItem={setSelectedTrip} 
            height='100%'
          ></Table>
        </Content>
        <ModalAddTrip
         open={openModalAddTrip} 
         handleOpen={setOpenModalAddTrip} 
         width={800} 
         height={670} 
         ></ModalAddTrip>
         <ModalEditTrip
          open={selectedTrip.open && selectedTrip.mode === 'edit'} 
          setSelectedItem={setSelectedTrip} 
          selectedItem={selectedTrip}
          width={700} 
          height={670}
        ></ModalEditTrip>
        <ModalRemoveTrip
          open={selectedTrip.open && selectedTrip.mode === 'remove'} 
          setSelectedItem={setSelectedTrip} 
          tripName={selectedTrip?.trips_name}
          tripId={selectedTrip?.id_trip}
          width={600} 
          height={160} 
        ></ModalRemoveTrip>
           <ModalTripDetails
          open={selectedTrip.open && selectedTrip.mode === 'visualize'} 
          setSelectedItem={setSelectedTrip} 
          tripName={selectedTrip?.trips_name}
          tripId={selectedTrip?.id_trip}
          width={700} 
          height={670}
          selectedItem={selectedTrip}
        ></ModalTripDetails>
      </Container>
    );
  };

  export default React.createElement(Trips);


import {useState} from 'react';
import { Container, Title, Graph, RowSelect, TooltipBox} from './styles'
import { BarChart, XAxis, YAxis, Tooltip, Bar, Legend, ResponsiveContainer} from 'recharts'
import Spinner from '../Spinner';
import { formatMoney } from '../../services/functions'; 

export default function BoatsGraph({ trips,  loading }) {
  let data = [];
  trips.map((trip)=>{
    data.push(
      {
        name: trip.boats_name,
        total: trip?.totalInflow + trip?.totalOutflow,
        Entradas: trip?.totalInflow,
        Saidas: trip?.totalOutflow,
        Entradas_Executadas: trip?.total_exec_credit, 
        Entradas_Pendentes: trip?.total_pend_credit, 
        Saidas_Executadas: trip?.total_exec_pay,
        Saidas_Pendentes: trip?.total_pend_pay,

      }
    )
  })


    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload) {
        return (
          <TooltipBox>
            <p className="label">{`${label}`}</p>
            <p className="value" style={{color:'var(--subtitle)'}}>Entradas: {formatMoney(payload[0]?.payload?.Entradas)}</p>
            <p className="value" style={{color:payload[0].fill }}>Executado: {formatMoney(payload[0]?.payload?.Entradas_Executadas)}</p>
            <p className="value" style={{color:payload[1].fill }}>Pendente: {formatMoney(payload[0]?.payload?.Entradas_Pendentes)}</p>
            <p className="value" style={{color:'var(--subtitle)' }}>Saídas: {formatMoney(payload[1]?.payload?.Saidas)}</p>
            <p className="value" style={{color:payload[2].fill }}>Executado: {formatMoney(payload[1]?.payload?.Saidas_Executadas)}</p>
            <p className="value" style={{color:payload[3].fill }}>Pendente: {formatMoney(payload[1]?.payload?.Saidas_Pendentes)}</p>
          </TooltipBox>
        );
      }
    
      return null;
    };

    function renderGraph(){
      if(loading) {
        return (
          <Spinner width={40} fontSize={0}></Spinner>
        )
      } 
        return (
            <Graph>
            <ResponsiveContainer width="100%" height={150} >
            <BarChart data={data}>
                <XAxis dataKey="name" tickSize={12} tick={{fontSize:12}}/>
                <YAxis tick={{fontSize:12}} tickFormatter={(element)=> element/1000 + 'k'}/>
                <Tooltip content={<CustomTooltip />} />
                <Legend 
                    layout="horizontal" 
                    align="center" 
                    verticalAlign='bottom'
                    iconSize={12}
                    wrapperStyle={{fontSize: 12, marginTop: 8}}
                    ></Legend>
                <Bar dataKey="Entradas_Executadas" stackId="ent" fill="#03A03E" />
                <Bar dataKey="Entradas_Pendentes" stackId="ent" fill="#65bc85" />
                <Bar dataKey="Saidas_Executadas" stackId="saidas" fill="#FF4D5E" />
                <Bar dataKey="Saidas_Pendentes" stackId="saidas" fill="#ff919c" />

            </BarChart>
            </ResponsiveContainer>
            </Graph>
        )
    }


    return (
        <Container>
            <RowSelect>
            <Title>Despesas e faturamento por barco</Title>
            </RowSelect>
            {renderGraph()}
        </Container>
    )
}


import { Container, Title, RowInfo, Divider, Content, Value} from './styles'
import Spinner from '../Spinner';

export default function ValueBox({  loading, title, value, color, small }) {
    if(loading) {
        return (
            <Container>
                <Title>{title}</Title>
                <Content>
                    <Spinner width={40} fontSize={0}></Spinner>
                </Content>
            </Container>
        )
        }
    return (
        <Container>
            <Title>{title}</Title>
            <Value style={{color, fontSize: small ? '1rem' : '1.5rem'}}>{value}</Value>
        </Container>
    )
}
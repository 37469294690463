

import { Container, Icon, Info } from './styles';

interface BoxButtonProps{
    icon:  string;
    title: string;
    explanation: string;
    path: string;
    show: boolean;
}

export default function BoxButton({icon, title, explanation, path, show}: BoxButtonProps){
    if(show) {
        return (
        <Container to={path}>
            <Icon src={icon} alt={title}></Icon>
            <Info>
                <h1> {title} </h1>
                <h2> {explanation} </h2>
            </Info>
        </Container>
    )
        }
        return <></>
}
import styled from 'styled-components';

export const Container = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background: #FFFFFF;
border-radius: 10px;
box-shadow: 24px;
padding:16px 24px;
max-height: 90vh;
overflow: auto;
height: auto;
// width: auto !important;
    ` ;

export const Header  = styled.div`
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DCE4FF;
    position: relative;
    padding-bottom: 8px;
    h1{
        font-weight: 600;
        font-size: 1rem !important;
        color: var(--txt-title);
        text-align: center;
    };
    img {
        height: 1.2rem;
        position: absolute;
        right: 0px;
        cursor: pointer;
        transition: transform .2s;
        &:hover {
            transform: scale(1.05);
          }
    }
` ;

export const Content = styled.div`
height: auto;
width: 100%;
padding: 9px 0px;
display: flex;
flex-direction: column;
// overflow-y: auto;
// height: 100%;
// max-height: 80vh;
`;

export const Row = styled.div`
width: auto;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-top: 16px;
padding-left: 0px;
h4{
    font-size: 0.8rem !important;
    font-weight: 400;
    text-align: left;
    // width: 33.33%;
    b{
        font-weight: 500;
    }
}
`;

export const ListTitle  = styled.h1`
    font-weight: 600;
    font-size: 0.8rem !important;
    color: var(--txt-title);
    text-align: left;
` ;

export const List = styled.div`
height: auto;
width: 100%;
padding: 9px 24px;
display: flex;
flex-direction: column;
// align-items: end;
// justify-content: end;
li{
    font-weight: 600;
    font-size: 0.9rem !important;
    color: var(--txt-subtitle);
    text-align: left;
    padding:9px 0px;
};
`;
export const Divider = styled.div`
color: var(--txt-subtitle);
width: 100%;
height: 0px;
border: 0.05px solid rgba(84, 87, 122, 0.4);
margin-top: 8px;
margin-bottom: 8px;
opacity:0.3;
`;

export const Explanation  = styled.h1`
    font-weight: 400;
    font-size: 0.8rem !important;
    color: var(--light-grey);
    text-align: center;
    margin-bottom: 0.8rem;
` ;
import { Container, Header, Content } from "./styles";
import Modal from "@mui/material/Modal";
import { Close } from "../../../assets/icons/index";
import ExcelExport from "../../../components/SimulationExcel";

export default function ModalSimulationExcel({
  open,
  handleOpen,
  data,
  startDate,
  endDate,
}) {
  return (
    <Modal
      open={open}
      onClose={() => handleOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-type"
    >
      <Container>
        <Header>
          <h1>Gerar planilha de simulação</h1>
          <img src={Close} onClick={() => handleOpen(false)} alt="Fechar"></img>
        </Header>
        <Content>
          <h2>Deseja gerar a planilha de simulação?</h2>
          <ExcelExport data={data} startDate={startDate} endDate={endDate} />
        </Content>
      </Container>
    </Modal>
  );
}

import  { useState, useContext, useEffect} from "react";
import {
   Container,
   Box,
   LogoRow,
   LogoImg,
   Row,
   RowButton,
   Explanation,
   PasswordRequirements,
   Requirement,
  } from './styles';
  import { LogoLogin} from '../../assets/images/index';
  import TextfieldDark from "../../components/TextfieldDark";
  import Button from "../../components/Button";
  import { useNavigate } from "react-router-dom";
  import LocalStorageService from '../../services/storage';
  import AppContext from "../../state/App.context";
  import { UseUsers } from "../../hooks/useUsers";
  import Switch from "../../components/Switch";


export default function RegisterUser(){
  const { authenticateUser, registerUser } = UseUsers();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [company, setCompany] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLetterAmt: false,
    oneUpperCaseLetter: false,
    oneLowerCaseLetter: false,
    oneSpecialLetter: false,
    matchingPasswords: false,
  })
  const fontSize = 14;
  let navigate = useNavigate();
  const localStorageService = LocalStorageService();
  const [, setSnack] = useContext(AppContext).snackState;

  async function verifyingCredentials(){
    try{
      const responseLogin = await authenticateUser({e_mail: email, user_password: password});
      if(responseLogin.success){
        localStorageService.setToken({token: responseLogin?.data?.token});
        localStorageService.setUserInfo({email, id_user: responseLogin?.data?.id_user, user_role:responseLogin?.data?.user_role, company_name: responseLogin?.data?.company_name, fk_companies_id_company: responseLogin?.data?.fk_companies_id_company });
        setSnack({
          open: true,
          severity: 'success', 
          message:responseLogin?.message,
        });
        navigate('/orders');
      } else {
        setSnack({
          open: true,
          severity: 'error', 
          message:responseLogin?.message,
        })
      }
    } catch(err){
      console.log(err);
    }
  }
  
  async function registeringUser(){
    try{
      const responseRegister = await registerUser({e_mail: email, user_password: password, company_name: company});
      if(responseRegister.success){
        setSnack({
          open: true,
          severity: 'success', 
          message:responseRegister?.message,
        });
        verifyingCredentials();
      } else {
        setSnack({
          open: true,
          severity: 'error', 
          message:responseRegister?.message,
        })
      }
    } catch(err){
      console.log(err);
    }
  }

  function verifyRequirements(){
    try{
      let requirementsCopy = {... passwordRequirements};
      requirementsCopy.minLetterAmt = password.length > 7;
      requirementsCopy.oneLowerCaseLetter =  /[a-z]/.test(password);
      requirementsCopy.oneUpperCaseLetter =  /[A-Z]/.test(password);
      requirementsCopy.oneSpecialLetter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
      requirementsCopy.matchingPasswords = password === confirmPassword;
      setPasswordRequirements(requirementsCopy);
    } catch(err){
      console.log(err);
    }

  }

  function checkPassword() {
    for (let prop in passwordRequirements) {
      if (passwordRequirements.hasOwnProperty(prop) && !passwordRequirements[prop]) {
        return false;
      }
    }
    return true;
  }

  useEffect(()=>{
    verifyRequirements();

  }, [password, confirmPassword])

    return (
      <Container>
        <Box>
        {/* <LogoRow>
          <LogoImg src={LogoLogin} alt='logo' />
        </LogoRow> */}
        {/* <Row>
          <Explanation>
            Se você já foi pré-cadastrado, crie sua conta para começar a utilizar o sistema.
          </Explanation>
        </Row> */}
        <Row>
        <TextfieldDark
            label="Empresa"
            width={400}
            disabled={false} 
            value={company} 
            fontSize={fontSize} 
            onChange={setCompany}
            multiline={false}
            password={false}
            ></TextfieldDark>
          </Row>
        <Row>
          <TextfieldDark
            label="E-mail"
            width={400}
            disabled={false} 
            value={email} 
            fontSize={fontSize} 
            onChange={setEmail}
            multiline={false}
            password={false}
            ></TextfieldDark>
          </Row>
          <Row>
            <TextfieldDark
              label="Senha"
              width={400}
              disabled={false} 
              value={password} 
              fontSize={fontSize} 
              onChange={setPassword}
              multiline={false}
              password={!showPassword}
            ></TextfieldDark>
        </Row>
        <Row>
            <TextfieldDark
              label="Confirmar senha"
              width={400}
              disabled={false} 
              value={confirmPassword} 
              fontSize={fontSize} 
              onChange={setConfirmPassword}
              multiline={false}
              password={!showPassword}
            ></TextfieldDark>
        </Row>
        <Row style={{width: 400, justifyContent:'flex-start', alignItems:'flex-start'}}>
          <Switch 
            label="Mostrar senha" 
            disabled={false} 
            value={showPassword} 
            fontSize={fontSize} 
            onChange={setShowPassword}
            multiline={false}
            color="white"
            ></Switch>
          </Row>
          <PasswordRequirements style={{display: password ? 'flex' : 'none'}}>
            <Requirement style={{color: passwordRequirements.minLetterAmt ? '#03A03E' : '#FF4D5E'}}>- Mínimo de 8 caracteres</Requirement>
            <Requirement style={{color: passwordRequirements.oneUpperCaseLetter ? '#03A03E' : '#FF4D5E'}}>- Pelo menos 1 (uma) letra minúscula</Requirement>
            <Requirement style={{color: passwordRequirements.oneLowerCaseLetter ? '#03A03E' : '#FF4D5E'}}>- Pelo menos 1 (uma) letra maíscula</Requirement>
            <Requirement style={{color: passwordRequirements.oneSpecialLetter ? '#03A03E' : '#FF4D5E'}}>- Pelo menos 1 (um) caracter especial</Requirement>
            <Requirement style={{color: passwordRequirements.matchingPasswords ? '#03A03E' : '#FF4D5E'}}>- As senhas são compatíveis</Requirement>
          </PasswordRequirements>
        <RowButton>
        <Button
          label="Criar conta" 
          background="#FFFFFF" 
          color="#141522" 
          borderColor="#FFFFFF" 
          disabled={!checkPassword()} 
          onClick={()=> {registeringUser()}}
          ></Button>
        </RowButton>
        <RowButton style={{ marginTop: '4%'}}>
          <Button
            label="Voltar para o login" 
            background="#141522" 
            color="#FFFFFF" 
            borderColor="#FFFFFF" 
            disabled={false} 
            onClick={()=> { navigate('/')}}
            ></Button>
        </RowButton>
        </Box>
      </Container>
    );
  };
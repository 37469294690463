

import React, { useState } from 'react'
import { Container, Title, TitleRow, Row, Subtitle, SubtitleRow, Content, Values, RowContent, Tabs } from './styles';
import { ArrowUp, ArrowDown } from '../../assets/icons';
import moment from 'moment';
import { formatMoney, formatNumber, getRandomColor, adjustCloseToZero } from '../../services/functions';
import Table from '../TableTrip';
import ModalVisualizeOrder from './ModalVisualizeOrder';
import ModalVisualizeOrderSalary from './ModalVisualizeOrderSalary';

const columns = [
    {
        name: 'Produto',
        key: 'name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Comprador',
        key: 'providers_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Vendido (unid.)',
        key: 'qty',
        type: 'float',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'qty_exec',
        type: 'float',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'qty_pend',
        type: 'float',
        unit: '',
    },
    // {
    //     name: 'Comprado (%)',
    //     key: 'percent',
    //     type: 'float',
    //     unit: '%',
    // },
    {
        name: 'Vendido (R$)',
        key: 'total_value',
        type: 'money',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'total_value_exec',
        type: 'money',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'total_value_pend',
        type: 'money',
        unit: '',
    },
    {
        name: 'Preço Médio',
        key: 'average_price',
        type: 'money',
        unit: '/unid.',
    },
]

const columnsOutputs = [
    {
        name: 'Insumo',
        key: 'name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Fornecedor',
        key: 'providers_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Comprado (unid.)',
        key: 'qty',
        type: 'float',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'qty_exec',
        type: 'float',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'qty_pend',
        type: 'float',
        unit: '',
    },
    // {
    //     name: 'Comprado (%)',
    //     key: 'percent',
    //     type: 'float',
    //     unit: '%',
    // },
    {
        name: 'Comprado (R$)',
        key: 'total_value',
        type: 'money',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'total_value_exec',
        type: 'money',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'total_value_pend',
        type: 'money',
        unit: '',
    },
    {
        name: 'Preço Médio',
        key: 'average_price',
        type: 'money',
        unit: '/unid.',
    },
]

const columnsSalarys = [
    {
        name: 'Funcionário',
        key: 'name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Valor (R$)',
        key: 'total_value',
        type: 'money',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'total_value_exec',
        type: 'money',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'total_value_pend',
        type: 'money',
        unit: '',
    },
]

const tabsStates = [
    {
        id: 'inputs',
        label: 'Entradas',
    },
    {
        id: 'selling-expenses',
        label: 'Desp. Venda',
    },
    {
        id: 'direct-outputs',
        label: 'Saídas Diretas',
    },
    {
        id: 'indirect-outputs',
        label: 'Saídas Indiretas',
    },
    {
        id: 'direct-salarys',
        label: 'Remuneração Direta',
    },
    {
        id: 'indirect-salarys',
        label: 'Remuneração Indireta',
    },
]

const columnsSellingExpenses = [
    {
        name: 'Item',
        key: 'name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Fornecedor',
        key: 'providers_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Comprado (unid.)',
        key: 'qty',
        type: 'float',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'qty_exec',
        type: 'float',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'qty_pend',
        type: 'float',
        unit: '',
    },
    {
        name: 'Comprado (R$)',
        key: 'total_value',
        type: 'money',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'total_value_exec',
        type: 'money',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'total_value_pend',
        type: 'money',
        unit: '',
    },
    {
        name: 'Preço Médio',
        key: 'average_price',
        type: 'money',
        unit: '/unid.',
    },
]

export default function Trip({ trip }) {
    const [expanded, setExpanded] = useState(false);
    const [tab, setTab] = useState(tabsStates[0].id);
    const [modalVisualizeOrder, setModalVisualizeOrder] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState('');
    const icon = expanded ? ArrowUp : ArrowDown;
    const resultExec = parseFloat(trip?.total_exec_credit) - parseFloat(trip?.total_exec_pay);
    const resultPend = parseFloat(trip?.total_pend_credit) - parseFloat(trip?.total_pend_pay);

    const colors = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#f1c40f', '#e67e22', '#e74c3c', '#95a5a6'];
    let products = [];
    let directSupplies = [];
    let indirectSupplies = [];
    let directSalarys = [];
    let indirectSalarys = [];
    let sellingExpenses = [];
    let itensToShow = [...trip.products];
    let salarysToShow = trip.salarys ? [...trip.salarys] : [];
    let totalValueExpenses = 0;
    let averageExpensesPerKg = 0;

    itensToShow && itensToShow.forEach((product, index) => {
        if (product.type === 'Entrada') {
            let color = index < 9 ? colors[index] : getRandomColor();
            let percent = product?.total_value_exec * 100 / trip?.totalInflow;
            let average_price = (product?.total_value_exec + product?.total_value_pend) / (product?.qty_exec + product?.qty_pend);
            let average_price_exec = product?.total_value_exec / product?.qty_exec;
            let average_price_pend = product?.total_value_pend / product?.qty_pend;
            let qty = product?.qty_exec + product?.qty_pend;
            let total_value = product?.total_value_exec + product?.total_value_pend;
            let total_value_pend = adjustCloseToZero(product?.total_value_pend);
            let total_value_exec = adjustCloseToZero(product?.total_value_exec);

            products.push({
                ...product, percent, color, average_price, average_price_pend, average_price_exec, qty, total_value, total_value_pend, total_value_exec,
            });
        } else {
            if (product?.selling_orders === null) {
                let color = index < 9 ? colors[index] : getRandomColor();
                let percent = product?.total_value_exec * 100 / trip?.totalOutflow;
                let average_price = (product?.total_value_exec + product?.total_value_pend) / (product?.qty_exec + product?.qty_pend);
                let qty = product?.qty_exec + product?.qty_pend;
                let total_value = product?.total_value_exec + product?.total_value_pend;
                let total_value_pend = adjustCloseToZero(product?.total_value_pend);
                let total_value_exec = adjustCloseToZero(product?.total_value_exec);
                
                if (product?.is_indirect_cost) {
                    indirectSupplies.push({
                        ...product, percent, color, average_price, qty, total_value, total_value_pend, total_value_exec
                    });
                } else {
                    directSupplies.push({
                        ...product, percent, color, average_price, qty, total_value, total_value_pend, total_value_exec
                    });
                }
            } else {
                let color = index < 9 ? colors[index] : getRandomColor();
                let percent = product?.total_value_exec * 100 / trip?.totalOutflow;
                let average_price = (product?.total_value_exec + product?.total_value_pend) / (product?.qty_exec + product?.qty_pend);
                let qty = product?.qty_exec + product?.qty_pend;
                let total_value = product?.total_value_exec + product?.total_value_pend;
                let total_value_pend = adjustCloseToZero(product?.total_value_pend);
                let total_value_exec = adjustCloseToZero(product?.total_value_exec);
                totalValueExpenses += total_value;

                sellingExpenses.push({
                    ...product, percent, color, average_price, qty, total_value, total_value_pend, total_value_exec
                });
            }
        }
    });

    if (sellingExpenses.length > 0) {
        averageExpensesPerKg = (totalValueExpenses / trip?.totalSale) || 0;
    }

    salarysToShow && salarysToShow.forEach((product, index) => {
        let color = index < 9 ? colors[index] : getRandomColor();
        let percent = product?.total_value_exec * 100 / trip?.totalOutflow;
        let average_price = (product?.total_value_exec + product?.total_value_pend) / (product?.qty_exec + product?.qty_pend);
        let qty = product?.qty_exec + product?.qty_pend;
        let total_value = product?.total_value_exec + product?.total_value_pend;
        let total_value_pend = adjustCloseToZero(product?.total_value_pend);
        let total_value_exec = adjustCloseToZero(product?.total_value_exec);
        
        if (product?.is_indirect_cost) {
            indirectSalarys.push({
                ...product, percent, color, average_price, qty, total_value, total_value_pend, total_value_exec
            });
        } else {
            directSalarys.push({
                ...product, percent, color, average_price, qty, total_value, total_value_pend, total_value_exec
            });
        }
    });

    let totalValueDirectSupplies = directSupplies.reduce((acumulator, supplies) => acumulator + supplies.total_value, 0);
    let totalValueIndirectSupplies = indirectSupplies.reduce((acumulator, supplies) => acumulator + supplies.total_value, 0);
    let totalValueDirectSalarys = directSalarys.reduce((acumulator, salarys) => acumulator + salarys.total_value, 0);
    let totalValueIndirectSalarys = indirectSalarys.reduce((acumulator, salarys) => acumulator + salarys.total_value, 0);

    function renderTable() {
        switch (tab) {
            case 'inputs':
                return (
                    <Table
                        columns={columns}
                        rows={products || []}
                        hasEditing={false}
                        hasRemoving={false}
                        setSelectedItem={() => { }}
                        height="100%"
                        actionsInFirstLine={false}
                        fitWidth={false}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        setModalVisualizeOrder={setModalVisualizeOrder}
                    ></Table>
                );
            case 'selling-expenses':
                return (
                    <Table
                        columns={columnsSellingExpenses}
                        rows={sellingExpenses || []}
                        hasEditing={false}
                        hasRemoving={false}
                        setSelectedItem={() => { }}
                        height="100%"
                        actionsInFirstLine={false}
                        fitWidth={false}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        setModalVisualizeOrder={setModalVisualizeOrder}
                    ></Table>
                );
            case 'direct-outputs':
                return (
                    <Table
                        columns={columnsOutputs}
                        rows={directSupplies || []}
                        hasEditing={false}
                        hasRemoving={false}
                        setSelectedItem={() => { }}
                        height="100%"
                        actionsInFirstLine={false}
                        fitWidth={false}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        setModalVisualizeOrder={setModalVisualizeOrder}
                    ></Table>
                );
            case 'indirect-outputs':
                return (
                    <Table
                        columns={columnsOutputs}
                        rows={indirectSupplies || []}
                        hasEditing={false}
                        hasRemoving={false}
                        setSelectedItem={() => { }}
                        height="100%"
                        actionsInFirstLine={false}
                        fitWidth={false}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        setModalVisualizeOrder={setModalVisualizeOrder}
                    ></Table>
                );
            case 'direct-salarys':
                return (
                    <Table
                        columns={columnsSalarys}
                        rows={directSalarys || []}
                        hasEditing={false}
                        hasRemoving={false}
                        setSelectedItem={() => { }}
                        height="100%"
                        actionsInFirstLine={false}
                        fitWidth={false}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        setModalVisualizeOrder={setModalVisualizeOrder}
                    ></Table>
                );
            case 'indirect-salarys':
                return (
                    <Table
                        columns={columnsSalarys}
                        rows={indirectSalarys || []}
                        hasEditing={false}
                        hasRemoving={false}
                        setSelectedItem={() => { }}
                        height="100%"
                        actionsInFirstLine={false}
                        fitWidth={false}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        setModalVisualizeOrder={setModalVisualizeOrder}
                    ></Table>
                );
            default:
                return null;
        }
    }

    return (
        <Container style={{ height: 'auto', justifyContent: expanded ? 'flex-start' : 'center' }}>
            <Row>
                <TitleRow>
                    <img src={icon} alt={'Expandir'} style={{ cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}></img>
                    <Title>{trip?.boats_name}</Title>
                </TitleRow>
                <SubtitleRow>
                    <Subtitle>Saída: {moment(trip?.begin_date).format('DD/MM/YYYY')}</Subtitle>
                    <Subtitle>Chegada: {trip?.days?.days ? moment(trip?.end_date).format('DD/MM/YYYY') : 'Em aberto'}</Subtitle>
                    <Subtitle >Saldo Executado: {formatMoney(resultExec)}</Subtitle>
                    <Subtitle >Saldo Pendente: {formatMoney(resultPend)}</Subtitle>
                    <Subtitle style={{ color: 'var(--txt-title)', fontWeight: 'bold' }} >Saldo Total: {formatMoney(resultPend + resultExec)}</Subtitle>
                </SubtitleRow>
            </Row>
            <Row style={{ display: expanded ? 'flex' : 'none', marginTop: 0, width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <RowContent style={{ marginTop: 16, justifyContent: 'space-evenly'}}>
                        <Content>
                            <Values>Espécie Alvo: {trip?.fishingtypes_name}</Values>
                            <Values>Quantidade de dias: {trip?.days?.days}</Values>
                            <Values>Produção total: {formatNumber(trip?.production)} kg</Values>
                            <Values>Venda total: {formatNumber(parseFloat(trip?.totalSale))} kg</Values>
                            <Values>Desperdício:  {formatNumber(parseFloat(trip?.waste))} %</Values>
                        </Content>
                        <Content>
                            <Values>Óleo: {formatNumber(parseFloat(trip?.oil_consumption || 0))} L | {formatNumber(parseFloat(trip?.daily_oil_consumption || 0))} L/dia</Values>
                            <Values>Preço Médio: {formatMoney(trip?.average_price)}/kg</Values>
                            <Values>Desp. Venda: {formatMoney(trip?.total_pay_sale || 0)}</Values>
                            <Values>Despesa Média: {formatMoney(averageExpensesPerKg)}/Kg</Values>
                            <Values>Resultado Médio: {formatMoney((trip?.totalSale !== 0) ? (trip?.totalInflow - totalValueExpenses)/trip?.totalSale : 0)}/kg</Values>
                        </Content>
                        <Content >
                            <Values>Entradas: {formatMoney(trip?.totalInflow)}</Values>
                            <Values style={{ textIndent: '0.5em' }}>Executado: <h1 style={{ color: "var(--green)" }}>{formatMoney(trip?.total_exec_credit)}</h1></Values>
                            <Values style={{ textIndent: '0.5em' }}>Pendente: <h1 style={{ color: "#65bc85" }}>{formatMoney(trip?.total_pend_credit)}</h1></Values>
                        </Content>
                        <Content >
                            <Values>Saídas: {formatMoney(trip?.totalOutflow)}</Values>
                            <Values style={{ textIndent: '0.5em' }}>Executado: <h1 style={{ color: "var(--red)" }}>{formatMoney(trip?.total_exec_pay)}</h1></Values>
                            <Values style={{ textIndent: '0.5em' }}>Pendente: <h1 style={{ color: "#ff919c" }}>{formatMoney(trip?.total_pend_pay)}</h1></Values>
                        </Content>
                        <Content>
                            <Values>Pedidos: {formatMoney(trip?.totalOutflow - trip?.totalOutflowSalary)}</Values>
                            <Values style={{ textIndent: '0.5em' }}>Diretos: <h1>{formatMoney(totalValueDirectSupplies)}</h1></Values>
                            <Values style={{ textIndent: '0.5em' }}>Indiretos: <h1>{formatMoney(totalValueIndirectSupplies)}</h1></Values>
                        </Content>
                        <Content>
                            <Values>Remuneração: {formatMoney(trip?.totalOutflowSalary)}</Values>
                            <Values style={{ textIndent: '0.5em' }}>Direta: {formatMoney(totalValueDirectSalarys)}</Values>
                            <Values style={{ textIndent: '0.5em' }}>Inireta: {formatMoney(totalValueIndirectSalarys)}</Values>
                        </Content>
                    </RowContent>
                    <Row>
                        <Tabs style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr' }}>
                            {
                                tabsStates.map((state, index) => {
                                    if (state.id === tab) {
                                        return (
                                            <h4 style={{
                                                color: '#256CE1', borderBottom: '1px solid #256CE1', fontWeight: 600
                                            }} onClick={() => setTab(state.id)} key={index}>{state?.label}</h4>
                                        )
                                    }
                                    return (
                                        <h4 onClick={() => setTab(state.id)} key={index}>{state?.label}</h4>
                                    )
                                })
                            }
                        </Tabs>

                    </Row>
                    <Row style={{ marginTop: 8 }}>
                        {renderTable()}
                    </Row>
                </div>
            </Row>
            {
                selectedOrder !== '' && modalVisualizeOrder && selectedOrder?.id_order && !selectedOrder.issalary && (
                    <ModalVisualizeOrder
                        open={selectedOrder !== '' && modalVisualizeOrder && selectedOrder?.id_order && !selectedOrder.issalary}
                        setSelectedItem={setSelectedOrder}
                        selectedItem={selectedOrder}
                        width={1100}
                        height={900}
                        trip={trip}
                    ></ModalVisualizeOrder>
                )
            }
            {selectedOrder !== '' && selectedOrder.id_order && modalVisualizeOrder && selectedOrder.providers_name === null && (
                <ModalVisualizeOrderSalary
                    open={selectedOrder !== '' && selectedOrder.id_order && selectedOrder.providers_name === null}
                    setSelectedItem={setSelectedOrder}
                    selectedItem={selectedOrder}
                    width={1100}
                    height={900}
                ></ModalVisualizeOrderSalary>
            )}
        </Container>
    )
}
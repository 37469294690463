import React, { useRef } from 'react';
import { Button } from './styles';

const ButtonUploadFile = ({ disabled, onChange, accept, multiple }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (!disabled) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <input
        id="button-upload-file"
        name="button-upload-file"
        type="file"
        onChange={onChange}
        accept={accept}
        multiple={multiple}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <label htmlFor="button-upload-file">
        <Button
          style={{
            background: "transparent",
            color: "#256CE1",
            border: '1px solid #256CE1',
            paddingLeft: 16,
            cursor: disabled ? 'default' : 'pointer',
          }}
          disabled={disabled}
          onClick={handleButtonClick}
        >
          Anexar arquivo
        </Button>
      </label>
    </div>
  );
};

export default ButtonUploadFile;

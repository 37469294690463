import Button from "../../../components/Button";
import Autocomplete from "../../../components/Autocomplete";
import { UseBoats} from "../../../hooks/useBoats";
import { UseFishingTypes } from "../../../hooks/useFishingTypes";
import { UseProducts } from "../../../hooks/useProducts";
import { harvestOptions, tripsOptions} from "../options";
import { UseEmployees } from "../../../hooks/useEmployees";
import { UseCostCategories } from "../../../hooks/useCostCategories";
import { UseProviders } from "../../../hooks/useProviders";

export default function OverviewFilters({filters, setFilters, defaultFilters}){
    const { boats } = UseBoats();  
    const {fishingTypes} = UseFishingTypes();
    const {products} = UseProducts();
    const {employees } = UseEmployees();
    const {costCategories} = UseCostCategories();
    const { providers } = UseProviders();


    let productsOptions = [];
    products && !products.error && products.map((element) => {
      if(element.isactive) {
        productsOptions.push(
          {
            id: element?.id_product,
            label: element?.product_name,
          }
        )
      }
    });


  let boatsOptions = [];
  boatsOptions && !boatsOptions.error && boats.map((element)=> {
    if(element.isactive) {
      boatsOptions.push(
        {
          id: element?.id_boat,
          label: element?.boats_name,
        }
      )
    }
  })

  let fishingTypesOptions = [];
  fishingTypes && !fishingTypes.error && fishingTypes.map((element)=> {
    if(element.isactive) {
      fishingTypesOptions.push(
        {
          id: element?.id_fishingtype,
          label: element?.fishingtypes_name,
        }
      )
    }
  })

  let employeesOptions = [];
  employees && !employees.error && employees.map((element)=> {
    if(element.isactive) {
      employeesOptions.push(
        {
          id: element?.id_employee,
          label: element?.full_name,
        }
      )
    }
  })

  let costCategoriesOptions = [];
costCategories && !costCategories.error && costCategories.map((element)=> {
  if(element.isactive) {
    costCategoriesOptions.push({
    id: element?.costs_categories_name,
    label: element?.costs_categories_name,
    })
  }
});

let providersOptions = [];
providers && !providers.error && providers.map((element)=> {
  if(element.isactive) {
    providersOptions.push(
      {
        id: element?.providers_name,
        label: element?.providers_name,
      }
    )
  }
})



    function cleanFilters(){
        setFilters({...defaultFilters});
      }

    return (
       
        <div style={{marginTop: 16}}>
        <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Button 
            label="Limpar filtros" 
            background="transparent" 
            color="#54577A" 
            borderColor="#54577A" 
            disabled={false} 
            onClick={()=> {cleanFilters()}}
            ></Button>
          </div> 
          <br></br>
          <Autocomplete
          value={filters?.harvest}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todas'}, ...harvestOptions]}
          fontSize={'0.7rem'}  
          label="Safra"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.harvest = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
        <br></br>
        <Autocomplete
          value={filters?.trip} 
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todas'}, ...tripsOptions]}
          fontSize={'0.7rem'}  
          label="Viagem"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.trip = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
        <br></br>
        <Autocomplete
          value={filters?.boat}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todos'}, ...boatsOptions]}
          fontSize={'0.7rem'}  
          label="Barco"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.boat = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
        <br></br>
           <Autocomplete
          value={filters?.fishingType} 
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todas'}, ...fishingTypesOptions]}
          fontSize={'0.7rem'}  
          label="Espécie"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.fishingType = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
        <br></br>
        <Autocomplete
          value={filters?.product} 
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todos'}, ...productsOptions]}
          fontSize={'0.7rem'}  
          label="Produto/Insumo"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.product = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
                         <br></br>
        <Autocomplete
          value={filters?.employee}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todos'}, ...employeesOptions]}
          fontSize={'0.7rem'}  
          label="Colaborador"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.employee = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
        <br></br>
        <Autocomplete
          value={filters?.costCategory}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todas'}, ...costCategoriesOptions]}
          fontSize={'0.7rem'}  
          label="Categoria de Custo"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.costCategory = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
          ></Autocomplete>
          <br></br>
        <Autocomplete
          value={filters?.provider}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todos'}, ...providersOptions]}
          fontSize={'0.7rem'}  
          label="Fornecedor/Comprador"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.provider = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
          ></Autocomplete>
          <br></br>
      </div>
       )
  }
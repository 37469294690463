export const columns = [
    {
      name: 'Colaborador',
      key:'employee_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Categoria de custo',
      key:'cost_category_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Salário',
      key:'unity_price',
      type: 'money', 
      unit: '',
    },
  ]
  
  export const columnsParcels = [
    {
      name: 'Nº',
      key:'name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Valor',
      key:'value',
      type: 'money', 
      unit: '',
    },
    {
      name: 'Vencimento',
      key:'expiration_date',
      type: 'date', 
      unit: '',
    },
    {
      name: 'Pagamento',
      key:'payment_date',
      type: 'date', 
      unit: '',
    },
    {
      name: 'Status',
      key:'status_label',
      type: 'string', 
      unit: '',
    },
  ]
  
  export const columnsApportionment = [
    {
      name: 'Viagem',
      key:'trip_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Centro de custo',
      key:'cost_center_name',
      type: 'string', 
      unit: '',
    },
  ]

  
    export const statusOptions = [
      {
        id: 0,
        label: 'Pendente'
      },
      {
        id: 1,
        label: 'Executado',
      }
    ]

    export const employeeTypeOptions = [
      {
        id: 'terra',
        label: 'Funcionários de terra'
      },
      {
        id: 'tripulação',
        label: 'Tripulação',
      },
      {
        id: 'socio',
        label: 'Sócios',
      },
    ]

    export const monthsOptions = [
      {
        id: 1,
        label: 'Janeiro',
      },
      {
        id: 2,
        label: 'Fevereiro',
      },
      {
        id: 3,
        label: 'Março',
      },
      {
        id: 4,
        label: 'Abril',
      },
      {
        id: 5,
        label: 'Maio',
      },
      {
        id: 6,
        label: 'Junho',
      },
      {
        id: 7,
        label: 'Julho',
      },
      {
        id: 8,
        label: 'Agosto',
      },
      {
        id: 9,
        label: 'Setembro',
      },
      {
        id: 10,
        label: 'Outubro',
      },
      {
        id: 11,
        label: 'Novembro',
      },
      {
        id: 12,
        label: 'Dezembro',
      },
    ];

    export const yearsOptions = [
      {
        id: 2023,
        label: '2023'
      },
      {
        id: 2024,
        label: '2024'
      },
      {
        id: 2025,
        label: '2025'
      },
      {
        id: 2026,
        label: '2026'
      },
    ]
    


    
import styled from "styled-components";

export const Container = styled.div`
  width: 240px;
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: top;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  h1 {
    color: var(--txt-subtitle);
    font-size: 0.8rem;
    margin-left: 0.8rem;
  }
`;

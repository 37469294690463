import {useEffect, useState, useContext} from "react";
import Button from "../../../components/Button";
import {Container, Header, Row, Content, ListTitle, Divider, Explanation} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import Textfield from "../../../components/Textfield";
import AppContext from "../../../state/App.context";
import { UseStock } from "../../../hooks/useStock";
import LocalStorageService from '../../../services/storage';

export default function ModalReturnToStock({open, selectedItem, setSelectedItem, setOpenModalReturnToStock, width, height}){
    const { returnItensToStock} = UseStock();
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;
    const title = 'Devolver item ao estoque';
    const localStorageService = LocalStorageService();
    const [notes, setNotes] = useState('');
    const fontSize = 12;

    function handleClose(){
      setOpenModalReturnToStock(false);
      setSelectedItem({});
    }
    
    async function handleReturnToStock(event){
      try{
        setLoading(true);
        event.preventDefault();
        const userInfo = localStorageService.getUserInfo();
        const id_user = userInfo?.id_user;
        const itemToReturn = {
          id_stock: selectedItem?.id_stock,
          id_order:selectedItem?.id_order,
          id_user,
          notes,
        }
        const response = await returnItensToStock(itemToReturn);
        if (response.success){
          handleClose();
          setSnack({
            open: true,
            severity: 'success', 
            message:"Operação realizada com sucesso!",
          })
        } else {
          setSnack({
            open: true,
            severity: 'error', 
            message:'Ocorreu um erro ao retirar insumo do estoque: ' + response.message,
          })
        } 
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }   
     }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
            return(
            <Content>
             {/* <Row><ListTitle>Informações do item:</ListTitle></Row>
             <Divider></Divider> */}
              <Row>
                <h4>Item: <b>{selectedItem?.product_name} </b></h4>
                <h4>Quantidade total: <b> {selectedItem?.output_amount} </b></h4>
              </Row>
              <Row>
                <h4>Centro de Custo: <b>{selectedItem?.costs_center_name} </b></h4>
                <h4>Viagem: <b> {selectedItem?.trips_name} - {selectedItem?.boats_name}</b></h4>
              </Row>
              <Row>
                 <Textfield
                    label="Observações"
                    width={"100%"}
                    disabled={false} 
                    value={notes} 
                    fontSize={fontSize} 
                    onChange={setNotes}
                    multiline={true}
                    ></Textfield>
                 </Row>
                <Row style={{marginTop:'2rem'}}>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleClose()}
                    ></Button>
                    <Button
                    label="Devolver item" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={handleReturnToStock}
                    ></Button>
                </Row>
        </Content>
            )
            
        }}

    return (
        <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleClose()} alt="Fechar"></img>
          </Header>
          {renderContent()}
 
        </Container>
      </Modal>
       )
  }

import {createContext , useEffect, useState, ReactNode, useContext} from 'react';
import  api from '../services/api';


interface FishingType{
    id_fishingtype: string; 
    fishingtypes_name: string;
}
type FishingTypeInput = Omit<FishingType, 'id_fishingtype'>

interface FishingTypesProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface FishingTypesContextData {
    fishingTypes: FishingType [];
    createFishingType: (fishingType: FishingTypeInput) =>Promise<ResponseProps>;
    updateFishingType: (fishingType: FishingType) =>Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
}

const FishingTypesContext = createContext<FishingTypesContextData>(
    {} as FishingTypesContextData
);


export function FishingTypesProvider ({children}: FishingTypesProvidersProps){
      const [fishingTypes, setFishingTypes] = useState<FishingType[]>([]);
      const [refreshOnDelete, setRefreshOnDelete] = useState(0);
      
  useEffect(() =>{
        api.get('fishingtypes').then(response => setFishingTypes(response.data))
    }, [refreshOnDelete])

    async function updateOnDelete (id: number){
            const response =  await api.post('/deletefishingtype',  { id_fishingtype: id });
            if (response.status === 200){
                setRefreshOnDelete(oldKey => oldKey + 1);
                return {
                    success: true,
                    message: "Espécie removida com sucesso!"
                }
            }
            return {
                success: false,
                message: "Erro ao remover espécie."
            }
        }

    async function createFishingType (fishingTypesInput: FishingTypeInput){
        const response = await  api.post('/fishingtypes', fishingTypesInput);
        if (response.status === 200){
            api.get('fishingtypes').then(response => setFishingTypes(response.data))
            return {
                success: true,
                message: "Espécie adicionada com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar espécie."
        }
      
    }

    async function updateFishingType (fishingType: FishingType){
       const response = await api.put('/fishingtypes', fishingType);
       if (response.status === 200){
        setRefreshOnDelete(oldKey => oldKey + 1)
        return {
            success: true,
            message: "Espécie editada com sucesso!"
        }
        }
    return {
        success: false,
        message: "Erro ao editar espécie."
    }
    }

    return (
        <FishingTypesContext.Provider value = {{fishingTypes, createFishingType, updateFishingType, updateOnDelete}}>
            {children}
        </FishingTypesContext.Provider>
    );
}

export function UseFishingTypes(){
    const context = useContext(FishingTypesContext);
    return context;
}
import {Container, Header, Row, Content, ListTitle, Divider, Print, PrintHeader} from './styles';
import { formatMoney } from '../../../services/functions';
import moment from 'moment';
import Table from '../../../components/Table';
import { columnsApportionment } from './options';


const columnsTransactions = [
    {
      name: 'Colaborador',
      key:'full_name',
      type: 'string', 
      unit: '',
    },  
   {
    name: 'Tipo',
    key:'type',
    type: 'string', 
    unit: '',
  },
    {
      name: 'valor',
      key:'total_value',
      type: 'money', 
      unit: '',
    }, 
  ]




export default function OrderToPrint({order, transactions, apportionment}){

  function renderApportionment(){
    return (
      <>
        <Row style={{width:'100%', marginTop: 0}}>
          <ListTitle>Rateio:</ListTitle>
        </Row>
        <Divider></Divider>
          <Table 
            columns={columnsApportionment} 
            rows={apportionment || []} 
            hasEditing={false} 
            hasRemoving={false}
            setSelectedItem={()=>{}} 
            height="auto"
            actionsInFirstLine={false}
            fitWidth={false}
          ></Table>
      </>
    )

  }

    return (
        <Print id="report">
            <PrintHeader>
                <h4>Detalhes do Pedido:</h4>
                <h3>Data de criação: {moment(order?.created_at).format('DD/MM/YYYY')}</h3>
                </PrintHeader>
              <Divider></Divider>
              <Row style={{width:'100%', marginTop:24, justifyContent: 'flex-start'}}> 
                  <h4 ><b>Criado por:</b> {order?.created_by}</h4>
                  <h4><b>Subsidiária:</b> {order?.subsidiary_name}</h4>
                  <h4 ><b>Categoria:</b> {order?.costs_categories_name}</h4>
              </Row> 
              <Row style={{width:'100%', justifyContent: 'flex-start'}}>
                <h4><b>Competência:</b> {order?.salary_month}</h4>
                <h4><b>Período:</b> {moment(order?.begin_salary_date).add(3, 'hours').format('DD/MM/YYYY')} - {moment(order?.end_salary_date).add(3, 'hours').format('DD/MM/YYYY')}</h4>
              </Row> 
                <Row style={{width:'100%', marginBottom: 36}}>
                    <h4><b>Tipo de pagamento:</b> {order?.paymenttype_name}</h4>
                    <h4><b>Vencimento:</b> {moment(order?.expiration_date_name).add(3, 'hours').format('DD/MM/YYYY')}</h4>
                    <h4><b>Status:</b> {order?.status_name}</h4>
                </Row> 
                { order?.category === 'Rateio' && renderApportionment()}
                <Row style={{width:'100%',  marginTop: 0}}>
                  <ListTitle>Produtos:</ListTitle>
                </Row>
              <Divider></Divider>
              <Table 
                  columns={columnsTransactions} 
                  rows={transactions || []} 
                  hasEditing={false} 
                  hasRemoving={false}
                  setSelectedItem={()=>{}} 
                  actionsInFirstLine={false}
                  height="auto"
                  fitWidth={false}
                ></Table>  
                <Row style={{width:'95%', borderTop:'1px solid var(--blue)', paddingTop: 16 }}>
                    <h3>Valor Total:</h3>
                    <h3> {formatMoney(order?.total_order_value) }</h3>
                </Row> 
                <Row style={{width:'95%', justifyContent:'flex-end'}}>
                    <h3>Desconto:</h3>
                    <h3> - {formatMoney(order?.discount)}</h3>
                </Row> 
                <Row style={{width:'95%',  justifyContent:'flex-end'}}>
                    <h3>A pagar:</h3>
                    <h3><b>{formatMoney(order?.total_order_value - order?.discount)}</b></h3>
                </Row> 
        </Print>
    )

}


import {createContext , useEffect, useState, ReactNode, useContext} from 'react';
import  api from '../services/api';


interface Employee{
    id_employee: string; 
    full_name: string;
    birthday: string;
    role: string;
    salary: number;
    admission_date: string;
    phone_number: string;
    type: string;
    isactive: boolean;
    // fk_companies_id_company: string;
    fk_subsidiary_id_subsidiary: string;
}
type EmployeeInput = Omit<Employee, 'id_employee'>

interface EmployeesProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface EmployeesContextData {
    employees: Employee [];
    createEmployee: (employee: EmployeeInput) =>Promise<ResponseProps>;
    updateEmployee: (employee: Employee) =>Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
    getTripEmployees: (id_trip: string) => Promise<ResponseProps>;
}

const EmployeesContext = createContext<EmployeesContextData>(
    {} as EmployeesContextData
);


export function EmployeesProvider ({children}: EmployeesProvidersProps){
      const [employees, setEmployees] = useState<Employee[]>([]);
      const [refreshOnDelete, setRefreshOnDelete] = useState(0);

  useEffect(() =>{
        api.get('employees').then(response => {setEmployees(response.data)})
    }, [refreshOnDelete])

    async function updateOnDelete (id: number){
            const response =  await api.post('/deleteemployees',  { id_employee: id });
            if (response.status === 200){
                setRefreshOnDelete(oldKey => oldKey + 1);
                return {
                    success: true,
                    message: "Funcionário removida com sucesso!"
                }
            }
            return {
                success: false,
                message: "Erro ao remover funcionário."
            }
        }

    async function createEmployee (employeesInput: EmployeeInput){
        const response = await  api.post('/employees', employeesInput);
        if (response.status === 200){
            api.get('employees').then(response => setEmployees(response.data))
            return {
                success: true,
                message: "Funcionário adicionada com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar funcionário."
        }
      
    }

    async function updateEmployee (employee: Employee){
       const response = await api.put('/employees', employee);
       if (response.status === 200){
        setRefreshOnDelete(oldKey => oldKey + 1)
        return {
            success: true,
            message: "Funcionário editado com sucesso!"
        }
        }
    return {
        success: false,
        message: "Erro ao editar funcionário."
    }
    }

    async function getTripEmployees (id_trip: string){
        const response =  await api.get('/employeesintrip',  {
            params: { fk_trips : id_trip },
          } );
        if (response.status === 200 && !response.data.error ){
            const tripEmployees = response?.data;
            return {
                success: true,
                message: "Ok!",
                data: tripEmployees,
            }
        }
        return {
            success: false,
            message: response.data && response.data.error ? response.data.error : "Erro ao buscar dados dos tripulantes da viagem." ,
            data: null
        }
    }

    return (
        <EmployeesContext.Provider value = {{employees, createEmployee, updateEmployee, updateOnDelete,getTripEmployees}}>
            {children}
        </EmployeesContext.Provider>
    );
}

export function UseEmployees(){
    const context = useContext(EmployeesContext);
    return context;
}
import { FormEvent, useState, useContext} from "react";
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import Datepicker from "../../../../components/Datepicker";
import Numberfield from "../../../../components/Numberfield";
import {Container, Header, Row, Content, ButtonRow} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import { UseEmployees } from "../../../../hooks/useEmployees";
import { UseSubsidiaries } from "../../../../hooks/useSubsidiaries"
import AppContext from "../../../../state/App.context";
import Autocomplete from "../../../../components/Autocomplete";
import LocalStorageService from '../../../../services/storage';

export default function ModalAddEmployee({open, handleOpen, width, height}){
    const { createEmployee } = UseEmployees();
    const { subsidiaries } = UseSubsidiaries();
    const [fullName, setFullName] = useState('');
    const [birthday, setBirthday] = useState(new Date());
    const [role, setRole] = useState('');
    const [salary, setSalary] = useState(0);
    const [admissionDate, setAdmissionDate] = useState(new Date());
    const [subsidiary, setSubsidiary] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const localStorageService = LocalStorageService();
    let fk_companies_id_company = 0;
    const employeeInfo = localStorageService.getUserInfo();
    fk_companies_id_company = employeeInfo?.fk_companies_id_company;
    const fontSize = 14;
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;

    let subsidiariesOptions = [];
    subsidiaries && !subsidiaries.error && subsidiaries.map((element)=> {
      if(element.isactive) {
        subsidiariesOptions.push({
          id: element?.id_subsidiary,
          label: element?.subsidiary_name,
        })
      }
    }
    )

    function cleanAllInputs(){
      try{
        setFullName('');
        setBirthday(new Date());
        setRole('');
        setSalary(0);
        setAdmissionDate(new Date());
        setSubsidiary('');
        setPhoneNumber('');
      } catch(err){
           console.log(err);
      }
  }

  
  function checkingRequiredFields() {
    if (!fullName || !salary || !subsidiary) {
      setSnack({
        open: true,
        severity: 'error', 
        message: 'Preencha todos os campos necessários!',
      });
      return false;
    }
    return true;
  }

    async function handleCreateNewEmployee(event){
      try{
        setLoading(true);
        event.preventDefault();
        if(checkingRequiredFields()){
          const response = await createEmployee(
              {
              full_name: fullName,
              birthday,
              role,
              salary,
              admission_date: admissionDate,
              phone_number: phoneNumber,
              type: 'terra',
              // fk_companies_id_company,
              fk_subsidiary_id_subsidiary: subsidiary?.id,
          }
          );
          if(response.success){
            cleanAllInputs();
            handleOpen(false);
            setSnack({
              open: true,
              severity: 'success', 
              message:response?.message,
            })
          } else {
            setSnack({
              open: true,
              severity: 'error', 
              message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
            })
          }
      } else {
        setSnack({
          open: true,
          severity: 'error', 
          message:'É necessário preencher todos os campos obrigatórios!',
        })
      }
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
   
     }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
            return(
<Content>
                <Row style={{gridTemplateColumns:'2fr 1fr'}}>
                    <Textfield
                    label="Nome completo*"
                    width={'100%'}
                    disabled={false} 
                    value={fullName} 
                    fontSize={fontSize} 
                    onChange={setFullName}
                    multiline={false}
                    ></Textfield>
                    <Datepicker
                    label="Data de nascimento"
                    width={197}
                    disabled={false}
                    value={birthday} 
                    fontSize={fontSize} 
                    onChange={setBirthday} />
                </Row>
                <Row>
                    <Textfield
                    label="Cargo"
                    width={'auto'}
                    disabled={false} 
                    value={role} 
                    fontSize={fontSize} 
                    onChange={setRole}
                    multiline={false}
                    ></Textfield>
                     <Numberfield
                    label="Salário*"
                    width={'auto'}
                    disabled={false} 
                    value={salary} 
                    fontSize={fontSize} 
                    onChange={setSalary}
                  ></Numberfield>
                    <Datepicker
                    label="Data de admissão"
                    width={'auto'}
                    disabled={false}
                    value={admissionDate} 
                    fontSize={fontSize} 
                    onChange={setAdmissionDate} />
                </Row>
                <Row style={{gridTemplateColumns:'2fr 1fr'}}>
                  <Autocomplete
                      value={subsidiary}
                      width={'100%'}
                      disabled={false}
                      options={subsidiariesOptions}
                      fontSize={fontSize} 
                      label="Subsidiária*"
                      onChange={setSubsidiary}
                      ></Autocomplete>
                 <Textfield
                    label="Telefone"
                    width={'100%'}
                    disabled={false} 
                    value={phoneNumber} 
                    fontSize={fontSize} 
                    onChange={setPhoneNumber}
                    multiline={false}
                    ></Textfield>
                </Row>
                <ButtonRow>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleOpen(false)}
                    ></Button>
                    <Button
                    label="Cadastrar funcionário" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={handleCreateNewEmployee}
                    ></Button>
                </ButtonRow>
        </Content>
            )
            
        }}

    return (
        <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>Cadastrar funcionário de terra</h1>
          <img src={Close} onClick={()=> handleOpen(false)} alt="Fechar"></img>
          </Header>
          {renderContent()}
 
        </Container>
      </Modal>
       )
  }

import {useState, useEffect} from "react";
import {Logo, LogoExpanded} from '../../assets/images/index';
import {Overview, OverviewSelected, Transactions, TransactionsSelected, Trips,TripsSelected, Schedule, ScheduleSelected, Records,RecordsSelected, Settings, SettingsSelected,Orders, OrdersSelected, Logout, Logs, LogsSelected, TripsByBoat, TripsByBoatSelected, Stock, StockSelected, Salary, SalarySelected, Chart, ChartSelected} from '../../assets/icons/index';
import {
  Nav,
  NavExpanded,
  NavIcon,
  NavLink,
  NavLinkActive,
  NavMenu,
  LogoArea,
  LogoImg,
  LogoImgExpanded,
} from './styles';
import LocalStorageService from '../../services/storage';
import { Tooltip } from "@material-ui/core";


function getCurrentURL () {
  return window.location.href
}

export function Menu() {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const LogoToShow = menuExpanded ? LogoExpanded : Logo;
  const LogoImgToShow = menuExpanded ? LogoImgExpanded : LogoImg;
  const localStorageService = LocalStorageService();
  const isAuthenticated = !!localStorage.getItem('token');

  const url = getCurrentURL();

  const pages = [
    
    {
      label: 'Pedidos',
      icon: Orders,
      iconSelected: OrdersSelected,
      path: '/orders',
    },
    {
      label: 'Resumo Geral',
      icon: Overview,
      iconSelected: OverviewSelected,
      path: '/overview'
    },
    {
      label: 'Resumo Conta Correntes',
      icon: Chart,
      iconSelected: ChartSelected,
      path: '/financial-overview'
    },

    {
      label: 'Viagens por barco',
      icon: TripsByBoat,
      iconSelected: TripsByBoatSelected,
      path: '/tripsbyboat',
    },
    {
      label: 'Programação',
      icon: Schedule,
      iconSelected: ScheduleSelected,
      path: '/schedule'
    },
    {
      label: 'Cadastros',
      icon: Records,
      iconSelected: RecordsSelected,
      path: '/records'
    },
      {
      label: 'Estoque',
      icon: Stock,
      iconSelected: StockSelected,
      path: '/stock'
    },
    {
      label: 'Remuneração',
      icon: Salary,
      iconSelected: SalarySelected,
      path: '/salary'
    },
    // {
    //   label: 'Sistema',
    //   icon: Settings,
    //   iconSelected: SettingsSelected,
    //   path: '/system'
    // },
      {
      label: 'Histórico',
      icon: Logs,
      iconSelected: LogsSelected,
      path: '/logs'
    },
  ]


  function MenuItem({page, index}){
    if(url.includes(page.path)){
      return(
        <Tooltip title={menuExpanded ? '' : page.label}>
        <NavLinkActive to={page.path} onClick={()=>setSelectedPage(index)} >
            <NavIcon src={page.iconSelected } alt={page.label}></NavIcon>
            {menuExpanded && page.label}
        </NavLinkActive>
        </Tooltip>
      )
    } else {
      return(
        <Tooltip title={menuExpanded ? '' : page.label}>
        <NavLink to={page.path} onClick={()=>setSelectedPage(index)} >
          <NavIcon src={page.icon} alt={page.label}></NavIcon>
          {menuExpanded && page.label}
        </NavLink>
      </Tooltip>
      )
    }
    
  }
  
  function LogoutItem(){
    if(menuExpanded){
      return(
        <NavLink to={'/'} onClick={()=>{localStorageService.logout(); setSelectedPage(pages.length)}} key="Logout" >
          <NavIcon src={Logout} alt="logout"></NavIcon>
          Sair
        </NavLink>
      )
    } else {
      return(
        <Tooltip title="Sair">
        <NavLink to={'/'} onClick={()=>{localStorageService.logout(); setSelectedPage(pages.length)}} key="Logout" >
          <NavIcon src={Logout} alt="logout"></NavIcon>
        </NavLink>
        </Tooltip>
      )
    }

  }

  return (
    <Nav style={{display: isAuthenticated ? 'flex': 'none' }} expanded={menuExpanded} >
      <LogoArea>
        <LogoImgToShow src={LogoToShow} onClick={()=>setMenuExpanded(!menuExpanded)} alt='logo' />
      </LogoArea>
      <NavMenu>
      {
      pages.map((page, index)=>{
        return (
        <MenuItem page={page} index={index} key={page.label}></MenuItem>
        )
      })
      }
    </NavMenu>
    <LogoutItem/>
   </Nav>
  )
};
import {useEffect, useState, useContext} from "react";
import Button from "../../../components/Button";
import Numberfield from "../../../components/Numberfield";
import {Container, Header, Row, Content} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import { UseOrders } from "../../../hooks/useOrders";
import moment from "moment";
import AppContext from "../../../state/App.context";
import LocalStorageService from '../../../services/storage';
import { formatMoney } from "../../../services/functions";
import Datepicker from "../../../components/Datepicker";


export default function ModalChangeExpirationDate({open, selectedItem, setSelectedItem, width, height, setRefreshData}){
    let order = selectedItem; 
    const { shortcutEditOrder } = UseOrders();
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;
    const [newExpirationDate, setNewExpirationDate] = useState(new Date());
    const title = 'Mudar data de vencimento';
    const fontSize = 14;
    const amount_parcels = order?.amount_parcels;

    let actionType = order?.type === 'Entrada' ? 'recebimento' : 'pagamento';
    let receivingType = order?.type === 'Entrada' ? 'do' : 'ao';

    function cleanAllInputs(){
      setNewExpirationDate(new Date());
      }

    function handleClose(){
        setSelectedItem({open: false, mode: '', name: '', id_order: null});
        cleanAllInputs();
        setRefreshData(prevIsRefreshData => !prevIsRefreshData);
      }
    
      function addTimestampToArray(newElement, index, array){
        newElement = newElement || new Date();
        if (index === 0 && amount_parcels !== 0){
          return "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
        }
        if (index === 0 && amount_parcels === 0){
          return "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
        }
        if (index === (amount_parcels - 1)){
          return array + "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
        }
        return array +  "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
      }

      function addStringToArray(newElement, index, array){ 
        if (index === 0 && amount_parcels !== 0){
          return "'"+ newElement + "',";
        }
        if (index === 0 && amount_parcels === 0){
          return "'"+ newElement + "'";
        }
        if (index === (amount_parcels - 1)){
          return array + "'" + newElement + "'";
        }
        return array + "'" + newElement + "',";
      }
  

    async function handleChangeExpirationDate(event){
        try{
            setLoading(true);
            event.preventDefault();
            let status_array = '';
            let expiration_date_array = '';
            let payment_date_array = '';
            let new_expiration_date  = moment(newExpirationDate).add(3, 'hours');
            let current_parcel_index = order?.current_parcel_name.split('/')[0] - 1;
            const status = order?.status[current_parcel_index];
            const payment_date = moment(order?.payment_date[current_parcel_index]).add(3, 'hours');
            if(amount_parcels === 0){
                status_array = addStringToArray(status, 0,status_array);
                expiration_date_array = addTimestampToArray(new_expiration_date, 0,expiration_date_array);
                payment_date_array = addTimestampToArray(payment_date, 0,payment_date_array);
              } else {
                order?.status.map((status_item, index)=> {
                  let parcel_new_expiration_date = index === current_parcel_index ? new_expiration_date : order?.expiration_date[index];
                  status_array = addStringToArray(status_item, index,status_array);
                  expiration_date_array = addTimestampToArray(parcel_new_expiration_date, index,expiration_date_array);
                  payment_date_array = addTimestampToArray(moment(order?.payment_date[index]).add(3, 'hours'), index,payment_date_array);
                });
              };
            const updatedOrder = {
                id_array: [order?.id_order],
                status_array: [status_array],
                expiration_date_array: [expiration_date_array],
                payment_date_array: [payment_date_array],
            }
            const response = await shortcutEditOrder(updatedOrder);
            if(response.success){
                handleClose();
                cleanAllInputs()
                setSnack({
                  open: true,
                  severity: 'success', 
                  message:"Operação realizada com sucesso!",
                })
            } else {
                setSnack({
                    open: true,
                    severity: 'error', 
                    message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
                  })
            }
        } catch(err){
            console.log(err);
        } finally {
            setLoading(false);
        }
    }
  

    function renderContent(){
     if(loading){
       return (
         <Spinner width={40} fontSize={14}></Spinner>
       )
     } else {
         return(
         <Content>
           <Row>
             <h1>Deseja adiar a data de vencimento do {actionType} da parcela {order?.current_parcel_name} da subsidiária <b>{order?.subsidiary_name}</b> no valor de <b>{formatMoney(order?.current_parcel_value)}</b> {receivingType} <b>{order?.providers_name}</b> de <b>{moment(order?.current_parcel_expiration_date).add(3, 'hours').format('DD/MM/YYYY')}</b> para:</h1>
           </Row>
           <Datepicker
              label={"Data de vencimento"}
              width="100%"
              disabled={false}
              value={newExpirationDate} 
              fontSize={fontSize -2} 
              onChange={(item) => setNewExpirationDate(item)} />
             <Row>
                 <Button
                 label="Cancelar" 
                 background="#8E92BC" 
                 color="white" 
                 borderColor="#8E92BC" 
                 disabled={false} 
                 onClick={()=> handleClose()}
                 ></Button>
                 <Button
                 label="Salvar alterações" 
                 background="#256CE1" 
                 color="white" 
                 borderColor="#256CE1" 
                 disabled={false} 
                 onClick={handleChangeExpirationDate}
                 ></Button>
             </Row>
     </Content>
         )
         
     }}


   return (
       <Modal
       open={open}
       onClose={() => handleClose()}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
       >
       <Container style={{width, height}}>
         <Header>
         <h1>
           {title}
         </h1>
         <img src={Close} onClick={()=> handleClose()} alt="Fechar"></img>
         </Header>
         {renderContent()}

       </Container>
     </Modal>
      )

}

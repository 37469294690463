export const columns = [
    {
      name: 'Produto/Insumo',
      key:'product_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Categoria de custo',
      key:'cost_category_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Quantidade',
      key:'product_amount',
      type: 'number', 
      unit: '',
    },
    {
      name: 'Preço',
      key:'unity_price',
      type: 'money', 
      unit: '',
    },
    {
      name: 'Valor Total',
      key:'total_value',
      type: 'money', 
      unit: '',
    },
  ]
  
  export const columnsParcels = [
    {
      name: 'Nº',
      key:'name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Vencimento',
      key:'expiration_date',
      type: 'date', 
      unit: '',
    },
    {
      name: 'Pagamento',
      key:'payment_date',
      type: 'date', 
      unit: '',
    },
    {
      name: 'Status',
      key:'status_label',
      type: 'string', 
      unit: '',
    },
  ]
  
  export const columnsApportionment = [
    {
      name: 'Viagem',
      key:'trip_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Centro de custo',
      key:'cost_center_name',
      type: 'string', 
      unit: '',
    },
  ]
  
  export  const typeOptions = [
      {
        id: 0,
        label: 'Entrada'
      },
      {
        id: 1,
        label: 'Saída',
      }
    ];
  
    export const statusOptions = [
      {
        id: 0,
        label: 'Pendente'
      },
      {
        id: 1,
        label: 'Executado',
      }
    ]


    
import { Container, Header } from "./styles";
import { Filters } from "../../assets/icons/index";
import Drawer from "@mui/material/Drawer";

interface DrawerProps {
  open: boolean;
  handleOpen: (arg0: boolean) => void;
  Children: React.ComponentType;
}

export default function CustomizedDrawer({
  open,
  handleOpen,
  Children,
}: DrawerProps) {
  return (
    <Drawer anchor="right" open={open} onClose={() => handleOpen(false)}>
      <Container>
        <Header>
          <img src={Filters} alt="Filtros"></img>
          <h1>Filtros</h1>
        </Header>
        <Children />
      </Container>
    </Drawer>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
    width:100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: space-between;
    justify-content: start;
    background: var(--white);
    border-radius: 10px;
    padding: 16px 30px;
    margin-top: 12px;
    ` ;

export const Section = styled.div`
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: start;
    ` ;

export const Title = styled.h1`
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 160%;
    color: var(--txt-title);
    text-align: left;
` ;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
` ;

export const RowInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 2rem;
        color: var(--txt-subtitle);
        text-align: left;
    }
` ;

export const SectionTitle = styled.h1`
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 160%;
    color: var(--txt-title);
    text-align: left;
    border-bottom: 0.5px solid #B8B9C7
` ;



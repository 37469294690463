export const columnsParcels = [
    {
      name: 'Nº',
      key:'name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Valor',
      key:'price',
      type: 'money', 
      unit: '',
    },
    {
      name: 'Vencimento',
      key:'expiration_date',
      type: 'date', 
      unit: '',
    },
    {
      name: 'Data de Pagamento',
      key:'payment_date',
      type: 'date', 
      unit: '',
    },
    {
      name: 'Status',
      key:'status',
      type: 'string', 
      unit: '',
    },
  
  ]
  


  export const columnsEmployees = [
    {
      name: 'Funcionário',
      key:'full_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Salário',
      key:'total_value',
      type: 'money', 
      unit: '',
    },
    {
      name: 'Categoria de custo',
      key:'costs_categories_name',
      type: 'string', 
      unit: '',
    },
  ]

  export const columnsApportionment = [
    {
      name: 'Viagem',
      key:'trip_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Safra',
      key:'harvest',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Centro de custo',
      key:'cost_center_name',
      type: 'string', 
      unit: '',
    },
    {
        name: 'Valor',
        key:'total_value',
        type: 'money', 
        unit: '',
      },
  ]



  export const columnsOutputs = [
    {
      name: 'Produto',
      key:'product_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Quantidade',
      key:'output_amount',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Valor',
      key:'output_value',
      type: 'money', 
      unit: '',
    },
    {
      name: 'Percentual',
      key:'percent_total_order_value',
      type: 'percent', 
      unit: '%',
    },
    {
      name: 'Centro de custo',
      key:'costs_center_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Barco',
      key:'boats_name',
      type: 'string', 
      unit: '',
    },
    {
      name: 'Data/Hora',
      key:'created_at',
      type: 'datetime', 
      unit: '',
    },
    {
      name: 'Responsável',
      key:'created_by',
      type: 'string', 
      unit: '',
    },
  ]
  

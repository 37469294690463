import React, {useState} from 'react'
import { Container, Title, TitleRow, Row, Subtitle, SubtitleRow, Content, Values, RowContent, Tabs} from './styles';
import { formatMoney} from '../../services/functions';

export default function SubsidiariesTotal({ resolved_outputs_total, pending_outputs_total, resolved_inputs_total, pending_inputs_total }) {

return(
    <Container style={{height: 'auto', justifyContent: 'center' }}>
        <Row>
        <TitleRow>
            <Title><b>TOTAL</b></Title>
            </TitleRow>
            <SubtitleRow>
                <Subtitle >Pago: <b>{formatMoney(resolved_outputs_total || 0)}</b></Subtitle>
                <Subtitle >Pendente à pagar: <b>{formatMoney(pending_outputs_total || 0)}</b></Subtitle>
                <Subtitle >Recebido: <b>{formatMoney(resolved_inputs_total || 0)}</b></Subtitle>
                <Subtitle >Pendente à receber: <b>{formatMoney(pending_inputs_total || 0)}</b></Subtitle>
            </SubtitleRow>
        </Row>
    </Container>
)
}


import { Container, Label} from './styles';
import TextField from '@mui/material/TextField';

interface NumberfieldProps{
    label: string;
    width: number,
    disabled: boolean;
    value: number;
    fontSize: number;
    onChange: (arg0: number) => void;
}

export default function CustomizedNumberfield({ label, width, disabled, fontSize, value, onChange}: NumberfieldProps){

    return (
        <Container>
            <Label style={{fontSize:fontSize}}>{label}:</Label>
            <TextField  
                variant="outlined" 
                size="small"
                type="number"
                disabled={disabled}
                value={value}
                onChange={(event)=> onChange(Number(event.target.value))}
                sx={{ 
                    borderRadius:'10px', 
                    border: '0.2px solid #8E92BC',
                    '& input': {
                                color: '#141522',
                                fontFamily:'Plus Jakarta Sans',
                                fontSize: fontSize,
                            }
                    }}
                style={{
                    width: width,
                    color: '#141522',
                    background:'#FFFFFF',
                    fontSize: fontSize
                    }} />
        </Container>
    )
}
import { useEffect, useState, useContext} from "react";
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import { UseCostCenters } from "../../../../hooks/useCostCenters"
import {Container, Header, Row, Content} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import AppContext from "../../../../state/App.context";

export default function ModalEditCostCenter({open, setSelectedItem, width, height, selectedItem}){
    const { costCenters, updateCostCenter } = UseCostCenters();
    const [costs_center_name, setName] = useState('');
    const [description, setDescription] = useState('');
    const fontSize = 14;
    const [loading, setLoading] = useState(true);
    const [, setSnack] = useContext(AppContext).snackState;

    function handleClose(){
        setSelectedItem({open: false, mode: '', costs_center_name: '', id_cost: null});
      }

     function getInfo(){
      try{
        const costCenter = costCenters.filter((element)=> element.id_cost === selectedItem?.id_cost)[0];
        setName(costCenter.costs_center_name);
        setDescription(costCenter.description);
      }catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
    }

    useEffect(()=>{
      if (open) getInfo();
    },[open])

    const title = 'Editar centro de custo';

    function checkingRequiredFields() {
      if (!costs_center_name) {
        setSnack({
          open: true,
          severity: 'error', 
          message: 'Preencha todos os campos necessários!',
        });
        return false;
      }
      return true;
    }

    async function handleEditCostCenter(event){
      try{
        setLoading(true);
        event.preventDefault();
        if(checkingRequiredFields()){
          const response = await updateCostCenter(
              {
              id_cost: selectedItem.id_cost,
              costs_center_name,
              description,
          }
          );
          if(response.success){
              handleClose();
              setSnack({
                open: true,
                severity: 'success', 
                message:response?.message,
              })
          }  else {
            setSnack({
              open: true,
              severity: 'error', 
              message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
            })
          }
      }
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
     }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
          return (
            <Content>
             <Row>
                    <Textfield
                    label="Nome do centro de custo*"
                    width={600}
                    disabled={false} 
                    value={costs_center_name} 
                    fontSize={fontSize} 
                    onChange={setName}
                    multiline={false}
                    ></Textfield>
                </Row>
                <Row>
                <Textfield
                    label="Descrição"
                    width={600}
                    disabled={false} 
                    value={description} 
                    fontSize={fontSize} 
                    onChange={setDescription}
                    multiline={false}
                    ></Textfield>
                </Row>
                <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=>handleClose()}
                    ></Button>
                    <Button
                    label="Salvar alterações" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={handleEditCostCenter}
                    ></Button>
                </Row>
        </Content>
          )
        }
  
      }
  
    return (
        <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleClose()} alt="Fechar"></img>
          </Header>
          {renderContent()}
   
        </Container>
      </Modal>
       )
  }
import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from "react";
import api from "../services/api";
import LocalStorageService from "../services/storage";

const localStorageService = LocalStorageService();

interface LogsRequest {
  startDate: string;
  endDate: string;
  pages: number;
  limit: number;
}

interface Log {
  id_historic: string;
  e_mail: string;
  user_action: string;
  created_at: string;
  fk_users_id_user: string;
}

interface LogsProvidersProps {
  children: ReactNode;
}

interface ResponseProps {
  success: boolean;
  message: string;
}

interface ResponsePropsWithData {
  success: boolean;
  message: string;
  data: any;
}

interface LogsContextData {
  logs: Log[];
  loading: boolean;
  getFilteredLogs: (request: LogsRequest) => Promise<ResponsePropsWithData>;
}

const LogsContext = createContext<LogsContextData>({} as LogsContextData);

export function LogsProvider({ children }: LogsProvidersProps) {
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  async function getFilteredLogs(request: LogsRequest) {
    let params = {
      ...request,
      token: api.defaults.params.token
        ? api.defaults.params.token
        : localStorageService.getIdToken(),
    };
    const response = await api.get("/logs", {
      params: { ...api.defaults.params, ...params },
    });
    if (response.status === 200) {
      return {
        success: true,
        message: "",
        data: response.data,
      };
    }
    return {
      success: false,
      message: "Erro ao consultar histórico.",
      data: [],
    };
  }

  return (
    <LogsContext.Provider value={{ logs, loading, getFilteredLogs }}>
      {children}
    </LogsContext.Provider>
  );
}

export function UseLogs() {
  const context = useContext(LogsContext);
  return context;
}

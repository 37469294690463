import React, {useState, useContext, useEffect} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UseSubsidiaries } from "../../../hooks/useSubsidiaries" 
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import ModalAddSubsidiary from './ModalAddSubsidiary/index';
import ModalRemoveSubsidiary from './ModalRemoveSubsidiary/index'; 
import ModalEditSubsidiary from './ModalEditSubsidiary/index';
import AppContext from "../../../state/App.context";

  const columns = [
    {
      name: 'Nome',
      key:'subsidiary_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'CNPJ',
      key:'cnpj',
      type: 'string', 
      unit:'',
    },
  ]

function Subsidiaries(){
  const { subsidiaries } = UseSubsidiaries();
  const [search, setSearch] = useState('');
  const [openModalAddSubsidiary, setOpenModalAddSubsidiary] = useState(false);
  const [selectedSubsidiary, setSelectedSubsidiary] = useState({open: false, mode: '', subsidiary_name: '', id_subsidiary: null});
  const [, setSnack] = useContext(AppContext).snackState;

  useEffect(()=>{
    if(subsidiaries.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:subsidiaries.error,
      })
    }
  },[subsidiaries])

  let subsidiariesToShow = subsidiaries.filter((subsidiary)=> subsidiary.isactive);
  if (search.length > 0) {
    subsidiariesToShow = subsidiaries.filter(a => a.subsidiary_name.toLowerCase().includes(search.toLowerCase()));
  }


    return (
      <Container>
        <Header title="Subsidiárias" subtitle="Gerencie as subsidiárias cadastradas" />
        <Content>
          <Row>
            <Button
              label="Adicionar subsidiária" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddSubsidiary(true)}
             ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar subsidiária..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={subsidiariesToShow || []} 
            hasEditing={true} 
            hasRemoving={true}
            setSelectedItem={setSelectedSubsidiary} 
          ></Table>
        </Content>
        <ModalAddSubsidiary
         open={openModalAddSubsidiary} 
         handleOpen={setOpenModalAddSubsidiary} 
         width={700} 
         height={320} 
         ></ModalAddSubsidiary>
         <ModalEditSubsidiary
          open={selectedSubsidiary.open && selectedSubsidiary.mode === 'edit'} 
          setSelectedItem={setSelectedSubsidiary} 
          selectedItem={selectedSubsidiary}
          width={700} 
          height={330} 
        ></ModalEditSubsidiary>
        <ModalRemoveSubsidiary
          open={selectedSubsidiary.open && selectedSubsidiary.mode === 'remove'} 
          setSelectedItem={setSelectedSubsidiary} 
          subsidiaryName={selectedSubsidiary?.subsidiary_name}
          subsidiaryId={selectedSubsidiary?.id_subsidiary}
          width={600} 
          height={160} 
        ></ModalRemoveSubsidiary>
      </Container>
    );
  };

  export default React.createElement(Subsidiaries);
import styled from "styled-components";

export const Container = styled.div`
  background: var(--background);
  height: 100vh;
  width: 95%;
  padding: 32px;
  overflow-y: auto;
`;

export const Content = styled.div`
  height: auto;
  width: 100%;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
`;

export const Row = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RowPagination = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 16px;
  h1 {
    color: var(--txt-subtitle);
    font-size: 0.8rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: regular;
  }
  img {
    cursor: pointer;
  }
`;

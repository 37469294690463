

import React, {useState, useEffect} from 'react';
import { TableContainer, TableHeader, TableRow, Icons } from './styles'
import { TakeFromStock, ReturnToStock, Eye, Edit, AddCalendar, Pay} from '../../assets/icons/index';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import {formatNumber, formatMoney} from '../../services/functions';
import Button  from '../Button';

function calculateSumByProvider(rows) {
    const sumByProvider = {};
    rows.forEach((row) => {
      const provider = row.providers_name;
      const value = row.current_parcel_value;
      if (!sumByProvider[provider]) {
        sumByProvider[provider] = value;
      } else {
        sumByProvider[provider] += value;
      }
    });
    return sumByProvider;
  }
  

export default function TableSubsidiary({ columns, rows, type, loading, height, fitWidth, setModalEditOrder, setModalVisualizeOrder, setSelectedOrder, setSelectedOrders, setModalChangeExpirationDate, setModalChangeMultipleExpirationDates, setModalChangePaymentDate, setModalChangeMultiplesPaymentDates}) {
  const [rowsState, setRowsState] = useState([...rows]);

  useEffect(()=> {
    setRowsState([...rows])
  }, [rows])

  function getSelectedRows(){
    try {
      let selectedRows = [];
      rowsState.map((row)=>{
        if(row.checked){
          selectedRows.push(row);
        }
      })
      return selectedRows;
    } catch(err){
      console.log(err);
    }
  }

    function createDynamicStyle(){
        try{
            let elementToAdd = fitWidth ? '150px ' : '1fr ';
            let styleString = '1fr 1fr ';
            columns.map((item) => {
                styleString = styleString + elementToAdd;
            });
            styleString = styleString + elementToAdd;
            return styleString;
        } catch(err){
            console.log(err);
        }
    }
    let columnsWidths = createDynamicStyle();

    function handleChangeExpirationDate(rowIndex){
      setModalVisualizeOrder(false);
      setModalChangePaymentDate(false);
      setModalEditOrder(false);
      const selectedRows = getSelectedRows();
      if(selectedRows.length > 0){
        setSelectedOrders(selectedRows);
        setModalChangeMultipleExpirationDates(true);
      } else {
        setSelectedOrder({ ... rows[rowIndex]});
        setModalChangeExpirationDate(true)
      }
    }

    function handleChangePaymentDate(rowIndex){
      setModalVisualizeOrder(false);
      setModalChangeExpirationDate(false);
      setModalEditOrder(false);
      const selectedRows = getSelectedRows();
      if(selectedRows.length > 0){
        setSelectedOrders(selectedRows);
        setModalChangeMultiplesPaymentDates(true);
      } else {
        setSelectedOrder({ ... rows[rowIndex]});
        setModalChangePaymentDate(true)
      }
    }

    function RenderActions({rowIndex}){
                return (
                    <Icons>
                        <Tooltip title="Ver pedido original">
                            <img src={Eye} alt="Olho" onClick={()=>{setSelectedOrder({ ... rows[rowIndex]});setModalEditOrder(false);setModalChangeExpirationDate(false);setModalChangePaymentDate(false);setModalVisualizeOrder(true)}}/>
                        </Tooltip>
                        <Tooltip title="Editar pedido">
                            <img src={Edit} alt="Editar" onClick={()=>{setSelectedOrder({ ... rows[rowIndex]});setModalVisualizeOrder(false);setModalChangeExpirationDate(false);setModalChangePaymentDate(false);setModalEditOrder(true)}}/>
                        </Tooltip>
                        <Tooltip title="Adiar data de vencimento">
                            <img src={AddCalendar} alt="Adiar" onClick={()=>{handleChangeExpirationDate(rowIndex)}}/>
                        </Tooltip>
                        <Tooltip title="Pagar/Receber pedido">
                            <img src={Pay} alt="Pagar/Receber" onClick={()=>{handleChangePaymentDate(rowIndex)}}/>
                        </Tooltip> 
                    </Icons>
                )

    }


    function renderActionsRow(rowIndex){
        return (
<RenderActions rowIndex={rowIndex}></RenderActions>
        )
    }

    function renderTableHeader() {
        return (
          <TableHeader style={{ gridTemplateColumns: columnsWidths }}>
            <h3>Selecionar</h3>
            {columns.map((column, index) => (
              <h3 key={column?.key}>{column?.name}</h3>
            ))}
            <h3>Somatório</h3> {/* Adicionando a coluna "Soma" no cabeçalho */}
            <h3>Ações</h3>
          </TableHeader>
        );
      }

      function handleChangeCheckboxState(newState, rowIndex){
        try{
          let oldRowsState = [...rowsState];
          oldRowsState[rowIndex].checked = newState;
          setRowsState(oldRowsState);
        } catch(err){
          console.log(err);
        }
      }
      
    function RenderRow({ rowIndex }) {
        const sumByProvider = calculateSumByProvider(rows);
      
        const isLastRowWithSameProvider =
          rowIndex === rows.length - 1 || rows[rowIndex].providers_name !== rows[rowIndex + 1].providers_name;
        return (
          <TableRow style={{ gridTemplateColumns: columnsWidths }} key={rowIndex}>
            <h3>
              <Checkbox
              checked={rowsState[rowIndex]?.checked}
              onChange={(event) => handleChangeCheckboxState(event?.target.checked,rowIndex)}
              ></Checkbox>
            </h3>
            {columns.map((column, index) => {
              const key = column.key;
              let value = rows[rowIndex][key];
              const unit = column.unit;
              if (column.type === 'date') {
                if (value) {
                  value = moment(value).add(3, 'hours').format('DD/MM/YYYY');
                }
              }
              if (column.type === 'datetime') {
                if (value) {
                  value = moment(value).format('DD/MM/YYYY [às] HH:mm');
                }
              }
              if (column.type === 'boolean') {
                value = value ? 'Sim' : 'Não';
              }
              if (column.type === 'money') {
                value = formatMoney(value);
              }
              if (column.type === 'percent') {
                value = formatNumber(value * 100);
              }
              if (column.type === 'float') {
                value = formatNumber(value);
              }
              let valueToShow = value || value === 0 ? value + unit : '-';
              return (
                <Tooltip title={value + unit || '-'} key={key}>
                  <h3 key={key} style={{ color: '#54577A' }}>
                    {valueToShow}
                  </h3>
                </Tooltip>
              );
            })}
            {isLastRowWithSameProvider ? (
              <Tooltip title={sumByProvider[rows[rowIndex].providers_name]} key="soma">
                <h3 style={{ color: '#54577A', fontWeight:'bold'}}>
                  {formatMoney(sumByProvider[rows[rowIndex].providers_name]) || '-'}
                </h3>
              </Tooltip>
            ) : (
              <h3 style={{ color: '#54577A' }}>-</h3>
            )}
            {renderActionsRow(rowIndex)}
          </TableRow>
        );
      }
      

    return (
        <TableContainer style={{width: fitWidth ? 'fit-content' : '100%', height: height || '100%'}}>
            {renderTableHeader()}
          {   rows && rows.length > 0 && rows.map((row, index) => (
                    <RenderRow rowIndex={index} key={index} ></RenderRow>
                    ))}
        </TableContainer>
    )
}
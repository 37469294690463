import styled from 'styled-components';
import backgroundImage from '../../assets/images/login_background.png';

export const Container = styled.div`
background-image: url(${backgroundImage});
height: 100vh;
width: 100vw;
background-size: cover;
background-repeat: no-repeat;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const Box = styled.div`
background: var(--txt-title);
height: auto;
max-height:90%;
padding: 2%;
border-radius:10px;
display: flex;
flex-direction: column;
align-items: center;
overflow-y: auto;
scrollbar-width: thin; 
    scrollbar-color: #989898 #FFFFFF; 
    &::-webkit-scrollbar {
    width: 6px; 
    }
    &::-webkit-scrollbar-thumb {
    background-color: #989898; 
    border-radius: 4px; 
    }
    &::-webkit-scrollbar-track {
    background-color: #FFFFFF;
    }
`;

export const LogoRow = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

export const LogoImg = styled.img`
  margin-bottom: 8%;
  transition: transform .2s;
  &:hover {
    transform: scale(1.05);
  }
`;

export const Row = styled.div`
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const RowButton = styled.div`
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.8rem;
`;

export const Explanation  = styled.h1`
    font-weight: 400;
    font-size: 0.8rem !important;
    color: var(--light-grey);
    text-align: center;
    margin-bottom: 0.8rem;
` ;

export const ShowPassword  = styled.h1`
    font-weight: 400;
    font-size: 0.8rem !important;
    color: var(--light-grey);
    text-align: start;
    width: 400px;
` ;

export const PasswordRequirements = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 0.8rem;
`;

export const Requirement  = styled.h1`
    font-weight: 400;
    font-size: 0.8rem !important;
    color: var(--light-grey);
    text-align: center;
    margin-bottom: 4%;
` ;




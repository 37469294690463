import React, {useState, useEffect, useContext} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UseCostCategories } from "../../../hooks/useCostCategories"
// import { NewBoatModal } from "../../components/Modals/NewBoatModal";
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import ModalAddCostCategory from './ModalAddCostCategory/index';
import ModalEditCostCategory from './ModalEditCostCategory/index';
import ModalRemoveCostCategory from './ModalRemoveCostCategory/index';
import AppContext from "../../../state/App.context";

  const columns = [
    {
      name: 'Nome',
      key:'costs_categories_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Descrição',
      key:'type',
      type: 'string', 
      unit:'',
    },
  ]

 function Cost_Categories(){
  const { costCategories } = UseCostCategories();
  const [search, setSearch] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModalAddCostCategory, setOpenModalAddCostCategory] = useState(false);
  const [selectedCostCategory, setSelectedCostCategory] = useState({open: false, mode: '', categoryType: '',  costs_categories_name: '', id_category: null});
  const [, setSnack] = useContext(AppContext).snackState;

  let costsToShow = costCategories.filter((category) => category.isactive);
  if (search.length > 0) {
    costsToShow = costCategories.filter(a => a.costs_categories_name.toLowerCase().includes(search.toLowerCase()));
  }

  useEffect(()=>{
    if(costCategories.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:costCategories.error,
      })
    }
  },[costCategories])

  function Filters(){
    return(
      <></>
    )
  }

    return (
      <Container>
        <Header title="Categorias de custo" subtitle="Gerencie as categorias de custo cadastradas" />
        <Content>
          <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer>
          <Row>
            <Button
              label="Adicionar categoria" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddCostCategory(true)}
             ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar categorias..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={costsToShow || []} 
            hasEditing={true} 
            hasRemoving={true}
            setSelectedItem={setSelectedCostCategory}
          ></Table>
        </Content>
        <ModalAddCostCategory 
         open={openModalAddCostCategory} 
         handleOpen={setOpenModalAddCostCategory} 
         width={700} 
         height={330} 
         ></ModalAddCostCategory>
         <ModalEditCostCategory
          open={selectedCostCategory.open && selectedCostCategory.mode === 'edit'} 
          setSelectedItem={setSelectedCostCategory} 
          selectedItem={selectedCostCategory}
          width={700} 
          height={330} 
        ></ModalEditCostCategory>
        <ModalRemoveCostCategory
          open={selectedCostCategory.open && selectedCostCategory.mode === 'remove'} 
          setSelectedItem={setSelectedCostCategory} 
          costCategoryName={selectedCostCategory?.costs_categories_name}
          costCategoryId={selectedCostCategory?.id_category}
          width={600} 
          height={180} 
        ></ModalRemoveCostCategory>
      </Container>
    );
  };

  export default React.createElement(Cost_Categories);
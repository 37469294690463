
import {createContext , useEffect, useState, ReactNode, useContext} from 'react';
import  api from '../services/api';

interface Account{
    id_account: string;
    account_name: string;
    type: string;
}
type AccountInput = Omit<Account, 'id_account'>

interface Balance{
    id: string;
    value: number;
    fk_accounts_id_account: string;
}
type BalanceInput = Omit<Balance, 'id'>

interface AccountsProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface AccountsContextData {
    accounts: Account [];
    balances: Balance [];
    createAccount: (account: AccountInput) =>Promise<ResponseProps>;
    updateAccount: (account: Account) =>Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
    createBalance: (account: BalanceInput) =>Promise<ResponseProps>;
    getLastBalance: (endDate: string) =>Promise<ResponseProps>;
}

const AccountsContext = createContext<AccountsContextData>(
    {} as AccountsContextData
);


export function AccountsProvider ({children}: AccountsProvidersProps){
      const [accounts, setAccounts] = useState<Account[]>([]);
      const [balances, setBalances] = useState<Balance[]>([]);
      const [refreshOnDelete, setRefreshOnDelete] = useState(0);

  useEffect(() =>{
        api.get('accounts').then(response => setAccounts(response?.data))
        api.get('accounts_balance').then(response => setBalances(response?.data))
    }, [refreshOnDelete])

    async function updateOnDelete (id: number){
            const response =  await api.post('/deleteaccounts',  { id_account: id });
            if (response.status === 200){
                setRefreshOnDelete(oldKey => oldKey + 1);
                return {
                    success: true,
                    message: "Conta removida com sucesso!"
                }
            }
            return {
                success: false,
                message: "Erro ao remover conta."
            }
        }

    async function createAccount (accountInput: AccountInput){
        const response = await  api.post('/accounts', accountInput);
        if (response.status === 200){
            api.get('accounts').then(response => setAccounts(response?.data))
            return {
                success: true,
                message: "Conta adicionada com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar conta."
        }
      
    }

    async function updateAccount (account: Account){
       const response = await api.put('/accounts', account);
       if (response.status === 200){
        setRefreshOnDelete(oldKey => oldKey + 1)
        return {
            success: true,
            message: "Conta editada com sucesso!"
        }
        }
    return {
        success: false,
        message: "Erro ao editar conta."
    }
    }

    async function createBalance (balanceInput: BalanceInput){
        const response = await  api.post('/accounts_balance', balanceInput);
        if (response.status === 200){
            api.get('accounts_balance').then(response => setBalances(response?.data))
            return {
                success: true,
                message: "Saldo adicionado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar saldo."
        }
      
    }

    async function getLastBalance (endDate: string){
        const response =  await api.get('/accounts_balance',  {
            params: { endDate : endDate },
          } );
        if (response.status === 200 && !response?.data?.error ){
            const lastBalances = response?.data;
            return {
                success: true,
                message: "Ok!",
                data: lastBalances,
            }
        }
        return {
            success: false,
            message: response.data && response.data.error ? response.data.error : "Erro ao buscar dados dos saldos." ,
            data: null
        }
    }

    return (
        <AccountsContext.Provider value = {{accounts, balances, createAccount, updateAccount, updateOnDelete, createBalance, getLastBalance}}>
            {children}
        </AccountsContext.Provider>
    );
}

export function UseAccounts(){
    const context = useContext(AccountsContext);
    return context;
}


import {useState} from 'react';
import { Container, Title, Graph, RowSelect, TooltipBox} from './styles'
import { BarChart, XAxis, YAxis, Tooltip, Bar, Legend, ResponsiveContainer, Cell, ReferenceLine} from 'recharts'
import Spinner from '../Spinner';
import { formatMoney } from '../../services/functions'; 

export default function OverviewGraph({ boats,  loading }) {

  let data = [];
  boats.map((boat)=>{
    data.push(
      {
        name: boat.boats_name,
        Saldo: boat?.boat_balance,
        color: boat?.boat_balance >= 0 ? '#03A03E' : '#FF4D5E'
      }
    )
  })


    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload) {
        return (
          <TooltipBox>
            <p className="label">{`${label}`}</p>
            <p className="value" style={{color:'var(--subtitle)'}}>Saldo: {formatMoney(payload[0]?.payload?.Saldo)}</p>
          </TooltipBox>
        );
      }
    
      return null;
    };

    function renderGraph(){
      if(loading) {
        return (
          <Spinner width={40} fontSize={0}></Spinner>
        )
      } 
        return (
            <Graph>
            <ResponsiveContainer width="100%" height={200} >
            <BarChart data={data}>
                <XAxis 
                  dataKey="name" 
                  tickSize={8} 
                  tick={{fontSize:10}}
                  interval={0} 
                  angle={-30}
                  textAnchor="end"
                  height={50}
                  />
                <YAxis tick={{fontSize:12}} tickFormatter={(element)=> element/1000 + 'k'}/>
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine y={0} stroke="#292D32" />
                  <Bar dataKey="Saldo" stackId="a">
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Bar>
            </BarChart>
            </ResponsiveContainer>
            </Graph>
        )
    }
    return (
        <Container>
            <RowSelect>
            <Title>Saldo por barco</Title>
            </RowSelect>
            {renderGraph()}
        </Container>
    )
}
import { useEffect, useState, useContext} from "react";
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import Numberfield from "../../../../components/Numberfield";
import Switch from "../../../../components/Switch";
import Datepicker from "../../../../components/Datepicker";
import { UseBoats } from "../../../../hooks/useBoats"
import {Container, Header, Row, Content} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import AppContext from "../../../../state/App.context";

export default function ModalEditBoat({open, setSelectedItem, width, height, selectedItem}){
    const { boats, updateBoat } = UseBoats();
    const [name, setName] = useState('');
    const [length, setLength] = useState(0);
    const [weight, setWeight] = useState(0);
    const [engine_1, setEngine_1] = useState('');
    const [engine_2, setEngine_2] = useState('');
    const [engine_3, setEngine_3] = useState('');
    const [rgp, setRgp] = useState('');
    const [permissions, setPermissions] = useState(false);
    const [validity, setValidity] = useState('');
    const fontSize = 14;
    const [loading, setLoading] = useState(true);
    const [, setSnack] = useContext(AppContext).snackState;

    function handleClose(){
        setSelectedItem({open: false, mode: '', name: '', id_boat: null});
      }

     function getInfo(){
      try{
        const boat = boats.filter((element)=> element.id_boat === selectedItem?.id_boat)[0];
        setName(boat.boats_name);
        setRgp(boat.rpg);
        setPermissions(boat.permissions);
        setValidity(boat.validity);
        setLength(boat.length);
        setWeight(boat.weigth);
        setEngine_1(boat.engine_1);
        setEngine_2(boat.engine_2);
        setEngine_3(boat.engine_3);
      }catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
    }

    useEffect(()=>{
      if (open) getInfo();
    },[open])

    const title = 'Editar barco';

    function checkingRequiredFields() {
      if (!name || !validity) {
        setSnack({
          open: true,
          severity: 'error', 
          message: 'Preencha todos os campos necessários!',
        });
        return false;
      }
      return true;
    }

    async function handleEditBoat(event){
      try{
        setLoading(true);
        event.preventDefault();
        if(checkingRequiredFields()){
          const response = await updateBoat(
              {
              id_boat: selectedItem.id_boat,
              boats_name: name,
              rgp,
              permissions: permissions,
              validity: validity,
              length,
              weigth: weight,
              engine_1,
              engine_2,
              engine_3,
          }
          );
          if(response.success){
              handleClose();
              setSnack({
                open: true,
                severity: 'success', 
                message:response?.message,
              })
          } else {
            setSnack({
              open: true,
              severity: 'error', 
              message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
            })
          }
      }
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
      
     }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
          return (
            <Content>
            <Row>
                <Textfield
                label="Nome do barco*"
                width={600}
                disabled={false} 
                value={name} 
                fontSize={fontSize} 
                onChange={setName}
                multiline={false}
                ></Textfield>
                <Switch 
                label="Permissão" 
                disabled={false} 
                value={permissions} 
                fontSize={fontSize} 
                onChange={setPermissions}
                multiline={false}
                ></Switch>
            </Row>
            <Row>
                <Datepicker
                label="Validade*"
                width={200}
                disabled={false}
                value={validity} 
                fontSize={fontSize} 
                onChange={setValidity} />
                <Numberfield
                label="Peso"
                width={200}
                disabled={false} 
                value={weight} 
                fontSize={fontSize} 
                onChange={setWeight}
                ></Numberfield>
                 <Numberfield
                label="Comprimento"
                width={200}
                disabled={false} 
                value={length} 
                fontSize={fontSize} 
                onChange={setLength}
                ></Numberfield>
            </Row>
            <Row>
            <Textfield
                label="Motor 1 (hp)"
                width={200}
                disabled={false} 
                value={engine_1} 
                fontSize={fontSize} 
                onChange={setEngine_1}
                multiline={false}
            ></Textfield>
            <Textfield
                label="Motor 2 (hp)"
                width={200}
                disabled={false} 
                value={engine_2} 
                fontSize={fontSize} 
                onChange={setEngine_2}
                multiline={false}
            ></Textfield>
            <Textfield
                label="Motor 3 (hp)"
                width={200}
                disabled={false} 
                value={engine_3} 
                fontSize={fontSize} 
                onChange={setEngine_3}
                multiline={false}
            ></Textfield>
            </Row>
            <Row>
                <Button
                label="Cancelar" 
                background="#8E92BC" 
                color="white" 
                borderColor="#8E92BC" 
                disabled={false} 
                onClick={()=> handleClose()}
                ></Button>
                <Button
                label="Salvar alterações" 
                background="#256CE1" 
                color="white" 
                borderColor="#256CE1" 
                disabled={false} 
                onClick={handleEditBoat}
                ></Button>
            </Row>
        </Content>
          )
        }
  
      }
  
    return (
        <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleClose()} alt="Fechar"></img>
          </Header>
          {renderContent()}
   
        </Container>
      </Modal>
       )
  }
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Container, Label } from './styles';

interface OptionInterface {
  label: string;
  value: string;
}

interface MultiSelectPropsInterface {
  label: string;
  width: string;
  placeholder?: string;
  disabled?: boolean;
  selectOptions: OptionInterface[];
  defaultValue?: OptionInterface[];  // Para valores padrão múltiplos
  fontSize: string;
  handleChange: (value: OptionInterface[]) => void;
}

export default function MultiSelect({
  label,
  width,
  placeholder,
  disabled,
  selectOptions,
  defaultValue,
  fontSize,
  handleChange,
}: MultiSelectPropsInterface) {
  return (
    <Container>
      <Label style={{ fontSize: fontSize }}>{label}:</Label>
      <Autocomplete
        id="multiselect-in-modal-autocomplete"
        multiple
        options={selectOptions}
        size="small"
        getOptionLabel={(option) => option.label}
        defaultValue={defaultValue || []}
        onChange={(event, value) => handleChange(value)}
        sx={{
          width: width,
          background: '#FFFFFF',
          borderRadius: '10px',
          border: '0.2px solid #8E92BC',
          '& input': {
            color: '#141522',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '0.7rem',
          }
        }}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} variant="outlined" style={{color: '#141522'}} />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disabled={disabled}
        loadingText="Carregando..."
        noOptionsText="Nenhuma opção selecionada"
      />
    </Container>
  );
}

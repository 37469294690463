import {createContext , useEffect, useState, ReactNode, useContext} from 'react';
import  api from '../services/api';


interface Transaction{
    id_order: string,
    id_transaction: string,
    isactive: boolean,
    product_amount: number,
    unity_price: number,
    total_value: number,
    fk_costs_categories_id_category: string,
    fk_products_id_product: string,
}

type TransactionInput = Omit<Transaction, 'id_transaction'>

interface TransactionsProviderProps {
    //Para aceitar qualquer tipo de conteudo (Children) válido dentro do react
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface TransactionsContextData {
    transactions: Transaction [];
    loading: boolean;
    createTransaction: (transaction: TransactionInput) => Promise<ResponseProps>;
    updateTransaction: (transaction: Transaction) => Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
}

const TransactionsContext = createContext<TransactionsContextData>(
    // Para burlar o TS forçando a tipagem que a gente quer
    {} as TransactionsContextData
    );


    export function TransactionsProvider({ children } : TransactionsProviderProps){
        const [transactions, setTransactions] =useState<Transaction[]>([])
        const [loading, setLoading] = useState<boolean>(true);
        const [LogTransactions, setLogTransactions] =useState<Transaction[]>([])
        const [LogTransactionsFirst, setLogTransactionsFirst] =useState<Transaction[]>([])
        const [refreshOnDelete, setRefreshOnDelete] = useState(0);

        // useEffect(() =>{
        //     api.get('transactions')
        //     .then(response => {setTransactions(response.data); setLoading(false)})
        //     api.get('transactions')
        //     .then(response => setLogTransactionsFirst(response.data))
            
        // },[refreshOnDelete])

        useEffect(() =>{
            if(LogTransactionsFirst.length > 0){
            setLogTransactions([...LogTransactionsFirst]);
            }
        }, [LogTransactionsFirst])

        async function updateOnDelete (id: number){
            const Id = {id};
            const response = await api.post('/deletetransactions', Id);
            if (response.status === 200){
                setRefreshOnDelete(oldKey => oldKey + 1);
                return {
                    success: true,
                    message: "Transação removida com sucesso!"
                }
            }
            return {
                success: false,
                message: "Erro ao remover transação."
            }
        }

        async function updateTransaction(transaction: Transaction){
            const response = await api.put('/transactions', transaction)        
            if (response.status === 200){
                setRefreshOnDelete(oldKey => oldKey + 1);
                return {
                    success: true,
                    message: "Transação editada com sucesso!"
                }
            }
            return {
                success: false,
                message: "Erro ao editar transação."
            }
        }

         async function createTransaction(transactionInput: TransactionInput){
            const response = await api.post('/transactions', transactionInput)        
            if (response.status === 200){
                api.get('transactions').then(response => setTransactions(response.data))
                return {
                    success: true,
                    message: "Transação adicionada com sucesso!"
                }
            }
            return {
                success: false,
                message: "Erro ao adicionar transação."
            }

           
        }

        return (
            <TransactionsContext.Provider value={{ transactions, loading,  createTransaction, updateTransaction, updateOnDelete }}>
                {children}
            </TransactionsContext.Provider>
        );

}

export function UseTransactions(){
    const context = useContext(TransactionsContext);

    return context;
}
import styled from 'styled-components';

export const Container = styled.div`
    width:100%;
    height: 114px; ;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: start;
    background: var(--white);
    border-radius: 10px;
    padding: 16px 30px;
    margin-top: 12px;
    ` ;

export const TitleRow = styled.div`
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
` ;

export const Title = styled.h1`
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 180%;
    color: var(--txt-title);
    text-align: left;
` ;

export const Value = styled.h1`
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 180%;
    color: var(--blue);
    text-align: left;
` ;

export const Bar = styled.div`
    width: 100%;
    height: 20%;
    display: flex;
    // flex-direction: column;
    // justify-content:flex-start;
    margin-top:18px;
    border-radius: 8px;
    background: var(--light-blue);

` ;
export const Progress = styled.div`
    height: 100%;
    background: var(--blue);
    border-radius: 8px;
` ;





export const defaultFilters = {
    type: "''",
    costCenter: "''",
    subsidiary: "''",
    provider: "''",
    trip: "''",
    harvest: "''",
    paymentType: "''",
    boat: "''", 
    status: "''", 
    startDate:"''",
    endDate:"''",
    pages: 1, // implement
    limit: 10,
  };

export const columns = [
  {
    name: 'Categoria',
    key:'category',
    type: 'string', 
    unit: '',
    show: true,
  },
    {
      name: 'Forn./Comp.',
      key:'providers_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Status',
      key:'status_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Tipo',
      key:'type',
      type: 'string', 
      unit: '',
      show: true,
    },
  
    {
      name: 'Tipo de pagamento',
      key:'paymenttype_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Qtd. Parcelas',
      key:'amount_parcels',
      type: 'number', 
      unit: '',
      show: true,
    },
    // {
    //   name: 'Qtd. Parcelas Pagas',
    //   key:'qty_parcels_exec',
    //   type: 'number', 
    //   unit: '',
    //   show: true,
    // },
    {
      name: 'Valor total original',
      key:'total_order_value',
      type: 'money', 
      unit: '',
      show: true,
    },
    {
      name: 'Valor total atualizado',
      key:'total_order_value_updated',
      type: 'money', 
      unit: '',
      show: true,
    },
    {
      name: 'Desconto',
      key:'discount',
      type: 'money', 
      unit: '',
      show: true,
    },
    {
      name: 'Valor total original com desconto',
      key:'total_order_value_with_discount',
      type: 'money', 
      unit: '',
      show: true,
    },
    {
      name: 'Valor total pago',
      key:'total_order_value_exec',
      type: 'money', 
      unit: '',
      show: true,
    },
    {
      name: 'É custo indireto?',
      key:'is_indirect_cost',
      type: 'boolean', 
      unit: '',
      show: true,
    },
    {
      name: 'Subsidiária',
      key:'subsidiary_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Cent. Custo',
      key:'costs_center_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Viagem',
      key:'trips_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Safra',
      key:'harvest',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Barco',
      key:'boats_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Pagamento',
      key:'payment_date_name',
      type: 'date', 
      unit: '',
      show: true,
    },
    {
      name: 'Vencimento',
      key:'expiration_date_name',
      type: 'date', 
      unit: '',
      show: true,
    },
    
    {
      name: 'Criado em',
      key:'created_at',
      type: 'date', 
      unit: '',
      show: true,
    },
    {
      name: 'Criado por',
      key:'created_by',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Aprovado',
      key:'approved',
      type: 'boolean', 
      unit: '',
      show: true,
    },
    {
      name: 'Aprovado por',
      key:'approved_by',
      type: 'string', 
      unit: '',
      show: true,
    },
  ]


  export const harvestOptions = [
    {
      id: 2023,
      label: '2023',
    },
    {
      id: 2024,
      label: '2024',
    },
    {
      id: 2025,
      label: '2025',
    },
  ];
  
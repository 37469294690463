import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 24px;
  padding: 16px 24px;
  max-height: 90vh;
  height: auto;
  width: 700px;
`;

export const Header = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dce4ff;
  position: relative;
  padding-bottom: 8px;
  h1 {
    font-weight: 600;
    font-size: 1rem !important;
    color: var(--txt-title);
    text-align: center;
  }
  img {
    height: 1.2rem;
    position: absolute;
    right: 0px;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const Content = styled.div`
  height: 800px;
  width: 100%;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  h2 {
    font-weight: 600;
    font-size: 0.7rem !important;
    color: var(--txt-title);
    text-align: left;
    width: 80%;
  }
`;

export const CategoryInfo = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 16px;
  h3 {
    font-weight: 400;
    font-size: 0.7rem !important;
    color: var(--txt-body);
    text-align: left;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
`;
export const RowBoat = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 4px 0px;
`;

export const Divider = styled.div`
  color: var(--txt-subtitle);
  width: 100%;
  height: 0px;
  border: 0.1px solid rgba(84, 87, 122, 0.2);
  margin-top: 4px;
  margin-bottom: 4px;
`;

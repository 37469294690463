import React, {useState, useContext} from 'react';
import Button from "../../../components/Button";
import Switch from "../../../components/Switch";

import {Container, Header, Row, Content, Grid} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import { UseUsers } from '../../../hooks/useUsers';
import LocalStorageService from '../../../services/storage';
import AppContext from "../../../state/App.context";

export default function ModalChooseColumns({width, height, open, columnsToShow, handleOpen}){
    const title = 'Colunas para exibir';
    const fontSize = 10;
    const [columnsPreferences, setColumnsPreferences] = useState([...columnsToShow]);
    const {setUserPreferences} = UseUsers();
    const [, setSnack] = useContext(AppContext).snackState;
    const localStorageService = LocalStorageService();

    async function settingColumnsPreferences(){
      try {
        let newColumnsPreferences = [];
        columnsPreferences.map((column)=>{
          newColumnsPreferences.push({
            name: column.key,
            // status: column.show ? 'true':'false',
            status: column.show 

          })
        });
        const userInfo = localStorageService.getUserInfo();
        const id_user = userInfo?.id_user;
        const responsePreferences = await setUserPreferences(id_user, newColumnsPreferences);
        if(responsePreferences.success){
          setSnack({
            open: true,
            severity: 'success', 
            message:responsePreferences?.message,
          });
          handleOpen(false);
        }
      } catch (err){
        console.log(err);
      }
    }

    return (
        <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
        <Header>
          <h1>{title}</h1>
          <img src={Close} onClick={() => handleOpen(false)} alt="Fechar"></img>
        </Header>
        <Content>
              <Row >
                <h1>Selecione as colunas que deseja visualizar:</h1>
              </Row>
              <Grid>
              {columnsPreferences && columnsPreferences.map((column, index)=>{
                return (
               <Switch 
               key={index}
                label={column.name}
                disabled={false} 
                value={column.show} 
                fontSize={fontSize} 
                onChange={(value)=>{
                  let copyColumns = [... columnsToShow];
                  copyColumns[index].show = value;
                  setColumnsPreferences(copyColumns);
                }}
                multiline={false}
                ></Switch>
                )
              })}
              </Grid>
                  <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleOpen(false)}
                    ></Button>
                    <Button
                    label="Salvar alterações" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={()=>settingColumnsPreferences()}
                    ></Button>
                </Row>
            </Content>
        </Container>
      </Modal>
       )
  }
import Button from "../../../components/Button";
import Autocomplete from "../../../components/Autocomplete";
import Datepicker from "../../../components/Datepicker";
import { UseCostCenters } from "../../../hooks/useCostCenters";
import { UseSubsidiaries } from "../../../hooks/useSubsidiaries";
import { UseProviders } from "../../../hooks/useProviders";
import { UseTrips} from "../../../hooks/useTrips";
import { UsePaymentTypes} from "../../../hooks/usePaymentTypes";
import { UseBoats} from "../../../hooks/useBoats";

export const harvestOptions = [
  {
    id: 2023,
    label: '2023',
  },
  {
    id: 2024,
    label: '2024',
  },
  {
    id: 2025,
    label: '2025',
  },
];



export default function OrdersFilters({filters, setFilters, defaultFilters}){
    const { costCenters } = UseCostCenters();
    const { providers } = UseProviders();
    const { subsidiaries } = UseSubsidiaries();
    const { trips } = UseTrips();
    const { paymentTypes } = UsePaymentTypes();
    const { boats } = UseBoats();


    let typeOptions = [
        {
          id: "''", 
          label:'Todos'
        },
        {
          id: 'Entrada',
          label: 'Entrada',
        },
        {
          id: 'Saída',
          label: 'Saída',
        }
      ]

      let statusOptions = [
        {
          id: "''", 
          label:'Todos'
        },
        {
          id: 'Executado',
          label: 'Executado',
        },
        {
          id: 'Pendente',
          label: 'Pendente',
        }
      ]

    let costCentersOptions = [];
    costCenters && !costCenters.error && costCenters.map((element)=> {
      if(element.isactive) {
        costCentersOptions.push({
        id: element?.costs_center_name,
        label: element?.costs_center_name,
        })
      }
  });

let subsidiariesOptions = [];
subsidiaries && !subsidiaries.error && subsidiaries.map((element)=> {
  if(element.isactive) {
    subsidiariesOptions.push({
      id: element?.subsidiary_name,
      label: element?.subsidiary_name,
    })
  }
 }
)

let providersOptions = [];
providers && !providers.error && providers.map((element)=> {
  if(element.isactive) {
    providersOptions.push(
      {
        id: element?.providers_name,
        label: element?.providers_name,
      }
    )
  }
})


  let tripsOptions = [];

  // trips && !trips.error && trips.map((trip)=> {
  //   if(trip.isactive) {
  //       tripsOptions.push({
  //         id:  trip?.trips_name + '-' + trip?.harvest,
  //         label: trip?.trips_name + '-' + trip?.harvest,
  //       })
  //   }
  // })
  
  trips && !trips.error && trips.map((trip)=> {
    const index = tripsOptions.findIndex((option) => option.label === trip.name);
    if(index === -1 && trip.isactive && tripsOptions.findIndex((element) => element.id === trip.trips_name) === -1){
      tripsOptions.push({
        id: trip?.trips_name,
        label: trip?.trips_name
      })
    }
  })

  let paymentTypesOptions = [];
  paymentTypes && !paymentTypes.error && paymentTypes.map((element)=> {
    if(element.isactive) {
      paymentTypesOptions.push(
        {
          id: element?.paymenttype_name,
          label: element?.paymenttype_name,
        }
      )
    }
});

  let boatsOptions = [];
  boatsOptions && !boatsOptions.error && boats.map((element)=> {
    if(element.isactive) {
      boatsOptions.push(
        {
          id: element?.boats_name,
          label: element?.boats_name,
        }
      )
    }
  })

    function cleanFilters(){
        setFilters({...defaultFilters});
      }


    return (
       
        <div style={{marginTop: 16}}>
        <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Button 
            label="Limpar filtros" 
            background="transparent" 
            color="#54577A" 
            borderColor="#54577A" 
            disabled={false} 
            onClick={()=> {cleanFilters()}}
            ></Button>
          </div>
          <Datepicker
            label="Vencimento entre"
            width={200}
            disabled={false}
            value={filters?.startDate} 
            fontSize={'0.7rem'} 
            onChange={(element)=> {
                let filtersCopy = {... filters};
                filtersCopy.startDate = element;
                filtersCopy.pages = 1;
                setFilters(filtersCopy);
            }} />
          <Datepicker
          label="e"
          width={200}
          disabled={false}
          value={filters?.endDate} 
          fontSize={'0.7rem'} 
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.endDate = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }} />
         <br></br>   
         <Autocomplete
          value={filters?.type}
          width={200}
          disabled={false}
          options={typeOptions}
          fontSize={'0.7rem'} 
          label="Tipo"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.type = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
          ></Autocomplete>   
          <br></br>   
          <Autocomplete
          value={filters?.status}
          width={200}
          disabled={false}
          options={statusOptions}
          fontSize={'0.7rem'} 
          label="Status"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.status = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
          ></Autocomplete>   
          <br></br>  
         <Autocomplete
          value={filters?.provider}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todos'}, ...providersOptions]}
          fontSize={'0.7rem'}  
          label="Fornecedor/Comprador"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.provider = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
          ></Autocomplete>
          <br></br>
        <Autocomplete
          value={filters?.costCenter}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todos'}, ...costCentersOptions]}
          fontSize={'0.7rem'}  
          label="Centro de Custo"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.costCenter = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
          ></Autocomplete>
          <br></br>
        <Autocomplete
          value={filters?.subsidiary}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todas'}, ...subsidiariesOptions]}
          fontSize={'0.7rem'}  
          label="Subsidiária"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.subsidiary = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
              <br></br>
        <Autocomplete
          value={filters?.trip}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todas'}, ...tripsOptions]}
          fontSize={'0.7rem'}  
          label="Viagem"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.trip = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
        <br></br>
        <Autocomplete
          value={filters?.harvest}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todas'}, ...harvestOptions]}
          fontSize={'0.7rem'}  
          label="Safra"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.harvest = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
        <br></br>
        <Autocomplete
          value={filters?.boat}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todos'}, ...boatsOptions]}
          fontSize={'0.7rem'}  
          label="Barco"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.boat = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
        <br></br>
        <Autocomplete
          value={filters?.paymentType}
          width={200}
          disabled={false}
          options={[{id:"''", label:'Todos'}, ...paymentTypesOptions]}
          fontSize={'0.7rem'}  
          label="Tipo de pagamento"
          onChange={(element)=> {
            let filtersCopy = {... filters};
            filtersCopy.paymentType = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
        }}
        ></Autocomplete>
      </div>
       )
  }
import React, {useEffect, useState, useContext} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UseFishingTypes } from "../../../hooks/useFishingTypes"
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import ModalAddFishingType from './ModalAddFishingType/index';
import ModalEditFishingType from './ModalEditFishingType/index';
import ModalRemoveFishingType from './ModalRemoveFishingType/index';
import AppContext from "../../../state/App.context";

  const columns = [
    {
      name: 'Nome',
      key:'fishingtypes_name',
      type: 'string', 
      unit:'',
    },
  ]

function FishingTypes(){
  const { fishingTypes } = UseFishingTypes();
  const [search, setSearch] = useState('');
  const [openModalAddFishingType, setOpenModalAddFishingType] = useState(false);
  const [selectedFishingType, setSelectedFishingType] = useState({open: false, mode: '', fishingtypes_name: '', id_fishingtype: null});
  const [, setSnack] = useContext(AppContext).snackState;

  let fishingTypesToShow = fishingTypes.filter((type)=> type.isactive );
  if (search.length > 0) {
    fishingTypesToShow = fishingTypes.filter(a => a.fishingtypes_name.toLowerCase().includes(search.toLowerCase()));
  }
  useEffect(()=>{
    if(fishingTypes.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:fishingTypes.error,
      })
    }
  },[fishingTypes])


    return (
      <Container>
        <Header title="Espécies Alvo" subtitle="Gerencie as espécies alvo cadastradas" />
        <Content>
          {/* <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer> */}
          <Row>
            <Button
              label="Adicionar espécie" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddFishingType(true)}
             ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar espécie alvo..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={fishingTypesToShow || []} 
            hasEditing={true} 
            hasRemoving={true}
            setSelectedItem={setSelectedFishingType} 
          ></Table>
        </Content>
        <ModalAddFishingType 
         open={openModalAddFishingType} 
         handleOpen={setOpenModalAddFishingType} 
         width={700} 
         height={240} 
         ></ModalAddFishingType>
         <ModalEditFishingType
          open={selectedFishingType.open && selectedFishingType.mode === 'edit'} 
          setSelectedItem={setSelectedFishingType} 
          selectedItem={selectedFishingType}
          width={700} 
          height={240} 
        ></ModalEditFishingType>
        <ModalRemoveFishingType
          open={selectedFishingType.open && selectedFishingType.mode === 'remove'} 
          setSelectedItem={setSelectedFishingType} 
          fishingTypeName={selectedFishingType?.fishingtypes_name}
          fishingTypeId={selectedFishingType?.id_fishingtype}
          width={600} 
          height={170} 
        ></ModalRemoveFishingType>
      </Container>
    );
  };

  export default React.createElement(FishingTypes);

import {createContext , useEffect, useState, ReactNode, useContext} from 'react';
import  api from '../services/api';


interface Product{
    id_product: string;
    type: string;
    product_name: string;
    description: string;
    id_providers: string;

}

interface ProductsProviders{
    id: string;
    fk_providers_id_providers: string;
    fk_products_id_product: string;
}

type ProductInput = Omit<Product, 'id_product'>

type ProductUpdateInput = Omit<Product, 'id_providers'>


interface ProductsProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface ProductsContextData { 
    products: Product [];
    productsProviders: ProductsProviders [];
    createProduct: (product: ProductInput) =>Promise<ResponseProps>;
    updateProduct: (product: ProductUpdateInput) =>Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
}

const ProductsContext = createContext<ProductsContextData>(
    {} as ProductsContextData
);


export function ProductsProvider ({children}: ProductsProvidersProps){
      const [products, setProducts] = useState<Product[]>([]);
      const [productsProviders, setProductsProviders] = useState<ProductsProviders[]>([]);
      const [refreshOnDelete, setRefreshOnDelete] = useState(0);

  useEffect(() =>{
        api.get('products').then(response => setProducts(response.data));
    }, [refreshOnDelete])

    async function updateOnDelete (id: number){
            const response =  await api.post('/deleteproducts',  { id_product: id });
            if (response.status === 200){
                setRefreshOnDelete(oldKey => oldKey + 1);
                return {
                    success: true,
                    message: "Removido com sucesso!"
                }
            }
            return {
                success: false,
                message: "Erro ao remover."
            }
        }

    async function createProduct (productInput: ProductInput){
        const productInfo = {
            type: productInput.type,
            product_name: productInput.product_name,
            description: productInput.description,
        }
        const response = await  api.post('/products', productInfo);
        if (response.status === 200) {
            api.get('products').then(response => setProducts(response.data))
            return {
                success: true,
                message: "Adicionado com sucesso!"
            } 
        }
        return {
            success: false,
            message: "Erro ao adicionar."
        }
      
    }

    async function updateProduct (product: ProductUpdateInput){
       const response = await api.put('/products', product);
       if (response.status === 200){
        setRefreshOnDelete(oldKey => oldKey + 1)
        return {
            success: true,
            message: "Editado com sucesso!"
        }
        }
    return {
        success: false,
        message: "Erro ao editar."
    }
    }

    return (
        <ProductsContext.Provider value = {{products,productsProviders, createProduct, updateProduct, updateOnDelete}}>
            {children}
        </ProductsContext.Provider>
    );
}

export function UseProducts(){
    const context = useContext(ProductsContext);
    return context;
}
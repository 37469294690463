import { FormEvent, useEffect, useState, useContext} from "react";
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import Numberfield from "../../../../components/Numberfield";
import Switch from "../../../../components/Switch"; 
import Datepicker from "../../../../components/Datepicker";
import { UseBoats } from "../../../../hooks/useBoats"
import {Container, Header, Row, Content} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import AppContext from "../../../../state/App.context";

export default function ModalAddBoat({open, handleOpen, width, height}){
    const { createBoat } = UseBoats();
    const [, setSnack] = useContext(AppContext).snackState;
    const [name, setName] = useState('');
    const [length, setLength] = useState(0);
    const [weight, setWeight] = useState(0);
    const [engine_1, setEngine_1] = useState('');
    const [engine_2, setEngine_2] = useState('');
    const [engine_3, setEngine_3] = useState('');
    const [rgp, setRgp] = useState('');
    const [permissions, setPermissions] = useState(false);
    const [validity, setValidity] = useState('');
    const fontSize = 14;
    const [loading, setLoading] = useState(false);

    const title = 'Adicionar novo barco';

    function cleanAllInputs(){
        try{
            setName('');
            setLength(0);
            setWeight(0);
            setEngine_1('');
            setEngine_2('');
            setEngine_3('');
            setRgp('');
            setPermissions(false);
            setValidity('');
        } catch(err){
             console.log(err);
        }
    
    }

    function checkingRequiredFields() {
        if (!name || !validity) {
          setSnack({
            open: true,
            severity: 'error', 
            message: 'Preencha todos os campos necessários!',
          });
          return false;
        }
        return true;
      }

    async function handleCreateNewBoat(event){
        try{
            setLoading(true);
            event.preventDefault();
            if(checkingRequiredFields()){
            const response = await createBoat(
                {
                boats_name: name,
                rgp,
                permissions: permissions,
                validity: validity,
                length,
                weigth: weight,
                engine_1,
                engine_2,
                engine_3,
            }
            );
            setLoading(false);
            if(response.success){
                cleanAllInputs();
                handleOpen(false);
                setSnack({
                    open: true,
                    severity: 'success', 
                    message:response.message || 'Sucesso!',
                  })
            } else {
                setSnack({
                    open: true,
                    severity: 'error', 
                    message:response.message ||  'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
                  })
            }
        }
        } catch(err){
            console.log(err);
        } finally{
            setLoading(false);
        }
     }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
            return(
<Content>
                <Row>
                    <Textfield
                    label="Nome do barco*"
                    width={600}
                    disabled={false} 
                    value={name} 
                    fontSize={fontSize} 
                    onChange={setName}
                    multiline={false}
                    ></Textfield>
                    <Switch 
                    label="Permissão" 
                    disabled={false} 
                    value={permissions} 
                    fontSize={fontSize} 
                    onChange={setPermissions}
                    ></Switch>
                </Row>
                <Row>
                    <Datepicker
                    label="Validade*"
                    width={200}
                    disabled={false}
                    value={validity} 
                    fontSize={fontSize} 
                    onChange={setValidity} />
                    <Numberfield
                    label="Peso"
                    width={200}
                    disabled={false} 
                    value={weight} 
                    fontSize={fontSize} 
                    onChange={setWeight}
                    ></Numberfield>
                    <Numberfield
                    label="Comprimento"
                    width={200}
                    disabled={false} 
                    value={length} 
                    fontSize={fontSize} 
                    onChange={setLength}
                    ></Numberfield>
                </Row>
                <Row>
                    <Textfield
                        label="Motor 1 (hp)"
                        width={200}
                        disabled={false} 
                        value={engine_1} 
                        fontSize={fontSize} 
                        onChange={setEngine_1}
                        multiline={false}
                    ></Textfield>
                    <Textfield
                        label="Motor 2 (hp)"
                        width={200}
                        disabled={false} 
                        value={engine_2} 
                        fontSize={fontSize} 
                        onChange={setEngine_2}
                        multiline={false}
                    ></Textfield>
                    <Textfield
                        label="Motor 3 (hp)"
                        width={200}
                        disabled={false} 
                        value={engine_3} 
                        fontSize={fontSize} 
                        onChange={setEngine_3}
                        multiline={false}
                    ></Textfield>
                </Row>
                <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleOpen(false)}
                    ></Button>
                    <Button
                    label="Adicionar barco" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={handleCreateNewBoat}
                    ></Button>
                </Row>
        </Content>
            )
            
        }}

    return (
        <Modal
        open={open}
        onClose={() => { handleOpen(false); }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title} 
          </h1>
          <img src={Close} onClick={()=> handleOpen(false)} alt="Fechar"></img>
          </Header>
          {renderContent()}
 
        </Container>
      </Modal>
       )
  }
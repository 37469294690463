import React, {useState, useContext, useEffect} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UseCostCenters } from "../../../hooks/useCostCenters"
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import ModalAddCostCenter from './ModalAddCostCenter/index';
import ModalEditCostCenter from './ModalEditCostCenter/index';
import ModalRemoveCostCenter from './ModalRemoveCostCenter/index';
import AppContext from "../../../state/App.context";

  const columns = [
    {
      name: 'Nome',
      key:'costs_center_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Descrição',
      key:'description',
      type: 'string', 
      unit:'',
    },
  ]

 function Cost_Centers(){
  const { costCenters } = UseCostCenters();
  const [search, setSearch] = useState('');
  const [openModalAddCostCenter, setOpenModalAddCostCenter] = useState(false);
  const [selectedCost, setSelectedBoat] = useState({open: false, mode: '', costs_center_name: '', id_cost: null});
  const [, setSnack] = useContext(AppContext).snackState;

  useEffect(()=>{
    if(costCenters.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:costCenters.error,
      })
    }
  },[costCenters])

  let costsToShow = costCenters.filter((cost)=> cost.isactive);
  if (search.length > 0) {
    costsToShow = costCenters.filter(a => a.costs_center_name.toLowerCase().includes(search.toLowerCase()));
  }


    return (
      <Container>
        <Header title="Centros de Custo" subtitle="Gerencie os centro de custo cadastrados" />
        <Content>
          {/* <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer> */}
          <Row>
            <Button
              label="Adicionar centro de custo" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddCostCenter(true)}
             ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar centros de custo..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={costsToShow || []} 
            hasEditing={true} 
            hasRemoving={true}
            setSelectedItem={setSelectedBoat} 
          ></Table>
        </Content>
        <ModalAddCostCenter 
         open={openModalAddCostCenter} 
         handleOpen={setOpenModalAddCostCenter} 
         width={700} 
         height={330} 
         ></ModalAddCostCenter>
         <ModalEditCostCenter
          open={selectedCost.open && selectedCost.mode === 'edit'} 
          setSelectedItem={setSelectedBoat} 
          selectedItem={selectedCost}
          width={700} 
          height={330} 
        ></ModalEditCostCenter>
        <ModalRemoveCostCenter
          open={selectedCost.open && selectedCost.mode === 'remove'} 
          setSelectedItem={setSelectedBoat} 
          boatName={selectedCost?.costs_center_name}
          boatId={selectedCost?.id_cost}
          width={600} 
          height={170} 
        ></ModalRemoveCostCenter>
      </Container>
    );
  };

  export default React.createElement(Cost_Centers);


import {Button} from './styles';

export default function CustomizedButton({label, background, color, disabled, borderColor, onClick}){

    return (
    <Button
     style={{
        background,
        color,
        border: '1px solid ' + borderColor,
        paddingLeft: 16,
        cursor: disabled ? 'default' : 'pointer',
        }}
        disabled={disabled}
        onClick={onClick}
        > {label}</Button> 
    )
}
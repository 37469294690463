

import React, {useState, useContext, useEffect} from 'react'
import { Container, Title, RowInfo,SectionTitle, Section} from './styles'
import { formatMoney } from '../../services/functions';


export default function FinantialBalance({ total, manager, cash }) {  

    return (
        <Container>
            <Section>
            <SectionTitle>Espécie</SectionTitle>
                <RowInfo>
                    <h1>Saldo:</h1>
                    <h1 style={{color: cash?.balance > 0 ? "#03A03E" : "#FF4D5E"}}>{formatMoney(cash?.balance)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1>Pagamentos:</h1>
                    <h1 style={{color: "#FF4D5E"}}>-{formatMoney(cash?.sum_resolved_outputs + cash?.sum_pending_outputs)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1>Recebimentos:</h1>
                    <h1 style={{color: "#03A03E"}}>+{formatMoney(cash?.sum_resolved_inputs + cash?.sum_pending_inputs)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1><b>Resultado:</b></h1>
                    <h1 style={{color: cash?.result > 0 ? "#03A03E" : "#FF4D5E"}}><b>{formatMoney(cash?.result)}</b></h1>
                </RowInfo>
        </Section>
        <Section>
            <SectionTitle>Gerenciador</SectionTitle>
            <RowInfo>
                    <h1>Saldo:</h1>
                    <h1 style={{color: manager?.balance > 0 ? "#03A03E" : "#FF4D5E"}}>{formatMoney(manager?.balance)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1>Pagamentos:</h1>
                    <h1 style={{color: "#FF4D5E"}}>-{formatMoney(manager?.sum_resolved_outputs + manager?.sum_pending_outputs)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1>Recebimentos:</h1>
                    <h1 style={{color: "#03A03E"}}>+{formatMoney(manager?.sum_resolved_inputs + manager?.sum_pending_inputs)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1><b>Resultado:</b></h1>
                    <h1 style={{color: manager?.result > 0 ? "#03A03E" : "#FF4D5E"}}><b>{formatMoney(manager?.result)}</b></h1>
                </RowInfo>
        </Section>
        <Section>
            <SectionTitle>Total</SectionTitle>
            <RowInfo>
                    <h1>Saldo:</h1>
                    <h1 style={{color: total?.balance > 0 ? "#03A03E" : "#FF4D5E"}}>{formatMoney(total?.balance)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1>Pagamentos:</h1>
                    <h1 style={{color: "#FF4D5E"}}>-{formatMoney(total?.sum_resolved_outputs + total?.sum_pending_outputs)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1>Recebimentos:</h1>
                    <h1 style={{color: "#03A03E"}}>+{formatMoney(total?.sum_resolved_inputs + total?.sum_pending_inputs)}</h1>
                </RowInfo>
                <RowInfo>
                    <h1><b>Resultado:</b></h1>
                    <h1 style={{color: total?.result > 0 ? "#03A03E" : "#FF4D5E"}}><b>{formatMoney(total?.result)}</b></h1>
                </RowInfo>
                </Section>
        </Container>
    )
}

import {createContext , useEffect, useState, ReactNode, useContext} from 'react';
import  api from '../services/api';

interface CostCategory{
    id_category: string;
    costs_categories_name: string;
    type: string;

}
type CostCategoryInput = Omit<CostCategory, 'id_category'>

interface CostCategoriesProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface CostCategoriesContextData {
    costCategories: CostCategory [];
    createCostCategory: (costCategory: CostCategoryInput) =>Promise<ResponseProps>;
    updateCostCategory: (costCategory: CostCategory) =>Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
}

const CostCategoriesContext = createContext<CostCategoriesContextData>(
    {} as CostCategoriesContextData
);


export function CostCategoriesProvider ({children}: CostCategoriesProvidersProps){
      const [costCategories, setCostCategories] = useState<CostCategory[]>([]);
      const [refreshOnDelete, setRefreshOnDelete] = useState(0);

  useEffect(() =>{
        api.get('categories').then(response => setCostCategories(response.data))
    }, [refreshOnDelete])

    async function updateOnDelete (id: number){
            const response =  await api.post('/deletecategory',  { id_category: id });
            if (response.status === 200){
                setRefreshOnDelete(oldKey => oldKey + 1);
                return {
                    success: true,
                    message: "Categoria removida com sucesso!"
                }
            }
            return {
                success: false,
                message: "Erro ao remover categoria."
            }
        }

    async function createCostCategory (costCategoryInput: CostCategoryInput){
        const response = await  api.post('/categories', costCategoryInput);
        if (response.status === 200){
            api.get('categories').then(response => setCostCategories(response.data))
            return {
                success: true,
                message: "Categoria adicionada com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar categoria."
        }
      
    }

    async function updateCostCategory (costCategory: CostCategory){
       const response = await api.put('/categories', costCategory);
       if (response.status === 200){
        setRefreshOnDelete(oldKey => oldKey + 1)
        return {
            success: true,
            message: "Categoria editada com sucesso!"
        }
        }
    return {
        success: false,
        message: "Erro ao editar categoria."
    }
    }

    return (
        <CostCategoriesContext.Provider value = {{costCategories, createCostCategory, updateCostCategory, updateOnDelete}}>
            {children}
        </CostCategoriesContext.Provider>
    );
}

export function UseCostCategories(){
    const context = useContext(CostCategoriesContext);
    return context;
}
import {Container, Header, Row, Content, ListTitle, Divider, Print, PrintHeader} from './styles';
import { formatMoney } from '../../../services/functions';
import moment from 'moment';
import Table from '../../../components/Table';

const columnsTransactions = [
    {
      name: 'Produto',
      key:'product_name',
      type: 'string', 
      unit: '',
    },  
   {
    name: 'Quantidade',
    key:'product_amount',
    type: 'number', 
    unit: '',
  },
    {
      name: 'Preço unitário',
      key:'unity_price',
      type: 'money', 
      unit: '',
    },

    {
      name: 'Valor total',
      key:'total_value',
      type: 'money', 
      unit: '',
    }, 
  ]




export default function OrderToPrintLastPage({order, transactions, index}){
    return (
        <Print id={"report_" + index}>
            <PrintHeader>
                <h4>Detalhes do Pedido:</h4>
                <h3>Data de criação: {moment(order?.created_at).format('DD/MM/YYYY')}</h3>
                </PrintHeader>
              <Divider></Divider>
              <br></br>
              <Table 
                  columns={columnsTransactions} 
                  rows={transactions || []} 
                  hasEditing={false} 
                  hasRemoving={false}
                  setSelectedItem={()=>{}} 
                  actionsInFirstLine={false}
                  height="auto"
                  fitWidth={false}
                ></Table>  
              <Row style={{width:'95%', borderTop:'1px solid var(--blue)', paddingTop: 16 }}>
                    <h3>Valor Total:</h3>
                    <h3> {formatMoney(order?.total_order_value) }</h3>
                </Row> 
                <Row style={{width:'95%', justifyContent:'flex-end'}}>
                    <h3>Desconto:</h3>
                    <h3> - {formatMoney(order?.discount)}</h3>
                </Row> 
                <Row style={{width:'95%',  justifyContent:'flex-end'}}>
                    <h3>A pagar:</h3>
                    <h3><b>{formatMoney(order?.total_order_value - order?.discount)}</b></h3>
                </Row> 
        </Print>
    )

}

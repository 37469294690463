import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from "react";
import api from "../services/api";
import LocalStorageService from "../services/storage";

const localStorageService = LocalStorageService();

interface FinancialData {
  issalaryTrue: Array<{
    type: string;
    employee: string;
    total_value_exec: number;
    total_value_pend: number;
    total_global: number;
  }>;
  issalaryNull: Array<{
    type: string;
    provider: string;
    total_value_exec: number;
    total_value_pend: number;
    total_global: number;
  }>;
  summary: {
    entradas: {
      total_exec: number;
      total_pend: number;
      total_global: number;
    };
    saidas: {
      total_exec: number;
      total_pend: number;
      total_global: number;
    };
  };
}

interface FinancialProviderProps {
  children: ReactNode;
}

interface OverviewRequest {
  trip_name?: string | string[];
  boats_name?: string | string[];
  harvest: string;
  fishingtypes_name?: string | string[];
  product_name?: string | string[];
  pages: number;
  limit: number;
}

interface ResponsePropsWithData {
  success: boolean;
  message: string;
  data: any;
}

interface FinancialContextData {
  financialData: FinancialData | null;
  loading: boolean;
  getCcOverview: (request: OverviewRequest) => Promise<ResponsePropsWithData>;
}

const FinancialContext = createContext<FinancialContextData>(
  {} as FinancialContextData
);

export function FinancialProvider({ children }: FinancialProviderProps) {
  const [financialData, setFinancialData] = useState<FinancialData | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);

  async function getCcOverview(
    request: OverviewRequest
  ): Promise<ResponsePropsWithData> {
    setLoading(true);
    try {
      let params = {
        ...request,
        token: api.defaults.params.token
          ? api.defaults.params.token
          : localStorageService.getIdToken(),
      };
      const response = await api.get("/ccOverview", {
        params: { ...api.defaults.params, ...params },
      });
      console.log(response);
      if (response.status === 200) {
        // setFinancialData(response.data);
        return {
          success: true,
          message: "",
          data: response.data,
        };
      }
      return {
        success: false,
        message: "Erro ao consultar os dados financeiros.",
        data: [],
      };
    } catch (error) {
      console.error("Erro ao buscar dados financeiros:", error);
      return {
        success: false,
        message: "Erro ao consultar os dados financeiros.",
        data: [],
      };
    } finally {
      setLoading(false);
    }
  }

  return (
    <FinancialContext.Provider
      value={{ financialData, loading, getCcOverview }}
    >
      {children}
    </FinancialContext.Provider>
  );
}

export function useFinancialData() {
  const context = useContext(FinancialContext);
  return context;
}

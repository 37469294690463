import styled from 'styled-components';

export const Container = styled.div`
background: var(--background);
height: 100vh;
width: 100%;
padding: 32px;
overflow-y: auto;
`;

export const Content = styled.div`
height: auto;
width: 100%;
margin-top: 18px;
display: flex;
flex-direction: column;
align-items: end;
justify-content: end;
`;

export const Row = styled.div`
width: auto;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`;

export const ReviewContainer = styled.div`
    width:100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: start;
    background: var(--white);
    border-radius: 10px;
    padding: 16px 30px;
    margin-top: 12px;
    ` ;

    
export const Title = styled.h1`
font-weight: 600;
font-size: 0.9rem;
line-height: 160%;
color: var(--txt-title);
text-align: left;
` ;

export const ReviewContent = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
` ;

export const RowInfo = styled.h1`
font-weight: 400;
font-size: 0.8rem;
line-height: 2rem;
color: var(--txt-subtitle);
text-align: left;
` ;

export const Divider = styled.div`
    color: var(--txt-subtitle);
    width: 98%;
    height: 0px;
    border: 0.1px solid rgba(84, 87, 122, 0.2);
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
` ;

export const Tabs = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
background-color: var(--white);
padding-top: 0.5rem;
h4{
    cursor: pointer;
    padding-bottom: 0.4rem;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--txt-subtitle);
    margin-left: 2rem;
}
`;

export const Section = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: var(--white);
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    // padding-left: 1rem;
    padding: 16px 30px;
    h1{
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 160%;
        color: var(--txt-title);
    }
`;

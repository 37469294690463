export const tripsOptions = [
  {
    id: 1,
    label: '1',
  },
  {
    id: 2,
    label: '2',
  },
  {
    id: 3,
    label: '3',
  },
  {
    id: 4,
    label: '4',
  },
  {
    id: 5,
    label: '5',
  },
  {
    id: 6,
    label: '6',
  },
  {
    id: 7,
    label: '7',
  },
  {
    id: 8,
    label: '8',
  },
  {
    id: 9,
    label: '9',
  },
  {
    id: 10,
    label: '10',
  },
  {
    id: 11,
    label: '11',
  },
  {
    id: 12,
    label: '12',
  },
  {
    id: 13,
    label: '13',
  },
  {
    id: 14,
    label: '14',
  },
  {
    id: 15,
    label: '15',
  },
  {
    id: 16,
    label: '16',
  },
  {
    id: 17,
    label: '17',
  },
  {
    id: 18,
    label: '18',
  },
  {
    id: 19,
    label: '19',
  },
  {
    id: 20,
    label: '20',
  },
  {
    id: 21,
    label: '21',
  },
  {
    id: 22,
    label: '22',
  },
  {
    id: 23,
    label: '23',
  },
  {
    id: 24,
    label: '24',
  },
  {
    id: 25,
    label: '25',
  },
  {
    id: 26,
    label: '26',
  },
  {
    id: 27,
    label: '27',
  },
  {
    id: 28,
    label: '28',
  },
  {
    id: 29,
    label: '29',
  },
  {
    id: 30,
    label: '30',
  },
  {
    id: 31,
    label: '31',
  },
  {
    id: 32,
    label: '32',
  },
  {
    id: 33,
    label: '33',
  },
  {
    id: 34,
    label: '34',
  },
  {
    id: 35,
    label: '35',
  },
  {
    id: 36,
    label: '36',
  },
  {
    id: 37,
    label: '37',
  },
  {
    id: 38,
    label: '38',
  },
  {
    id: 39,
    label: '39',
  },
  {
    id: 40,
    label: '40',
  },
  {
    id: 41,
    label: '41',
  },
  {
    id: 42,
    label: '42',
  },
  {
    id: 43,
    label: '43',
  },
  {
    id: 44,
    label: '44',
  },
  {
    id: 45,
    label: '45',
  },
  {
    id: 46,
    label: '46',
  },
  {
    id: 47,
    label: '47',
  },
  {
    id: 48,
    label: '48',
  },
  {
    id: 49,
    label: '49',
  },
  {
    id: 50,
    label: '50',
  },
];


export const harvestOptions = [
  {
    id: 2023,
    label: '2023',
  },
  {
    id: 2024,
    label: '2024',
  },
  {
    id: 2025,
    label: '2025',
  },
];

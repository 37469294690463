import React from "react";
import XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import moment from "moment";
import Button from "../Button";
import { sortByProperty } from "../../services/functions";

const ExcelExport = ({ data, startDate, endDate }) => {
  const generateExcel = () => {
    const title =
      "Pagamentos da semana: " +
      moment(startDate).format("DD/MM/YYYY") +
      " - " +
      moment(endDate).format("DD/MM/YYYY");

    const titleInputs =
      "Recebimendos da semana: " +
      moment(startDate).format("DD/MM/YYYY") +
      " - " +
      moment(endDate).format("DD/MM/YYYY");
    const wb = XLSX.utils.book_new();
    let arrayTotalsOutputs = [];
    let arrayTotalsInputs = [];
    let arrayTotalsBalances = [];
    let arrayTotalsBalanceCaixa = [];
    let superTitlesPositionTomerge = [];
    let wsData = [
      [title],
      [
        "Item",
        "Fornecedor",
        "Centro de custo",
        "Tipo de operação",
        "Valor total do pedido",
        "Parcela",
        "Valor",
        "Pendente",
        "",
        "Data original de vencimento",
        "Data de vencimento",
        "Pago",
        "",
        "Total pago",
        "",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Gerenciador",
        "Espécie",
        "",
        "",
        "Gerenciador",
        "Espécie",
        "",
      ],
    ];
    let titlesPositionsToMerge = [1];
    superTitlesPositionTomerge.push(wsData.length - 1);
    function checkIfTotalNull(condition, value) {
      if (condition) return value;
      return {
        t: "n",
        v: 0,
        s: styleTotal,
        z: "R$ #,##0.00",
      };
    }

    function setRowTitle(row) {
      wsData[row].forEach((_, colIdx) => {
        wsData[row][colIdx] = {
          v: wsData[row][colIdx],
          s: {
            font: { bold: true, sz: 14, color: { rgb: "F5F5F7" } },
            alignment: { horizontal: "center" },
            fill: { fgColor: { rgb: "244062" } },
          },
        };
      });
    }

    function setRowSubtitleTitle(row) {
      wsData[row].forEach((_, colIdx) => {
        wsData[row][colIdx] = {
          v: wsData[row][colIdx],
          s: {
            font: { bold: true, color: { rgb: "141522" } },
            alignment: { horizontal: "center" },
            fill: { fgColor: { rgb: "F5F5F7" } },
          },
        };
      });
    }

    function setRowBold(row) {
      wsData[row].forEach((_, colIdx) => {
        wsData[row][colIdx] = {
          v: wsData[row][colIdx],
          s: {
            font: { bold: true, color: { rgb: "244062" } },
            alignment: { horizontal: "center" },
          },
        };
      });
    }

    let styleTotal = {
      font: { bold: true, color: { rgb: "244062" } },
      alignment: { horizontal: "center" },
    };
    let styleValue = {
      alignment: { horizontal: "center" },
    };

    setRowTitle(0);
    setRowBold(1);
    setRowBold(2);

    data.forEach((subsidiary) => {
      wsData.push([subsidiary.subsidiary_name]);
      titlesPositionsToMerge.push(wsData.length);
      setRowSubtitleTitle(wsData.length - 1);
      let subsidiaryPendingOutputs = sortByProperty(
        subsidiary.pending_outputs,
        "providers_name"
      );
      subsidiaryPendingOutputs.forEach((item, index) => {
        let expiration_date = item.amount_parcels
          ? item.current_parcel_expiration_date
          : item.expiration_date[0];
        let original_expiration_date = item.amount_parcels
          ? item.original_parcel_expiration_date
          : item.original_expiration_date[0];
        let value = item.amount_parcels
          ? parseFloat(item.current_parcel_value)
          : parseFloat(item.total_order_value) - parseFloat(item.discount);
        let managerValue = 0;
        let moneyValue = 0;
        if (item.paymenttype_name === "Dinheiro") {
          moneyValue = value;
        } else {
          managerValue = value;
        }
        let provider = item.providers_name;
        if (item.issalary) {
          provider = item.full_name + "-" + item.salary_month;
        }
        wsData.push([
          { t: "s", v: index + 1, s: styleValue },
          { t: "s", v: provider, s: styleValue },
          { t: "s", v: item.costs_center_name || "", s: styleValue },
          { t: "s", v: item.paymenttype_name, s: styleValue },
          {
            t: "n",
            v: parseFloat(item.total_order_value),
            z: "R$ #,##0.00",
            s: styleValue,
          },
          { t: "s", v: item.current_parcel_name, s: styleValue },
          { t: "n", v: value, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: managerValue, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: moneyValue, z: "R$ #,##0.00", s: styleValue },
          {
            t: "s",
            v: moment(original_expiration_date).format("DD/MM/YYYY"),
            s: styleValue,
          },
          {
            t: "s",
            v: moment(expiration_date).format("DD/MM/YYYY"),
            s: styleValue,
          },
          { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
          {
            t: "n",
            v: 0,
            f: `=L${wsData.length + 1} + M${wsData.length + 1}`,
            z: "R$ #,##0.00",
            s: styleValue,
          },
        ]);
      });
      let subsidiaryResolvedOutputs = sortByProperty(
        subsidiary.resolved_outputs,
        "providers_name"
      );
      subsidiaryResolvedOutputs.forEach((item, index) => {
        let expiration_date = item.amount_parcels
          ? item.current_parcel_expiration_date
          : item.expiration_date[0];
        let original_expiration_date = item.amount_parcels
          ? item.original_parcel_expiration_date
          : item.original_expiration_date[0];
        let value = item.amount_parcels
          ? parseFloat(item.current_parcel_value)
          : parseFloat(item.total_order_value) - parseFloat(item.discount);
        let managerValue = 0;
        let moneyValue = 0;
        if (item.paymenttype_name === "Dinheiro") {
          moneyValue = value;
        } else {
          managerValue = value;
        }
        let provider = item.providers_name;
        if (item.issalary) {
          provider = item.full_name + "-" + item.salary_month;
        }
        wsData.push([
          {
            t: "s",
            v: index + 1 + subsidiary.pending_outputs.length,
            s: styleValue,
          },
          { t: "s", v: provider, s: styleValue },
          { t: "s", v: item.costs_center_name || "", s: styleValue },
          { t: "s", v: item.paymenttype_name, s: styleValue },
          {
            t: "n",
            v: parseFloat(item.total_order_value),
            z: "R$ #,##0.00",
            s: styleValue,
          },
          { t: "s", v: item.current_parcel_name, s: styleValue },
          { t: "n", v: value, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
          {
            t: "s",
            v: moment(original_expiration_date).format("DD/MM/YYYY"),
            s: styleValue,
          },
          {
            t: "s",
            v: moment(expiration_date).format("DD/MM/YYYY"),
            s: styleValue,
          },
          { t: "n", v: managerValue, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: moneyValue, z: "R$ #,##0.00", s: styleValue },
          {
            t: "n",
            v: 0,
            f: `=L${wsData.length + 1} + M${wsData.length + 1}`,
            z: "R$ #,##0.00",
            s: styleValue,
          },
        ]);
      });

      const startRow =
        wsData.length -
        subsidiary.pending_outputs.length -
        subsidiary.resolved_outputs.length;
      const endRow = wsData.length;
      // eslint-disable-next-line no-sparse-arrays
      wsData.push([
        { t: "s", v: "Total", s: styleTotal },
        "",
        "",
        "",
        "",
        "",
        checkIfTotalNull(
          subsidiary.pending_outputs.length +
            subsidiary.resolved_outputs.length >
            0,
          {
            t: "n",
            f: `SUM(G${startRow + 1}:G${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
        checkIfTotalNull(
          subsidiary.pending_outputs.length +
            subsidiary.resolved_outputs.length >
            0,
          {
            t: "n",
            f: `SUM(H${startRow + 1}:H${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
        checkIfTotalNull(
          subsidiary.pending_outputs.length +
            subsidiary.resolved_outputs.length >
            0,
          {
            t: "n",
            f: `SUM(I${startRow + 1}:I${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
        "",
        "",
        checkIfTotalNull(
          subsidiary.pending_outputs.length +
            subsidiary.resolved_outputs.length >
            0,
          {
            t: "n",
            f: `SUM(L${startRow + 1}:L${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
        checkIfTotalNull(
          subsidiary.pending_outputs.length +
            subsidiary.resolved_outputs.length >
            0,
          {
            t: "n",
            f: `SUM(M${startRow + 1}:M${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
        checkIfTotalNull(
          subsidiary.pending_outputs.length +
            subsidiary.resolved_outputs.length >
            0,
          {
            t: "n",
            f: `SUM(N${startRow + 1}:N${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
      ]);
      arrayTotalsOutputs.push(wsData.length);
      wsData.push([]);
    });
    function createSumStringOutputs(letter) {
      let sumString = "";
      arrayTotalsOutputs.map((position) => {
        sumString = sumString + ` +${letter}${position}`;
      });
      return sumString;
    }
    function createSumStringInputs(letter) {
      let sumString = "";
      arrayTotalsInputs.map((position) => {
        sumString = sumString + ` +${letter}${position}`;
      });
      return sumString;
    }

    function createSumStringBalances() {
      let sumString = "";
      arrayTotalsBalances.map((position) => {
        sumString = sumString + ` +B${position}`;
      });
      return sumString;
    }
    function createSumStringBalancesCaixa() {
      let sumString = "";
      arrayTotalsBalanceCaixa.map((position) => {
        sumString = sumString + ` +B${position}`;
      });
      return sumString;
    }

    wsData.push([
      { t: "s", v: "Total de saídas", s: styleTotal },
      "",
      "",
      "",
      "",
      "",
      {
        t: "n",
        f: createSumStringOutputs("G"),
        s: styleTotal,
        z: "R$ #,##0.00",
      },
      {
        t: "n",
        f: createSumStringOutputs("H"),
        s: styleTotal,
        z: "R$ #,##0.00",
      },
      {
        t: "n",
        f: createSumStringOutputs("I"),
        s: styleTotal,
        z: "R$ #,##0.00",
      },
      "",
      "",
      {
        t: "n",
        f: createSumStringOutputs("L"),
        s: styleTotal,
        z: "R$ #,##0.00",
      },
      {
        t: "n",
        f: createSumStringOutputs("M"),
        s: styleTotal,
        z: "R$ #,##0.00",
      },
      {
        t: "n",
        f: createSumStringOutputs("N"),
        s: styleTotal,
        z: "R$ #,##0.00",
      },
    ]);

    // Entradas
    wsData.push([]);
    wsData.push([titleInputs]);
    titlesPositionsToMerge.push(wsData.length);
    setRowTitle(wsData.length - 1);
    wsData.push([
      "Item",
      "Comprador",
      "Centro de custo",
      "Tipo de operação",
      "Valor total do pedido",
      "Parcela",
      "Valor",
      "Pendente",
      "",
      "Data original de vencimento",
      "Data de vencimento",
      "Pago",
      "",
      "Total pago",
      "Saldo",
    ]);
    setRowBold(wsData.length - 1);
    superTitlesPositionTomerge.push(wsData.length);
    wsData.push([
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "Gerenciador",
      "Espécie",
      "",
      "",
      "Gerenciador",
      "Espécie",
      "",
      "",
    ]);
    setRowBold(wsData.length - 1);
    data.forEach((subsidiary) => {
      wsData.push([subsidiary.subsidiary_name]);
      titlesPositionsToMerge.push(wsData.length);
      setRowSubtitleTitle(wsData.length - 1);
      let subsidiaryPendingInputs = sortByProperty(
        subsidiary.pending_inputs,
        "providers_name"
      );
      subsidiaryPendingInputs.forEach((item, index) => {
        let expiration_date = item.amount_parcels
          ? item.current_parcel_expiration_date
          : item.expiration_date[0];
        let original_expiration_date = item.amount_parcels
          ? item.original_parcel_expiration_date
          : item.original_expiration_date[0];
        let value = item.amount_parcels
          ? parseFloat(item.current_parcel_value)
          : parseFloat(item.total_order_value) - parseFloat(item.discount);
        let managerValue = 0;
        let moneyValue = 0;
        if (item.paymenttype_name === "Dinheiro") {
          moneyValue = value;
        } else {
          managerValue = value;
        }
        wsData.push([
          { t: "s", v: index + 1, s: styleValue },
          { t: "s", v: item.providers_name, s: styleValue },
          { t: "s", v: item.costs_center_name || "", s: styleValue },
          { t: "s", v: item.paymenttype_name, s: styleValue },
          {
            t: "n",
            v: parseFloat(item.total_order_value),
            z: "R$ #,##0.00",
            s: styleValue,
          },
          { t: "s", v: item.current_parcel_name, s: styleValue },
          { t: "n", v: value, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: managerValue, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: moneyValue, z: "R$ #,##0.00", s: styleValue },
          {
            t: "s",
            v: moment(original_expiration_date).format("DD/MM/YYYY"),
            s: styleValue,
          },
          {
            t: "s",
            v: moment(expiration_date).format("DD/MM/YYYY"),
            s: styleValue,
          },
          { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
          {
            t: "n",
            v: 0,
            f: `=L${wsData.length + 1} + M${wsData.length + 1}`,
            z: "R$ #,##0.00",
            s: styleValue,
          },
          {
            t: "n",
            f: `=H${wsData.length + 1} + I${wsData.length + 1} + N${
              wsData.length + 1
            }`,
            z: "R$ #,##0.00",
            s: styleValue,
          },
        ]);
      });
      let subsidiaryResolvedInputs = sortByProperty(
        subsidiary.resolved_inputs,
        "providers_name"
      );
      subsidiaryResolvedInputs.forEach((item, index) => {
        let expiration_date = item.amount_parcels
          ? item.current_parcel_expiration_date
          : item.expiration_date[0];
        let original_expiration_date = item.amount_parcels
          ? item.original_parcel_expiration_date
          : item.original_expiration_date[0];
        let value = item.amount_parcels
          ? parseFloat(item.current_parcel_value)
          : parseFloat(item.total_order_value) - parseFloat(item.discount);
        let managerValue = 0;
        let moneyValue = 0;
        if (item.paymenttype_name === "Dinheiro") {
          moneyValue = value;
        } else {
          managerValue = value;
        }
        wsData.push([
          {
            t: "s",
            v: index + 1 + subsidiary.pending_inputs.length,
            s: styleValue,
          },
          { t: "s", v: item.providers_name, s: styleValue },
          { t: "s", v: item.costs_center_name || "", s: styleValue },
          { t: "s", v: item.paymenttype_name, s: styleValue },
          {
            t: "n",
            v: parseFloat(item.total_order_value),
            z: "R$ #,##0.00",
            s: styleValue,
          },
          { t: "s", v: item.current_parcel_name, s: styleValue },
          { t: "n", v: value, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
          {
            t: "s",
            v: moment(original_expiration_date).format("DD/MM/YYYY"),
            s: styleValue,
          },
          {
            t: "s",
            v: moment(expiration_date).format("DD/MM/YYYY"),
            s: styleValue,
          },
          { t: "n", v: managerValue, z: "R$ #,##0.00", s: styleValue },
          { t: "n", v: moneyValue, z: "R$ #,##0.00", s: styleValue },
          {
            t: "n",
            v: 0,
            f: `=L${wsData.length + 1} + M${wsData.length + 1}`,
            z: "R$ #,##0.00",
            s: styleValue,
          },
          {
            t: "n",
            f: `=H${wsData.length + 1} + I${wsData.length + 1} + N${
              wsData.length + 1
            }`,
            z: "R$ #,##0.00",
            s: styleValue,
          },
        ]);
      });
      const startRow =
        wsData.length -
        subsidiary.pending_inputs.length -
        subsidiary.resolved_inputs.length;
      const endRow = wsData.length;
      wsData.push([
        { t: "s", v: "Total", s: styleTotal },
        "",
        "",
        "",
        "",
        "",
        checkIfTotalNull(
          subsidiary.pending_inputs.length + subsidiary.resolved_inputs.length >
            0,
          {
            t: "n",
            f: `SUM(G${startRow + 1}:G${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
        checkIfTotalNull(
          subsidiary.pending_inputs.length + subsidiary.resolved_inputs.length >
            0,
          {
            t: "n",
            f: `SUM(H${startRow + 1}:H${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
        checkIfTotalNull(
          subsidiary.pending_inputs.length + subsidiary.resolved_inputs.length >
            0,
          {
            t: "n",
            f: `SUM(I${startRow + 1}:I${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
        "",
        "",
        checkIfTotalNull(
          subsidiary.pending_inputs.length + subsidiary.resolved_inputs.length >
            0,
          {
            t: "n",
            f: `SUM(L${startRow + 1}:L${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
        checkIfTotalNull(
          subsidiary.pending_inputs.length + subsidiary.resolved_inputs.length >
            0,
          {
            t: "n",
            f: `SUM(M${startRow + 1}:M${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
        checkIfTotalNull(
          subsidiary.pending_inputs.length + subsidiary.resolved_inputs.length >
            0,
          {
            t: "n",
            f: `SUM(N${startRow + 1}:N${endRow})`,
            s: styleTotal,
            z: "R$ #,##0.00",
          }
        ),
      ]);
      arrayTotalsInputs.push(wsData.length);
      wsData.push([
        { t: "s", v: "Saldo BB", s: styleTotal },
        { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
      ]);
      arrayTotalsBalances.push(wsData.length);
      wsData.push([
        { t: "s", v: "Saldo Itaú", s: styleTotal },
        { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
      ]);
      arrayTotalsBalances.push(wsData.length);
      wsData.push([
        { t: "s", v: "Saldo Bradesco", s: styleTotal },
        { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
      ]);
      arrayTotalsBalances.push(wsData.length);
      wsData.push([
        { t: "s", v: "Saldo Caixa", s: styleTotal },
        { t: "n", v: 0, z: "R$ #,##0.00", s: styleValue },
      ]);
      arrayTotalsBalanceCaixa.push(wsData.length);
      wsData.push([]);
    });

    wsData.push(["Saldos"]);
    titlesPositionsToMerge.push(wsData.length);
    setRowSubtitleTitle(wsData.length - 1);
    wsData.push([
      { t: "s", v: "Saldo do dia anterior", s: styleTotal },
      "",
      "",
      "",
      "",
      "",
      "",
      {
        t: "n",
        f: createSumStringBalances(),
        s: styleValue,
        z: "R$ #,##0.00",
      },
      {
        t: "n",
        f: createSumStringBalancesCaixa(),
        s: styleValue,
        z: "R$ #,##0.00",
      },
    ]);
    wsData.push([
      { t: "s", v: "Saque", s: styleTotal },
      "",
      "",
      "",
      "",
      "",
      "",
      {
        t: "n",
        s: styleValue,
        z: "R$ #,##0.00",
        v: 0,
      },
      {
        t: "n",
        s: styleValue,
        z: "R$ #,##0.00",
        v: 0,
      },
    ]);
    wsData.push([
      { t: "s", v: "Total disponível (início do dia)", s: styleTotal },
      "",
      "",
      "",
      "",
      "",
      {
        t: "n",
        f: `=H${wsData.length + 1} + I${wsData.length + 1}`,
        z: "R$ #,##0.00",
        s: styleValue,
      },
      {
        t: "n",
        f: `=H${wsData.length} + H${wsData.length - 1}`,
        z: "R$ #,##0.00",
        s: styleValue,
      },
      {
        t: "n",
        f: `=I${wsData.length} + I${wsData.length - 1}`,
        z: "R$ #,##0.00",
        s: styleValue,
      },
    ]);
    const positionTotalBalances = wsData.length;
    wsData.push(["Previsões"]);
    titlesPositionsToMerge.push(wsData.length);
    setRowSubtitleTitle(wsData.length - 1);
    wsData.push(["", "", "Total", "Gerenciador", "Espécie"]);
    setRowBold(wsData.length - 1);
    wsData.push([
      { t: "s", v: "Entradas", s: styleTotal },
      "",
      {
        t: "n",
        f: `=D${wsData.length + 1} + E${wsData.length + 1}`,
        z: "R$ #,##0.00",
        s: styleValue,
      },
      {
        t: "n",
        f: createSumStringInputs("H"),
        z: "R$ #,##0.00",
        s: styleValue,
      },
      {
        t: "n",
        f: createSumStringInputs("I"),
        z: "R$ #,##0.00",
        s: styleValue,
      },
    ]);
    wsData.push([
      { t: "s", v: "Entradas + saldo", s: styleTotal },
      "",
      {
        t: "n",
        f: `=C${wsData.length} + G${positionTotalBalances}`,
        z: "R$ #,##0.00",
        s: styleValue,
      },
      {
        t: "n",
        f: `=D${wsData.length} + H${positionTotalBalances}`,
        z: "R$ #,##0.00",
        s: styleValue,
      },
      {
        t: "n",
        f: `=E${wsData.length} + I${positionTotalBalances}`,
        z: "R$ #,##0.00",
        s: styleValue,
      },
    ]);
    wsData.push(["Saldos executados"]);
    titlesPositionsToMerge.push(wsData.length);
    setRowSubtitleTitle(wsData.length - 1);
    wsData.push([
      { t: "s", v: "Total de entradas executadas", s: styleTotal },
      "",
      "",
      "",
      "",
      "",
      "",
      {
        t: "n",
        f: createSumStringInputs("L"),
        z: "R$ #,##0.00",
        s: styleValue,
      },
      {
        t: "n",
        f: createSumStringInputs("M"),
        z: "R$ #,##0.00",
        s: styleValue,
      },
    ]);
    wsData.push([
      { t: "s", v: "Total de saídas executadas", s: styleTotal },
      "",
      "",
      "",
      "",
      "",
      "",
      {
        t: "n",
        f: createSumStringOutputs("L"),
        z: "R$ #,##0.00",
        s: styleValue,
      },
      {
        t: "n",
        f: createSumStringOutputs("M"),
        z: "R$ #,##0.00",
        s: styleValue,
      },
    ]);
    wsData.push([
      { t: "s", v: "Saldo do dia", s: styleTotal },
      "",
      "",
      "",
      "",
      "",
      "",
      {
        t: "n",
        f: `=H${wsData.length - 1} - H${
          wsData.length
        } + H${positionTotalBalances}`,
        z: "R$ #,##0.00",
        s: styleValue,
      },
      {
        t: "n",
        f: `=I${wsData.length - 1} - I${
          wsData.length
        } + I${positionTotalBalances}`,
        z: "R$ #,##0.00",
        s: styleValue,
      },
    ]);
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Mesclar as células "Forma de pagamento" e a célula vazia ao lado dela
    if (!ws["!merges"]) ws["!merges"] = [];

    titlesPositionsToMerge.map((position) => {
      ws["!merges"].push({
        s: { r: position - 1, c: 0 },
        e: { r: position - 1, c: 13 },
      });
    });
    superTitlesPositionTomerge.map((position) => {
      ws["!merges"].push({
        s: { r: position - 1, c: 7 },
        e: { r: position - 1, c: 8 },
      });
      ws["!merges"].push({
        s: { r: position - 1, c: 11 },
        e: { r: position - 1, c: 12 },
      });
    });

    const colWidth = { wch: 18 };
    ws["!cols"] = Array(wsData[1].length).fill(colWidth);

    XLSX.utils.book_append_sheet(wb, ws, "Programação");

    // Gerar o arquivo Excel
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "Simulacao_Programacao.xlsx"
    );
  };

  return (
    <div>
      <Button
        label="Gerar Planilha"
        background="#256CE1"
        color="white"
        borderColor="white"
        disabled={false}
        onClick={() => generateExcel()}
        width={200}
      ></Button>
    </div>
  );
};

export default ExcelExport;


import {createContext , useEffect, useState, ReactNode, useContext} from 'react';
import  api from '../services/api';

import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();

interface OutputStock{
    id_transaction: string; 
    output_amount: number;
    fk_costs_center_id_cost: string;
    fk_trips_id_trip: string;
}

interface StockItemRequest{
    item_name: string; 
    provider_name: string;
    subsidiary_name: string;
    pages: number;
    limit: number;
}

interface StockOutputsRequest{
    product: string; 
    costcenter: string;
    boat: string;
    trip: string;
    pages: number;
    limit: number;
}

interface StockItemsProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface ResponsePropsWithData {
    success: boolean;
    message: string;
    data: any;
}

interface InputReturnToStock{
    id_stock: string;
    id_order: string;
    id_user:  string;
    notes: string;
}

interface StockItemsContextData {
    getFilteredStockItems: (request : StockItemRequest) => Promise<ResponsePropsWithData>;
    getOutputsStock: (request: StockOutputsRequest) => Promise<ResponsePropsWithData>;
    getStockHistory: () => Promise<ResponsePropsWithData>;
    takeItensFromStock: (request : OutputStock) => Promise<ResponseProps>;
    returnItensToStock: (request : InputReturnToStock) => Promise<ResponseProps>;
}

const StockContext = createContext<StockItemsContextData>(
    {} as StockItemsContextData
);


export function StockProvider ({children}: StockItemsProvidersProps){

    async function getFilteredStockItems (request : StockItemRequest){
        let params = {
            ...request, 
            token: api.defaults.params.token ? api.defaults.params.token :  localStorageService.getIdToken(),
          };
          const response = await api.get('/stock', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200){
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar itens do estoque.",
            data: [],
        }
    }

    async function getOutputsStock (request : StockOutputsRequest){
        let params = {
            ...request, 
            token: api.defaults.params.token ? api.defaults.params.token :  localStorageService.getIdToken(),
          };
          const response = await api.get('/stockoutputs', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200){
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar movimentações do estoque.",
            data: [],
        }
    }

    async function getStockHistory (){
        let params = {
            token: api.defaults.params.token ? api.defaults.params.token :  localStorageService.getIdToken(),
          };
          const response = await api.get('/stocklogs', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200){
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar histórico do estoque.",
            data: [],
        }
    }


    async function takeItensFromStock (transaction: OutputStock){
       const response = await api.post('/outputstock', transaction);
       if (response.status === 200){
        // setRefreshOnDelete(oldKey => oldKey + 1);
        return {
            success: true,
            message: "Item retirado do estoque com sucesso!"
        }
        }
    return {
        success: false,
        message: "Erro ao retirar item do estoque."
    }
    }

    async function returnItensToStock (request: InputReturnToStock){
        const response = await api.put('/outputstock', {...request});
        if (response.status === 200){
         // setRefreshOnDelete(oldKey => oldKey + 1);
         return {
             success: true,
             message: "Item devolvido ao estoque com sucesso!"
         }
         }
     return {
         success: false,
         message: "Erro ao devolver item ao estoque."
     }
     }

    return (
        <StockContext.Provider value = {{getFilteredStockItems, getOutputsStock, takeItensFromStock, returnItensToStock, getStockHistory}}>
            {children}
        </StockContext.Provider>
    );
}

export function UseStock(){
    const context = useContext(StockContext);
    return context;
}
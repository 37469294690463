
import {createContext , useEffect, useState, ReactNode, useContext} from 'react';
import  api from '../services/api';


interface CostCenter{
    id_cost: string;
    costs_center_name: string;
    description: string;

}
type CostCenterInput = Omit<CostCenter, 'id_cost'>

interface CostCentersProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface CostCentersContextData {
    costCenters: CostCenter [];
    createCostCenter: (costCenter: CostCenterInput) =>Promise<ResponseProps>;
    updateCostCenter: (costCenter: CostCenter) =>Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
}

const CostCentersContext = createContext<CostCentersContextData>(
    {} as CostCentersContextData
);


export function CostCentersProvider ({children}: CostCentersProvidersProps){
      const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
      const [refreshOnDelete, setRefreshOnDelete] = useState(0);

  useEffect(() =>{
        api.get('costcenter').then(response => setCostCenters(response.data))
    }, [refreshOnDelete])

    async function updateOnDelete (id: number){
            const response =  await api.post('/deletecostcenter',  { id_cost: id });
            if (response.status === 200){
                setRefreshOnDelete(oldKey => oldKey + 1);
                return {
                    success: true,
                    message: "Centro de custo removido com sucesso!"
                }
            }
            return {
                success: false,
                message: "Erro ao remover centro de custo."
            }
        }

    async function createCostCenter (costCenterInput: CostCenterInput){
        const response = await  api.post('/costcenter', costCenterInput);
        if (response.status === 200){
            api.get('costcenter').then(response => setCostCenters(response.data))
            return {
                success: true,
                message: "Centro de custo adicionado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar centro de custo."
        }
      
    }

    async function updateCostCenter (costCenter: CostCenter){
       const response = await api.put('/costcenter', costCenter);
       if (response.status === 200){
        setRefreshOnDelete(oldKey => oldKey + 1)
        return {
            success: true,
            message: "Centro de custo editado com sucesso!"
        }
        }
    return {
        success: false,
        message: "Erro ao editar centro de custo."
    }
    }

    return (
        <CostCentersContext.Provider value = {{costCenters, createCostCenter, updateCostCenter, updateOnDelete}}>
            {children}
        </CostCentersContext.Provider>
    );
}

export function UseCostCenters(){
    const context = useContext(CostCentersContext);
    return context;
}
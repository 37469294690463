import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 24px;
    padding:16px 24px;
    max-height: 90vh;
    height: auto !important;
    width: auto !important;
    ` ;

export const Header  = styled.div`
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DCE4FF;
    position: relative;
    padding-bottom: 8px;
    h1{
        font-weight: 600;
        font-size: 1rem !important;
        color: var(--txt-title);
        text-align: center;
    };
    img {
        height: 1.2rem;
        position: absolute;
        right: 0px;
        cursor: pointer;
        transition: transform .2s;
        &:hover {
            transform: scale(1.05);
          }
    }
` ;

export const Content = styled.div`
height: auto;
width: 100%;
padding: 9px 12px;
display: flex;
flex-direction: column;
align-items: space-between;
// justify-content: space-between;
overflow-y: auto;
`;

export const Row = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-bottom: 16px;
h1{
    font-size: 14px;
    font-weight: 400;
    width: 100%;
}
`;


import { useState, useEffect, useContext } from 'react';
import { Container, Header, Row, Content, ListTitle, Divider, Values } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../assets/icons/index';
import Table from '../../../components/Table';
import moment from 'moment';
import { formatMoney } from '../../../services/functions';
import { UseOrders } from '../../../hooks/useOrders';
import { UseTrips } from "../../../hooks/useTrips";
import { UseCostCenters } from "../../../hooks/useCostCenters";
import { UseFiles } from "../../../hooks/useFiles";
import AppContext from "../../../state/App.context";
import Button from "../../../components/Button";
import OrderToPrint from './orderToPrint';
import OrderToPrintAdd from './orderToPrintAddPage';
import OrderToPrintFirstPage from './orderToPrintFirstPage';
import OrderToPrintLastPage from './orderToPrintLastPage';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { columnsApportionment, columnsParcels, columnsTransactions, columnsOutputs } from './options';
import Spinner from "../../../components/Spinner";
import { RowContent } from '../styles';

const columnsFiles = [
  {
    name: 'Nome do arquivo',
    key: 'name',
    type: 'string',
    unit: '',
  },
]

export default function ModalVisualizeOrder({ open, setSelectedItem, width, height, selectedItem, handleOpen }) {
  const { getOrderTransactions, getOrderDetails, getInputOrdersFromOrder } = UseOrders();
  const { costCenters } = UseCostCenters();
  const { trips } = UseTrips();
  const { getOrderFiles, downloadFile } = UseFiles();

  const [transactions, setTransactions] = useState([]);
  const [, setSnack] = useContext(AppContext).snackState;
  const [printMode, setPrintMode] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [associatedOrdersOptions, setAssociatedOrdersOptions] = useState([]);

  let order = selectedItem?.item;
  let parcels = [];
  if (order?.amount_parcels && order?.expiration_date) {
    for (let i = 0; i < order.amount_parcels; i++) {
      parcels.push(
        {
          name: i + 1,
          expiration_date: order?.expiration_date[i],
          payment_date: order?.status[i] === 'Executado' ? order?.payment_date[i] : '',
          status: order?.status[i],
          price: order?.values_parcels[i],
        }
      )
    }
  }

  let apportionment = [];
  if (order?.isapportionment) {
    for (let i = 0; i < order.fk_trips_id_trip_array?.length; i++) {
      let trip_name = '';
      let harvest = '';
      let tripIndex = trips.findIndex((trip) => (trip?.id_trip === order?.fk_trips_id_trip_array[i]));
      if (tripIndex !== -1) {
        trip_name = trips[tripIndex]?.trips_name + ' - ' + trips[tripIndex]?.boats_name;
        harvest = trips[tripIndex]?.harvest;
      }
      let cost_center_name = '';
      let costIndex = costCenters.findIndex((cost) => (cost?.id_cost === order?.fk_costs_center_id_cost_array[i]));
      if (costIndex !== -1) {
        cost_center_name = costCenters[costIndex]?.costs_center_name;
      }
      apportionment.push(
        {
          trip: order?.fk_trips_id_trip_array[i],
          trip_name: trip_name,
          cost_center: order?.fk_costs_center_id_cost_array[i],
          cost_center_name: cost_center_name,
          total_value: order?.total_order_array[i],
          harvest: harvest,
        }
      )
    }
  }

  let totalOutputs = 0;
  if (order?.saidas_estoque && order?.saidas_estoque.length > 0) {
    order?.saidas_estoque.forEach((output) => {
      totalOutputs = totalOutputs + output?.output_value;
    })
  }

  const title = 'Detalhes do pedido';

  async function getInfo() {
    try {
      let responseTransactions = await getOrderTransactions(order?.id_order);
      if (responseTransactions.success) {
        setTransactions(responseTransactions?.data.filter(element => element.isactive));
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: responseTransactions?.message,
        })
      }
    } catch (err) {
      console.log(err);
    }
  }

  function extractFileInfo(filename) {
    let firstUnderscoreIndex = filename.indexOf('_');
    let id = filename.slice(0, firstUnderscoreIndex);
    let name = filename.slice(firstUnderscoreIndex + 1);
    return {
      id: id,
      name: name,
    };
  }

  async function getFilesInfo() {
    try {
      let responseFiles = await getOrderFiles(order?.id_order);
      if (responseFiles.success) {
        let filesFormatted = [];
        responseFiles?.data.forEach((oldFile) => {
          const { name, id } = extractFileInfo(oldFile);
          filesFormatted.push({
            name,
            id,
            filename: oldFile,
          })
        })
        setFiles(filesFormatted);
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: responseFiles?.message,
        })
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (order && order.id_order) getInfo();
    if (order && order.id_order) getFilesInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  async function handleDownloadFile(selectedItem) {
    try {
      const filename = selectedItem.item.filename;
      const name = selectedItem.item.name;
      const response = await downloadFile(filename, name);
      if (response.success) {
        setSnack({
          open: true,
          severity: 'success',
          message: 'Baixando arquivo ' + name + '...',
        });
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Erro ao baixar o arquivo ' + name + '!',
        });
      }

    } catch (err) {
      console.log(err);
    }
  }

  function renderParcels() {
    if (order && order.amount_parcels) {
      return (
        <>
          <Row style={{ marginBottom: 8 }}>
            <h4><b>Parcelas:</b></h4>
          </Row>
          <Table
            columns={columnsParcels}
            rows={parcels || []}
            hasEditing={false}
            hasRemoving={false}
            setSelectedItem={() => { }}
            height="160px"
            actionsInFirstLine={false}
            fitWidth={false}
          ></Table>
        </>
      )
    } else {
      return (
        <Row>
          <h4><b>Data de vencimento:</b> {order.expiration_date ? moment(order?.expiration_date[0]).add(3, 'hours').format('DD/MM/YYYY') : ''}</h4>
          <h4><b>Data de pagamento:</b> {order.status ? order?.status[0] === 'Executado' ? moment(order?.payment_date[0]).add(3, 'hours').format('DD/MM/YYYY') : '-' : ''}</h4>
          <h4><b>Status:</b> {order.status ? order?.status[0] : ''}</h4>
        </Row>
      )
    }
  }


  function renderOutputs() {
    if (order && order.saidas_estoque) {
      return (
        <>
          <Row>
            <ListTitle>Movimentações do estoque:</ListTitle>
          </Row>
          <Divider></Divider>
          <Table
            columns={columnsOutputs}
            rows={order.saidas_estoque || []}
            hasEditing={false}
            hasRemoving={false}
            setSelectedItem={() => { }}
            height="100%"
            actionsInFirstLine={false}
            fitWidth={false}
          ></Table>
        </>
      )
    } else {
      return (
        <></>
      )
    }
  }

  function renderUpdatedValue() {
    if (order && order.total_order_value_updated) {
      return (
        <>
          <Row>
            <h4 style={{ width: '100%' }}><b>Valor total de retiradas:</b> {totalOutputs ? formatMoney(totalOutputs) : ''} </h4>
          </Row>
          <Row>
            <h4 style={{ width: '100%' }}><b>Valor atualizado do pedido:</b> {order?.total_order_value_updated ? formatMoney(order?.total_order_value_updated) + ' (valor utilizado nos cálculos)' : ''} </h4>
          </Row>
        </>
      )
    } else {
      return (
        <></>
      )
    }
  }


  function renderCostCenterAndTrip() {
    if (order?.category !== 'Estoque' && order?.category !== 'Rateio') {
      return (
        <Row>
          <h4><b>Viagem/Safra:</b> {order?.trips_name}/{order?.harvest}</h4>
          <h4><b>Barco:</b> {order?.boats_name}</h4>
          <h4><b>Centro de custo:</b> {order?.costs_center_name}</h4>
        </Row>
      )
    } else {
      return (
        <></>
      )
    }
  }

  async function goToOriginalOrder() {
    try {
      setLoading(true);
      let responseOriginalOrder = await getOrderDetails(order?.original_order);
      if (responseOriginalOrder.success) {
        setSelectedItem({ ...selectedItem, item: responseOriginalOrder?.data[0] });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function renderButtonOriginalOrder() {
    if (order && order.original_order) {
      return (
        <Button
          label="Ver pedido original"
          background="#256CE1"
          color="white"
          borderColor="#256CE1"
          disabled={false}
          onClick={() => { goToOriginalOrder() }}
        ></Button>
      )
    } else {
      return (
        <></>
      )
    }
  }

  function renderApportionment() {
    if (selectedItem?.item?.isapportionment) {
      return (
        <>
          <Row style={{ marginBottom: 8 }}>
            <h4><b>Rateio:</b></h4>
          </Row>
          <Table
            columns={columnsApportionment}
            rows={apportionment || []}
            hasEditing={false}
            hasRemoving={false}
            setSelectedItem={() => { }}
            height="160px"
            actionsInFirstLine={false}
            fitWidth={false}
          ></Table>
        </>
      )

    } else {
      return (
        <></>
      )
    }
  }

  function renderPaymentInfo() {
    if (order?.original_order) {
      return (
        <>
          <Row>
            <h4><b>Valor total do pedido:</b> {order.total_order_value ? formatMoney(order?.total_order_value) : ''}</h4>
            <h4><b>Tipo de pagamento:</b> {order?.paymenttype_name || ''}</h4>
            <h4><b>É custo indireto?:</b> {order?.is_indirect_cost ? 'Sim' : 'Não'}</h4>
          </Row>
        </>
      )
    } else {
      return (
        <>
          <Row>
            <h4><b>Valor total original:</b> {order.total_order_value ? formatMoney(order?.total_order_value) : '-'}</h4>
            <h4><b>Desconto:</b> {order.discount ? formatMoney(order?.discount) : ''}</h4>
            <h4><b>Valor total original com desconto:</b> {formatMoney(order?.total_order_value - order?.discount)}</h4>
          </Row>
          <Row>
            <h4><b>Tipo de pagamento:</b> {order?.paymenttype_name || ''}</h4>
            <h4><b>Qtd. de parcelas:</b> {order.amount_parcels ? parseInt(order?.amount_parcels) : '0'}</h4>
            <h4><b>Recibo:</b> {order?.receipt || 'Sem recibo'}</h4>
          </Row>
          {renderParcels()}
          <Row>
            <h4><b>É custo indireto?:</b> {order?.is_indirect_cost ? 'Sim' : 'Não'}</h4>
          </Row>
        </>
      )
    }
  }

  useEffect(() => {
    async function handleAssociatedOrders() {
      let ordersOptions = [];
      const inputOrdersFromTripList = await getInputOrdersFromOrder(selectedItem?.item.id_order);

      if (inputOrdersFromTripList && inputOrdersFromTripList.success &&
        inputOrdersFromTripList.data && (inputOrdersFromTripList.data.length > 0)) {
        inputOrdersFromTripList.data.forEach((element) => (
          ordersOptions.push({
            id: element?.input_order_id,
            provider: element?.providers_name,
            totalValue: formatMoney(element?.total_order_value),
          })
        ))
      }

      setAssociatedOrdersOptions(ordersOptions);
    }

    handleAssociatedOrders();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    }
    return (
      <Content>
        <Row><ListTitle>Informações do pedido:</ListTitle></Row>
        <Divider></Divider>
        <Row>
          <h4 style={{ width: '100%' }}><b>Id do pedido:</b> {order?.id_order}</h4>
        </Row>
        <Row>
          <h4><b>Tipo:</b> {order?.type}</h4>
          <h4><b>Subsidiária:</b> {order?.subsidiary_name}</h4>
          <h4><b>{order?.type === 'Entrada' ? 'Comprador' : 'Fornecedor'}</b>: {order?.providers_name}</h4>
        </Row>
        {renderCostCenterAndTrip()}
        <Row >
          <h4><b>Criado em:</b> {moment(order?.created_at).format('DD/MM/YYYY [às] HH:mm')}</h4>
          <h4><b>Criado por:</b> {order?.created_by}</h4>
          <h4><b>Aprovado por:</b> {order?.approved === true ? order?.approved_by : 'Não aprovado'}</h4>
        </Row>
        <Row style={{ marginBottom: 8 }}>
          <h4><b>Observações:</b>{order?.notes || ' Sem observações'}</h4>
        </Row>
        {renderApportionment()}
        <Row>
          <ListTitle>Produtos:</ListTitle>
        </Row>
        <Divider></Divider>
        <Table
          columns={columnsTransactions}
          rows={transactions || []}
          hasEditing={false}
          hasRemoving={false}
          setSelectedItem={() => { }}
          actionsInFirstLine={false}
          height="100%"
          fitWidth={false}
        ></Table>
        <Row>
          <ListTitle>Pagamento:</ListTitle>
          {/* <Button
                  label="Atualizar pagamento"
                  background="white" 
                  color="#256CE1" 
                  borderColor="#256CE1" 
                  disabled={false}
                  onClick={() => {}}
                ></Button> */}
        </Row>
        <Divider></Divider>
        {order && renderPaymentInfo()}
        <br></br>
        {renderOutputs()}
        {renderUpdatedValue()}
        {
          (associatedOrdersOptions.length > 0) && (
            <>
              <Row></Row>
              <Row style={{ marginTop: 8, marginBottom: 8 }}><h1>Pedidos de Venda Associados:</h1></Row>
              <Divider></Divider>
              <RowContent style={{ justifyContent: 'space-evenly'}}>
              {associatedOrdersOptions.map((option) => {
                return (
                  <Content>
                    <Values>{option.id}</Values>
                    <Values>{option.provider}</Values>
                    <Values>{option.totalValue}</Values>
                  </Content>
                )
              })}
              </RowContent>
            </>
          )
        }
        <Row>
          <ListTitle>Anexos salvos</ListTitle>
        </Row>
        <Divider></Divider>
        <Row style={{ marginTop: 8, marginBottom: 8 }}>
          <h1> Clique no arquivo para realizar o download:</h1>
        </Row>
        <Table
          columns={columnsFiles}
          rows={files || []}
          hasEditing={false}
          hasRemoving={false}
          setSelectedItem={handleDownloadFile}
          height="auto"
        ></Table>
        <br></br>
        <Row style={{ width: '100%', justifyContent: 'center' }}>
          {renderButtonOriginalOrder()}
          <Button
            label="Imprimir pedido"
            background="white"
            color="#256CE1"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => { setPrintMode(true) }}
          ></Button>
        </Row>
      </Content>
    )

  }

  function print() {
    setSnack({
      open: true,
      severity: 'success',
      message: 'Gerando PDF ...',
    });
    window.scroll(0, 0);
    const input = document.getElementById('report');
    html2canvas(input, { scale: 5 }).then((canvas) => {
      const image = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF('p', 'px', 'a4');
      const { width } = pdf.internal.pageSize;
      const { height } = pdf.internal.pageSize;
      pdf.addImage(image, 'JPEG', -5, 0, width + 5, height);
      pdf.save('Pedido.pdf');
    });
  }

  // function multiplePrint() {
  //   setSnack({
  //     open: true,
  //     severity: 'success',
  //     message: 'Gerando PDF ...',
  //   });
  //   window.scroll(0, 0);
  //   const input = document.getElementById('report');
  //   html2canvas(input, { scale: 5 }).then((canvas) => {
  //     const image = canvas.toDataURL('image/jpeg', 1.0);
  //     const pdf = new jsPDF('p', 'px', 'a4');
  //     const { width } = pdf.internal.pageSize;
  //     const { height } = pdf.internal.pageSize;
  //     pdf.addImage(image, 'JPEG', -5, 0, width + 5, height);
  //     pdf.save('Pedido' + '.pdf');
  //   });
  // }
  function multiplePrint() {
    setSnack({
      open: true,
      severity: 'success',
      message: 'Gerando PDF ...',
    });
    window.scroll(0, 0);

    const pdf = new jsPDF('p', 'px', 'a4');

    // Iterar sobre os elementos com IDs diferentes
    let pageNumber = 1;
    let element = document.getElementById(`report_${pageNumber}`);
    const scale = 5;

    // Função recursiva para adicionar páginas ao PDF
    function addPageToPDF() {
      html2canvas(element, { scale }).then((canvas) => {
        const image = canvas.toDataURL('image/jpeg', 1.0);
        const { width } = pdf.internal.pageSize;
        const { height } = pdf.internal.pageSize;
        pdf.addImage(image, 'JPEG', -5, 0, width + 5, height);

        pageNumber++;
        element = document.getElementById(`report_${pageNumber}`);

        if (element) {
          pdf.addPage();
          addPageToPDF();
        } else {
          pdf.save('Pedido.pdf');
        }
      });
    }

    addPageToPDF();
  }

  function renderPrint() {
    return (
      <Content style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Row style={{ width: '100%', justifyContent: 'center', marginTop: 36 }}>
          <Button
            label="Voltar para Detalhes"
            background="white"
            color="#256CE1"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => { setPrintMode(false) }}
          ></Button>
          <Button
            label="Imprimir"
            background="#256CE1"
            color="white"
            borderColor="white"
            disabled={false}
            onClick={() => print()}
          ></Button>
        </Row>
        <OrderToPrint order={order} transactions={transactions} apportionment={apportionment} parcels={parcels} renderParcels={renderParcels} />
      </Content>
    )

  }
  function splitTransactions(originalArray, amountFirstPage) {
    const maxSize = 14;
    const splittedArray = [];

    let remaining = originalArray.length;
    let index = 0;

    // Verificar se a quantidade de elementos na primeira página é maior que o limite máximo
    if (amountFirstPage > maxSize) {
      console.error("A quantidade de elementos para a primeira página não pode exceder o limite máximo de elementos por página.");
      return splittedArray;
    }

    // Adicionar elementos à primeira página
    const firstPage = originalArray.slice(index, amountFirstPage);
    splittedArray.push(firstPage);
    remaining -= amountFirstPage;
    index += amountFirstPage;

    // Dividir o restante dos elementos em páginas adicionais
    while (remaining > 0) {
      const pieceArray = originalArray.slice(index, index + maxSize);
      splittedArray.push(pieceArray);
      remaining -= maxSize;
      index += maxSize;
    }

    return splittedArray;
  }

  function renderPrintMoreThanOnePage() {
    let amountFirstPage = 14 - apportionment.length - parcels?.length - transactions?.length;
    const splittedTransactions = splitTransactions(transactions, amountFirstPage);

    return (
      <Content style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Row style={{ width: '100%', justifyContent: 'center', marginTop: 36 }}>
          <Button
            label="Voltar para Detalhes"
            background="white"
            color="#256CE1"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => { setPrintMode(false) }}
          ></Button>
          <Button
            label="Imprimir"
            background="#256CE1"
            color="white"
            borderColor="white"
            disabled={false}
            onClick={() => { multiplePrint() }}
          ></Button>
        </Row>
        <OrderToPrintFirstPage order={order} transactions={splittedTransactions[0]} apportionment={apportionment} parcels={parcels} />
        {splittedTransactions.forEach((piece, index) => {
          if (index) {
            if (index !== splittedTransactions.length - 1) {
              return (
                <OrderToPrintAdd order={order} transactions={piece} index={index + 1} />
              )
            } else {
              return (
                <OrderToPrintLastPage order={order} transactions={piece} index={index + 1} />
              )
            }
          }
        })}
      </Content>
    )

  }

  return (
    <Modal
      open={open}
      onClose={() => setSelectedItem({ open: false, mode: 'view', name: '', id_order: null })}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={() => setSelectedItem({ open: false, mode: 'view', name: '', id_order: null })} alt="Fechar"></img>
        </Header>
        {printMode ? apportionment.length + transactions.length + parcels.length < 10 ? renderPrint() : renderPrintMoreThanOnePage() : renderContent()}
      </Container>
    </Modal>
  )
}


import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Title, TitleRow, Row, Subtitle, SubtitleRow, Content, Values, RowContent, Tabs} from './styles';
import { ArrowUp, ArrowDown } from '../../assets/icons';
import moment from 'moment';
import { formatMoney, formatNumber } from '../../services/functions';
import Table from '../OrderedTable';

const columns = [
    {
        name: 'Viagem',
        key: 'trips_name',
        type: 'number',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Espécie',
        key: 'fishingtypes_name',
        type: 'string',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Saída',
        key: 'begin_date',
        type: 'dateWithoutYear',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Chegada',
        key: 'end_date',
        type: 'dateWithoutYear',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Dias',
        key: 'days',
        type: 'string',
        unit: '',
        sortable: false,
        isColorful: false, 
        formatter: (days) => {
            let valueToShow =  days.days + ' dias';
            if(days.hours){
                valueToShow = valueToShow + ' e ' +  days.hours + ' horas';
            }
            return valueToShow;
        },
    },
    {
        name: 'Produção',
        key: 'production',
        type: 'float',
        unit: 'kg',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Preço Médio',
        key: 'average_price',
        type: 'money',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Faturamento',
        key: 'revenue',
        type: 'money',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Despesas',
        key: 'expenses',
        type: 'money',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Consumo/dia',
        key: 'daily_oil_consumption',
        type: 'float',
        unit: 'L/dia',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Bruto (kg)',
        key: 'gross_kg',
        type: 'float',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Líquido (kg)',
        key: 'net_kg',
        type: 'float',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Queda',
        key: 'drop',
        type: 'float',
        unit: '%',
        sortable: true,
        isColorful: false, 
        formatter: (value) => {
            let valueToShow =  value*100;
            return valueToShow;
        },
    },
    {
        name: 'Custo/kg',
        key: 'kg_cost',
        type: 'money',
        unit: '/kg',
        sortable: true,
        isColorful: false, 
    },
    
    {
        name: 'Custo direto',
        key: 'direct_cost',
        type: 'money',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Custo direto/kg',
        key: 'direct_cost_per_kg',
        type: 'money',
        unit: '/kg',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Custo direto (%)',
        key: 'direct_cost_percentage',
        type: 'percent',
        unit: '%',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Saldo custo direto',
        key: 'direct_cost_balance',
        type: 'money',
        unit: '',
        sortable: true,
        isColorful: true, 
    },
    {
        name: 'Custo indireto',
        key: 'indirect_cost',
        type: 'money',
        unit: '',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Custo indireto/kg',
        key: 'indirect_cost_per_kg',
        type: 'money',
        unit: '/kg',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Custo indireto (%)',
        key: 'indirect_cost_percentage',
        type: 'percent',
        unit: '%',
        sortable: true,
        isColorful: false, 
    },
    {
        name: 'Saldo',
        key: 'balance',
        type: 'money',
        unit: '',
        sortable: true,
        isColorful: true, 
    },
]


export default function Boat({ boat }) {
const [expanded, setExpanded] = useState(false);
let navigate = useNavigate();
const icon = expanded ? ArrowUp : ArrowDown;
let numberOfTrips = boat?.trips.length;

function sortByTripName(trips) {
    trips.sort(function(a, b) {
        var nameANumber = parseFloat(a.trips_name);
        var nameBNumber = parseFloat(b.trips_name);
        return nameANumber - nameBNumber;
    });
    return trips;
}

const tripsToShow = sortByTripName(boat?.trips);

function redirectingToTrip(trip){
    let boatToRedirect = boat?.boats_name;
    let tripsToRedirect = trip?.trips_name;
    let harvestToRedirect = trip?.harvest;
    navigate('/tripsbyboat', { state: {boat:boatToRedirect,trip: tripsToRedirect, harvest: harvestToRedirect} });
}
 

    function renderTable(){
        return (
            <Table 
            columns={columns} 
            rows={tripsToShow} 
            hasEditing={false} 
            hasRemoving={false}
            setSelectedItem={(value)=>{redirectingToTrip(value?.item)}} 
            height="100%"
            actionsInFirstLine={false}
            fitWidth={true}
            defaultOrderKey="trips_name"
        ></Table>
        );
    }


    return (
        <Container style={{height: 'auto', justifyContent:expanded ? 'flex-start' : 'center' }}>
            <Row>
            <TitleRow>
                    <img src={icon} alt={'Expandir'} style={{cursor:'pointer'}} onClick={()=> setExpanded(!expanded)}></img>
                    <Title>{boat?.boats_name}</Title>
            </TitleRow>
            <SubtitleRow>
                <Subtitle >Saldo: <b style={{color: boat?.boat_balance > 0 ? '#03A03E' : '#FF4D5E' }}>{formatMoney(boat?.boat_balance)}</b></Subtitle>
                <Subtitle >Bruto (kg): <b>{formatNumber(boat?.boat_gross_kg/numberOfTrips)}</b></Subtitle>
                <Subtitle >Líquido (kg): <b>{formatNumber(boat?.boat_net_kg/numberOfTrips)}</b></Subtitle>
                <Subtitle >Custo dir.: <b>{formatMoney(boat?.boat_direct_cost/numberOfTrips)}</b></Subtitle>
                <Subtitle >Custo dir./kg: <b>{formatMoney(boat?.boat_direct_cost/boat?.boat_net_kg)}/kg</b></Subtitle>
                <Subtitle >Custo ind.: <b>{formatMoney(boat?.boat_indirect_cost/numberOfTrips)}</b></Subtitle>
                <Subtitle >Custo ind./kg: <b>{formatMoney(boat?.boat_indirect_cost/boat?.boat_net_kg)}/kg</b></Subtitle>
                <Subtitle >Custo/kg: <b>{boat?.boat_net_kg ? formatMoney(boat?.boat_expenses/(boat?.boat_net_kg)) : formatNumber(0)}</b></Subtitle>
                <Subtitle >L/dia: <b>{boat?.boat_daily_consumption ? formatNumber(boat?.boat_daily_consumption/numberOfTrips) : formatNumber(0)}</b></Subtitle>
                <Subtitle >Preço/kg: <b>{boat?.boat_net_kg ? formatNumber(boat?.boat_revenue/(boat?.boat_net_kg)) : formatNumber(0)}</b></Subtitle>
                <Subtitle >Queda: <b>{boat?.boat_gross_kg ? formatNumber((boat?.boat_gross_kg - boat?.boat_net_kg)*100/(boat?.boat_gross_kg)) : formatNumber(0)}%</b></Subtitle>
            </SubtitleRow>
            </Row>
            <Row style={{display: expanded? 'flex': 'none', marginTop: 0, width: '100%', height: '100%'}}>
                <div style={{display:'flex', flexDirection: 'column', width:'100%'}}>
                    <Row style={{marginTop: 8}}>
                      {renderTable()}
                    </Row>
                </div>
            </Row>     
        </Container>
    )
}
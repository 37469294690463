import { GlobalStyle } from "./styles/global";
import Modal from 'react-modal';
import { BrowserRouter as Router , Routes, Route} from "react-router-dom";
import  Overview  from './pages/Overview/index';
import FinantialCC from "./pages/FinantialCC";
import  Login  from './pages/Login/index';
import  Orders  from './pages/Orders/index';
import  Records  from './pages/Records/index';
import Stock from "./pages/Stock/index";
import Boats  from './pages/Records/Boats';
import CostCenters  from './pages/Records/Cost_Centers';
import CostCategories  from './pages/Records/Cost_Categories';
import Providers  from './pages/Records/Providers';
import Buyers  from './pages/Records/Buyers';
import Products  from './pages/Records/Products';
import Supplies  from './pages/Records/Supplies';
import FishingTypes  from './pages/Records/Fishing_Types';
import PaymentTypes  from './pages/Records/Payment_Types';
import Subsidiaries from "./pages/Records/Subsidiaries";
import Trips from './pages/Records/Trips/index';
import LandEmployees  from './pages/Records/Land_Employees';
import CrewEmployees  from './pages/Records/Crew_Employees';
import Partners from './pages/Records/Partners';
import Accounts from "./pages/Records/Accounts";
import TripsByBoat from './pages/TripsByBoat/index';
import Schedule from './pages/FinantialSchedule/index';
import Logs from "./pages/Logs";
import Salary from "./pages/Salary";
import DetailPageWithProvider from './pages/DetailPage'; 
import {Provider} from "./state/App.context";

// Providers
import { TransactionsProvider } from "./hooks/useTransactions";
import { BoatsProvider } from "./hooks/useBoats";
import { CostCentersProvider } from "./hooks/useCostCenters";
import { CostCategoriesProvider } from "./hooks/useCostCategories";
import { ProvidersProvider } from "./hooks/useProviders";
import { ProductsProvider } from "./hooks/useProducts";
import { FishingTypesProvider } from "./hooks/useFishingTypes";
import { PaymentTypesProvider } from "./hooks/usePaymentTypes";
import { TripsProvider } from "./hooks/useTrips";
import { SubsidiariesProvider } from "./hooks/useSubsidiaries";
import { UsersProvider } from "./hooks/useUsers";
import { OrdersProvider } from "./hooks/useOrders";
import { LogsProvider } from "./hooks/useLogs";
import { EmployeesProvider } from "./hooks/useEmployees";
import { StockProvider } from "./hooks/useStock";
import { AccountsProvider } from "./hooks/useAccounts";
import { FilesProvider } from "./hooks/useFiles";
import { FinancialProvider } from './hooks/useFinancialData'; 



import ProtectedRoute from './routes/ProtectedRoute';

import styled from 'styled-components';
import Base from './Base';
import Users from "./pages/Records/Users";
import RegisterUser from "./pages/RegisterUser";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--background);
  height: 100vh;
  width: 100%;
`;

Modal.setAppElement('#root');

export function App() {

  return (
    <FilesProvider>
    <AccountsProvider>
    <StockProvider>
    <EmployeesProvider>
    <LogsProvider>
    <OrdersProvider>
    <TransactionsProvider>
      <UsersProvider>
      <BoatsProvider>
      <CostCentersProvider>
      <CostCategoriesProvider>
        <ProvidersProvider>
        <ProductsProvider>
        <FishingTypesProvider>
          <PaymentTypesProvider>
            <TripsProvider>
              <SubsidiariesProvider>
              <FinancialProvider>
                <Provider>
                <>
                <Router>
                <Base>
                  <Root>
                    <Routes>
                        <Route path='/' element={<Login />} />
                        <Route path='/register' element={<RegisterUser />} />
                        <Route path='/overview' element={<ProtectedRoute component={Overview}> </ProtectedRoute>} />
                        <Route path='/financial-overview' element={<ProtectedRoute component={FinantialCC}> </ProtectedRoute>} />
                        <Route path="/details" element={<DetailPageWithProvider />} />
                        <Route path='/records' element={<ProtectedRoute component={Records}> </ProtectedRoute>} />
                        <Route path='/tripsbyboat' element={<ProtectedRoute component={TripsByBoat}> </ProtectedRoute>} />
                        <Route path='/orders' element={<ProtectedRoute component={Orders}> </ProtectedRoute>} />
                        {/* <Route path='/transactions' element={<ProtectedRoute component={Transactions}> </ProtectedRoute>} /> */}
                        <Route path='/schedule' element={<ProtectedRoute component={Schedule}> </ProtectedRoute>}/>
                        <Route path='/logs' element={<ProtectedRoute component={Logs}> </ProtectedRoute>}/>
                        <Route path='/stock' element={<ProtectedRoute component={Stock}> </ProtectedRoute>}/>
                        <Route path='/salary' element={<ProtectedRoute component={Salary}> </ProtectedRoute>}/>
                        <Route path='/records/boats' element={<ProtectedRoute component={Boats}> </ProtectedRoute>} />
                        <Route path='/records/costCenters' element={<ProtectedRoute component={CostCenters}> </ProtectedRoute>} />
                        <Route path='/records/costcategories' element={<ProtectedRoute component={CostCategories}> </ProtectedRoute>} />
                        <Route path='/records/providers' element={<ProtectedRoute component={Providers}> </ProtectedRoute>} />
                        <Route path='/records/buyers' element={<ProtectedRoute component={Buyers}> </ProtectedRoute>} />
                        <Route path='/records/products' element={<ProtectedRoute component={Products}> </ProtectedRoute>} />
                        <Route path='/records/supplies' element={<ProtectedRoute component={Supplies}> </ProtectedRoute>} />
                        <Route path='/records/targetSpecies' element={<ProtectedRoute component={FishingTypes}> </ProtectedRoute>} />
                        <Route path='/records/paymentTypes' element={<ProtectedRoute component={PaymentTypes}> </ProtectedRoute>} />
                        <Route path='/records/subsidiaries' element={<ProtectedRoute component={Subsidiaries}> </ProtectedRoute>} /> 
                        <Route path='/records/users' element={<ProtectedRoute component={Users}> </ProtectedRoute>} /> 
                        <Route path='/records/landEmployees' element={<ProtectedRoute component={LandEmployees}> </ProtectedRoute>} /> 
                        <Route path='/records/crewEmployees' element={<ProtectedRoute component={CrewEmployees}> </ProtectedRoute>} /> 
                        <Route path='/records/partners' element={<ProtectedRoute component={Partners}> </ProtectedRoute>} /> 
                        <Route path='/records/trips' element={<ProtectedRoute component={Trips}> </ProtectedRoute>} />
                        <Route path='/records/accounts' element={<ProtectedRoute component={Accounts}> </ProtectedRoute>} />
                    </Routes>
                    </Root>
                    </Base>
                </Router>
        <GlobalStyle /> 
        </>
        </Provider>
        </FinancialProvider>
        </SubsidiariesProvider>
        </TripsProvider>
        </PaymentTypesProvider>
        </FishingTypesProvider>
        </ProductsProvider>
        </ProvidersProvider>
        </CostCategoriesProvider>
        </CostCentersProvider>
      </BoatsProvider>
      </UsersProvider>
    </TransactionsProvider>
    </OrdersProvider>
    </LogsProvider>
    </EmployeesProvider>
    </StockProvider>
    </AccountsProvider>
    </FilesProvider>
  );
}


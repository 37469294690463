import {useEffect, useState, useContext} from "react";
import Button from "../../../components/Button";
import Textfield from "../../../components/Textfield";
import Datepicker from "../../../components/Datepicker";
import Numberfield from "../../../components/Numberfield";
import Switch from "../../../components/Switch";
import {Container, Header, Row, Content, ListTitle, Divider, Explanation} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import { UseOrders } from "../../../hooks/useOrders";
import { UseCostCenters } from "../../../hooks/useCostCenters";
import { UseCostCategories } from "../../../hooks/useCostCategories";
import { UseSubsidiaries } from "../../../hooks/useSubsidiaries";
import { UseProviders } from "../../../hooks/useProviders";
import { UseTrips} from "../../../hooks/useTrips";
import { UseBoats} from "../../../hooks/useBoats";
import { UseEmployees } from "../../../hooks/useEmployees";
import { UsePaymentTypes} from "../../../hooks/usePaymentTypes"; 
import { UseTransactions } from "../../../hooks/useTransactions";
import moment from "moment";
import Autocomplete from "../../../components/Autocomplete";
import AppContext from "../../../state/App.context";
import Table from '../../../components/Table'; 
import LocalStorageService from '../../../services/storage';
import { columns, columnsParcels, columnsApportionment, statusOptions, employeeTypeOptions, monthsOptions, yearsOptions } from "./options";
import { countExecutedParcels, formatMoney } from "../../../services/functions";

export default function ModalAddOrderSalary({open, selectedItem, setSelectedItem, handleOpen, width, height, setRefreshData}){
    let order = selectedItem;   
    const localStorageService = LocalStorageService();
    const { updateOrder, getOrderSalaryTransactions } = UseOrders();
    const { updateTransaction, createTransaction } = UseTransactions();
    const { costCenters } = UseCostCenters();
    const { costCategories } = UseCostCategories();
    const { providers } = UseProviders();
    const { subsidiaries } = UseSubsidiaries();
    const { trips } = UseTrips();
    const { boats } = UseBoats();
    const { paymentTypes } = UsePaymentTypes();
    const {employees } = UseEmployees();

    const [disableAddButton, setDisableAddButton] = useState(false);
    const [employeeType, setEmployeeType] = useState(employeeTypeOptions[0]);
    const [unity_price, setUnity_price] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [costCenter, setCostCenter] = useState({id: 0});
    const [cost_category, setCost_category] = useState({id: 0});
    const [subsidiary, setSubsidiary] = useState({id: 0});
    const [employee, setEmployee] = useState({id: 0});
    const [employeesList, setEmployeesList] = useState([]);
    const [apportiomentList, setApportiomentList] = useState([]);
    const [trip, setTrip] = useState({id: 0});
    const [paymentType, setPaymentType] = useState({id: 0});
    const [amount_parcels, setAmount_parcels] = useState(0);
    const [receipt, setReceipt] = useState('');
    const [parcel_value, setParcelValue] = useState(0);
    const [expiration_date, setExpirationDate] = useState('');
    const [payment_date, setPaymentDate] = useState('');
    const [status, setStatus] = useState(statusOptions[0]);
    const fontSize = 14;
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;
    const [parcels, setParcels] = useState([]);
    const [is_indirect_cost, setIsIndirectCost] = useState(false);
    const [begin_salary_date, setBeginSalaryDate] = useState('');
    const [end_salary_date, setEndSalaryDate] = useState('');
    const [month, setMonth] = useState(monthsOptions[0]); 
    const [year, setYear] = useState(yearsOptions[1]);
    const [notes, setNotes] = useState('');

    const title = 'Editar pedido remuneração';

    let parcelsInput = paymentType?.label && (paymentType.label.includes("Parcelado") || paymentType?.label && paymentType.label.includes("Cartão"));
    
    function calculateTotalValue() {
      return employeesList.reduce((total, product) => total + product.unity_price*1, 0);
    }

    const total_order_value = employeesList.length > 0  ? calculateTotalValue() : 0;

    function settingDefaultStates(transactionsArray){
      try{
        const subsidiaryIndex = subsidiariesOptions.findIndex((sub) => sub.id === order?.fk_subsidiary_id_subsidiary);
        const paymentTypeIndex = paymentTypesOptions.findIndex((paymentType) => paymentType.id === order?.fk_paymenttype_id_paymenttype);
        let apportionmentListDefault = [];
        order.fk_costs_center_id_cost_array.map((cost)=>{
          const costIndex = costCentersOptions.findIndex((element) => element.id === cost);
          if(costIndex !== -1) apportionmentListDefault.push({
            cost_center: costCentersOptions[costIndex],
            cost_center_name: costCentersOptions[costIndex]?.label,
            trip:null,
            trip_name: '',
          });
        })
        apportionmentListDefault.map((item, index)=>{
          const trip = order?.fk_trips_id_trip_array[index];
          const tripIndex = tripsOptions.findIndex((element) => element.id === trip);
          if(tripIndex !== -1) {
            apportionmentListDefault[index].trip = tripsOptions[tripIndex];
            apportionmentListDefault[index].trip_name = tripsOptions[tripIndex]?.label;
          };
        })
        setApportiomentList(apportionmentListDefault);
        setSubsidiary(subsidiaryIndex !== -1 ? subsidiariesOptions[subsidiaryIndex] : '');
        setPaymentType(paymentTypeIndex !== -1 ? paymentTypesOptions[paymentTypeIndex] : '');
        setAmount_parcels(order?.amount_parcels);
        setReceipt(order?.receipt);
        setDiscount(order?.discount);
        setIsIndirectCost(order?.is_indirect_cost || false);
        setBeginSalaryDate(moment(order?.begin_salary_date).add(3, 'hours'));
        setEndSalaryDate(moment(order?.end_salary_date).add(3, 'hours'));
        setNotes(order?.notes);
        if(order?.amount_parcels === 0  && order?.status[0]){
          let statusOrder = order?.status[0] === 'Pendente' ? statusOptions[0] : statusOptions[1];
          setStatus(statusOrder);
        }
        if(order?.amount_parcels === 0 && order?.expiration_date[0] ){
          setExpirationDate(moment(order?.expiration_date[0]).add(3, 'hours'));
        }
        if(order?.amount_parcels === 0 && order?.payment_date[0] && order?.status[0] === 'Executado'){
          setPaymentDate(moment(order?.payment_date[0]).add(3, 'hours'));
        }
        let splittedSalaryMonth = order?.salary_month.split('-');
        let monthIndex = monthsOptions.findIndex((monthOption) => monthOption.label === splittedSalaryMonth[0]);
        setMonth(monthIndex !== -1 ? monthsOptions[monthIndex] : monthsOptions[0]);
        let yearIndex = yearsOptions.findIndex((yearOption) => yearOption.label === splittedSalaryMonth[1]);
        setYear(yearIndex !== -1 ? yearsOptions[yearIndex] : yearsOptions[0]);       
        let defaultEmployeeInfo = [];
        transactionsArray.map((transaction)=>{
          let employeeIndex = employeesOptions.findIndex((emp)=> emp.id === transaction?.fk_employees_id_employee);
          const transactionEmployee = employeeIndex !== -1 ? employeesOptions[employeeIndex] : '';
          let categoryIndex = costCategoriesOptions.findIndex((cat)=> cat.id === transaction?.fk_costs_categories_id_category);
          const transactionCategory = categoryIndex !==  -1 ? costCategoriesOptions[categoryIndex] : '';
          let employeeInfo = {
            id_transaction: transaction?.id_transaction,
            isactive:transaction?.isactive,
            id_order: order?.id_order,
            employee: transactionEmployee,
            employee_name: transaction?.full_name,
            product_amount: 1,
            unity_price: transaction?.unity_price,
            total_value: transaction?.total_value,
            cost_category: transactionCategory,
            cost_category_name: transaction?.costs_categories_name,
            type: transaction?.type,
            fk_employees_id_employee: transaction?.fk_employees_id_employee,
          };
          defaultEmployeeInfo.push(employeeInfo);      
        })
        setEmployeesList(defaultEmployeeInfo);
        let employeeTypeIndex = employeeTypeOptions.findIndex((type)=> type.id === defaultEmployeeInfo[0]?.type )
        if(employeeTypeIndex !==-1){
          setEmployeeType(employeeTypeOptions[employeeTypeIndex]);
        }
        let defaultParcels = [];
        if(order.amount_parcels){
        for (let i = 0; i < order.amount_parcels; i++){
          const parcel = {
            name: i+1,
            expiration_date: order?.expiration_date[i],
            payment_date: order?.status[i] === 'Pendente' ? null : order?.payment_date[i],
            values_parcels: order?.values_parcels[i],
            status: order?.status[i] === 'Pendente' ? statusOptions[0] : statusOptions[1] ,
            status_label: order?.status[i],
          }
          defaultParcels.push(parcel);
        }
      } else {
        defaultParcels = [
          {
            name: 1,
            expiration_date: order?.expiration_date[0],
            payment_date: order?.status[0] === 'Pendente' ? null : order?.payment_date[0],
            values_parcels: order?.values_parcels[0],
            status: order?.status[0] === 'Pendente' ? statusOptions[0] : statusOptions[1] ,
            status_label: order?.status[0],
          }
        ]
      }
      setParcels(defaultParcels);

      } catch(err){
        console.log(err);
      }
    }

    async function getInfo(){
      try{
        setLoading(true);
        let responseTransactions = await getOrderSalaryTransactions(order?.id_order);
        if(responseTransactions.success){
          settingDefaultStates(responseTransactions?.data)
        } else {
          setSnack({
            open: true,
            severity: 'error', 
            message: responseTransactions?.message,
          })
        }
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
    }

    useEffect(()=>{
       getInfo()
    }, [selectedItem])


    function cleanAllInputs(){
      setUnity_price(0);
      setCostCenter({id: 0});
      setCost_category({id: 0});
      setSubsidiary({id: 0});
      setTrip({id: 0});
      setPaymentType({id: 0});
      setReceipt('');
      setParcelValue(0);
      setExpirationDate('');
      setPaymentDate('');
      setStatus(statusOptions[0]);
      setParcels([]);
      setAmount_parcels(0);
      setEmployeesList([]);
      setApportiomentList([]);
      setDiscount(0);
      setIsIndirectCost(false);
      setNotes('');
    }

    function handleClose(){
      setSelectedItem({open: false, mode: '', name: '', id_order: null});
      cleanAllInputs();
      setRefreshData(prevIsRefreshData => !prevIsRefreshData);
    }

    let costCentersOptions = costCenters && !costCenters.error && costCenters.filter((element)=> element.isactive).map((element)=> {return {
      id: element?.id_cost,
      label: element?.costs_center_name,
    }})

    let costCategoriesOptions = costCategories &&  !costCategories.error && costCategories.map((element)=> {return {
      id: element?.id_category,
      label: element?.costs_categories_name,
    }})

    let subsidiariesOptions = subsidiaries && !subsidiaries.error && subsidiaries.map((element)=> {return {
      id: element?.id_subsidiary,
      label: element?.subsidiary_name,
    }})

    let employeesToShow = employees && !employees.error && employees.filter(a => a.type === employeeType?.id);
    let employeesOptions = [];
    employeesToShow && employeesToShow.map((element)=> {
      if(element.isactive)
      employeesOptions.push({
        id: element?.id_employee,
        label: element?.full_name,
        });
      })

    
      let parcels_sum = 0;

      for (let i = 0; i < parcels.length; i++) {
        if (parcels[i]?.values_parcels !== undefined && !isNaN(parcels[i]?.values_parcels)) {
          parcels_sum += parcels[i]?.values_parcels;
        }
      }
      

      function getBoatName(boatId){
        try{
          let index = boats.findIndex((boat) => boat.id_boat === boatId);
          if(index !== -1){
            return boats[index].boats_name;

          } else return '';

        } catch(err){
          console.log(err)
        }
      
      }

      let tripsOptions = trips && !trips?.error && trips.map((element)=> {return {
        id: element?.id_trip,
        label: element?.trips_name + ' - ' + getBoatName(element?.fk_boats_id_boat) + ' (' + element?.harvest + ')' ,
      }})

    let paymentTypesOptions = paymentTypes && !paymentTypes.error && paymentTypes.map((element)=> {return {
      id: element?.id_paymenttype,
      label: element?.paymenttype_name,
    }})


    function addTimestampToArray(newElement, index, array){
      newElement = newElement || new Date();
      if (index === 0 && amount_parcels !== 0){
        return "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
      }
      if (index === 0 && amount_parcels === 0){
        return "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
      }
      if (index === (amount_parcels - 1)){
        return array + "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
      }
      return array +  "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
    }

    function addElementToArray(newElement, index, array){
      if (index === 0 && amount_parcels !== 0){
        return newElement + ",";
      }
      if (index === 0 && amount_parcels === 0){
        return newElement;
      }
      if (index === (amount_parcels - 1)){
        return array + newElement;
      }
      return array + newElement + ",";
    }
    function addStringToArray(newElement, index, array){ 
      if (index === 0 && amount_parcels !== 0){
        return "'"+ newElement + "',";
      }
      if (index === 0 && amount_parcels === 0){
        return "'"+ newElement + "'";
      }
      if (index === (amount_parcels - 1)){
        return array + "'" + newElement + "'";
      }
      return array + "'" + newElement + "',";
    }

    function addUuidToArray(newElement, index, array){
      newElement = newElement || new Date();
      if (apportiomentList.length === 1){
        return "'"+ newElement + "'::uuid";
      }
      if (index === 0 && apportiomentList.length !== 0){
        return "'"+ newElement + "'::uuid,";
      }
      if (index === 0 && apportiomentList.length === 0){
        return "'"+ newElement + "'::uuid";
      }
      if (index === (apportiomentList.length - 1)){
        return array + "'"+ newElement + "'::uuid";
      }
      return array +  "'"+ newElement + "'::uuid,";
    }


    function creatingApportionmentArrays(totalOrderValue, discount){
      try{
        let fk_trips_id_trip_array = [];
        let fk_costs_center_id_cost_array = [];
        let total_order_array = [];
        let apportionmentValue = (totalOrderValue - discount)/(apportiomentList.length);
        apportiomentList.map((element, index)=>{
          fk_trips_id_trip_array = addUuidToArray(element?.trip?.id,index, fk_trips_id_trip_array);
          fk_costs_center_id_cost_array = addUuidToArray(element?.cost_center?.id,index, fk_costs_center_id_cost_array);
          total_order_array.push(apportionmentValue);
        });
        return {
          fk_trips_id_trip_array,
          fk_costs_center_id_cost_array,
          total_order_array,
        }

      } catch(err){
        console.log(err);
      }
    }

    function checkingRequiredFields() {
      if ( !subsidiary.id || !paymentType.id) {
        return false;
      }
      return true;
    }

    
    async function handleEditOrder(event){
      try{
        setLoading(true);
        setDisableAddButton(true);
        event.preventDefault();
          if(checkingRequiredFields()){
            if(employeesList.length){
              let error = false;
              let status_array = '';
              let expiration_date_array = '';
              let payment_date_array = '';
              let values_parcels_array = '';
              let total_order_value_with_discount = total_order_value - discount;
              let total_order_value_exec = 0;
              if(amount_parcels === 0){
                if(status?.label === 'Executado'){
                  total_order_value_exec = total_order_value_with_discount;
                }
                status_array = addStringToArray(status.label, 0,status_array);
                values_parcels_array = addElementToArray(total_order_value_with_discount, 0,values_parcels_array);
                expiration_date_array = addTimestampToArray(expiration_date, 0,expiration_date_array);
                payment_date_array = addTimestampToArray(payment_date, 0,payment_date_array);
              } else {
                parcels.map((parcel, index)=> {
                  if(parcel.status.label === 'Executado'){
                    total_order_value_exec = total_order_value_exec + parcel?.values_parcels;
                  }
                  status_array = addStringToArray(parcel.status.label, index,status_array);
                  values_parcels_array = addElementToArray(parcel.values_parcels, index,values_parcels_array);
                  expiration_date_array = addTimestampToArray(parcel.expiration_date, index,expiration_date_array);
                  payment_date_array = addTimestampToArray(parcel.payment_date, index,payment_date_array);
                });
              };
              let transactions = [];
              employeesList.map((employee)=>{
                transactions.push({
                  unity_price: employee?.unity_price,
                  total_value: employee?.total_value,
                  product_amount: 1,
                  fk_costs_categories_id_category: employee?.cost_category?.id,
                  fk_employees_id_employee: employee?.fk_employees_id_employee,
                  fk_products_id_product: null,
                })
              });
              const userInfo = localStorageService.getUserInfo();
              const {fk_trips_id_trip_array, fk_costs_center_id_cost_array, total_order_array} = creatingApportionmentArrays(total_order_value, discount);
              const qty_parcels_exec = countExecutedParcels(parcels);
              const orderToEdit = {
                  ...order,
                  id_order: order?.id_order,
                  created_at: moment().format('YYYY-MM-DD'),
                  expiration_date: expiration_date_array,
                  payment_date: payment_date_array,
                  amount_parcels,
                  type: 'Saída',
                  status: status_array,
                  values_parcels: values_parcels_array,
                  total_order_value,
                  approved: true,
                  approved_by: userInfo?.email ,
                  created_by:userInfo?.email ,
                  receipt,
                  discount: discount,
                  fk_subsidiary_id_subsidiary: subsidiary?.id,
                  fk_paymenttype_id_paymenttype: paymentType?.id,
                  transactions,
                  isstocked: false,
                  fk_trips_id_trip_array,
                  fk_costs_center_id_cost_array,
                  total_order_array,
                  isapportionment: true,
                  qty_parcels_exec,
                  total_order_value_exec,
                  is_indirect_cost,
                  issalary: true,
                  begin_salary_date: moment(begin_salary_date).format('YYYY-MM-DD'),
                  end_salary_date: moment(end_salary_date).format('YYYY-MM-DD'),
                  salary_month: month?.label + '-'  + year?.label,
                  notes,
              };
              const response = await updateOrder(orderToEdit);
              if(!response.success){
                error = true;
              } 
              if(!error){
                 handleClose()
                  cleanAllInputs()
                  setSnack({
                    open: true,
                    severity: 'success', 
                    message:"Operação realizada com sucesso!",
                  })
              } else {
                setSnack({
                  open: true,
                  severity: 'error', 
                  message:'Ocorreu um erro no cadastro:' + response?.message,
                })
              }
      
              } else {
                setSnack({
                  open: true,
                  severity: 'error', 
                  message:'É necessário adicionar ao menos um funcionário na lista de funcionários',
                })
              }
  
          } else {
            setSnack({
              open: true,
              severity: 'error', 
              message: 'Preencha todos os campos necessários!',
            });
          }     
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
        setDisableAddButton(false);
      }
       
     }

     function addEmployeeToList(){
      try{
        if(employee?.id && unity_price && cost_category.id){
          let employeesListCopy = [... employeesList];
          let employeeInfo = {
            employee: employee,
            employee_name: employee?.label,
            unity_price: unity_price,
            total_value: unity_price,
            cost_category: cost_category,
            cost_category_name: cost_category?.label,
            fk_employees_id_employee: employee?.id,
          }
          employeesListCopy.push(employeeInfo);
          setEmployeesList(employeesListCopy);
        } else {
          setSnack({
            open: true,
            severity: 'error', 
            message: 'Preencha todos os campos necessários no funcionário!',
          });
        }
      
      } catch(err){
        console.log(err);
      }
     }

     function addApportionmentToList(){
      try{
        let apportionmentListCopy = [... apportiomentList];
        let apportionmentItem = {
          trip: trip,
          trip_name: trip?.label,
          cost_center: costCenter,
          cost_center_name: costCenter?.label,
        }
        apportionmentListCopy.push(apportionmentItem);
        setApportiomentList(apportionmentListCopy);
      } catch(err){
        console.log(err);
      }
     }
     function removeEmployeeFromList(selectedItem){
      try{
        let employeesListCopy = [... employeesList];
        let index = employeesListCopy.findIndex((element) => element.employee.id === selectedItem.employee.id);
        if(index > -1){
          employeesListCopy.splice(index,1);
        }
        setEmployeesList(employeesListCopy);
      } catch(err){
        console.log(err);
      }
     }

     function removeApportionmentFromList(selectedItem){
      try{
        let apportionmentListCopy = [... apportiomentList];
        let index = apportionmentListCopy.findIndex((element) => element.trip.id === selectedItem.trip.id);
        if(index > -1){
          apportionmentListCopy.splice(index,1);
        }
        setApportiomentList(apportionmentListCopy);
      } catch(err){
        console.log(err);
      }
     }


     function addParcelToList(){
      try{
        let parcelsCopy = [... parcels];
        let parcelInfo = {
          name: parcelsCopy.length + 1,
          value: parcel_value,
          expiration_date: expiration_date,
          payment_date: payment_date,
          status: status,
          status_label: status.label,
        }
        parcelsCopy.push(parcelInfo);
        setParcels(parcelsCopy);
      } catch(err){
        console.log(err);
      }
     }

  function setPropParcel(item, index, prop){
      try {
        let parcelsCopy = [... parcels];
        parcelsCopy[index][prop] = item;
        setParcels(parcelsCopy)
      } catch(err){
        console.log(err);
      }
    }

     function renderParcelArea(){
      if(amount_parcels > 1){
        return (
          <>
           <Row>
            <ListTitle>Adicionar parcela:</ListTitle>
          </Row>
          <Row style={{marginBottom: 16}}>
          <Datepicker
              label="Data de Vencimento"
              width={240}
              disabled={false}
              value={expiration_date} 
              fontSize={fontSize} 
              onChange={setExpirationDate} />
          <Datepicker
              label="Data de Pagamento"
              width={240}
              disabled={false}
              value={payment_date} 
              fontSize={fontSize} 
              onChange={setPaymentDate} />
          <Autocomplete
              value={status}
              width={240}
              disabled={false}
              options={statusOptions}
              fontSize={fontSize} 
              label="Status"
              onChange={setStatus}
              ></Autocomplete>
            <Button
                label="Adicionar" 
                background="transparent" 
                color="#256CE1" 
                borderColor="#256CE1" 
                disabled={parcels.length >= amount_parcels} 
                onClick={addParcelToList}
                ></Button>
          </Row> 
          <Row>
                  <ListTitle>Parcelas:</ListTitle>
                 </Row>
                 <Divider></Divider>
          { 
                   parcels.map((parcel, index)=>{
                    return (
                      <Row>
                          <Numberfield
                            label="Valor"
                            width={220}
                            value={parcel.values_parcels} 
                            fontSize={fontSize} 
                            onChange={(item) => setPropParcel(item, index, 'values_parcels')}
                          ></Numberfield>
                      <Datepicker
                          label={"Data de Vencimento " + parcel?.name}
                          width={220}
                          disabled={false}
                          value={parcel.expiration_date} 
                          fontSize={fontSize - 2} 
                          onChange={(item) => setPropParcel(item, index, 'expiration_date')} />
                      <Datepicker
                          label={"Data de Pagamento " + parcel?.name}
                          width={220}
                          disabled={false}
                          value={parcel.payment_date} 
                          fontSize={fontSize -2} 
                          onChange={(item) => setPropParcel(item, index, 'payment_date')} />
                      <Autocomplete
                          value={parcel.status}
                          width={220}
                          disabled={false}
                          options={statusOptions}
                          fontSize={fontSize -2} 
                          label={"Status " + parcel?.name}
                          onChange={(item) => setPropParcel(item, index, 'status')} 
                          ></Autocomplete>
                      </Row> 
                    )
                })
                }
                   <Row>
            <Explanation>
                  Valor total do pedido: {formatMoney(total_order_value - discount)}
                  <br></br>
                  Somatório das parcelas: <b>{formatMoney(parcels_sum)}</b>
            </Explanation>
          </Row>
          <Row style={{marginTop: 0}}>
          <Explanation style={{color: 'var(--red)'}}>
                  {total_order_value - discount !== parcels_sum ? 'O somátório das parcelas deve ser igual ao valor total do pedido!' : ''}
            </Explanation>
          </Row>
          </>
        )
      } else {
        return(
          <>
          <Row style={{marginBottom: 16}}> 
          <Datepicker
              label="Data de Vencimento"
              width={280}
              disabled={false}
              value={expiration_date} 
              fontSize={fontSize} 
              onChange={setExpirationDate} />
          <Datepicker
              label="Data de Pagamento"
              width={280}
              disabled={false}
              value={payment_date} 
              fontSize={fontSize} 
              onChange={setPaymentDate} />
          <Autocomplete
              value={status}
              width={280}
              disabled={false}
              options={statusOptions}
              fontSize={fontSize} 
              label="Status"
              onChange={setStatus}
              ></Autocomplete>
          </Row> 
           </>
        )
      }
     }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
            return(
            <Content>
              <Row>
              <ListTitle>Informações do pedido:</ListTitle>
              </Row>
              <Divider></Divider>
                <Row>
                  <Autocomplete
                      value={subsidiary}
                      width={280}
                      disabled={false}
                      options={subsidiariesOptions}
                      fontSize={fontSize} 
                      label="Subsidiária*"
                      onChange={setSubsidiary}
                    ></Autocomplete>
                  <Autocomplete
                      value={employeeType}
                      width={280}
                      disabled={false}
                      options={employeeTypeOptions}
                      fontSize={fontSize} 
                      label="Tipo de colaborador*"
                      onChange={setEmployeeType}
                    ></Autocomplete>
                      <Textfield
                      label="Link do recibo"
                      width={'100%'}
                      disabled={false} 
                      value={receipt} 
                      fontSize={fontSize} 
                      onChange={setReceipt}
                      multiline={false}
                    ></Textfield>
                </Row> 
                <Row>
                  <ListTitle>Período*:</ListTitle>
                 </Row>
                 <Divider></Divider>
                 <Row  style={{marginBottom: 16}}>
                  <Datepicker
                    label="Data de ínicio*"
                    width={180}
                    disabled={false}
                    value={begin_salary_date} 
                    fontSize={fontSize} 
                    onChange={setBeginSalaryDate} />
                  <Datepicker
                    label="Data de fim"
                    width={180}
                    disabled={false}
                    value={end_salary_date} 
                    fontSize={fontSize} 
                    onChange={setEndSalaryDate} />
                 <Autocomplete
                    value={month}
                    width={180}
                    disabled={false}
                    options={monthsOptions}
                    fontSize={fontSize} 
                    label="Mês de Competência*"
                    onChange={setMonth}
                  ></Autocomplete>
                  <Autocomplete
                    value={year}
                    width={180}
                    disabled={false}
                    options={yearsOptions}
                    fontSize={fontSize} 
                    label="Ano de Competência*"
                    onChange={setYear}
                  ></Autocomplete>
                  </Row>
       
                <Row>
                  <ListTitle>Lista de funcionários*:</ListTitle>
                 </Row>
                 <Divider></Divider>
                 <Row style={{marginBottom: 16}}>
                <Autocomplete
                  value={employee}
                  width={230}
                  disabled={false}
                  options={employeesOptions}
                  fontSize={fontSize} 
                  label={'Colaborador*'}
                  onChange={setEmployee}
                ></Autocomplete>
                <Autocomplete
                  value={cost_category}
                  width={230}
                  disabled={false}
                  options={costCategoriesOptions}
                  fontSize={fontSize} 
                  label="Categoria de custo*"
                  onChange={setCost_category}
                  ></Autocomplete>
                    <div style={{display:'flex', flexDirection:'row', minWidth:'330px', justifyContent:'space-between'}}>
                      <Numberfield
                        label="Valor*"
                        width={230}
                        disabled={false} 
                        value={unity_price} 
                        fontSize={fontSize} 
                        onChange={setUnity_price}
                      ></Numberfield>
                  </div>
                  <Button
                    label="Adicionar" 
                    background="transparent" 
                    color="#256CE1" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={addEmployeeToList}
                    ></Button>
                 </Row>
                  <Table 
                    columns={columns} 
                    rows={employeesList || []} 
                    hasEditing={false} 
                    hasRemoving={true}
                    setSelectedItem={removeEmployeeFromList}
                    height="auto" 
                  ></Table>
                  <Row style={{marginBottom: 16}}></Row>
                 <Row>
                  <ListTitle>Lista de rateio*:</ListTitle>
                 </Row>
                 <Divider></Divider>
                 <Row style={{marginBottom: 16}}>
                  <Autocomplete
                    value={trip}
                    width={280}
                    disabled={false}
                    options={tripsOptions}
                    fontSize={fontSize} 
                    label="Viagem*"
                    onChange={setTrip}
                  ></Autocomplete>
                  <Autocomplete
                      value={costCenter}
                      width={280}
                      disabled={false}
                      options={costCentersOptions}
                      fontSize={fontSize} 
                      label="Centro de custo*"
                      onChange={setCostCenter}
                  ></Autocomplete>
                  <Button
                    label="Adicionar" 
                    background="transparent" 
                    color="#256CE1" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={addApportionmentToList}
                  ></Button>
                </Row>
                <Table 
                    columns={columnsApportionment} 
                    rows={apportiomentList || []} 
                    hasEditing={false} 
                    hasRemoving={true}
                    setSelectedItem={removeApportionmentFromList}
                    height="auto" 
                  ></Table>
                  <br></br>
    
             <Row><ListTitle>Pagamento:</ListTitle></Row>
             <Divider></Divider>
              <Row>
                <Autocomplete
                  value={paymentType}
                  width={280}
                  disabled={false}
                  options={paymentTypesOptions}
                  fontSize={fontSize} 
                  label="Tipo de pagamento*"
                  onChange={setPaymentType}
                  ></Autocomplete>
                <Numberfield
                    label="Parcela"
                    width={280}
                    disabled={!parcelsInput} 
                    value={amount_parcels} 
                    fontSize={fontSize} 
                    onChange={setAmount_parcels}
                  ></Numberfield>
                  <Numberfield
                    label="Desconto"
                    width={280}
                    disabled={false} 
                    value={discount} 
                    fontSize={fontSize} 
                    onChange={setDiscount}
                  ></Numberfield>
              
                </Row>
                {renderParcelArea()}
              <Row style={{marginTop: 0, marginBottom: 16}}>
              <Switch 
                label="Custo Indireto" 
                disabled={false} 
                value={is_indirect_cost} 
                fontSize={fontSize} 
                onChange={setIsIndirectCost}
                multiline={false}
                color="black"
                ></Switch>
              </Row>
              <Row style={{ marginBottom: 16}}>
                <Textfield
                    label="Observações"
                    width={'100%'}
                    disabled={false} 
                    value={notes} 
                    fontSize={fontSize} 
                    onChange={setNotes}
                    multiline={true}
                    ></Textfield>
                </Row>
                <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleClose()}
                    ></Button>
                    <Button
                    label="Salvar alterações" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={disableAddButton} 
                    onClick={handleEditOrder}
                    ></Button>
                </Row>
        </Content>
            )
            
        }}

    return (
        <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleClose()} alt="Fechar"></img>
          </Header>
          {renderContent()}
 
        </Container>
      </Modal>
       )
  }
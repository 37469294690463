import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDetailData, DetailsProvider } from '../../hooks/useDetailData';
import Table from '../../components/Table';
import { columnsSalary, columnsComprador } from './options';
import {Container, Content, Row,ReviewContainer, ReviewContent, RowInfo, Title, Divider, Tabs, Section, TripTitle, RowContent} from './styles';
import Header from '../../components/Header';
import { verifyInterval, formatMoney } from '../../services/functions';
import { useNavigate  } from 'react-router-dom';

const DetailPage = () => {
    const location = useLocation();
    const { state } = location;
    const { detailsData, loading, getCcOrders, getCcSalary } = useDetailData();
    const [tripsResponse, setTripsResponse] = useState(null);

    const navigate = useNavigate();

    const handleRowClick = (rowData) => {
        state?.employee ? navigate('/salary', { state: rowData }) : navigate('/orders', { state: rowData }) ;
    };

    useEffect(() => {
        async function fetchInfo() {
            if (state?.provider ) {
                try {
                    const response = await getCcOrders({ providers_name: state.provider });
                    setTripsResponse(response);
                } catch (err) {
                    console.error(err);
                }
            }
            else if (state?.employee) {
                try {
                    const response = await getCcSalary({ full_name: state.employee });
                    setTripsResponse(response);
                } catch (err) {
                    console.error(err);
                }
            }
        }
        fetchInfo();
    }, []);

    function renderReview() {
     
        const total = tripsResponse.data.summary.total;
        const totalPaid = tripsResponse.data.summary.total_pago;
        const totalPend = tripsResponse.data.summary.total_pendente;

        return (
            <ReviewContainer>
                <ReviewContent>
                    <Title >Resumo</Title>
                    <Divider></Divider>
                    <RowInfo>Total:{formatMoney(total)}</RowInfo>
                    <RowInfo>Total Pago:{formatMoney(totalPaid)}</RowInfo>
                    <RowInfo>Total Pendente:{formatMoney(totalPend)}</RowInfo>
                    <Divider></Divider>
                </ReviewContent>
            </ReviewContainer>
        )
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!tripsResponse?.success) {
        return <div>Data not found</div>;
    }

    return (
        <Container>
        <Header title="Resumo Conta Correte" subtitle={(state.employee ? state.employee : state.provider)}  />
              <Table
                columns={(state.employee ? columnsSalary : columnsComprador)}
                rows={tripsResponse.data.transactions || []}
                hasEditing={false}
                hasRemoving={false}
                setSelectedItem={() => {}}
                onRowClick={handleRowClick}
                colorfulRowsById = {true}
            />
              {renderReview()}
        </Container>
    );
};


const DetailPageWithProvider = () => (
    <DetailsProvider>
        <DetailPage />
    </DetailsProvider>
);

export default DetailPageWithProvider;

import styled from 'styled-components';

export const Container = styled.div`
background: var(--background);
height: 100vh;
width: 100%;
padding: 32px;
`;

export const Content = styled.div`
height: auto;
width: 100%;
margin-top: 36px;
display: grid;
column-gap: 1vw;
row-gap: 1vw;
grid-template-columns: auto auto auto auto;
`;


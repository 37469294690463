import { FormEvent, useState, useContext} from "react";
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import {Container, Header, Row, Content, Explanation} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import { UseUsers } from "../../../../hooks/useUsers";
import AppContext from "../../../../state/App.context";
import Autocomplete from "../../../../components/Autocomplete";
import LocalStorageService from '../../../../services/storage';

  const rolesOptions = [
    {id:'adm', label:'Administrador'},
    {id: 'write', label:'Visualização, Criação, Edição e Remoção'},
    {id:'view', label:'Apenas Visualização'}
  ]

export default function ModalAddFishingType({open, handleOpen, width, height}){
    const { createUser } = UseUsers();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('view');
    const localStorageService = LocalStorageService();
    let fk_companies_id_company = 0;
    const userInfo = localStorageService.getUserInfo();
    fk_companies_id_company = userInfo?.fk_companies_id_company;

    const fontSize = 14;
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;

    const title = 'Pré-cadastrar usuário';

    function cleanAllInputs(){
      try{
        setEmail('');
        setRole('');
      } catch(err){
           console.log(err);
      }
  }

  
  function checkingRequiredFields() {
    if (!email || !role) {
      setSnack({
        open: true,
        severity: 'error', 
        message: 'Preencha todos os campos necessários!',
      });
      return false;
    }
    return true;
  }

    async function handleCreateNewUser(event){
      try{
        setLoading(true);
        event.preventDefault();
        if(checkingRequiredFields()){
          const response = await createUser(
              {
              e_mail: email,
              user_role: role?.id,
              fk_companies_id_company: fk_companies_id_company,
          }
          );
          if(response.success){
            cleanAllInputs();
            handleOpen(false);
            setSnack({
              open: true,
              severity: 'success', 
              message:response?.message,
            })
          } else {
            setSnack({
              open: true,
              severity: 'error', 
              message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
            })
          }
      }
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
   
     }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
            return(
<Content>
              <Row>
               <Explanation>Ao pré-cadastrar um usuário, ele irá receber um e-mail com instruções de ativação da conta.</Explanation> 
              </Row>
                <Row>
                    <Textfield
                    label="E-mail*"
                    width={600}
                    disabled={false} 
                    value={email} 
                    fontSize={fontSize} 
                    onChange={setEmail}
                    multiline={false}
                    ></Textfield>
                </Row>
                <Row>
                  <Autocomplete
                      value={role}
                      width={600}
                      disabled={false}
                      options={rolesOptions}
                      fontSize={fontSize} 
                      label="Permissão*"
                      onChange={setRole}
                      ></Autocomplete>
                </Row>
                <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleOpen(false)}
                    ></Button>
                    <Button
                    label="Pré-cadastrar usuário" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={handleCreateNewUser}
                    ></Button>
                </Row>
        </Content>
            )
            
        }}

    return (
        <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleOpen(false)} alt="Fechar"></img>
          </Header>
          {renderContent()}
 
        </Container>
      </Modal>
       )
  }
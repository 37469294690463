

import { Container} from './styles';
import TextField from '@mui/material/TextField';
import {Search, Clear} from '../../assets/icons/index';

interface SearchbarProps{
    width: number,
    disabled: boolean;
    value: string;
    fontSize: string;
    placeholder: string;
    onChange: (arg0: string) => void;
}

export default function Searchbar({ width, disabled, fontSize, value, placeholder, onChange}: SearchbarProps){

    return (
        <Container>
            <TextField  
                type="search"
                variant="outlined" 
                size="small"
                disabled={disabled}
                value={value}
                onChange={(event)=> onChange(event.target.value)}
                placeholder={placeholder}
                style={{
                    width: width,
                    color: '#141522',
                    background:'#FFFFFF',
                    fontSize: fontSize
                    }} 
                sx={{ 
                    borderRadius:'10px', 
                    border: '0.2px solid #9C9CA4',
                    '& input': {
                                color: '#141522',
                                fontFamily:'Plus Jakarta Sans',
                                fontSize: fontSize,
                            }
                    }}
                InputProps={{
                    startAdornment: (
                        <img src={Search} alt="Search"  style={{marginRight:12, width:fontSize}}></img>
                    ),
                    endAdornment: (
                        <img src={Clear} alt="clear" onClick={()=>onChange('')} style={{display: value.length > 0 ? 'block': 'none', marginRight:12, width:fontSize, cursor: 'pointer'}}></img>
                    ),
                    }}>
                    </TextField>
        </Container>
    )
}
import { useState, useContext} from "react";
import Button from "../../../components/Button";
import Datepicker from "../../../components/Datepicker";
import {Container, Header, Row, Content, ListTitle, Divider} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import { UseOrders } from "../../../hooks/useOrders";
import moment from "moment";
import AppContext from "../../../state/App.context";
import { formatMoney } from "../../../services/functions";
import { UseFiles } from "../../../hooks/useFiles";
import ButtonUploadFile from "../../../components/ButtonUpload";
import Table from '../../../components/Table';

const columnsFiles = [
  {
    name: 'Nome do arquivo',
    key:'name',
    type: 'string', 
    unit: '',
  },
]


export default function ModalChangePaymentDate({open, selectedItem, setSelectedItem, handleOpen, width, height, modalChangeExpirationDate, setRefreshData}){
    let order = selectedItem; 
    const { shortcutEditOrder } = UseOrders();
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;
    const [payment_date, setPaymentDate] = useState(new Date(order?.current_parcel_payment_date));
    const {getOrderFiles, uploadFilesToOrder, downloadFile} = UseFiles();
    const title = order?.type === 'Entrada' ? 'Receber parcela' : 'Pagar parcela';
    const fontSize = 14;
    const amount_parcels = order?.amount_parcels;
    const [files, setFiles] = useState([]);


    let actionType = order?.type === 'Entrada' ? 'recebimento' : 'pagamento';
    let receivingType = order?.type === 'Entrada' ? 'do' : 'ao';

    function cleanAllInputs(){
      setPaymentDate(new Date());
      }

    function handleClose(){
        setSelectedItem({open: false, mode: '', name: '', id_order: null});
        cleanAllInputs();
        setRefreshData(prevIsRefreshData => !prevIsRefreshData);
      }
    
      function addTimestampToArray(newElement, index, array){
        newElement = newElement || new Date();
        if (index === 0 && amount_parcels !== 0){
          return "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
        }
        if (index === 0 && amount_parcels === 0){
          return "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
        }
        if (index === (amount_parcels - 1)){
          return array + "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
        }
        return array +  "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
      }

      function addStringToArray(newElement, index, array){ 
        if (index === 0 && amount_parcels !== 0){
          return "'"+ newElement + "',";
        }
        if (index === 0 && amount_parcels === 0){
          return "'"+ newElement + "'";
        }
        if (index === (amount_parcels - 1)){
          return array + "'" + newElement + "'";
        }
        return array + "'" + newElement + "',";
      }
  

    async function handleChangePaymentDate(event){
        try{
            setLoading(true);
            event.preventDefault();
            let status_array = '';
            let expiration_date_array = '';
            let payment_date_array = '';
            let current_parcel_index = order?.current_parcel_name.split('/')[0] - 1;
            let new_payment_date = moment(payment_date).add(3, 'hours');
            let total_order_value_exec_array = 0;
            let total_order_value_with_discount = order?.total_order_value - order?.discount;
            const status = 'Executado';
            const expiration_date = moment(order?.expiration_date[current_parcel_index]).add(3, 'hours');
            if(amount_parcels === 0){
                total_order_value_exec_array = addStringToArray(total_order_value_with_discount, 0, total_order_value_exec_array );
                status_array = addStringToArray(status, 0,status_array);
                expiration_date_array = addTimestampToArray(expiration_date, 0,expiration_date_array);
                payment_date_array = addTimestampToArray(new_payment_date, 0,payment_date_array);
              } else {
                order?.status.map((status_item, index)=> {
                  let parcel_new_status = index === current_parcel_index ? 'Executado' : status_item;
                  if(status_item === 'Executado' || parcel_new_status === 'Executado'){
                    total_order_value_exec_array = total_order_value_exec_array + order?.values_parcels[index];
                  }
                  let parcel_new_payment_date = index === current_parcel_index ? new_payment_date : order?.payment_date[index];
                  status_array = addStringToArray(parcel_new_status, index,status_array);
                  expiration_date_array = addTimestampToArray(moment(order?.expiration_date[index]).add(3, 'hours'), index,expiration_date_array);
                  payment_date_array = addTimestampToArray(parcel_new_payment_date, index,payment_date_array);
                });
              };
            const updatedOrder = {
                id_array: [order?.id_order],
                status_array: [status_array],
                expiration_date_array: [expiration_date_array],
                payment_date_array: [payment_date_array],
                total_order_value_exec_array: [total_order_value_exec_array],
            }
            const response = await shortcutEditOrder(updatedOrder);
            if(response.success){
                handleClose();
                cleanAllInputs();
                if(files.length > 0){
                  let onlyFiles = [];
                  files.map((file)=>{
                    onlyFiles.push(file?.file);
                  })
                  const responseUploadFiles = await uploadFilesToOrder(order?.id_order, onlyFiles);
                  if(!responseUploadFiles.success){
                    setSnack({
                      open: true,
                      severity: 'error', 
                      message:"Erro ao carregar arquivo(s)",
                    })
                  }
                }
                setSnack({
                  open: true,
                  severity: 'success', 
                  message:"Operação realizada com sucesso!",
                })
            } else {
                setSnack({
                    open: true,
                    severity: 'error', 
                    message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
                  })
            }
        } catch(err){
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    function addFileToList(event){
      try{
        let filesCopy = [...files];
        let selectedFiles = [...event.target.files];
        selectedFiles && selectedFiles.map((file)=>{
          filesCopy.push({
            name: file.name,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            size: file.size,
            type: file.type,
            webkitRelativePath: file.webkitRelativePath,
            file: file,
          });
        })
        setFiles(filesCopy);
      }catch(err){
        console.log(err);
      }
     }

     function removeFileFromList(selectedItem){
      try{
        let filesCopy = [... files];
        let index = filesCopy.findIndex((element) => element.name === selectedItem.name);
        if(index > -1){
          filesCopy.splice(index, 1);
        }
        setFiles(filesCopy);
      } catch(err){
        console.log(err);
      }
     }
  

    function renderContent(){
     if(loading){
       return (
         <Spinner width={40} fontSize={14}></Spinner>
       )
     } else {
         return(
         <Content>
             <Row>
              <h1>Deseja definir o {actionType} da parcela {order?.current_parcel_name} da subsidiária <b>{order?.subsidiary_name}</b> no valor de <b>{formatMoney(order?.current_parcel_value)}, com vencimento dia {moment(order?.current_parcel_expiration_date).add(3, 'hours').format('DD/MM/YYYY')},</b> {receivingType} <b>{order?.providers_name}</b>  como <b>"Executado"</b> na seguinte data:</h1>
            </Row>
            <Datepicker
              label={"Data de Pagamento"}
              width="100%"
              disabled={false}
              value={payment_date} 
              fontSize={fontSize -2} 
              onChange={(item) => setPaymentDate(item)} />
            <br></br>
            <Row>
                <ListTitle>Anexar novos arquivos</ListTitle>
              </Row>
              <Divider></Divider>
              <Row style={{marginBottom: '2rem'}}>
                <ButtonUploadFile
                    disabled={false} 
                    onChange={addFileToList}
                    multiple
                    accept="*/*" 
                ></ButtonUploadFile>
              </Row>
              <Table 
                    columns={columnsFiles} 
                    rows={files || []} 
                    hasEditing={false} 
                    hasRemoving={true}
                    setSelectedItem={removeFileFromList}
                    height="auto" 
              ></Table>
              <br></br>
             <Row>
                 <Button
                 label="Cancelar" 
                 background="#8E92BC" 
                 color="white" 
                 borderColor="#8E92BC" 
                 disabled={false} 
                 onClick={()=> handleClose()}
                 ></Button>
                 <Button
                 label="Salvar alterações" 
                 background="#256CE1" 
                 color="white" 
                 borderColor="#256CE1" 
                 disabled={false} 
                 onClick={handleChangePaymentDate}
                 ></Button>
             </Row>
     </Content>
         )
         
     }}


   return (
       <Modal
       open={open}
       onClose={() => handleClose()}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
       >
       <Container style={{width, height}}>
         <Header>
         <h1>
           {title}
         </h1>
         <img src={Close} onClick={()=> handleClose()} alt="Fechar"></img>
         </Header>
         {renderContent()}

       </Container>
     </Modal>
      )

}

import {useState, useEffect, useContext} from 'react';
import {Container, Header, Row, Content, Column} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import AppContext from "../../../state/App.context"
import { sortByProperty } from '../../../services/functions';
import Button from "../../../components/Button";
import moment from 'moment';
import Papa from 'papaparse';


export default function ModalReportExcel({open, handleOpen, subsidiaries, startDate, endDate, sumsInfo}){
    const title = 'Relatório de Programação Financeira';
    const [, setSnack] = useContext(AppContext).snackState;

    let allOrders = [];
    subsidiaries && subsidiaries.map((subsidiary)=>{
        let pending_outputs = [];
        let pending_inputs = [];
        let resolved_inputs = [];
        let resolved_outputs = [];

subsidiary.pending_outputs && subsidiary.pending_outputs.map((output, index)=>{
    let current_parcel_name = '1/1';
    let current_parcel_value = parseFloat(output?.total_order_value) - parseFloat(output?.discount);
    let current_parcel_expiration_date = output?.expiration_date ? output?.expiration_date[0] : new Date();
    let providers_name = output.issalary ? output?.full_name : output?.providers_name;
    delete output?.current_parcel_payment_date;
    if(output?.current_parcel_name){
        pending_outputs.push({
            ...output,
            providers_name,
        })
    } else {
        pending_outputs.push({
            ...output,
            current_parcel_name,
            current_parcel_value,
            current_parcel_expiration_date,
            providers_name,
        })
    }
});


subsidiary.resolved_outputs && subsidiary.resolved_outputs.map((output, index)=>{
    let current_parcel_name = '1/1';
    let current_parcel_value = parseFloat(output?.total_order_value) - parseFloat(output?.discount);
    let current_parcel_expiration_date = output?.expiration_date ? output?.expiration_date[0] : new Date();
    let current_parcel_payment_date = output?.payment_date[0];
    let providers_name = output.issalary ? output?.full_name : output?.providers_name;
    if(output?.current_parcel_name){
        resolved_outputs.push({
            ...output,
            providers_name,
        })
    } else {
        resolved_outputs.push({
            ...output,
            current_parcel_name,
            current_parcel_value,
            current_parcel_expiration_date,
            providers_name,
            current_parcel_payment_date,
        })
    }
});


subsidiary.pending_inputs && subsidiary.pending_inputs.map((input, index)=>{
    let current_parcel_name = '1/1';
    let current_parcel_value = parseFloat(input?.total_order_value) - parseFloat(input?.discount);
    let current_parcel_expiration_date = input?.expiration_date ? input?.expiration_date[0] : new Date();
    delete input?.current_parcel_payment_date;
    if(input?.current_parcel_name){
        pending_inputs.push({
            ...input,
        })
    }else {
        pending_inputs.push({
            ...input,
            current_parcel_name,
            current_parcel_value,
            current_parcel_expiration_date,
        })
    }

});
;

subsidiary.resolved_inputs && subsidiary.resolved_inputs.map((input, index)=>{
    let current_parcel_name = '1/1';
    let current_parcel_value = parseFloat(input?.total_order_value) - parseFloat(input?.discount);
    let current_parcel_expiration_date = input?.expiration_date ? input?.expiration_date[0] : new Date();
    let current_parcel_payment_date = input?.payment_date[0];
    if(input?.current_parcel_name){
        resolved_inputs.push({
            ...input,
        })
    }else {
        resolved_inputs.push({
            ...input,
            current_parcel_name,
            current_parcel_value,
            current_parcel_expiration_date,
            current_parcel_payment_date,
        })
    }

});

pending_inputs = sortByProperty(pending_inputs, 'providers_name');
pending_outputs = sortByProperty(pending_outputs, 'providers_name');
resolved_inputs = sortByProperty(resolved_inputs, 'providers_name');
resolved_outputs = sortByProperty(resolved_outputs, 'providers_name');

        pending_outputs.map((element)=>{
            allOrders.push({
                ...element,
                subsidiary_name: subsidiary?.subsidiary_name,
                type: 'Pagamento Pendente',
            });
        });
        // allOrders.push({
        //     subsidiary_name: subsidiary?.subsidiary_name,
        //     type: 'Total Pagamentos',
        //     status: 'Total Pagamentos Pendentes',
        //     total_order_value: subsidiary.pending_outputs_total,
        // });

        resolved_outputs.map((element)=>{
            allOrders.push({
                ...element,
                subsidiary_name: subsidiary?.subsidiary_name,
                type: 'Pagamento Executado',
            });
            });

        // allOrders.push({
        //     subsidiary_name: subsidiary?.subsidiary_name,
        //     type: 'Total Pagamentos Executados',
        //     total_order_value: subsidiary?.resolved_outputs_total,
        // });

        pending_inputs.map((element)=>{
            allOrders.push({
                ...element,
                subsidiary_name: subsidiary?.subsidiary_name,
                type: 'Recebimento Pendente',
            });
        });

        // allOrders.push({
        //     subsidiary_name: subsidiary?.subsidiary_name,
        //     type: 'Total Recebimentos Pendentes',
        //     total_order_value: subsidiary.pending_inputs_total,
        // });

        resolved_inputs.map((element)=>{
            allOrders.push({
                ...element,
                subsidiary_name: subsidiary?.subsidiary_name,
                type: 'Recebimento Executado',
            });
        });

        // allOrders.push({
        //     subsidiary_name: subsidiary?.subsidiary_name,
        //     type: 'Total Recebimentos',
        //     status: 'Executado',
        //     total_order_value: subsidiary.resolved_inputs_total,
        // });
    });
    
    function createExcel(){
      try{
        const reportName = 'Programação Financeira.csv';
        const csv = '\ufeff' + Papa.unparse(allOrders); // Adicionando a BOM
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', reportName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch(err){
        console.log(err);
      }
    }


    return (
        <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-type"
        >
        <Container>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleOpen(false)} alt="Fechar"></img>
          </Header>
          <Column>
            <h2>Deseja gerar o relatório em formato excel do periodo <b>{moment(startDate).format('DD/MM/YYYY')} à {moment(endDate).format('DD/MM/YYYY')}</b>? </h2>
            <Button
                label="Gerar relatório"
                background="#256CE1" 
                color="white" 
                borderColor="white" 
                disabled={false}
                onClick={() => createExcel()}
                width={200}
           ></Button>
          </Column>
          <Content>
          </Content>
        </Container>
      </Modal>
       )
  }
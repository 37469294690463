
import { createContext, useState, ReactNode, useContext } from 'react';
import api from '../services/api';
import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();

interface Order {
    id_order: string;
    discount: number;
    expiration_date: string;
    payment_date: string;
    amount_parcels: number;
    type: string;
    status: string;
    value_parcels: string;
    total_order_value: number;
    isactive: boolean;
    approved: boolean;
    approved_by: string;
    created_by: string;
    fk_costs_center_id_cost: string;
    fk_providers_id_provider: string;
    fk_trips_id_trips: string;
    fk_subsidiary_id_subsidiary: string;
    fk_paymenttype_id_paymenttype: string;
    transactions: string;
    created_at: string;
    isstocked: string;
    notes: string;
    selling_orders: string[];
}

interface ChangeExpirationDateRequest {
    id_array: string[];
    expiration_date_array: any[];
    payment_date_array: any[];
    status_array: string[];
}

interface OrdersRequest {
    type?: string | string[];
    costCenter?: string | string[];
    subsidiary?: string | string[];
    provider?: string | string[];
    trip?: string | string[];
    paymentType?: string | string[];
    boat?: string | string[];
    status?: string | string[];
    startDate: string;
    endDate: string;
    pages: number;
    limit: number;
}


type OrderInput = Omit<Order, 'id_order'>


interface OrdersProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface ResponsePropsWithData {
    success: boolean;
    message: string;
    data: any;
}

interface OrdersContextData {
    orders: Order[];
    getFilteredOrders: (request: OrdersRequest) => Promise<ResponsePropsWithData>;
    getOrdersSalary: (request: OrdersRequest) => Promise<ResponsePropsWithData>;
    getOrderTransactions: (order: string) => Promise<ResponsePropsWithData>;
    getOrderSalaryTransactions: (order: string) => Promise<ResponsePropsWithData>;
    getOrderDetails: (order: string) => Promise<ResponsePropsWithData>;
    getOrderSalaryDetails: (order: string) => Promise<ResponsePropsWithData>;
    createOrder: (order: OrderInput) => Promise<ResponseProps>;
    updateOrder: (order: Order) => Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
    deleteStockOrder: (id: number) => Promise<ResponseProps>;
    getResultByDate: (startDate: string, endDate: string) => Promise<ResponsePropsWithData>;
    getResultByCategory: (category: string, startDate: string, endDate: string) => Promise<ResponsePropsWithData>;
    shortcutEditOrder: (order: ChangeExpirationDateRequest) => Promise<ResponseProps>;
    getInputOrdersFromTrip: (tripId: string) => Promise<ResponsePropsWithData>;
    getInputOrdersFromOrder: (orderId: string) => Promise<ResponsePropsWithData>;
}

const OrdersContext = createContext<OrdersContextData>(
    {} as OrdersContextData
);


export function OrdersProvider({ children }: OrdersProvidersProps) {
    const [orders, setOrders] = useState<Order[]>([]);
    const [, setRefreshOnDelete] = useState(0);

    async function getOrderTransactions(order: string) {
        const params = {
            fk_orders_id_order: order,
        };
        const response = await api.get('/transactions', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar transações relacionadas ao pedido.",
            data: [],
        }
    }

    async function getOrderSalaryTransactions(order: string) {
        const params = {
            fk_orders_id_order: order,
        };
        const response = await api.get('/salarytransactions', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar transações relacionadas ao pedido.",
            data: [],
        }
    }

    async function getOrderDetails(order: string) {
        const params = {
            id_order: order,
        };
        const response = await api.get('/orderdetails', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar os detalhes relacionados ao pedido.",
            data: [],
        }
    }


    async function getOrderSalaryDetails(order: string) {
        const params = {
            id_order: order,
        };
        const response = await api.get('/salaryorderdetails', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar os detalhes relacionados ao pedido.",
            data: [],
        }
    }

    async function getFilteredOrders(request: OrdersRequest) {
        let params = {
            ...request,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };
        const response = await api.get('/ordersfilt', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar pedidos.",
            data: [],
        }
    }


    async function getOrdersSalary(request: OrdersRequest) {
        let params = {
            ...request,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };
        const response = await api.get('/salaryordersfilt', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar pedidos.",
            data: [],
        }
    }

    async function updateOnDelete(id: number) {
        const response = await api.post('/deleteorder', { id_order: id });
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1);
            return {
                success: true,
                message: "Pedido removido com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover pedido."
        }
    }

    async function deleteStockOrder(id: number) {
        const response = await api.delete('/deletestocktransactions', {
            data: { id_order: id }
        });

        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1);
            return {
                success: true,
                message: "Pedido removido com sucesso!"
            };
        }
        return {
            success: false,
            message: "Erro ao remover pedido."
        }
    }

    async function createOrder(orderInput: OrderInput) {
        const response = await api.post('/orders', orderInput);
        if (response.status === 200) {
            api.get('orders').then(response => setOrders(response.data))
            return {
                success: true,
                message: "Pedido adicionado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar pedido."
        }

    }

    async function updateOrder(order: Order) {
        const response = await api.put('/orders', order);
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1)
            return {
                success: true,
                message: "Pedido editado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar pedido."
        }
    }

    async function getResultByDate(startDate: string, endDate: string) {
        let params = {
            startDate: "'" + startDate + "'",
            endDate: "'" + endDate + "'",
        };
        const response = await api.get('/resultbydate', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar resultado.",
            data: [],
        }
    }

    async function getResultByCategory(category: string, startDate: string, endDate: string) {
        let params = {
            startDate: "'" + startDate + "'",
            endDate: "'" + endDate + "'",
        };
        const response = await api.get('/summary/' + category, { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar resultado por categoria.",
            data: [],
        }
    }

    async function shortcutEditOrder(order: ChangeExpirationDateRequest) {
        const response = await api.put('/shortcut', order);
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1)
            return {
                success: true,
                message: "Pedido editado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar pedido."
        }
    }

    async function getInputOrdersFromTrip(tripId: string) {
        const params = {
            fk_trips_id_trip: tripId,
        };
        const response = await api.get('/inputordersfromtrip', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar [pedidos de entrada de uma viagem] relacionadas ao pedido.",
            data: [],
        }
    }

    async function getInputOrdersFromOrder(orderId: string) {
        const params = {
            order_id: orderId,
        };
        const response = await api.get('/inputordersfromorder', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar [pedidos de entrada] relacionadps ao pedido.",
            data: [],
        }
    }
    
    return (
        <OrdersContext.Provider value={{ orders, getFilteredOrders, getOrdersSalary, getOrderDetails, getOrderSalaryDetails, getOrderTransactions, getOrderSalaryTransactions, createOrder, updateOnDelete, updateOrder, getResultByDate, getResultByCategory, deleteStockOrder, shortcutEditOrder, getInputOrdersFromTrip, getInputOrdersFromOrder }}>
            {children}
        </OrdersContext.Provider>
    );
}

export function UseOrders() {
    const context = useContext(OrdersContext);
    return context;
}
import { createContext, useState, ReactNode, useContext } from 'react';
import api from '../services/api';
import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();

interface DetailsData {
    id_order: string;
    boats_name: string | null;
    boats_names: Array<{ boats_names: string[] }>;
    created_at: string;
    payment_date: string[];
    values_parcels: number[];
    status: string[];
    amount_parcels: number;
    total_com_desconto: string;
    total_order_value_exec: string;
    valor_pendente: string;
}

interface DetailsProviderProps {
    children: ReactNode;
}

interface OverviewRequest {
    provider: string ;
    employee: string; 
}

interface ResponsePropsWithData {
    success: boolean;
    message: string;
    data: any;
}

interface DetailsContextData {
    detailsData: DetailsData | null;
    loading: boolean;
    getCcOrders: (request: OverviewRequest) => Promise<ResponsePropsWithData>;
    getCcSalary: (request: OverviewRequest) => Promise<ResponsePropsWithData>;
}

const DetailsContext = createContext<DetailsContextData>({} as DetailsContextData);

export function DetailsProvider({ children }: DetailsProviderProps) {
    const [detailsData, setDetailsData] = useState<DetailsData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    async function getCcOrders(request: OverviewRequest): Promise<ResponsePropsWithData> {
        setLoading(true);
        try {
            const token = api.defaults.params.token || localStorageService.getIdToken();
            const response = await api.get('/ccorders', { params: { ...request, token } });
            if (response.status === 200) {
                setDetailsData(response.data);
                return { success: true, message: "", data: response.data };
            }
            return { success: false, message: "Erro ao consultar os dados.", data: [] };
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            return { success: false, message: "Erro ao consultar os dados.", data: [] };
        } finally {
            setLoading(false);
        }
    }

    async function getCcSalary(request: OverviewRequest): Promise<ResponsePropsWithData> {
        setLoading(true);
        try {
            const token = api.defaults.params.token || localStorageService.getIdToken();
            const response = await api.get('/ccsalary', { params: { ...request, token } });
            if (response.status === 200) {
                setDetailsData(response.data);
                return { success: true, message: "", data: response.data };
            }
            return { success: false, message: "Erro ao consultar os dados.", data: [] };
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            return { success: false, message: "Erro ao consultar os dados.", data: [] };
        } finally {
            setLoading(false);
        }
    }

    return (
        <DetailsContext.Provider value={{ detailsData, loading, getCcOrders, getCcSalary }}>
            {children}
        </DetailsContext.Provider>
    );
}

export function useDetailData() {
    const context = useContext(DetailsContext);
    return context;
}

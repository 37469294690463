import  { useState, useContext, useEffect} from "react";
import {
   Container,
   Box,
   LogoRow,
   LogoImg,
   Row,
   RowButton,
  } from './styles';
  import Switch from "../../components/Switch";
  import { LogoLogin} from '../../assets/images/index';
  import TextfieldDark from "../../components/TextfieldDark";
  import Button from "../../components/Button";
  import { useNavigate } from "react-router-dom";
  import LocalStorageService from '../../services/storage';
  import AppContext from "../../state/App.context";
  import { UseUsers } from "../../hooks/useUsers";

export default function Login(){
  const { authenticateUser } = UseUsers();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const fontSize = 16;
  let navigate = useNavigate();
  const localStorageService = LocalStorageService();
  const [, setSnack] = useContext(AppContext).snackState;
  const token = localStorageService.getIdToken();

  useEffect(()=>{
    if(token){
      navigate('/orders');
    }
  })

  async function verifyingCredentials(){
    try{
      const responseLogin = await authenticateUser({e_mail: email, user_password: password});
      if(responseLogin.success){
        // localStorageService.setToken({token: responseLogin?.data?.token});
        // localStorageService.setUserInfo({email, id_user: responseLogin?.data?.id_user, user_role:responseLogin?.data?.user_role, company_name: responseLogin?.data?.company_name, fk_companies_id_company: responseLogin?.data?.fk_companies_id_company });
        await Promise.all([
          localStorageService.setToken({ token: responseLogin?.data?.token }),
          localStorageService.setUserInfo({
            email,
            id_user: responseLogin?.data?.id_user,
            user_role: responseLogin?.data?.user_role,
            company_name: responseLogin?.data?.company_name,
            fk_companies_id_company: responseLogin?.data?.fk_companies_id_company,
          }),
        ]);
        navigate('/orders');
        setSnack({
          open: true,
          severity: 'success', 
          message:responseLogin?.message,
        });
      } else {
        setSnack({
          open: true,
          severity: 'error', 
          message:responseLogin?.message,
        })
      }
    } catch(err){
      console.log(err);
    }
  }


    return (
      <Container>
        <Box>
        <LogoRow>
          <LogoImg src={LogoLogin} alt='logo' />
        </LogoRow>
        <Row>
          <TextfieldDark
            label="E-mail"
            width={400}
            disabled={false} 
            value={email} 
            fontSize={fontSize} 
            onChange={setEmail}
            multiline={false}
            password={false}
            ></TextfieldDark>
          </Row>
          <Row>
            <TextfieldDark
              label="Senha"
              width={400}
              disabled={false} 
              value={password} 
              fontSize={fontSize} 
              onChange={setPassword}
              multiline={false}
              password={!showPassword}
            ></TextfieldDark>
        </Row>
        <Row style={{width: 400, justifyContent:'flex-start', alignItems:'flex-start'}}>
          <Switch 
            label="Mostrar senha" 
            disabled={false} 
            value={showPassword} 
            fontSize={fontSize} 
            onChange={setShowPassword}
            multiline={false}
            color="white"
            ></Switch>
          </Row>
        <RowButton>
        <Button
          label="Entrar no sistema" 
          background="#FFFFFF" 
          color="#141522" 
          borderColor="#FFFFFF" 
          disabled={false} 
          onClick={()=> {verifyingCredentials()}}
          ></Button>
        </RowButton>
        <RowButton style={{ marginTop: '4%'}}>
        <Button
          label="Cadastrar usuário" 
          background="#141522" 
          color="#FFFFFF" 
          borderColor="#FFFFFF" 
          disabled={false} 
          onClick={()=> { navigate('/register')}}
          ></Button>
        </RowButton>
        </Box>
      </Container>
    );
  };
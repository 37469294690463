import React, {useEffect, useState, useContext} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UseEmployees } from "../../../hooks/useEmployees"
import { UseSubsidiaries } from "../../../hooks/useSubsidiaries"
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import ModalAddPartner from './ModalAddPartner/index';
import ModalRemovePartner from './ModalRemovePartner';
import ModalEditPartner from './ModalEditPartner';
import AppContext from "../../../state/App.context";
import LocalStorageService from '../../../services/storage';
import Autocomplete from "../../../components/Autocomplete";

  const columns = [
    {
      name: 'Nome Completo',
      key:'full_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Data de Nascimento',
      key:'birthday',
      type: 'date', 
      unit:'',
    },
    {
      name: 'Cargo',
      key:'role',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Salário',
      key:'salary',
      type: 'money', 
      unit:'',
    },
    {
      name: 'Data de Admissão',
      key:'admission_date',
      type: 'date', 
      unit:'',
    },
    {
      name: 'Subsidiária',
      key:'subsidiary_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Telefone',
      key:'phone_number',
      type: 'string', 
      unit:'',
    },

  ]


function Partners(){
  const { employees } = UseEmployees();
  const { subsidiaries } = UseSubsidiaries();
  const [search, setSearch] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModalAddEmployee, setOpenModalAddEmployee] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({open: false, mode: '', e_mail: '', id_employee: null});
  const [subsidiaryFilter, setSubsidiaryFilter] = useState({
    label: 'Todas',
    id: 0,
  });
  const [, setSnack] = useContext(AppContext).snackState;
  const localStorageService = LocalStorageService();
  const employeeInfo = localStorageService.getUserInfo();
  const fk_companies_id_company = employeeInfo?.fk_companies_id_company;

  let subsidiariesOptions = [];
  subsidiaries && !subsidiaries.error && subsidiaries.map((element)=> {
    if(element.isactive) {
      subsidiariesOptions.push({
        id: element?.id_subsidiary,
        label: element?.subsidiary_name,
      })
    }
  }
  )

  let employeesToShow = employees;
  if (search.length > 0) {
    employeesToShow = employees.filter(a => a.full_name.toLowerCase().includes(search.toLowerCase()));
  }
  if (subsidiaryFilter.id) {
    employeesToShow = employeesToShow.filter(a => subsidiaryFilter.id === a.fk_subsidiary_id_subsidiary);
  }
  employeesToShow = employeesToShow && !employeesToShow.error && employeesToShow.filter(employee => employee.fk_companies_id_company === fk_companies_id_company && employee.type === 'socio' && employee.isactive === true);

  useEffect(()=>{
    if(employees.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:employees.error,
      })
    }
  },[employees])

  function Filters(){
    return(
      <div style={{marginTop: 16}}>
        <Autocomplete
          value={subsidiaryFilter}
          width={200}
          disabled={false}
          options={[{id:0, label:'Todas'}, ...subsidiariesOptions]}
          fontSize={'0.7rem'} 
          label="Subsidiária"
          onChange={setSubsidiaryFilter}
          ></Autocomplete>
      </div>
    )
  }


    return (
      <Container>
        <Header title="Sócios" subtitle="Gerencie os sócios" />
        <Content>
          <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer>
          <Row>
            <Button
              label="Adicionar sócio" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddEmployee(true)}
             ></Button>
                <Button 
              label="Filtros" 
              background="transparent" 
              color="#54577A" 
              borderColor="#54577A" 
              disabled={false} 
              onClick={()=> setOpenDrawer(true)}
              ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar sócio..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={employeesToShow || []} 
            hasEditing={true} 
            hasRemoving={true}
            setSelectedItem={setSelectedEmployee} 
          ></Table>
        </Content>
        <ModalAddPartner 
         open={openModalAddEmployee} 
         handleOpen={setOpenModalAddEmployee} 
         width={'auto'} 
         height={340} 
         ></ModalAddPartner>
            <ModalRemovePartner
          open={selectedEmployee.open && selectedEmployee.mode === 'remove'} 
          setSelectedItem={setSelectedEmployee} 
          employeeName={selectedEmployee?.full_name}
          employeeId={selectedEmployee?.id_employee}
          width={600} 
          height={100} 
        ></ModalRemovePartner>
         <ModalEditPartner 
         open={selectedEmployee.open && selectedEmployee.mode === 'edit'}
         selectedItem={selectedEmployee}
         setSelectedItem={setSelectedEmployee}
         width={600} 
         height={100} 
         ></ModalEditPartner>
      </Container>
    );
  };

  export default React.createElement(Partners);
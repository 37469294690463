import {useEffect, useState, useContext} from "react";
import Button from "../../../components/Button";
import Textfield from "../../../components/Textfield";
import Datepicker from "../../../components/Datepicker";
import Numberfield from "../../../components/Numberfield";
import Switch from "../../../components/Switch";
import {Container, Header, Row, Content, ListTitle, Divider, Explanation} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import { UseOrders } from "../../../hooks/useOrders";
import { UseCostCenters } from "../../../hooks/useCostCenters";
import { UseCostCategories } from "../../../hooks/useCostCategories";
import { UseSubsidiaries } from "../../../hooks/useSubsidiaries";
import { UseProviders } from "../../../hooks/useProviders";
import { UseTrips} from "../../../hooks/useTrips";
import { UseBoats} from "../../../hooks/useBoats";
import { UseEmployees } from "../../../hooks/useEmployees";
import { UsePaymentTypes} from "../../../hooks/usePaymentTypes"; 
import moment from "moment";
import Autocomplete from "../../../components/Autocomplete";
import AppContext from "../../../state/App.context";
import Table from '../../../components/Table'; 
import LocalStorageService from '../../../services/storage';
import { columns, columnsParcels, columnsApportionment, statusOptions, employeeTypeOptions, monthsOptions, yearsOptions } from "./options";
import { countExecutedParcels, formatMoney } from "../../../services/functions";

function getCurrentMonthIndex() {
  const currentMonth = moment().month() + 1; 
  return monthsOptions.findIndex(month => month.id === currentMonth);
}

export default function ModalAddOrderSalary({open, handleOpen, width, height}){
    const localStorageService = LocalStorageService();
    const { createOrder } = UseOrders();
    const { costCenters } = UseCostCenters();
    const { costCategories } = UseCostCategories();
    const { providers } = UseProviders();
    const { subsidiaries } = UseSubsidiaries();
    const { trips } = UseTrips();
    const { boats } = UseBoats();
    const { paymentTypes } = UsePaymentTypes();
    const {employees } = UseEmployees();

    const [disableAddButton, setDisableAddButton] = useState(false);
    const [employeeType, setEmployeeType] = useState(employeeTypeOptions[0]);
    const [unity_price, setUnity_price] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [costCenter, setCostCenter] = useState({id: 0});
    const [cost_category, setCost_category] = useState({id: 0});
    const [subsidiary, setSubsidiary] = useState({id: 0});
    const [employee, setEmployee] = useState({id: 0});
    const [employeesList, setEmployeesList] = useState([]);
    const [apportiomentList, setApportiomentList] = useState([]);
    const [trip, setTrip] = useState({id: 0});
    const [paymentType, setPaymentType] = useState({id: 0});
    const [amount_parcels, setamount_parcels] = useState(0);
    const [receipt, setReceipt] = useState('');
    const [parcel_value, setParcelValue] = useState(0);
    const [expiration_date, setExpirationDate] = useState('');
    const [payment_date, setPaymentDate] = useState('');
    const [status, setStatus] = useState(statusOptions[0]);
    const fontSize = 14;
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;
    const [parcels, setParcels] = useState([]);
    const [is_indirect_cost, setIsIndirectCost] = useState(false);
    const [begin_salary_date, setBeginSalaryDate] = useState('');
    const [end_salary_date, setEndSalaryDate] = useState('');
    const [month, setMonth] = useState(monthsOptions[getCurrentMonthIndex()]); 
    const [year, setYear] = useState(yearsOptions[1]);
    const [notes, setNotes] = useState('');

    const title = 'Adicionar novo pedido remuneração';

    let parcelsInput = paymentType?.label && (paymentType.label.includes("Parcelado") || paymentType?.label && paymentType.label.includes("Cartão"));
    
    function calculateTotalValue() {
      return employeesList.reduce((total, product) => total + product.unity_price*1, 0);
    }

    const total_order_value = employeesList.length > 0  ? calculateTotalValue() : 0;

    function cleanAllInputs(){
      setUnity_price(0);
      setCostCenter({id: 0});
      setCost_category({id: 0});
      setSubsidiary({id: 0});
      setTrip({id: 0});
      setPaymentType({id: 0});
      setReceipt('');
      setParcelValue(0);
      setExpirationDate('');
      setPaymentDate('');
      setStatus(statusOptions[0]);
      setParcels([]);
      setamount_parcels(0);
      setEmployeesList([]);
      setApportiomentList([]);
      setDiscount(0);
      setIsIndirectCost(false);
      setNotes('');
    }

    let costCentersOptions = costCenters && !costCenters.error && costCenters.filter((element)=> element.isactive).map((element)=> {return {
      id: element?.id_cost,
      label: element?.costs_center_name,
    }})

    let costCategoriesOptions = costCategories &&  !costCategories.error && costCategories.map((element)=> {return {
      id: element?.id_category,
      label: element?.costs_categories_name,
    }})

    let subsidiariesOptions = subsidiaries && !subsidiaries.error && subsidiaries.map((element)=> {return {
      id: element?.id_subsidiary,
      label: element?.subsidiary_name,
    }})

    let employeesToShow = employees && !employees.error && employees.filter(a => a.type === employeeType?.id);
    let employeesOptions = [];
    employeesToShow && employeesToShow.map((element)=> {
      if(element.isactive)
      employeesOptions.push({
        id: element?.id_employee,
        label: element?.full_name,
        });
      })

    let parcels_sum = 0;

    for (let i = 0; i < parcels.length; i++) {
      if (parcels[i]?.value !== undefined && !isNaN(parcels[i]?.value)) {
        parcels_sum += parcels[i]?.value;
      }
    }
      

      function getBoatName(boatId){
        try{
          let index = boats.findIndex((boat) => boat.id_boat === boatId);
          if(index !== -1){
            return boats[index].boats_name;

          } else return '';

        } catch(err){
          console.log(err)
        }
      
      }

      let tripsOptions = trips && !trips?.error && trips.map((element)=> {return {
        id: element?.id_trip,
        label: element?.trips_name + ' - ' + getBoatName(element?.fk_boats_id_boat) + ' (' + element?.harvest + ')' ,
      }})

    let paymentTypesOptions = paymentTypes && !paymentTypes.error && paymentTypes.map((element)=> {return {
      id: element?.id_paymenttype,
      label: element?.paymenttype_name,
    }})


    function addTimestampToArray(newElement, index, array){
      newElement = newElement || new Date();
      if (index === 0 && parcels.length !== 0){
        return "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
      }
      if (index === 0 && parcels.length === 0){
        return "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
      }
      if (index === (parcels.length - 1)){
        return array + "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
      }
      return array +  "timestamp '"+ moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
    }

    function addElementToArray(newElement, index, array){
      if (index === 0 && parcels.length !== 0){
        return newElement + ",";
      }
      if (index === 0 && parcels.length === 0){
        return newElement;
      }
      if (index === (parcels.length - 1)){
        return array + newElement;
      }
      return array + newElement + ",";
    }
    function addStringToArray(newElement, index, array){
      if (index === 0 && parcels.length !== 0){
        return "'"+ newElement + "',";
      }
      if (index === 0 && parcels.length === 0){
        return "'"+ newElement + "'";
      }
      if (index === (parcels.length - 1)){
        return array + "'" + newElement + "'";
      }
      return array + "'" + newElement + "',";
    }

    function addUuidToArray(newElement, index, array){
      newElement = newElement || new Date();
      if (apportiomentList.length === 1){
        return "'"+ newElement + "'::uuid";
      }
      if (index === 0 && apportiomentList.length !== 0){
        return "'"+ newElement + "'::uuid,";
      }
      if (index === 0 && apportiomentList.length === 0){
        return "'"+ newElement + "'::uuid";
      }
      if (index === (apportiomentList.length - 1)){
        return array + "'"+ newElement + "'::uuid";
      }
      return array +  "'"+ newElement + "'::uuid,";
    }

    function creatingApportionmentArrays(totalOrderValue, discount){
      try{
        let fk_trips_id_trip_array = [];
        let fk_costs_center_id_cost_array = [];
        let total_order_array = [];
        let apportionmentValue = (totalOrderValue - discount)/(apportiomentList.length);
        apportiomentList.map((element, index)=>{
          fk_trips_id_trip_array = addUuidToArray(element?.trip?.id,index, fk_trips_id_trip_array);
          fk_costs_center_id_cost_array = addUuidToArray(element?.cost_center?.id,index, fk_costs_center_id_cost_array);
          total_order_array.push(apportionmentValue);
        });
        return {
          fk_trips_id_trip_array,
          fk_costs_center_id_cost_array,
          total_order_array,
        }

      } catch(err){
        console.log(err);
      }
    }

    function checkingRequiredFields() {
      if ( !subsidiary.id || !paymentType.id) {
        return false;
      }
      return true;
    }
    
    async function handleCreateOrder(event){
      try{
        setLoading(true);
        setDisableAddButton(true);
        event.preventDefault();
          if(checkingRequiredFields()){
            if(employeesList.length){
              let error = false;
              let status_array = '';
              let expiration_date_array = '';
              let payment_date_array = '';
              let values_parcels_array = '';
              let total_order_value_with_discount = total_order_value - discount;
              let total_order_value_exec = 0;
              if(amount_parcels === 0){
                if(status?.label === 'Executado'){
                  total_order_value_exec = total_order_value_with_discount;
                }
                status_array = addStringToArray(status.label, 0,status_array);
                values_parcels_array = addElementToArray(total_order_value_with_discount, 0,values_parcels_array);
                expiration_date_array = addTimestampToArray(expiration_date, 0,expiration_date_array);
                payment_date_array = addTimestampToArray(payment_date, 0,payment_date_array);
              } else {
                parcels.map((parcel, index)=> {
                  if(parcel.status.label === 'Executado'){
                    total_order_value_exec = total_order_value_exec + parcel?.value;
                  }
                  status_array = addStringToArray(parcel.status.label, index,status_array);
                  values_parcels_array = addElementToArray(parcel?.value, index,values_parcels_array);
                  expiration_date_array = addTimestampToArray(parcel.expiration_date, index,expiration_date_array);
                  payment_date_array = addTimestampToArray(parcel.payment_date, index,payment_date_array);
                });
              };
              let transactions = [];
              employeesList.map((employee)=>{
                transactions.push({
                  unity_price: employee?.unity_price,
                  total_value: employee?.total_value,
                  product_amount: 1,
                  fk_costs_categories_id_category: employee?.cost_category?.id,
                  fk_employees_id_employee: employee?.employee?.id,
                  fk_products_id_product: null,
                })
              });
              const userInfo = localStorageService.getUserInfo();
              const {fk_trips_id_trip_array, fk_costs_center_id_cost_array, total_order_array} = creatingApportionmentArrays(total_order_value, discount);
              const qty_parcels_exec = countExecutedParcels(parcels);
              const orderToAdd = {
                  created_at: moment().format('YYYY-MM-DD'),
                  expiration_date: expiration_date_array,
                  payment_date: payment_date_array,
                  amount_parcels,
                  type: 'Saída',
                  status: status_array,
                  values_parcels: values_parcels_array,
                  total_order_value,
                  approved: true,
                  approved_by: userInfo?.email ,
                  created_by:userInfo?.email ,
                  receipt,
                  discount: discount,
                  fk_subsidiary_id_subsidiary: subsidiary?.id,
                  fk_paymenttype_id_paymenttype: paymentType?.id,
                  transactions,
                  isstocked: false,
                  fk_trips_id_trip_array,
                  fk_costs_center_id_cost_array,
                  total_order_array,
                  isapportionment: true,
                  qty_parcels_exec,
                  total_order_value_exec,
                  is_indirect_cost,
                  issalary: true,
                  begin_salary_date,
                  end_salary_date,
                  salary_month: month?.label + '-'  + year?.label,
                  notes,
              };
                const response = await createOrder(orderToAdd);
                if(!response.success){
                  error = true;
                }
              if(!error){
                  handleOpen(false);
                  cleanAllInputs()
                  setSnack({
                    open: true,
                    severity: 'success', 
                    message:"Operação realizada com sucesso!",
                  })
              } else {
                setSnack({
                  open: true,
                  severity: 'error', 
                  message:'Ocorreu um erro no cadastro:' + response?.message,
                })
              }
      
              } else {
                setSnack({
                  open: true,
                  severity: 'error', 
                  message:'É necessário adicionar ao menos um funcionário na lista de funcionários',
                })
              }
  
          } else {
            setSnack({
              open: true,
              severity: 'error', 
              message: 'Preencha todos os campos necessários!',
            });
          }     
      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
        setDisableAddButton(false);
      }
       
     }

     function addEmployeeToList(){
      try{
        if(employee?.id && unity_price && cost_category.id){
          let employeesListCopy = [... employeesList];
          let employeeInfo = {
            employee: employee,
            employee_name: employee?.label,
            unity_price: unity_price,
            total_value: unity_price,
            cost_category: cost_category,
            cost_category_name: cost_category?.label,
          }
          employeesListCopy.push(employeeInfo);
          setEmployeesList(employeesListCopy);
        } else {
          setSnack({
            open: true,
            severity: 'error', 
            message: 'Preencha todos os campos necessários no funcionário!',
          });
        }
      
      } catch(err){
        console.log(err);
      }
     }

     function addApportionmentToList(){
      try{
        let apportionmentListCopy = [... apportiomentList];
        let apportionmentItem = {
          trip: trip,
          trip_name: trip?.label,
          cost_center: costCenter,
          cost_center_name: costCenter?.label,
        }
        apportionmentListCopy.push(apportionmentItem);
        setApportiomentList(apportionmentListCopy);
      } catch(err){
        console.log(err);
      }
     }

     function findRelatedCostCenter(boatName){
      try{
        const index = costCentersOptions.findIndex((element)=> element.label.toLowerCase() === boatName.toLowerCase())
        return index !== -1 ? costCentersOptions[index] : costCentersOptions[0];
      } catch(err){
        console.log(err);
      }

     }

     function addAllOpenTripsToList(){
      try{
        let apportionmentListCopy = [... apportiomentList];
        let openTrips = trips.filter((trip) => trip.end_date === null);
        openTrips.map((trip)=>{
          let boatName = getBoatName(trip?.fk_boats_id_boat);
        let tripOption = {
          id: trip?.id_trip,
          label: trip?.trips_name + ' - ' + boatName + ' (' + trip?.harvest + ')' ,
        }
        let costCenterRelated = findRelatedCostCenter(boatName);
        let apportionmentItem = {
          trip: tripOption,
          trip_name: tripOption?.label,
          cost_center: costCenterRelated,
          cost_center_name: costCenterRelated?.label,
          harvest_name: trip?.harvest,
        }
        apportionmentListCopy.push(apportionmentItem);
        })
        setApportiomentList(apportionmentListCopy);
      } catch(err){
        console.log(err);
      }
     }

     function removeEmployeeFromList(selectedItem){
      try{
        let employeesListCopy = [... employeesList];
        let index = employeesListCopy.findIndex((element) => element.employee.id === selectedItem.employee.id);
        if(index > -1){
          employeesListCopy.splice(index,1);
        }
        setEmployeesList(employeesListCopy);
      } catch(err){
        console.log(err);
      }
     }

     function removeApportionmentFromList(selectedItem){
      try{
        let apportionmentListCopy = [... apportiomentList];
        let index = apportionmentListCopy.findIndex((element) => element.trip.id === selectedItem.trip.id);
        if(index > -1){
          apportionmentListCopy.splice(index,1);
        }
        setApportiomentList(apportionmentListCopy);
      } catch(err){
        console.log(err);
      }
     }


     function addParcelToList(){
      try{
        let parcelsCopy = [... parcels];
        let parcelInfo = {
          name: parcelsCopy.length + 1,
          value: parcel_value,
          expiration_date: expiration_date,
          payment_date: payment_date,
          status: status,
          status_label: status.label,
        }
        parcelsCopy.push(parcelInfo);
        setParcels(parcelsCopy);
      } catch(err){
        console.log(err);
      }
     }

     function removeParcelFromList(selectedItem){
      try{
        let parcelsCopy = [... parcels];
        let index = parcelsCopy.findIndex((element) => element.name === selectedItem.name);
        if(index > -1){
          parcelsCopy.splice(index,1);
        }
        setParcels(parcelsCopy);
      } catch(err){
        console.log(err);
      }
     }


     function renderParcelArea(){
      if(amount_parcels > 1){
        return (
          <>
          <Row style={{marginBottom: 16}}>
          <Numberfield
            label="Valor*"
            width={180}
            disabled={false} 
            value={parcel_value} 
            fontSize={fontSize} 
            onChange={setParcelValue}
          ></Numberfield>
          <Datepicker
              label="Data de Vencimento*"
              width={180}
              disabled={false}
              value={expiration_date} 
              fontSize={fontSize} 
              onChange={setExpirationDate} />
          <Datepicker
              label="Data de Pagamento"
              width={180}
              disabled={false}
              value={payment_date} 
              fontSize={fontSize} 
              onChange={setPaymentDate} />
          <Autocomplete
              value={status}
              width={180}
              disabled={false}
              options={statusOptions}
              fontSize={fontSize} 
              label="Status*"
              onChange={setStatus}
              ></Autocomplete>
            <Button
                label="Adicionar" 
                background="transparent" 
                color="#256CE1" 
                borderColor="#256CE1" 
                disabled={parcels.length >= amount_parcels} 
                onClick={addParcelToList}
                ></Button>
          </Row> 
          <Table 
            columns={columnsParcels} 
            rows={parcels || []} 
            hasEditing={false} 
            hasRemoving={true}
            setSelectedItem={removeParcelFromList} 
            height="auto" 
          ></Table>
          <Row>
            <Explanation>
                  Valor total do pedido: {formatMoney(total_order_value - discount)}
                  <br></br>
                  Somatório das parcelas: <b>{formatMoney(parcels_sum)}</b>
            </Explanation>
          </Row>
          <Row style={{marginTop: 0}}>
          <Explanation style={{color: 'var(--red)'}}>
                  {total_order_value - discount !== parcels_sum ? 'O somátório das parcelas deve ser igual ao valor total do pedido!' : ''}
            </Explanation>
          </Row>
          </>
        )
      } else {
        return(
          <>
          <Row style={{marginBottom: 16}}> 
          <Datepicker
              label="Data de Vencimento*"
              width={280}
              disabled={false}
              value={expiration_date} 
              fontSize={fontSize} 
              onChange={setExpirationDate} />
          <Datepicker
              label="Data de Pagamento"
              width={280}
              disabled={false}
              value={payment_date} 
              fontSize={fontSize} 
              onChange={setPaymentDate} />
          <Autocomplete
              value={status}
              width={280}
              disabled={false}
              options={statusOptions}
              fontSize={fontSize} 
              label="Status*"
              onChange={setStatus}
              ></Autocomplete>
          </Row> 
           </>
        )
      }
     }
     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
            return(
            <Content>
              <Row>
              <ListTitle>Informações do pedido:</ListTitle>
              </Row>
              <Divider></Divider>
                <Row>
                  <Autocomplete
                      value={subsidiary}
                      width={280}
                      disabled={false}
                      options={subsidiariesOptions}
                      fontSize={fontSize} 
                      label="Subsidiária*"
                      onChange={setSubsidiary}
                    ></Autocomplete>
                  <Autocomplete
                      value={employeeType}
                      width={280}
                      disabled={false}
                      options={employeeTypeOptions}
                      fontSize={fontSize} 
                      label="Tipo de colaborador*"
                      onChange={setEmployeeType}
                    ></Autocomplete>
                      <Textfield
                      label="Link do recibo"
                      width={'100%'}
                      disabled={false} 
                      value={receipt} 
                      fontSize={fontSize} 
                      onChange={setReceipt}
                      multiline={false}
                    ></Textfield>
                </Row> 
                <Row>
                  <ListTitle>Período*:</ListTitle>
                 </Row>
                 <Divider></Divider>
                 <Row  style={{marginBottom: 16}}>
                  <Datepicker
                    label="Data de ínicio*"
                    width={180}
                    disabled={false}
                    value={begin_salary_date} 
                    fontSize={fontSize} 
                    onChange={setBeginSalaryDate} />
                  <Datepicker
                    label="Data de fim*"
                    width={180}
                    disabled={false}
                    value={end_salary_date} 
                    fontSize={fontSize} 
                    onChange={setEndSalaryDate} />
                 <Autocomplete
                    value={month}
                    width={180}
                    disabled={false}
                    options={monthsOptions}
                    fontSize={fontSize} 
                    label="Mês de Competência*"
                    onChange={setMonth}
                  ></Autocomplete>
                  <Autocomplete
                    value={year}
                    width={180}
                    disabled={false}
                    options={yearsOptions}
                    fontSize={fontSize} 
                    label="Ano de Competência*"
                    onChange={setYear}
                  ></Autocomplete>
                  </Row>
       
                <Row>
                  <ListTitle>Lista de funcionários*:</ListTitle>
                 </Row>
                 <Divider></Divider>
                 <Row style={{marginBottom: 16}}>
                <Autocomplete
                  value={employee}
                  width={230}
                  disabled={false}
                  options={employeesOptions}
                  fontSize={fontSize} 
                  label={'Colaborador*'}
                  onChange={setEmployee}
                ></Autocomplete>
                <Autocomplete
                  value={cost_category}
                  width={230}
                  disabled={false}
                  options={costCategoriesOptions}
                  fontSize={fontSize} 
                  label="Categoria de custo*"
                  onChange={setCost_category}
                  ></Autocomplete>
                    <div style={{display:'flex', flexDirection:'row', minWidth:'330px', justifyContent:'space-between'}}>
                      <Numberfield
                        label="Valor*"
                        width={230}
                        disabled={false} 
                        value={unity_price} 
                        fontSize={fontSize} 
                        onChange={setUnity_price}
                      ></Numberfield>
                  </div>
                  <Button
                    label="Adicionar" 
                    background="transparent" 
                    color="#256CE1" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={addEmployeeToList}
                    ></Button>
                 </Row>
                  <Table 
                    columns={columns} 
                    rows={employeesList || []} 
                    hasEditing={false} 
                    hasRemoving={true}
                    setSelectedItem={removeEmployeeFromList}
                    height="auto" 
                  ></Table>
                  <Row style={{marginBottom: 16}}></Row>
                 <Row>
                  <ListTitle>Lista de rateio*:</ListTitle>
                 </Row>
                 <Divider></Divider>
                 <Row style={{marginBottom: 16}}>
                      <Button
                        label="Adicionar todas as viagem em aberto" 
                        background="transparent" 
                        color="#256CE1" 
                        borderColor="#256CE1" 
                        disabled={false} 
                        onClick={addAllOpenTripsToList}
                      ></Button>
                    </Row>
                 <Row style={{marginBottom: 16}}>
                  <Autocomplete
                    value={trip}
                    width={280}
                    disabled={false}
                    options={tripsOptions}
                    fontSize={fontSize} 
                    label="Viagem*"
                    onChange={setTrip}
                  ></Autocomplete>
                  <Autocomplete
                      value={costCenter}
                      width={280}
                      disabled={false}
                      options={costCentersOptions}
                      fontSize={fontSize} 
                      label="Centro de custo*"
                      onChange={setCostCenter}
                  ></Autocomplete>
                  <Button
                    label="Adicionar" 
                    background="transparent" 
                    color="#256CE1" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={addApportionmentToList}
                  ></Button>
                </Row>
                <Table 
                    columns={columnsApportionment} 
                    rows={apportiomentList || []} 
                    hasEditing={false} 
                    hasRemoving={true}
                    setSelectedItem={removeApportionmentFromList}
                    height="auto" 
                  ></Table>
                  <br></br>
    
             <Row><ListTitle>Pagamento:</ListTitle></Row>
             <Divider></Divider>
              <Row>
                <Autocomplete
                  value={paymentType}
                  width={280}
                  disabled={false}
                  options={paymentTypesOptions}
                  fontSize={fontSize} 
                  label="Tipo de pagamento*"
                  onChange={setPaymentType}
                  ></Autocomplete>
                <Numberfield
                    label="Parcela"
                    width={280}
                    disabled={!parcelsInput} 
                    value={amount_parcels} 
                    fontSize={fontSize} 
                    onChange={setamount_parcels}
                  ></Numberfield>
                  <Numberfield
                    label="Desconto"
                    width={280}
                    disabled={false} 
                    value={discount} 
                    fontSize={fontSize} 
                    onChange={setDiscount}
                  ></Numberfield>
              
                </Row>
                {renderParcelArea()}
              <Row style={{marginTop: 0}}>
              <Switch 
                label="Custo Indireto" 
                disabled={false} 
                value={is_indirect_cost} 
                fontSize={fontSize} 
                onChange={setIsIndirectCost}
                multiline={false}
                color="black"
                ></Switch>
              </Row>
              <Row style={{ marginBottom: 16}}>
                <Textfield
                    label="Observações"
                    width={'100%'}
                    disabled={false} 
                    value={notes} 
                    fontSize={fontSize} 
                    onChange={setNotes}
                    multiline={true}
                    ></Textfield>
                </Row>
                <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleOpen(false)}
                    ></Button>
                    <Button
                    label="Adicionar pedido" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={disableAddButton} 
                    onClick={handleCreateOrder}
                    ></Button>
                </Row>
        </Content>
            )
            
        }}

    return (
        <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleOpen(false)} alt="Fechar"></img>
          </Header>
          {renderContent()}
        </Container>
      </Modal>
       )
  }
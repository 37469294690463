export const defaultFilters = {
    type: "''",
    costCenter: "''",
    costCategory: "''",
    subsidiary: "''",
    full_name: "''",
    trip: "''",
    paymentType: "''",
    boat: "''", 
    status: "''", 
    startDate:"''",
    endDate:"''",
    harvest: "''",
    employee: "''",
    pages: 1, // implement
    limit: 10, 
  };

export const columns = [
    {
      name: 'Status',
      key:'status_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Competência',
      key:'salary_month',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Colaborador',
      key:'full_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Data de Início',
      key:'begin_salary_date',
      type: 'date', 
      unit: '',
      show: true,
    },
    {
      name: 'Data de Fim',
      key:'end_salary_date',
      type: 'date', 
      unit: '',
      show: true,
    },
    {
      name: 'Tipo de pagamento',
      key:'paymenttype_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Qtd. Parcelas',
      key:'amount_parcels',
      type: 'number', 
      unit: '',
      show: true,
    },
    // {
    //   name: 'Qtd. Parcelas Pagas',
    //   key:'qty_parcels_exec',
    //   type: 'number', 
    //   unit: '',
    //   show: true,
    // },
    {
      name: 'Valor total original',
      key:'total_order_value',
      type: 'money', 
      unit: '',
      show: true,
    },
    {
      name: 'Valor total atualizado',
      key:'total_order_value_updated',
      type: 'money', 
      unit: '',
      show: true,
    },
    {
      name: 'Desconto',
      key:'discount',
      type: 'money', 
      unit: '',
      show: true,
    },
    {
      name: 'Valor total original com desconto',
      key:'total_order_value_with_discount',
      type: 'money', 
      unit: '',
      show: true,
    },
    {
      name: 'Valor total pago',
      key:'total_order_value_exec',
      type: 'money', 
      unit: '',
      show: true,
    },
    {
      name: 'É custo indireto?',
      key:'is_indirect_cost',
      type: 'boolean', 
      unit: '',
      show: true,
    },
    {
      name: 'Subsidiária',
      key:'subsidiary_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Centro de custo',
      key:'costs_center_name',
      type: 'string', 
      unit: '',
      show: true,
    },
    {
      name: 'Pagamento',
      key:'payment_date_name',
      type: 'date', 
      unit: '',
      show: true,
    },
    {
      name: 'Vencimento',
      key:'expiration_date_name',
      type: 'date', 
      unit: '',
      show: true,
    },
    
    {
      name: 'Criado em',
      key:'created_at',
      type: 'date', 
      unit: '',
      show: true,
    },
    {
      name: 'Criado por',
      key:'created_by',
      type: 'string', 
      unit: '',
      show: true,
    },
    // {
    //   name: 'Aprovado',
    //   key:'approved',
    //   type: 'boolean', 
    //   unit: '',
    //   show: true,
    // },
    // {
    //   name: 'Aprovado por',
    //   key:'approved_by',
    //   type: 'string', 
    //   unit: '',
    //   show: true,
    // },
  ]
  
import { useEffect, useState, useContext } from "react";
import Button from "../../../components/Button";
import Textfield from "../../../components/Textfield";
import Datepicker from "../../../components/Datepicker";
import Numberfield from "../../../components/Numberfield";
import Switch from "../../../components/Switch";
import { Container, Header, Row, Content, ListTitle, Divider, Explanation } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import { UseOrders } from "../../../hooks/useOrders";
import { UseCostCenters } from "../../../hooks/useCostCenters";
import { UseCostCategories } from "../../../hooks/useCostCategories";
import { UseSubsidiaries } from "../../../hooks/useSubsidiaries";
import { UseProviders } from "../../../hooks/useProviders";
import { UseProducts } from "../../../hooks/useProducts";
import { UseTrips } from "../../../hooks/useTrips";
import { UseBoats } from "../../../hooks/useBoats";
import { UsePaymentTypes } from "../../../hooks/usePaymentTypes";
import { UseFiles } from "../../../hooks/useFiles";
import moment from "moment";
import Autocomplete from "../../../components/Autocomplete";
import AppContext from "../../../state/App.context";
import Table from '../../../components/Table';
import LocalStorageService from '../../../services/storage';
import { UseTransactions } from "../../../hooks/useTransactions";
import { countExecutedParcels, formatMoney } from "../../../services/functions";
import ButtonUploadFile from "../../../components/ButtonUpload";
import MultipleSelect from "../../../components/MultiSelectInModal";

const columns = [
  {
    name: 'Produto/Insumo',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Categoria de Custo',
    key: 'cost_category_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'product_amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Preço',
    key: 'unity_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
]

const columnsFiles = [
  {
    name: 'Nome do arquivo',
    key: 'name',
    type: 'string',
    unit: '',
  },
]


const typeOptions = [
  {
    id: 0,
    label: 'Entrada'
  },
  {
    id: 1,
    label: 'Saída',
  }
];

const statusOptions = [
  {
    id: 0,
    label: 'Pendente'
  },
  {
    id: 1,
    label: 'Executado',
  }
]

export default function ModalEditOrder({ open, selectedItem, setSelectedItem, handleOpen, width, height }) {
  let order = selectedItem;
  const localStorageService = LocalStorageService();
  const { updateOrder, getOrderTransactions, getInputOrdersFromTrip, getInputOrdersFromOrder } = UseOrders();
  const { updateTransaction, createTransaction } = UseTransactions();
  const { costCenters } = UseCostCenters();
  const { costCategories } = UseCostCategories();
  const { providers } = UseProviders();
  const { products } = UseProducts();
  const { subsidiaries } = UseSubsidiaries();
  const { trips } = UseTrips();
  const { boats } = UseBoats();
  const { paymentTypes } = UsePaymentTypes();
  const { getOrderFiles, uploadFilesToOrder, downloadFile } = UseFiles();

  const [product_amount, setProduct_amount] = useState(0);
  const [unity_price, setUnity_price] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [type, setType] = useState(typeOptions[0]);
  const [costCenter, setCostCenter] = useState({ id: 0 });
  const [cost_category, setCost_category] = useState({ id: 0 });
  const [subsidiary, setSubsidiary] = useState({ id: 0 });
  const [provider, setProvider] = useState({ id: 0 });
  const [product, setProduct] = useState({ id: 0 });
  const [productsList, setProductsList] = useState([]);
  const [trip, setTrip] = useState({ id: 0 });
  const [paymentType, setPaymentType] = useState({ id: 0 });
  const [amount_parcels, setAmount_parcels] = useState(order?.amount_parcels);
  const [receipt, setReceipt] = useState(order?.receipt);
  const [expiration_date, setExpirationDate] = useState('');
  const [payment_date, setPaymentDate] = useState('');
  const [status, setStatus] = useState(statusOptions[0]);
  const fontSize = 14;
  const [loading, setLoading] = useState(true);
  const [, setSnack] = useContext(AppContext).snackState;
  const [parcels, setParcels] = useState([]);
  const [is_indirect_cost, setIsIndirectCost] = useState(false);
  const [notes, setNotes] = useState('');
  const [oldFiles, setOldFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [associatedSalesOrder, setAssociatedSalesOrder] = useState([]);
  const [associatedSalesOrdersOptions, setAssociatedSalesOrdersOptions] = useState([]);

  const title = 'Editar Pedido';

  let parcelsInput = paymentType?.label && (paymentType.label.includes("Parcelado") || paymentType.label.includes("Cartão"));

  function calculateTotalValue() {
    let copyProductList = [...productsList].filter((element) => element.isactive);
    return copyProductList.reduce((total, product) => total + (product.unity_price * product.product_amount), 0);
  }

  const total_order_value = productsList.length > 0 ? calculateTotalValue() : 0;

  let parcels_sum = 0;

  for (let i = 0; i < parcels.length; i++) {
    if (parcels[i]?.values_parcels !== undefined && !isNaN(parcels[i]?.values_parcels)) {
      parcels_sum += parcels[i]?.values_parcels;
    }
  }

  function settingDefaultStates(transactionsArray) {
    try {
      const typeOptionIndex = typeOptions.findIndex((type) => type.label === order?.type);
      const costCenterIndex = costCentersOptions.findIndex((costCenter) => costCenter.id === order?.fk_costs_center_id_cost);
      const tripIndex = tripsOptions.findIndex((trip) => trip.id === order?.fk_trips_id_trip);
      const subsidiaryIndex = subsidiariesOptions.findIndex((sub) => sub.id === order?.fk_subsidiary_id_subsidiary);
      let providersOptions2 = providers && !providers.error && providers.map((element) => {
        return {
          id: element?.id_providers,
          label: element?.providers_name,
        }
      })
      const providerIndex = providersOptions2.findIndex((provider) => provider.id === order?.fk_providers_id_provider);
      const paymentTypeIndex = paymentTypesOptions.findIndex((paymentType) => paymentType.id === order?.fk_paymenttype_id_paymenttype);
      setType(typeOptionIndex !== -1 ? typeOptions[typeOptionIndex] : '');
      setCostCenter(costCenterIndex !== -1 ? costCentersOptions[costCenterIndex] : '');
      setTrip(tripIndex !== -1 ? tripsOptions[tripIndex] : '');
      setSubsidiary(subsidiaryIndex !== -1 ? subsidiariesOptions[subsidiaryIndex] : '');
      setProvider(providerIndex !== -1 ? providersOptions2[providerIndex] : '');
      setPaymentType(paymentTypeIndex !== -1 ? paymentTypesOptions[paymentTypeIndex] : '');
      setAmount_parcels(order?.amount_parcels);
      setReceipt(order?.receipt);
      setDiscount(order?.discount);
      setIsIndirectCost(order?.is_indirect_cost || false);
      setNotes(order?.notes || '');
      if (order?.amount_parcels === 0 && order?.status[0]) {
        let statusOrder = order?.status_name === 'Pendente' ? statusOptions[0] : statusOptions[1];
        setStatus(statusOrder);
      }
      if (order?.amount_parcels === 0 && order?.expiration_date[0]) {
        setExpirationDate(moment(order?.expiration_date[0]).add(3, 'hours'));
      }
      if (order?.amount_parcels === 0 && order?.payment_date[0] && order?.status[0] === 'Executado') {
        setPaymentDate(moment(order?.payment_date[0]).add(3, 'hours'));
      }
      let defaultProductInfo = [];
      transactionsArray.map((transaction) => {
        let productIndex = productsOptions.findIndex((prod) => prod.id === transaction?.fk_products_id_product);
        const transactionProduct = productIndex !== -1 ? productsOptions[productIndex] : '';
        let categoryIndex = costCategoriesOptions.findIndex((cat) => cat.id === transaction?.fk_costs_categories_id_category);
        const transactionCategory = categoryIndex !== -1 ? costCategoriesOptions[categoryIndex] : '';
        let productInfo = {
          id_transaction: transaction?.id_transaction,
          isactive: transaction?.isactive,
          id_order: order?.id_order,
          product: transactionProduct,
          product_name: transaction?.product_name,
          product_amount: transaction?.product_amount,
          unity_price: transaction?.unity_price,
          total_value: transaction?.total_value,
          cost_category: transactionCategory,
          cost_category_name: transaction?.costs_categories_name,
          fk_products_id_product: transaction?.fk_products_id_product,
        };
        defaultProductInfo.push(productInfo);
        return null;
      })
      setProductsList(defaultProductInfo);
      let defaultParcels = [];
      if (order.amount_parcels) {
        for (let i = 0; i < order.amount_parcels; i++) {
          const parcel = {
            name: i + 1,
            expiration_date: order?.expiration_date[i],
            payment_date: order?.status[i] === 'Pendente' ? null : order?.payment_date[i],
            values_parcels: order?.values_parcels[i],
            status: order?.status[i] === 'Pendente' ? statusOptions[0] : statusOptions[1],
            status_label: order?.status[i],
          }
          defaultParcels.push(parcel);
        }
      } else {
        defaultParcels = [
          {
            name: 1,
            expiration_date: order?.expiration_date[0],
            payment_date: order?.status[0] === 'Pendente' ? null : order?.payment_date[0],
            values_parcels: order?.values_parcels[0],
            status: order?.status[0] === 'Pendente' ? statusOptions[0] : statusOptions[1],
            status_label: order?.status[0],
          }
        ]
      }
      setParcels(defaultParcels);

    } catch (err) {
      console.log(err);
    }
  }

  async function getInfo() {
    try {
      setLoading(true);
      let responseTransactions = await getOrderTransactions(order?.id_order);
      if (responseTransactions.success) {
        settingDefaultStates(responseTransactions?.data)
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: responseTransactions?.message,
        })
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function extractFileInfo(filename) {
    let firstUnderscoreIndex = filename.indexOf('_');
    let id = filename.slice(0, firstUnderscoreIndex);
    let name = filename.slice(firstUnderscoreIndex + 1);
    return {
      id: id,
      name: name,
    };
  }

  async function getFilesInfo() {
    try {
      let responseFiles = await getOrderFiles(order?.id_order);
      if (responseFiles.success) {
        let oldFilesFormatted = [];
        responseFiles?.data.map((oldFile) => {
          const { name, id } = extractFileInfo(oldFile);
          oldFilesFormatted.push({
            name,
            id,
            filename: oldFile,
          });

          return null;
        })
        setOldFiles(oldFilesFormatted);
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: responseFiles?.message,
        })
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (selectedItem.open && selectedItem.mode === 'edit') getInfo();
    if (selectedItem.open && selectedItem.mode === 'edit') getFilesInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem])

  function cleanAllInputs() {
    setProduct_amount(0);
    setUnity_price(0);
    setType(typeOptions[0]);
    setCostCenter({ id: 0 });
    setCost_category({ id: 0 });
    setSubsidiary({ id: 0 });
    setProvider({ id: 0 });
    setProduct({ id: 0 });
    setTrip({ id: 0 });
    setPaymentType({ id: 0 });
    setReceipt('');
    setExpirationDate('');
    setPaymentDate('');
    setStatus(statusOptions[0]);
    setParcels([]);
    setProductsList([]);
    setDiscount(0);
    setIsIndirectCost(false);
    setNotes('');
    setFiles([]);
    setOldFiles([]);
    setAssociatedSalesOrder([]);
    setAssociatedSalesOrdersOptions([]);
  }

  function handleClose() {
    setSelectedItem({ open: false, mode: '', name: '', id_order: null });
    cleanAllInputs();
  }

  let costCentersOptions = costCenters && !costCenters.error && costCenters.filter((element) => element.isactive).map((element) => {
    return {
      id: element?.id_cost,
      label: element?.costs_center_name,
    }
  })

  let costCategoriesOptions = costCategories && !costCategories.error && costCategories.map((element) => {
    return {
      id: element?.id_category,
      label: element?.costs_categories_name,
    }
  })

  let subsidiariesOptions = subsidiaries && !subsidiaries.error && subsidiaries.map((element) => {
    return {
      id: element?.id_subsidiary,
      label: element?.subsidiary_name,
    }
  })
  let providerType = type.id === 0 ? 'Comprador' : 'Fornecedor';

  let providersToShow = providers && !providers.error && providers.filter(a => a.type === providerType);
  let providersOptions = providers && !providers.error && providersToShow.map((element) => {
    return {
      id: element?.id_providers,
      label: element?.providers_name,
    }
  })

  let productType = type.id === 0 ? 'Produto' : 'Insumo';
  let productsToShow = (products && !products.error && products.filter(a => a.type === productType)) || products;
  let productsOptions = [];
  
  productsToShow && productsToShow.length > 0 && productsToShow.map((element) => {
    if (element.isactive)
      productsOptions.push({
        id: element?.id_product,
        label: element?.product_name,
      });

    return null;
  })
  
  productsOptions.sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });

  function getBoatName(boatId) {
    try {
      let index = boats.findIndex((boat) => boat.id_boat === boatId);
      if (index !== -1) {
        return boats[index].boats_name;

      } else return '';

    } catch (err) {
      console.log(err)
    }

  }

  let tripsOptions = trips && !trips?.error && trips.map((element) => {
    return {
      id: element?.id_trip,
      label: element?.trips_name + ' - ' + getBoatName(element?.fk_boats_id_boat) + ' (' + element?.harvest + ')',
    }
  })

  let paymentTypesOptions = paymentTypes && !paymentTypes.error && paymentTypes.map((element) => {
    return {
      id: element?.id_paymenttype,
      label: element?.paymenttype_name,
    }
  })

  function addTimestampToArray(newElement, index, array) {
    newElement = newElement || new Date();
    if (index === 0 && amount_parcels !== 0) {
      return "timestamp '" + moment(newElement).add(3, 'hours').startOf('day').format('YYYY-MM-DD HH:mm:ss') + "',";
    }
    if (index === 0 && amount_parcels === 0) {
      return "timestamp '" + moment(newElement).add(3, 'hours').startOf('day').format('YYYY-MM-DD HH:mm:ss') + "'";
    }
    if (index === (amount_parcels - 1)) {
      return array + "timestamp '" + moment(newElement).add(3, 'hours').startOf('day').format('YYYY-MM-DD HH:mm:ss') + "'";
    }
    return array + "timestamp '" + moment(newElement).add(3, 'hours').startOf('day').format('YYYY-MM-DD HH:mm:ss') + "',";
  }

  function addElementToArray(newElement, index, array) {
    if (index === 0 && amount_parcels !== 0) {
      return newElement + ",";
    }
    if (index === 0 && amount_parcels === 0) {
      return newElement;
    }
    if (index === (amount_parcels - 1)) {
      return array + newElement;
    }
    return array + newElement + ",";
  }

  function addStringToArray(newElement, index, array) {
    if (index === 0 && amount_parcels !== 0) {
      return "'" + newElement + "',";
    }
    if (index === 0 && amount_parcels === 0) {
      return "'" + newElement + "'";
    }
    if (index === (amount_parcels - 1)) {
      return array + "'" + newElement + "'";
    }
    return array + "'" + newElement + "',";
  }

  function checkingRequiredFields() {
    if (!subsidiary.id || !paymentType.id || !provider.id || !costCenter.id || !trip.id) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    async function getData() {
      if ((type.id === typeOptions[1].id) && (trip.id !== 0)) {
        setAssociatedSalesOrder([]);
        setAssociatedSalesOrdersOptions([]);
        const inputOrdersFromTripList = await getInputOrdersFromTrip(trip.id);
        const associatedOrdersList = await getInputOrdersFromOrder(selectedItem.id_order);
        let inputOrdersOptions = [];
        let associatedInputOrdersOptions = [];

        if (inputOrdersFromTripList && inputOrdersFromTripList.success &&
          inputOrdersFromTripList.data && (inputOrdersFromTripList.data.length > 0)) {
          inputOrdersFromTripList.data.forEach((element) => {
            const orderOption = {
              value: element?.id_order,
              label: element?.providers_name + ' - ' + formatMoney(element?.total_order_value),
            }

            inputOrdersOptions.push(orderOption);

            if (associatedOrdersList?.data?.findIndex((item) => element?.id_order === item.input_order_id) > -1) {
              associatedInputOrdersOptions.push(orderOption);
            }
          })
          
          setAssociatedSalesOrder(associatedInputOrdersOptions);
          setAssociatedSalesOrdersOptions(inputOrdersOptions);
        } else {  
          setAssociatedSalesOrder([]);
          setAssociatedSalesOrdersOptions([]);
        }
      } else {
        setAssociatedSalesOrder([]);
        setAssociatedSalesOrdersOptions([]);
      }
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip, type.id])

  async function handleEditOrder(event) {
    try {
      setLoading(true);
      event.preventDefault();
      if (checkingRequiredFields()) {
        if (productsList.length) {
          let error = false;
          let status_array = '';
          let expiration_date_array = '';
          let payment_date_array = '';
          let values_parcels_array = '';
          let total_order_value_with_discount = total_order_value - discount;
          let total_order_value_exec = 0;
          if (amount_parcels === 0) {
            if (status?.label === 'Executado') {
              total_order_value_exec = total_order_value_with_discount;
            }
            status_array = addStringToArray(status.label, 0, status_array);
            values_parcels_array = addElementToArray(total_order_value_with_discount, 0, values_parcels_array);
            expiration_date_array = addTimestampToArray(expiration_date, 0, expiration_date_array);
            payment_date_array = addTimestampToArray(payment_date, 0, payment_date_array);
          } else {
            parcels.map((parcel, index) => {
              if (parcel.status.label === 'Executado') {
                total_order_value_exec = total_order_value_exec + parcel?.values_parcels;
              }
              status_array = addStringToArray(parcel.status.label, index, status_array);
              values_parcels_array = addElementToArray(parcel.values_parcels, index, values_parcels_array);
              expiration_date_array = addTimestampToArray(parcel.expiration_date, index, expiration_date_array);
              payment_date_array = addTimestampToArray(parcel.payment_date, index, payment_date_array);
              return null;
            });
          };
          let transactions = [];
          productsList.map((product) => (
            transactions.push({
              fk_orders_id_order: product.id_order,
              id_transaction: product.id_transaction,
              isactive: product.isactive,
              product_amount: product?.product_amount,
              unity_price: product?.unity_price,
              total_value: product?.total_value,
              fk_costs_categories_id_category: product?.cost_category?.id,
              fk_products_id_product: product?.fk_products_id_product,
            })
          ));
          const userInfo = localStorageService.getUserInfo();
          const qty_parcels_exec = countExecutedParcels(parcels);
          const sellingOrdersIds = [];
          associatedSalesOrder.forEach((selectedSalesOrder) => sellingOrdersIds.push(selectedSalesOrder.value));

          const orderToEdit = {
            ...order,
            created_at: moment().format('YYYY-MM-DD'),
            expiration_date: expiration_date_array,
            payment_date: payment_date_array,
            amount_parcels,
            type: type?.label,
            status: status_array,
            values_parcels: values_parcels_array,
            total_order_value,
            approved: false,
            approved_by: '',
            created_by: userInfo?.email,
            receipt,
            discount: discount,
            fk_costs_center_id_cost: costCenter?.id,
            fk_providers_id_provider: provider?.id,
            fk_trips_id_trip: trip?.id,
            fk_subsidiary_id_subsidiary: subsidiary?.id,
            fk_paymenttype_id_paymenttype: paymentType?.id,
            transactions,
            qty_parcels_exec,
            total_order_value_exec,
            is_indirect_cost: is_indirect_cost,
            notes,
            selling_orders: sellingOrdersIds,
          };
          const response = await updateOrder(orderToEdit);
          if (!response.success) {
            error = true;
          } else {
            Promise.all(
              transactions.map(async (transaction) => {
                let response = '';
                if (transaction.id_transaction) {
                  response = await updateTransaction(transaction);
                } else {
                  response = await createTransaction(transaction);
                }
                return response;
              })).then(
                (results) => {
                  if (results.includes((element) => element.success === false)) {
                    error = true;
                  }
                }
              ).catch(err => console.log(err));
            if (files.length > 0) {
              let onlyFiles = [];
              files.map((file) => (
                onlyFiles.push(file?.file)
              ))
              const responseUploadFiles = await uploadFilesToOrder(order?.id_order, onlyFiles);
              if (!responseUploadFiles.success) {
                error = true;
              }
            }

          }
          if (!error) {
            handleClose();
            cleanAllInputs()
            setSnack({
              open: true,
              severity: 'success',
              message: "Operação realizada com sucesso!",
            })
          } else {
            setSnack({
              open: true,
              severity: 'error',
              message: 'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
            })
          }

        } else {
          setSnack({
            open: true,
            severity: 'error',
            message: 'É necessário adicionar ao menos um produto/insumo na lista de produtos/insumos',
          })
        }
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Preencha todos os campos necessários!',
        });
      }


    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

  }

  function addProductToList() {
    try {
      if (product?.id && product_amount && unity_price && cost_category.id) {
        let productsListCopy = [...productsList];
        let productInfo = {
          product: product,
          product_name: product?.label,
          product_amount: product_amount,
          unity_price: unity_price,
          total_value: product_amount * unity_price,
          cost_category: cost_category,
          cost_category_name: cost_category?.label,
          isactive: true,
          id_order: order?.id_order,
          fk_products_id_product: product?.id,
        }
        productsListCopy.push(productInfo);
        setProductsList(productsListCopy);
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Preencha todos os campos necessários no produto!',
        });
      }

    } catch (err) {
      console.log(err);
    }
  }


  function removeProductFromList(selectedItem) {
    try {
      let productsListCopy = [...productsList];
      let index = productsListCopy.findIndex((element) => element.product.id === selectedItem.product.id);
      if (index > -1) {
        productsListCopy.splice(index, 1);
      }
      setProductsList(productsListCopy);
    } catch (err) {
      console.log(err);
    }
  }

  function addFileToList(event) {
    try {
      let filesCopy = [...files];
      let selectedFiles = [...event.target.files];
      selectedFiles && selectedFiles.map((file) => (
        filesCopy.push({
          name: file.name,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          size: file.size,
          type: file.type,
          webkitRelativePath: file.webkitRelativePath,
          file: file,
        })
      ))
      setFiles(filesCopy);
    } catch (err) {
      console.log(err);
    }
  }

  function removeFileFromList(selectedItem) {
    try {
      let filesCopy = [...files];
      let index = filesCopy.findIndex((element) => element.name === selectedItem.name);
      if (index > -1) {
        filesCopy.splice(index, 1);
      }
      setFiles(filesCopy);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDownloadFile(selectedItem) {
    try {
      const filename = selectedItem.item.filename;
      const name = selectedItem.item.name;
      const response = await downloadFile(filename, name);
      if (response.success) {
        setSnack({
          open: true,
          severity: 'success',
          message: 'Baixando arquivo ' + name + '...',
        });
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Erro ao baixar o arquivo ' + name + '!',
        });
      }

    } catch (err) {
      console.log(err);
    }
  }

  function addParcelToList() {
    try {
      let parcelsCopy = [...parcels];
      let parcelInfo = {
        name: parcelsCopy.length + 1,
        expiration_date: expiration_date,
        payment_date: payment_date,
        status: status,
        status_label: status.label,
      }
      parcelsCopy.push(parcelInfo);
      setParcels(parcelsCopy);
    } catch (err) {
      console.log(err);
    }
  }

  function setPropParcel(item, index, prop) {
    try {
      let parcelsCopy = [...parcels];
      parcelsCopy[index][prop] = item;
      setParcels(parcelsCopy)
    } catch (err) {
      console.log(err);
    }
  }

  function renderParcelArea() {
    if (amount_parcels > 1) {
      return (
        <>
          <Row>
            <ListTitle>Adicionar parcela:</ListTitle>
          </Row>
          <Row style={{ marginBottom: 16 }}>
            <Datepicker
              label="Data de Vencimento"
              width={240}
              disabled={false}
              value={expiration_date}
              fontSize={fontSize}
              onChange={setExpirationDate} />
            <Datepicker
              label="Data de Pagamento"
              width={240}
              disabled={false}
              value={payment_date}
              fontSize={fontSize}
              onChange={setPaymentDate} />
            <Autocomplete
              value={status}
              width={240}
              disabled={false}
              options={statusOptions}
              fontSize={fontSize}
              label="Status"
              onChange={setStatus}
            ></Autocomplete>
            <Button
              label="Adicionar"
              background="transparent"
              color="#256CE1"
              borderColor="#256CE1"
              disabled={parcels.length >= amount_parcels}
              onClick={addParcelToList}
            ></Button>
          </Row>
          <Row>
            <ListTitle>Parcelas</ListTitle>
          </Row>
          <Divider></Divider>
          {
            parcels.map((parcel, index) => {
              return (
                <Row>
                  <Numberfield
                    label="Valor"
                    disabled={false}
                    width={220}
                    value={parcel.values_parcels}
                    fontSize={fontSize}
                    onChange={(item) => setPropParcel(item, index, 'values_parcels')}
                  ></Numberfield>
                  <Datepicker
                    label={"Data de Vencimento " + parcel?.name}
                    width={220}
                    disabled={false}
                    value={parcel.expiration_date}
                    fontSize={fontSize - 2}
                    onChange={(item) => setPropParcel(item, index, 'expiration_date')} />
                  <Datepicker
                    label={"Data de Pagamento " + parcel?.name}
                    width={220}
                    disabled={false}
                    value={parcel.payment_date}
                    fontSize={fontSize - 2}
                    onChange={(item) => setPropParcel(item, index, 'payment_date')} />
                  <Autocomplete
                    value={parcel.status}
                    width={220}
                    disabled={false}
                    options={statusOptions}
                    fontSize={fontSize - 2}
                    label={"Status " + parcel?.name}
                    onChange={(item) => setPropParcel(item, index, 'status')}
                  ></Autocomplete>
                </Row>
              )
            })
          }
          <Row>
            <Explanation>
              Valor total do pedido: {formatMoney(total_order_value - discount)}
              <br></br>
              Somatório das parcelas: <b>{formatMoney(parcels_sum)}</b>
            </Explanation>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Explanation style={{ color: 'var(--red)' }}>
              {total_order_value - discount !== parcels_sum ? 'O somátório das parcelas deve ser igual ao valor total do pedido!' : ''}
            </Explanation>
          </Row>
        </>
      )
    } else {
      return (
        <>
          <Row style={{ marginBottom: 16 }}>
            <Datepicker
              label="Data de Vencimento"
              width={280}
              disabled={false}
              value={expiration_date}
              fontSize={fontSize}
              onChange={setExpirationDate} />
            <Datepicker
              label="Data de Pagamento"
              width={280}
              disabled={false}
              value={payment_date}
              fontSize={fontSize}
              onChange={setPaymentDate} />
            <Autocomplete
              value={status}
              width={280}
              disabled={false}
              options={statusOptions}
              fontSize={fontSize}
              label="Status"
              onChange={setStatus}
            ></Autocomplete>
          </Row>
        </>
      )
    }
  }
  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      let productsToShow = [...productsList].filter((item) => item.isactive);
      return (
        <Content>
          <Row>
            <ListTitle>Informações do Pedido</ListTitle>
          </Row>
          <Divider></Divider>
          <Row>
            <Autocomplete
              value={type}
              width={280}
              disabled={true}
              options={typeOptions}
              fontSize={fontSize}
              label="Tipo"
              onChange={setType}
            ></Autocomplete>
            <Autocomplete
              value={subsidiary}
              width={280}
              disabled={false}
              options={subsidiariesOptions}
              fontSize={fontSize}
              label="Subsidiária"
              onChange={setSubsidiary}
            ></Autocomplete>
            <Autocomplete
              value={trip}
              width={280}
              disabled={false}
              options={tripsOptions}
              fontSize={fontSize}
              label="Viagem"
              onChange={setTrip}
            ></Autocomplete>
          </Row>
          <Row>
            <Autocomplete
              value={provider}
              width={280}
              disabled={false}
              options={providersOptions}
              fontSize={fontSize}
              label={providerType}
              onChange={setProvider}
            ></Autocomplete>
            <Autocomplete
              value={costCenter}
              width={280}
              disabled={false}
              options={costCentersOptions}
              fontSize={fontSize}
              label="Centro de Custo"
              onChange={setCostCenter}
            ></Autocomplete>

            <Textfield
              label="Link do Recibo"
              width={280}
              disabled={false}
              value={receipt}
              fontSize={fontSize}
              onChange={setReceipt}
              multiline={false}
            ></Textfield>
          </Row>
          <Row>
            <ListTitle>Lista de produtos</ListTitle>
          </Row>
          <Divider></Divider>
          <Row style={{ marginBottom: 16 }}>
            <Autocomplete
              value={product}
              width={230}
              disabled={false}
              options={productsOptions}
              fontSize={fontSize}
              label={productType}
              onChange={setProduct}
            ></Autocomplete>
            <Autocomplete
              value={cost_category}
              width={230}
              disabled={false}
              options={costCategoriesOptions}
              fontSize={fontSize}
              label="Categoria"
              onChange={setCost_category}
            ></Autocomplete>
            <div style={{ display: 'flex', flexDirection: 'row', minWidth: '330px', justifyContent: 'space-between' }}>
              <Numberfield
                label="Quantidade"
                width={145}
                disabled={false}
                value={product_amount}
                fontSize={fontSize}
                onChange={setProduct_amount}
              ></Numberfield>
              <Numberfield
                label="Preço"
                width={145}
                disabled={false}
                value={unity_price}
                fontSize={fontSize}
                onChange={setUnity_price}
              ></Numberfield>
            </div>
            <Button
              label="Adicionar"
              background="transparent"
              color="#256CE1"
              borderColor="#256CE1"
              disabled={false}
              onClick={addProductToList}
            ></Button>
          </Row>
          <Table
            columns={columns}
            rows={productsToShow || []}
            hasEditing={false}
            hasRemoving={true}
            setSelectedItem={removeProductFromList}
            height="auto"
          ></Table>
          <Row style={{ marginBottom: 16 }}></Row>
          <Row><ListTitle>Pagamento</ListTitle></Row>
          <Divider></Divider>
          <Row>
            <Autocomplete
              value={paymentType}
              width={280}
              disabled={false}
              options={paymentTypesOptions}
              fontSize={fontSize}
              label="Tipo de Pagamento"
              onChange={setPaymentType}
            ></Autocomplete>
            <Numberfield
              label="Parcela"
              width={280}
              disabled={!parcelsInput}
              value={amount_parcels}
              fontSize={fontSize}
              onChange={setAmount_parcels}
            ></Numberfield>
            <Numberfield
              label="Desconto"
              width={280}
              disabled={false}
              value={discount}
              fontSize={fontSize}
              onChange={setDiscount}
            ></Numberfield>

          </Row>
          {renderParcelArea()}
          <Row style={{ marginTop: 8, marginBottom: 8 }}>
            <h1><b>ATENÇÃO</b>: tenha cautela ao editar parcelas já executadas, pois alterações podem resultar em mudanças nos valores e causar possíveis erros caso não seja essa a sua intenção.</h1>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Switch
              label="Custo Indireto"
              disabled={false}
              value={is_indirect_cost}
              width={430}
              fontSize={fontSize}
              onChange={setIsIndirectCost}
              multiline={false}
              color="black"
            ></Switch>
          </Row>
          {
            ((type.id === typeOptions[1].id) && trip && (trip.id !== 0) && (associatedSalesOrdersOptions.length > 0)) ? (
              <Row>
                <MultipleSelect
                  selectOptions={associatedSalesOrdersOptions}
                  width={"280px"}
                  defaultValue={associatedSalesOrder}
                  fontSize={"0.9rem"}
                  label="Pedidos de Venda Associados"
                  handleChange={(selectedOptions) => {
                    setAssociatedSalesOrder(selectedOptions);
                  }}
                ></MultipleSelect>
              </Row>
            ) : ('')
          }
          <Row></Row>
          <Row>
            <ListTitle>Anexos Salvos</ListTitle>
          </Row>
          <Divider></Divider>
          <Row style={{ marginTop: 8, marginBottom: 8 }}>
            <h1> Clique no arquivo para realizar o download:</h1>
          </Row>
          <Table
            columns={columnsFiles}
            rows={oldFiles || []}
            hasEditing={false}
            hasRemoving={false}
            setSelectedItem={handleDownloadFile}
            height="auto"
          ></Table>
          <br></br>
          <Row>
            <ListTitle>Anexar Novos Arquivos</ListTitle>
          </Row>
          <Divider></Divider>
          <Row style={{ marginBottom: '2rem' }}>
            <ButtonUploadFile
              disabled={false}
              onChange={addFileToList}
              multiple
              accept="*/*"
            ></ButtonUploadFile>
          </Row>
          <Table
            columns={columnsFiles}
            rows={files || []}
            hasEditing={false}
            hasRemoving={true}
            setSelectedItem={removeFileFromList}
            height="auto"
          ></Table>
          <br></br>
          <Row style={{ marginBottom: 16 }}>
            <Textfield
              label="Observações"
              width={'100%'}
              disabled={false}
              value={notes}
              fontSize={fontSize}
              onChange={setNotes}
              multiline={true}
            ></Textfield>
          </Row>
          <Row>
            <Button
              label="Cancelar"
              background="#8E92BC"
              color="white"
              borderColor="#8E92BC"
              disabled={false}
              onClick={() => handleClose()}
            ></Button>
            <Button
              label="Salvar alterações"
              background="#256CE1"
              color="white"
              borderColor="#256CE1"
              disabled={false}
              onClick={handleEditOrder}
            ></Button>
          </Row>
        </Content>
      )

    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={() => handleClose()} alt="Fechar"></img>
        </Header>
        {renderContent()}

      </Container>
    </Modal>
  )
}
import {useState, useEffect, useContext} from 'react';
import {Container, Header, Row, Content} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import ReportToPrint from "./reportToPrint";
import ReportToPrintAddPage from './reportToPrintAddPage';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import AppContext from "../../../state/App.context"
import { sortByProperty } from '../../../services/functions';
import Button from "../../../components/Button";


export default function ModalReport({open, handleOpen, subsidiaries, startDate, endDate, sumsInfo}){
    const title = 'Relatório de Programação Financeira';
    const [, setSnack] = useContext(AppContext).snackState;

    let allOrders = [];
    subsidiaries.map((subsidiary)=>{
        let pending_outputs = [];
        let pending_inputs = [];
        let resolved_inputs = [];
        let resolved_outputs = [];

subsidiary.pending_outputs && subsidiary.pending_outputs.map((output, index)=>{
    let current_parcel_name = '1/1';
    let current_parcel_value = parseFloat(output?.total_order_value) - parseFloat(output?.discount);
    let current_parcel_expiration_date = output?.expiration_date ? output?.expiration_date[0] : new Date();
    let providers_name = output.issalary ? output?.full_name : output?.providers_name;
    delete output?.current_parcel_payment_date;
    if(output?.current_parcel_name){
        pending_outputs.push({
            ...output,
            providers_name,
        })
    } else {
        pending_outputs.push({
            ...output,
            current_parcel_name,
            current_parcel_value,
            current_parcel_expiration_date,
            providers_name,
        })
    }
});


subsidiary.resolved_outputs && subsidiary.resolved_outputs.map((output, index)=>{
    let current_parcel_name = '1/1';
    let current_parcel_value = parseFloat(output?.total_order_value) - parseFloat(output?.discount);
    let current_parcel_expiration_date = output?.expiration_date ? output?.expiration_date[0] : new Date();
    let current_parcel_payment_date = output?.payment_date[0];
    let providers_name = output.issalary ? output?.full_name : output?.providers_name;
    if(output?.current_parcel_name){
        resolved_outputs.push({
            ...output,
            providers_name,
        })
    } else {
        resolved_outputs.push({
            ...output,
            current_parcel_name,
            current_parcel_value,
            current_parcel_expiration_date,
            providers_name,
            current_parcel_payment_date,
        })
    }
});


subsidiary.pending_inputs && subsidiary.pending_inputs.map((input, index)=>{
    let current_parcel_name = '1/1';
    let current_parcel_value = parseFloat(input?.total_order_value) - parseFloat(input?.discount);
    let current_parcel_expiration_date = input?.expiration_date ? input?.expiration_date[0] : new Date();
    delete input?.current_parcel_payment_date;
    if(input?.current_parcel_name){
        pending_inputs.push({
            ...input,
        })
    }else {
        pending_inputs.push({
            ...input,
            current_parcel_name,
            current_parcel_value,
            current_parcel_expiration_date,
        })
    }

});
;

subsidiary.resolved_inputs && subsidiary.resolved_inputs.map((input, index)=>{
    let current_parcel_name = '1/1';
    let current_parcel_value = parseFloat(input?.total_order_value) - parseFloat(input?.discount);
    let current_parcel_expiration_date = input?.expiration_date ? input?.expiration_date[0] : new Date();
    let current_parcel_payment_date = input?.payment_date[0];
    if(input?.current_parcel_name){
        resolved_inputs.push({
            ...input,
        })
    }else {
        resolved_inputs.push({
            ...input,
            current_parcel_name,
            current_parcel_value,
            current_parcel_expiration_date,
            current_parcel_payment_date,
        })
    }

});

pending_inputs = sortByProperty(pending_inputs, 'providers_name');
pending_outputs = sortByProperty(pending_outputs, 'providers_name');
resolved_inputs = sortByProperty(resolved_inputs, 'providers_name');
resolved_outputs = sortByProperty(resolved_outputs, 'providers_name');

        pending_outputs.map((element)=>{
            allOrders.push({
                ...element,
                subsidiary_name: subsidiary?.subsidiary_name,
                type: 'Pagamento Pendente',
            });
        });
        // allOrders.push({
        //     subsidiary_name: subsidiary?.subsidiary_name,
        //     type: 'Total Pagamentos',
        //     status: 'Total Pagamentos Pendentes',
        //     total_order_value: subsidiary.pending_outputs_total,
        // });

        resolved_outputs.map((element)=>{
            allOrders.push({
                ...element,
                subsidiary_name: subsidiary?.subsidiary_name,
                type: 'Pagamento Executado',
            });
            });

        // allOrders.push({
        //     subsidiary_name: subsidiary?.subsidiary_name,
        //     type: 'Total Pagamentos Executados',
        //     total_order_value: subsidiary?.resolved_outputs_total,
        // });

        pending_inputs.map((element)=>{
            allOrders.push({
                ...element,
                subsidiary_name: subsidiary?.subsidiary_name,
                type: 'Recebimento Pendente',
            });
        });

        // allOrders.push({
        //     subsidiary_name: subsidiary?.subsidiary_name,
        //     type: 'Total Recebimentos Pendentes',
        //     total_order_value: subsidiary.pending_inputs_total,
        // });

        resolved_inputs.map((element)=>{
            allOrders.push({
                ...element,
                subsidiary_name: subsidiary?.subsidiary_name,
                type: 'Recebimento Executado',
            });
        });

        // allOrders.push({
        //     subsidiary_name: subsidiary?.subsidiary_name,
        //     type: 'Total Recebimentos',
        //     status: 'Executado',
        //     total_order_value: subsidiary.resolved_inputs_total,
        // });
    });




    function print() {
      setSnack({
        open: true,
        severity: 'success',
        message: 'Gerando PDF ...',
      });
      window.scroll(0, 0);
      const input = document.getElementById('report_1');
      html2canvas(input, { scale: 5 }).then((canvas) => {
        const image = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'px', 'a4');
        const { width } = pdf.internal.pageSize;
        const { height } = pdf.internal.pageSize;
        pdf.addImage(image, 'JPEG', -5, 0, width + 5, height);
        pdf.save('Programação_Financeira' + '.pdf');
      });
    }

    function multiplePrint() {
      setSnack({
        open: true,
        severity: 'success',
        message: 'Gerando PDF ...',
      });
      window.scroll(0, 0);
    
      const pdf = new jsPDF('p', 'px', 'a4');
    
      // Iterar sobre os elementos com IDs diferentes
      let pageNumber = 1;
      let element = document.getElementById(`report_${pageNumber}`);
      const scale = 5;
    
      // Função recursiva para adicionar páginas ao PDF
      function addPageToPDF() {
        html2canvas(element, { scale }).then((canvas) => {
          const image = canvas.toDataURL('image/jpeg', 1.0);
          const { width } = pdf.internal.pageSize;
          const { height } = pdf.internal.pageSize;
          pdf.addImage(image, 'JPEG', -5, 0, width + 5, height);
    
          pageNumber++;
          element = document.getElementById(`report_${pageNumber}`);
    
          if (element) {
            pdf.addPage();
            addPageToPDF();
          } else {
            pdf.save('Programação financeira' + '.pdf');
          }
        });
      }
    
      addPageToPDF();
    }

    function renderPrint(){
      return (
        <Content>
        <ReportToPrint allOrders={allOrders} startDate={startDate} endDate={endDate} sumsInfo={sumsInfo}></ReportToPrint>
        </Content>
      )
    }

    function splitOrders(originalArray, amountFirstPage) {
      const maxSize = 10;
      const splittedArray = [];
      
      let remaining = originalArray.length;
      let index = 0;
    
      // Adicionar elementos à primeira página
      const firstPage = originalArray.slice(index, amountFirstPage);
      splittedArray.push(firstPage);
      remaining -= amountFirstPage;
      index += amountFirstPage;
    
      // Dividir o restante dos elementos em páginas adicionais
      while (remaining > 0) {
        const pieceArray = originalArray.slice(index, index + maxSize);
        splittedArray.push(pieceArray);
        remaining -= maxSize;
        index += maxSize;
      }
    
      return splittedArray;
    }


    function renderPrintMoreThanOnePage(){
      let amountFirstPage = 9;
      const splittedOrders = splitOrders(allOrders, amountFirstPage);
      return(
      <Content style={{justifyContent:'center', alignItems:'center'}}>
          <ReportToPrint allOrders={splittedOrders[0]} startDate={startDate} endDate={endDate} sumsInfo={sumsInfo}/>

          {splittedOrders.map((piece, index)=>{
            if(index) {
                return (
                  <ReportToPrintAddPage key={index} allOrders={piece} startDate={startDate} endDate={endDate} index={index + 1}/>
                )
            }
          })}
       </Content>
      )
      
  }


    return (
        <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-type"
        >
        <Container>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleOpen(false)} alt="Fechar"></img>
          </Header>
          <Row style={{justifyContent:'center'}}>
            <Button
                label="Imprimir relatório"
                background="#256CE1" 
                color="white" 
                borderColor="white" 
                disabled={false}
                onClick={() => {allOrders.length < 9 ? print() : multiplePrint()}}
           ></Button>
          </Row>
          <Content>
            {allOrders.length < 9 ? renderPrint() : renderPrintMoreThanOnePage()}
          </Content>
        </Container>
      </Modal>
       )
  }


import { TableContainer, TableHeader, TableRow, Icons } from './styles'
import { TakeFromStock, ReturnToStock, Eye, Edit, AddCalendar} from '../../assets/icons/index';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import {formatNumber, formatMoney} from '../../services/functions';
import Button  from '../Button';

export default function TableSubsidiary({ columns, rows, type, loading, height, fitWidth, setModalVisualizeOrder,selectedOrder ,setSelectedOrder}) {
    
    function createDynamicStyle(){
        try{
            let elementToAdd = fitWidth ? '150px ' : '1fr ';
            let styleString = '';
            columns.map((item) => {
                styleString = styleString + elementToAdd;
            });
            styleString = styleString + elementToAdd;
            return styleString;
        } catch(err){
            console.log(err);
        }
    }
    let columnsWidths = createDynamicStyle();

    function RenderActions({rowIndex}){
                return (
                    <Icons>
                        <Tooltip title="Ver pedido original">
                            <img src={Eye} alt="Olho" onClick={()=>{setSelectedOrder({ ... rows[rowIndex]});setModalVisualizeOrder(true)}}/>
                        </Tooltip>
                    </Icons>
                )

    }


    function renderActionsRow(rowIndex){
        return (
<RenderActions rowIndex={rowIndex}></RenderActions>
        )
    }

    function RenderRow({rowIndex}) {
        return (
            <TableRow style={{gridTemplateColumns: columnsWidths}} key={rowIndex}>
                {columns.map((column, index)=>{
                    const key = column.key;
                    let value =  rows[rowIndex][key];
                    const unit = column.unit;
                    if (column.type === 'date'){
                        if(value){
                            value = moment(value).add(3, 'hours').format('DD/MM/YYYY');
                        }   
                    }
                    if (column.type === 'datetime'){
                        if(value){
                            value = moment(value).format('DD/MM/YYYY [às] HH:mm');
                        }   
                    }
                    if (column.type === 'boolean'){
                        value = value ? 'Sim' : 'Não'; 
                    }
                    if(column.type === 'money'){
                        value = formatMoney(value);
                    }
                    if(column.type === 'percent'){
                        value = formatNumber(value*100);
                    }
                    if(column.type === 'float'){
                        value = formatNumber(value);
                    }
                    let valueToShow = value || value === 0 ? value + unit : '-';
                    return (
                    <Tooltip title={value + unit || '-'} key={index}>
                        <h3 key={index} style={{color: '#54577A'}}>{valueToShow}</h3>
                    </Tooltip>
                    )
                    })}
              {renderActionsRow(rowIndex)}
            </TableRow>
        )
    }
 
    return (
        <TableContainer style={{width: fitWidth ? 'fit-content' : '100%', height: height || '100%'}}>
            <TableHeader style={{ gridTemplateColumns: columnsWidths}}>
                {columns && columns.map((column, index) => (
                        <h3 key={column?.key}>{column?.name}</h3>
                ))}
                            <h3>Ações</h3>
            </TableHeader>
          {   rows && rows.length > 0 && rows.map((row, index) => (
                    <RenderRow rowIndex={index} ></RenderRow>
                    ))}
        </TableContainer>
    )
}
import { FormEvent, useContext, useState} from "react";
import Button from "../../../components/Button";
import  Autocomplete  from  "../../../components/Autocomplete";
import Numberfield from "../../../components/Numberfield";
import {Container, Header, Row, Content} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import { UseAccounts } from "../../../hooks/useAccounts";
import AppContext from "../../../state/App.context";

  let typeOptions = [
    {
      id: 1,
      label: 'Espécie',
    },
    {
      id: 2,
      label: 'Gerenciador',
    },
  ]

export default function ModalAddBalance({open, handleOpen, width, height, setRefreshData}){
    const { createBalance, accounts } = UseAccounts();
    const [account_name, setName] = useState('');
    const [type, setType] = useState(typeOptions[0]);
    const [value, setValue] = useState(0);
    const [account, setAccount] = useState({id:0});
    const fontSize = 14;
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;

    const title = 'Atualizar saldo de conta';

    let accountOptions = account &&  !account.error && accounts.map((element)=> {
      if(element.isactive) return {
      id: element?.id_account,
      label: element?.account_name,
    }})

    function cleanAllInputs(){
      try{
          setValue(0);
          setAccount({id: 0});
      } catch(err){
           console.log(err);
      }
  }

  function checkingRequiredFields() {
    if (!value || !account.id) {
      setSnack({
        open: true,
        severity: 'error', 
        message: 'Preencha todos os campos necessários!',
      });
      return false;
    }
    return true;
  }

    async function handleCreateNewBalance(event){
      try {
        setLoading(true);
        event.preventDefault();
        if(checkingRequiredFields()){
        const response = await createBalance(
            {
            value,
            fk_accounts_id_account: account?.id,
        }
        );
        if(response.success){
            cleanAllInputs();
            handleOpen(false);
            setRefreshData(prevIsRefreshData => !prevIsRefreshData);
            setSnack({
              open: true,
              severity: 'success', 
              message:response?.message,
            })
        } else {
          setSnack({
            open: true,
            severity: 'error', 
            message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
          })
        }
      }
      } catch(err){
        console.log(err);
      } finally {
        setLoading(false);
      }
     }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
            return(
<Content>
                <Row>
                <Autocomplete
                  value={account}
                  width={400}
                  disabled={false}
                  options={accountOptions}
                  fontSize={fontSize} 
                  label="Conta bancária"
                  onChange={setAccount}
                ></Autocomplete>
                </Row>
                <Row>
                <Numberfield
                    label="Valor (R$)"
                    width={400}
                    disabled={false} 
                    value={value} 
                    fontSize={fontSize} 
                    onChange={setValue}
                  ></Numberfield>
                </Row>
                <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleOpen(false)}
                    ></Button>
                    <Button
                    label="Adicionar saldo" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={handleCreateNewBalance}
                    ></Button>
                </Row>
        </Content>
            )
            
        }}

    return (
        <Modal
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-type"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleOpen(false)} alt="Fechar"></img>
          </Header>
          {renderContent()}
 
        </Container>
      </Modal>
       )
  }
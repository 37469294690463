import React, {useState, useEffect, useContext} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UseAccounts } from "../../../hooks/useAccounts"
// import { NewBoatModal } from "../../components/Modals/NewBoatModal";
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import ModalAddAccount from './ModalAddAccount/index';
import ModalEditAccount from './ModalEditAccount/index';
import ModalRemoveAccount from './ModalRemoveAccount/index';
import AppContext from "../../../state/App.context";

  const columns = [
    {
      name: 'Nome',
      key:'account_name',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Tipo',
      key:'type',
      type: 'string', 
      unit:'',
    },
  ]

 function Accounts(){
  const { accounts } = UseAccounts();
  const [search, setSearch] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModalAddAccount, setOpenModalAddAccount] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({open: false, mode: '', accountType: '',  account_name: '', id_account: null});
  const [, setSnack] = useContext(AppContext).snackState;



  let accountsToShow = accounts.filter((account) => account.isactive);
  if (search.length > 0) {
    accountsToShow = accounts.filter(a => a.account_name.toLowerCase().includes(search.toLowerCase()));
  }

  useEffect(()=>{
    if(accounts.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:accounts.error,
      })
    }
  },[accounts])

  function Filters(){
    return(
      <></>
    )
  }

    return (
      <Container>
        <Header title="Contas bancárias" subtitle="Gerencie as contas cadastradas" />
        <Content>
          <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer>
          <Row>
            <Button
              label="Adicionar conta" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddAccount(true)}
             ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar conta..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={accountsToShow || []} 
            hasEditing={true} 
            hasRemoving={true}
            setSelectedItem={setSelectedAccount}
          ></Table>
        </Content>
        <ModalAddAccount 
         open={openModalAddAccount} 
         handleOpen={setOpenModalAddAccount} 
         width={700} 
         height={330} 
         ></ModalAddAccount>
         <ModalEditAccount
          open={selectedAccount.open && selectedAccount.mode === 'edit'} 
          setSelectedItem={setSelectedAccount} 
          selectedItem={selectedAccount}
          width={700} 
          height={330} 
        ></ModalEditAccount>
        <ModalRemoveAccount
          open={selectedAccount.open && selectedAccount.mode === 'remove'} 
          setSelectedItem={setSelectedAccount} 
          accountName={selectedAccount?.account_name}
          accountId={selectedAccount?.id_account}
          width={600} 
          height={180} 
        ></ModalRemoveAccount>
      </Container>
    );
  };

  export default React.createElement(Accounts);


import { Container, Label } from './styles';
import CircularProgress from '@mui/material/CircularProgress';

interface SpinnerProps{
    width: number;
    fontSize: number;
}

export default function CustomizedTextfield({width, fontSize}: SpinnerProps){

    return (
        <Container>
              <CircularProgress
                sx={{
                    color: '#256CE1',
                    animationDuration: '1.5s',
                  }}
                  size={width}
              />
              <Label style={{fontSize: fontSize}}>Carregando...</Label>
        </Container>
    )
}
export const columnsComprador = [

  {
    name: 'Id',
    key:'id_order',
    type: 'string', 
    unit: '',
  },
  {
    name: 'Produto',
    key:'name',
    type: 'string', 
    unit: '',
  },
  {
    name: 'Barco',
    key:'boat_name',
    type: 'string', 
    unit: '',
  },
  {
    name: 'Viagem',
    key:'trips_name',
    type: 'string', 
    unit: '',
  },
  {
    name: 'Data Criação Pedido',
    key:'created_at',
    type: 'date', 
    unit: '',
  },
  {
    name: 'Quantidade',
    key:'qty',
    type: 'strig', 
    unit: '',
  },
  {
    name: 'Valor/Unidade',
    key:'total_per_qty',
    type: 'strig', 
    unit: '',
  },
  {
    name: 'Valor Total',
    key:'total_product_value',
    type: 'money', 
    unit: '',
  },
  {
    name: 'Total Pago',
    key:'total_value_exec',
    type: 'money', 
    unit: '',
  },
  {
    name: 'Total Pendente',
    key:'total_value_pend',
    type: 'money', 
    unit: '',
  }


]


export const columnsSalary = [

  {
    name: 'Id',
    key:'id_order',
    type: 'string', 
    unit: '',
  },  
  {
    name: 'Competência',
    key:'salary_month',
    type: 'string', 
    unit: '',
  },  
  {
    name: 'Período',
    key:'period',
    type: 'string', 
    unit: '',
  },  
  {
    name: 'Serviço',
    key:'costs_categories_name',
    type: 'string', 
    unit: '',
  },
  {
    name: 'Barco',
    key:'boat_name',
    type: 'string', 
    unit: '',
  },
  {
    name: 'Viagem',
    key:'trips_name',
    type: 'string', 
    unit: '',
  },
  {
    name: 'Data Criação Pedido',
    key:'created_at',
    type: 'date', 
    unit: '',
  },
  {
    name: 'Quantidade',
    key:'qty',
    type: 'string', 
    unit: '',
  },
  {
    name: 'Valor Serviço',
    key:'total_service_value',
    type: 'money', 
    unit: '',
  },
  {
    name: 'Total Pago',
    key:'total_value_exec',
    type: 'money', 
    unit: '',
  },
  {
    name: 'Total Pendente',
    key:'total_value_pend',
    type: 'money', 
    unit: '',
  },
  {
    name: 'Obs',
    key:'notes',
    type: 'string', 
    unit: '',
  }

]



import { FormEvent, useEffect, useState, useContext} from "react";
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import Datepicker from "../../../../components/Datepicker";
import Numberfield from "../../../../components/Numberfield";
import {Container, Header, Row, Content, ListTitle, Divider} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../../assets/icons/index';
import Spinner from "../../../../components/Spinner";
import { UseTrips } from "../../../../hooks/useTrips";
import Autocomplete from "../../../../components/Autocomplete";
import { UseBoats } from "../../../../hooks/useBoats"
import { UseFishingTypes } from "../../../../hooks/useFishingTypes";
import { UseEmployees } from "../../../../hooks/useEmployees";
import AppContext from "../../../../state/App.context";
import moment from "moment";
import Table from "../../../../components/Table";

const columns = [
  {
    name: 'Funcionário',
    key:'label',
    type: 'string', 
    unit: '',
  },
]


export default function ModalEditrip({open, setSelectedItem, width, height, selectedItem}){
    const { trips, updateTrip } = UseTrips();
    const { boats } = UseBoats();
    const { fishingTypes } = UseFishingTypes();
    const {employees, getTripEmployees } = UseEmployees();
    const [trips_name, setName] = useState('');
    const [start, setStart] = useState('');
    const [initialStartDate, setInitialStartDate] = useState('');
    const [end, setEnd] = useState('');
    const [initialEndDate, setInitialEndDate] = useState('');
    const [boat, setBoat] = useState({id: 0});
    const [fishingType, setFishingType] = useState({id: 0});
    const [consumption, setConsumption] = useState(0);
    const [production, setProduction] = useState(0);
    const [notes, setNotes] = useState('');
    const [harvest, setHarvest] = useState('');
    const [employeesList, setEmployeesList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');

    const fontSize = 14;
    const [loading, setLoading] = useState(false);
    const [, setSnack] = useContext(AppContext).snackState;

    const title = 'Editar viagem';

    let boatsOptions = boats && !boats.error && boats.map((element)=> {return {
      id: element?.id_boat,
      label: element?.boats_name,
    }})

    let fishingTypesOptions = fishingTypes && !fishingTypes.error && fishingTypes.map((element)=> {return {
      id: element?.id_fishingtype,
      label: element?.fishingtypes_name,
    }})

    let employeesOptions = employees &&  !employees.error && employees.map((element)=> {return {
      id: element?.id_employee,
      label: element?.full_name,
    }})
  
    function handleClose(){
      setSelectedItem({open: false, mode: '', trips_name: '', id_trip: null});
    }

    async function getEmployessFromTrip(){
      try{
        const responseEmployees = await getTripEmployees(selectedItem?.id_trip);
        if(responseEmployees.success){
          let formattedEmployees = [];
          responseEmployees?.data.map((employee)=>{
            formattedEmployees.push({
              id: employee?.id_employee,
              label: employee?.full_name,
            })
          })
          setEmployeesList(formattedEmployees)
        } else {
          setSnack({
            open: true,
            severity: 'error', 
            message: 'Erro:' + responseEmployees?.message,
          });
        }
      } catch(err){
        console.log(err);
      }
    }
    
   function getInfo(){
    try{
      getEmployessFromTrip();
      const trip = trips.filter((element)=> element.id_trip === selectedItem?.id_trip)[0];
      let boatIndex = boats.findIndex((boat)=> boat.id_boat === trip.fk_boats_id_boat);
      let fishingTypeIndex = fishingTypes.findIndex((fishingType)=> fishingType.id_fishingtype === trip.fk_fishingtypes_id_fishingtype);
      setName(trip?.trips_name);
      let formattedStart = moment(trip?.begin_date).format('YYYY-MM-DDTHH:mm');
      let formattedEnd = trip?.end_date ? moment(trip?.end_date).format('YYYY-MM-DDTHH:mm') : null;
      setInitialStartDate(formattedStart);
      setStart(formattedStart);
      setInitialEndDate(formattedEnd);
      setEnd(formattedEnd);
      setBoat(boatIndex !== -1 ? boatsOptions[boatIndex] : '');
      setFishingType(fishingTypeIndex !== -1 ? fishingTypesOptions[fishingTypeIndex] : '');
      setConsumption(trip?.oil_consumption_engine_1 || 0);
      setProduction(trip?.production);
      setNotes(trip?.notes);
      setHarvest(trip?.harvest);
    }catch(err){
      console.log(err);
    } finally{
      setLoading(false);
    }
  }

  useEffect(()=>{
    if (open) getInfo();
  },[open])

  function addEmployeeToList(){
    try{
      let employeesListCopy = [... employeesList];
      employeesListCopy.push(selectedEmployee);
      setEmployeesList(employeesListCopy);
    } catch(err){
      console.log(err);
    }
   }
   function removeEmployeeFromList(selectedItem){
    try{
      let employeesListCopy = [... employeesList];
      let index = employeesListCopy.findIndex((element) => element.id === selectedItem.id);
      if(index > -1){
        employeesListCopy.splice(index,1);
      }
      setEmployeesList(employeesListCopy);
    } catch(err){
      console.log(err);
    }
   }

  function checkingRequiredFields() {
    if (!trips_name || !harvest || !start || !fishingType  || !boat.id || employeesList.length === 0) {
      setSnack({
        open: true,
        severity: 'error', 
        message: 'Preencha todos os campos necessários!',
      });
      return false;
    }
    return true;
  }
  function transformDateFormat(dateString, initialDateString) {
    let originalDate = moment(dateString);
    if(dateString === initialDateString){
      originalDate = originalDate.add(3, 'hours');
    }
    const formattedDate = originalDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    return formattedDate;
}

  const isEndAfterStart = () => start && (!end || moment(end)?.isAfter(moment(start)));
  function checkingStartAndEnd() {
    if (end && !isEndAfterStart()) {
      setSnack({
        open: true,
        severity: 'error', 
        message: 'A saída deve ser antes da chegada!',
      });
      return false;
    }
    return true;
  }

  function returnEmployees(){
    try{
      let list = [];
      employeesList.map((item)=> {
        list.push({employee: item?.id});
      });
      return list;
    } catch(err){
      console.log(err);
    }
  }

    async function handleEditTrip(event){
      try{
        setLoading(true);
      event.preventDefault();
      if(checkingRequiredFields() && checkingStartAndEnd()){
        const formattedEmployees = returnEmployees();
      const response = await updateTrip(
          {
          id_trip: selectedItem.id_trip,
          trips_name,
          begin_date: start ? transformDateFormat(start, initialStartDate) : start,
          end_date: end ? transformDateFormat(end, initialEndDate) : end,
          fk_boats_id_boat: boat?.id,
          fk_fishingtypes_id_fishingtype: fishingType?.id,
          oil_consumption_engine_1: consumption,
          oil_consumption_engine_2: 0,
          oil_consumption_engine_3: 0,
          production,
          notes,
          boatName: '',
          fishingTypeName: '',
          harvest: harvest,
          employees: formattedEmployees,
      }
      );
      if(response.success){
          handleClose();
          setSnack({
            open: true,
            severity: 'success', 
            message:response?.message,
          })
      } else {
        setSnack({
          open: true,
          severity: 'error', 
          message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
        })
      }
    }

      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
      

   }

     function renderContent(){
        if(loading){
          return (
            <Spinner width={40} fontSize={14}></Spinner>
          )
        } else {
            return(
<Content>
                <Row>
                    <Numberfield
                    label="Nome da viagem"
                    width={'95%'}
                    disabled={false} 
                    value={trips_name} 
                    fontSize={fontSize} 
                    onChange={setName}
                    ></Numberfield>
                    <Autocomplete
                    value={boat}
                    width={'95%'}
                    disabled={false}
                    options={boatsOptions}
                    fontSize={fontSize} 
                    label="Barco*"
                    onChange={setBoat}
                    ></Autocomplete>
                </Row>
                <Row>
                <Numberfield
                    label="Safra*"
                    width={'95%'}
                    disabled={false} 
                    value={harvest} 
                    fontSize={fontSize} 
                    onChange={setHarvest}
                  ></Numberfield>
                <Autocomplete
                  value={fishingType}
                  width={'95%'}
                  disabled={false}
                  options={fishingTypesOptions}
                  fontSize={fontSize} 
                  label="Espécie alvo*"
                  onChange={setFishingType}
                  ></Autocomplete>
                </Row>
                <Row>
                <Datepicker
                    label="Saída*"
                    width={'95%'}
                    disabled={false}
                    value={start} 
                    fontSize={fontSize} 
                    onChange={setStart}
                    type="datetime-local"
                     />
                <Datepicker
                    label="Chegada"
                    width={'95%'}
                    disabled={false}
                    value={end} 
                    fontSize={fontSize} 
                    onChange={setEnd} 
                    type="datetime-local"
                    />

                </Row>
                <Row>
                <Numberfield
                    label="Produção (kg)"
                    width={'95%'}
                    disabled={false} 
                    value={production} 
                    fontSize={fontSize} 
                    onChange={setProduction}
                  ></Numberfield>
                  <Numberfield
                    label="Consumo de óleo motor"
                    width={'95%'}
                    disabled={false} 
                    value={consumption} 
                    fontSize={fontSize} 
                    onChange={setConsumption}
                    ></Numberfield>
                </Row>
                <Row>
                  <ListTitle>Lista de tripulantes*:</ListTitle>
                 </Row>
                 <Divider></Divider>
                 <Row style={{marginBottom: 16, alignItems: 'end'}}>
                  <Autocomplete
                    value={selectedEmployee}
                    width={'80%'}
                    disabled={false}
                    options={employeesOptions}
                    fontSize={fontSize} 
                    label="Tripulante"
                    onChange={setSelectedEmployee}
                  ></Autocomplete>
                  <Button
                    label="Adicionar" 
                    background="transparent" 
                    color="#256CE1" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={addEmployeeToList}
                  ></Button>
                </Row>
                <Table 
                    columns={columns} 
                    rows={employeesList || []} 
                    hasEditing={false} 
                    hasRemoving={true}
                    setSelectedItem={removeEmployeeFromList}
                    height="auto" 
                  ></Table>
                <Row>
                <Textfield
                    label="Observações"
                    width={'100%'}
                    disabled={false} 
                    value={notes} 
                    fontSize={fontSize} 
                    onChange={setNotes}
                    multiline={true}
                    ></Textfield>
                </Row>
                <Row>
                    <Button
                    label="Cancelar" 
                    background="#8E92BC" 
                    color="white" 
                    borderColor="#8E92BC" 
                    disabled={false} 
                    onClick={()=> handleClose(false)}
                    ></Button>
                    <Button
                    label="Salvar alterações" 
                    background="#256CE1" 
                    color="white" 
                    borderColor="#256CE1" 
                    disabled={false} 
                    onClick={handleEditTrip}
                    ></Button>
                </Row>
        </Content>
            )
            
        }}

    return (
        <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={()=> handleClose(false)} alt="Fechar"></img>
          </Header>
          {renderContent()}
 
        </Container>
      </Modal>
       )
  }
import {Container, Header, Row, Content, ListTitle, Divider, Print, PrintHeader} from './styles';
import { formatMoney } from '../../../services/functions';
import moment from 'moment';
import Table from '../../../components/Table';
import { columnsApportionment } from './options';

const columnsTransactions = [
    {
      name: 'Produto',
      key:'product_name',
      type: 'string', 
      unit: '',
    },  
   {
    name: 'Quantidade',
    key:'product_amount',
    type: 'number', 
    unit: '',
  },
    {
      name: 'Preço unitário',
      key:'unity_price',
      type: 'money', 
      unit: '',
    },

    {
      name: 'Valor total',
      key:'total_value',
      type: 'money', 
      unit: '',
    }, 
  ]




export default function OrderToPrintFirstPage({order, transactions, apportionment}){
  function renderApportionment(){
    return (
      <>
        <Row style={{width:'100%', marginTop: 0}}>
          <ListTitle>Rateio:</ListTitle>
        </Row>
        <Divider></Divider>
          <Table 
            columns={columnsApportionment} 
            rows={apportionment || []} 
            hasEditing={false} 
            hasRemoving={false}
            setSelectedItem={()=>{}} 
            height="auto"
            actionsInFirstLine={false}
            fitWidth={false}
          ></Table>
      </>
    )

  }

  function renderTripInfo(){
    if(!order?.isapportionment){
      return (
        <Row style={{width:'100%'}}> 
        <h4><b>Barco:</b> {order?.boats_name}</h4>
        <h4><b>Viagem:</b> {order?.trips_name}</h4>
        <h4><b>Safra:</b> {order?.harvest}</h4>
      </Row> 
      )
    } else {
      return (
        <></>
      )
    }
  }
    return (
        <Print id="report_1">
            <PrintHeader>
                <h4>Detalhes do Pedido:</h4>
                <h3>Data de criação: {moment(order?.created_at).format('DD/MM/YYYY')}</h3>
                </PrintHeader>
              <Divider></Divider>
              <Row style={{width:'100%', marginTop:24, justifyContent: 'flex-start'}}> 
                  <h4 ><b>Criado por:</b> {order?.created_by}</h4>
                  <h4 ><b>Categoria:</b> {order?.category}</h4>
                  <h4><b>{order?.type === 'Entrada' ? 'Comprador' : 'Fornecedor'}</b>: {order?.providers_name}</h4>
                </Row> 
              {renderTripInfo()}
                <Row style={{width:'100%', marginBottom: 36}}>
                    <h4><b>Tipo de pagamento:</b> {order?.paymenttype_name}</h4>
                    <h4><b>Vencimento:</b> {moment(order?.expiration_date_name).add(3, 'hours').format('DD/MM/YYYY')}</h4>
                    <h4><b>Status:</b> {order?.status_name}</h4>
                </Row> 
                { order?.category === 'Rateio' && renderApportionment()}
                <Row style={{width:'100%', marginTop:0}}>
                  <ListTitle>Produtos:</ListTitle>
                </Row>
              <Divider></Divider>
              <Table 
                  columns={columnsTransactions} 
                  rows={transactions || []} 
                  hasEditing={false} 
                  hasRemoving={false}
                  setSelectedItem={()=>{}} 
                  actionsInFirstLine={false}
                  height="auto"
                  fitWidth={false}
                ></Table>  
                {/* <Row  style={{width:'100%',  justifyContent:'flex-end'}}>
                    <h5> Gerado em {moment().format('DD/MM/YYYY HH:mm')}</h5>
                </Row> */}
        </Print>
    )

}

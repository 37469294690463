import styled from 'styled-components';

export const Container = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: start;
    background: var(--white);
    border-radius: 10px;
    padding: 16px 16px;
    margin-top: 12px;
    height: 300px;
    ` ;

export const Title = styled.h1`
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 160%;
    color: var(--txt-title);
    text-align: left;
    margin-bottom: 16px;
` ;

export const Graph = styled.div`
    width:auto;
    height: auto;
    margin-top:16px;
    ` ;

export const RowSelect= styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const TooltipBox = styled.div`
    background: var(--white);
    padding: 8px;
    p {
        font-size:12px;
        line-height:180%;
    }
    ` ;

import React, { useState, useEffect, useContext} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row, RowTable
  } from './styles';
import { UseBoats } from "../../../hooks/useBoats"
import Searchbar from "../../../components/Searchbar"; 
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import ModalAddBoat from './ModalAddBoat/index';
import ModalEditBoat from './ModalEditBoat/index';
import ModalRemoveBoat from './ModalRemoveBoat/index';
import  Autocomplete  from  "../../../components/Autocomplete";
import AppContext from "../../../state/App.context";

  const columns = [
    {
      name: 'Nome',
      key:'boats_name',
      type: 'string', 
      unit:'',
    },
    // {
    //   name: 'RGP',
    //   key:'rgp',
    //   type: 'string', 
    //   unit:'',
    // },
    {
      name: 'Permissão',
      key:'permissions',
      type: 'boolean', 
      unit:'',
    },
    {
      name: 'Validade',
      key:'validity',
      type: 'date', 
      unit:'',
    },
    {
      name: 'Comprimento',
      key:'length',
      type: 'number', 
      unit:'m',
    },
    {
      name: 'Peso',
      key:'weigth',
      type: 'number', 
      unit:'kg',
    },
    {
      name: 'Motor 1',
      key:'engine_1',
      type: 'string', 
      unit:'hp',
    },
    {
      name: 'Motor 2',
      key:'engine_2',
      type: 'string', 
      unit:'hp',
    },
    {
      name: 'Motor 3',
      key:'engine_3',
      type: 'string', 
      unit:'hp',
    },
  ]

function Boats(){
  const { boats, } = UseBoats();
  const [search, setSearch] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModalAddBoat, setOpenModalAddBoat] = useState(false);
  const [selectedBoat, setSelectedBoat] = useState({open: false, mode: '', name: '', id_boat: null});
  const [, setSnack] = useContext(AppContext).snackState;
  const [permissionFilter, setPermissionFilter] = useState({
    label: 'Todos',
    id: 0,
  });
  const permissionsOptions = [
    {
      label: 'Todos',
      id: 0,
      value: ''
    },
    {
      label: 'Sim',
      id: 1,
      value: true,
    },
    {
      label: 'Não',
      id: 2,
      value: false,
    },

  ]


  useEffect(()=>{
    if(boats.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:boats.error,
      })
    }
  },[boats])

  let boatsToShow = boats.filter((element)=> element.isactive);

  boatsToShow = boatsToShow.map((boat)=>{
    return {
      ... boat,
    }
  })
  if (search.length > 0) {
    boatsToShow = boatsToShow.filter(a => a.boats_name.toLowerCase().includes(search.toLowerCase()));
  }
  if (permissionFilter.id) {
    boatsToShow = boatsToShow.filter(a => permissionFilter.value === a.permissions);
  }


  function Filters(){
    return(
      <div style={{marginTop: 16}}>
        <Autocomplete
          value={permissionFilter}
          width={200}
          disabled={false}
          options={permissionsOptions}
          fontSize={'0.7rem'} 
          label="Permissão"
          onChange={setPermissionFilter}
          ></Autocomplete>
      </div>
    )
  }

    return (
      <Container>
        <Header title="Barcos" subtitle="Gerencie os barcos cadastrados" />
        <Content>
          <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer>
          <Row>
            <Button
              label="Adicionar barco" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddBoat(true)}
             ></Button>
            <Button 
              label="Filtros" 
              background="transparent" 
              color="#54577A" 
              borderColor="#54577A" 
              disabled={false} 
              onClick={()=> setOpenDrawer(true)}
              ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar barcos..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          {/* <RowTable> */}
          <Table 
            columns={columns} 
            rows={boatsToShow || []} 
            hasEditing={true} 
            hasRemoving={true}
            setSelectedItem={setSelectedBoat}
          ></Table>
          {/* </RowTable> */}
        </Content>
        <ModalAddBoat 
         open={openModalAddBoat} 
         handleOpen={setOpenModalAddBoat} 
         width={800} 
         height={430} 
         ></ModalAddBoat>
         <ModalEditBoat
          open={selectedBoat.open && selectedBoat.mode === 'edit'} 
          setSelectedItem={setSelectedBoat} 
          selectedItem={selectedBoat}
          width={800} 
         height={430} 
        ></ModalEditBoat>
        <ModalRemoveBoat
          open={selectedBoat.open && selectedBoat.mode === 'remove'} 
          setSelectedItem={setSelectedBoat} 
          boatName={selectedBoat?.name}
          boatId={selectedBoat?.id_boat}
          width={600} 
          height={170} 
        ></ModalRemoveBoat>
      </Container>
    );
  };

  export default React.createElement(Boats);
import styled from 'styled-components';

export const Container = styled.div`
background: var(--background);
height: 100vh;
width: 100%;
padding: 32px;
overflow-y: auto;
scrollbar-width: thin; 
scrollbar-color: #989898 #FFFFFF; 
&::-webkit-scrollbar {
width: 6px; 
}
&::-webkit-scrollbar-thumb {
background-color: #989898; 
border-radius: 4px; 
}
&::-webkit-scrollbar-track {
background-color: #FFFFFF;
}

`;

export const Content = styled.div`
height: auto;
width: 100%;
margin-top: 36px;
display: flex;
flex-direction: column;
align-items: end;
justify-content: end;
`;

export const Row = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`;

export const RowPagination = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 16px 16px;
h1{
    color: var(--txt-subtitle);
    font-size: 0.8rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: regular;
}
img {
    cursor: pointer;
}
`;

export const Tabs = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
// border-bottom: 0.2px solid rgba(84, 87, 122, 0.4);
margin-bottom: 0.5rem;
h4{
    cursor: pointer;
    padding-bottom: 0.4rem;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--txt-subtitle);
    margin-left: 2rem;
}
`;





import {useState, useContext} from "react";
import Button from "../../../components/Button"; 
import Spinner from "../../../components/Spinner";
import {Container, Header, Row, Content} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../assets/icons/index';
import { UseOrders } from "../../../hooks/useOrders"
import AppContext from "../../../state/App.context";
import { formatMoney } from "../../../services/functions";
import moment from "moment";

export default function ModalRemoveOrder({open, setSelectedItem, width, height, selectedItem, getInfo}){
    const { updateOnDelete, deleteStockOrder } = UseOrders();
    const [loading, setLoading] = useState(false);
    const title = 'Remover pedido';
    const [, setSnack] = useContext(AppContext).snackState;
    const order = selectedItem;

    const type = order && order.type && order?.type.toLowerCase();
    const provider = type === 'entrada' ? 'comprador' : 'fornecedor';

    let orderDescription = order.isapportionment ? `o pedido da categoria rateio de ${formatMoney(order?.total_order_value)} (${order?.paymenttype_name})  da subsidiária "${order?.subsidiary_name}"do ${provider} "${order?.providers_name}", feita no dia ${moment(order?.created_at).format('DD/MM/YYYY')}?` : `a ${type} da categoria padrão de ${formatMoney(order?.total_order_value)} (${order?.paymenttype_name}) no centro de custo "${order?.costs_center_name}" da subsidiária "${order?.subsidiary_name}"do ${provider} "${order?.providers_name}", feita no dia ${moment(order?.created_at).format('DD/MM/YYYY')}?` ;

    function renderExplanation(){
      if(!order.isstocked){
        return (
          <Row>
          <h1>Deseja remover {orderDescription} <b> Essa ação não poderá ser desfeita.</b></h1>
        </Row>
        )

      } else {
        return (
          <>
          <Row>
          <h1>Deseja remover o pedido da categoria estoque de {formatMoney(order?.total_order_value)} ({order?.paymenttype_name}) da subsidiária {order?.subsidiary_name} do {provider} {order?.providers_name}, feita no dia {moment(order?.created_at).format('DD/MM/YYYY')}? </h1>
          </Row>
          <Row style={{marginTop: '0.5rem'}}>
            <h1> <b>Atenção:</b> as movimentações de estoque e pedidos do tipo retirada de estoque gerados por ele também serão removidos. <b>Essa ação não poderá ser desfeita.</b></h1>
          </Row>
          </>        )
      }
    }

    function handleClose(){
      setSelectedItem({open: false, mode: '', name: '', id_order: null});
      getInfo();
    }

    async function handleRemoveOrder(event){
      try{
      setLoading(true);
      event.preventDefault();
      let response;
      if(order.isstocked){
        response = await deleteStockOrder(selectedItem.id_order);
      } else {
        response = await updateOnDelete(selectedItem.id_order);
      }
      if(response.success){
        handleClose();
        setSnack({
          open: true,
          severity: 'success', 
          message:response?.message,
        })
      }  else {
        setSnack({
          open: true,
          severity: 'error', 
          message:'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
        })
      }

      } catch(err){
        console.log(err);
      } finally{
        setLoading(false);
      }
      
    }

    function renderContent(){
      if(loading){
        return (
          <Spinner width={40} fontSize={14}></Spinner>
        )
      } else {
        return (
            <Content>
           {renderExplanation()}
              <Row style={{marginTop: 32}}>
                <Button
                  label="Cancelar"
                  background="#8E92BC"
                  color="white"
                  borderColor="#8E92BC"
                  disabled={false}
                  onClick={() => setSelectedItem({ open: false, mode: '', name: '', id_order: null })}
                ></Button>
                <Button
                  label="Remover o pedido"
                  background="#FF4D5E"
                  color="white"
                  borderColor="#FF4D5E"
                  disabled={false}
                  onClick={handleRemoveOrder}
                ></Button>
              </Row>
            </Content>
        )
      }

    }

    return (
        <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
        <Header>
          <h1>{title}</h1>
          <img src={Close} onClick={() => setSelectedItem({ open: false, mode: '', name: '', id_order: null })} alt="Fechar"></img>
        </Header>
          {renderContent()}
        </Container>
      </Modal>
       )
  }
import styled from 'styled-components';

export const Container = styled.div`
background: var(--background);
height: 100vh;
width: 95%;
padding: 32px;
overflow-y: auto;
scrollbar-width: thin; 
scrollbar-color: #989898 #FFFFFF; 
&::-webkit-scrollbar {
width: 6px; 
}
&::-webkit-scrollbar-thumb {
background-color: #989898; 
border-radius: 4px; 
}
&::-webkit-scrollbar-track {
background-color: #FFFFFF;
}

`;

export const Content = styled.div`
height: auto;
width: 100%;
margin-top: 18px;
display: flex;
flex-direction: column;
align-items: end;
justify-content: end;
`;

export const Row = styled.div`
width: auto;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`;

export const TripTitle = styled.h2`
font-weight: 500;
font-size: 1rem !important;
color: var(--txt-title);
text-align: center;
margin-right: 1rem;
`;

export const RowContent = styled.div`
width: 100%;
// display: flex;
// flex-direction: row;
// align-items: center;
// justify-content: space-between;
display: grid;
grid-template-columns: 1fr 1fr;
column-gap: 0.8rem;
`;

export const LoadingContent = styled.div`
width: 100%;
height: 400px;
display: flex;
flex-direction: column;
justify-content:center;
align-items: center;
` ;




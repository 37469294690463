
import {createContext ,ReactNode, useContext} from 'react';
import  api from '../services/api';
import axios from 'axios';

interface File extends Blob {
    readonly lastModified: number;
    readonly name: string;
    readonly webkitRelativePath: string;
}

interface FilesProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface ResponsePropsWithData {
    success: boolean;
    message: string;
    data: any;
}

interface FilesContextData {
    getOrderFiles: (id_order: string) => Promise<ResponsePropsWithData>;
    downloadFile: (filename: string, name: string) => Promise<ResponseProps>;
    uploadFilesToOrder: (id_order:string, files: Array<File>) => Promise<ResponseProps>;
}

const FilesContext = createContext<FilesContextData>(
    {} as FilesContextData
);

const baseUrl = process.env.REACT_APP_BACKEND_URL;


export function FilesProvider ({children}: FilesProvidersProps){

      async function getOrderFiles (id_order: string){
        const response = await axios.get(`${baseUrl}listfiles`, {
            params: { id_order: id_order }
          });
        if (response.status === 200 && !response.data.error ){
            const orderFiles = response?.data;
            return {
                success: true,
                message: "Ok!",
                data: orderFiles,
            }
        }
        return {
            success: false,
            message: response.data && response.data.error ? response.data.error : "Erro ao buscar arquivos do pedido." ,
            data: null
        }
    }

    async function downloadFile (filename: string, name: string){
        const response = await axios.get(`${baseUrl}downloadfile`, {
            params: { key: filename },
            responseType: 'json',
          });
        if (response.status === 200 && !response.data.error ){
            const fileData = response.data.Body.data;
            const blob = new Blob([Uint8Array.from(fileData)], { type: response.data.ContentType });
            
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return {
                success: true,
                message: "Ok!",
            }
        }
        return {
            success: false,
            message: response.data && response.data.error ? response.data.error : "Erro ao buscar arquivos do pedido." ,
        }
    }

    async function uploadFilesToOrder (id_order: string, files: Array<any>){
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
          }
        formData.append('id_order', id_order);
        const response = await axios.post(`${baseUrl}uploadfiles`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        if (response.status === 200 && !response.data.error ){
            return {
                success: true,
                message: "Arquivos carregados com sucesso!",
            }
        }
        return {
            success: false,
            message: "Erro ao carregar arquivos." ,
        }
    }
    
    return (
        <FilesContext.Provider value = {{ getOrderFiles, downloadFile, uploadFilesToOrder}}>
            {children}
        </FilesContext.Provider>
    );
}

export function UseFiles(){
    const context = useContext(FilesContext);
    return context;
}


import { Container, Title, RowInfo, Divider, Content, Info} from './styles'
import Spinner from '../Spinner';
import { formatMoney, formatNumber } from '../../services/functions';

export default function ResultsOverview({  info , loading }) {
    if(loading) {
        return (
            <Container>
                <Title>Balanço financeiro</Title>
                <Content>
                    <Spinner width={40} fontSize={0}></Spinner>
                </Content>
            </Container>
        )
        }
    return (
        <Container>
            <Title>Balanço financeiro</Title>
            <Content>
                <Info>
                    <RowInfo>Receitas: <b style={{color:'#03A03E'}}> +{formatMoney(info?.entradas.total_global)}</b></RowInfo>
                    <RowInfo>Despesas: <b style={{color:'#FF4D5E'}}>-{formatMoney(info?.saidas.total_global)}</b></RowInfo>
                    <RowInfo>Saldo:<b style={{color: info?.global_balance > 0 ? '#03A03E' : '#FF4D5E',}}> {formatMoney(info?.global_balance)}</b></RowInfo>
                </Info>
            <Divider></Divider>
            <Title>Resumo</Title>
            <Info>
                <RowInfo>Bruto:<b>{formatNumber(info?.production_summary?.gross)} kg </b> </RowInfo>
                <RowInfo>Líquido:<b>{formatNumber(info?.production_summary?.net)} kg </b> </RowInfo>
                <RowInfo>Queda: <b>{formatNumber(info?.production_summary?.drop)}%</b></RowInfo>
                {/* <RowInfo>Custo direto:<b>{formatMoney(info?.direct_cost)} </b> </RowInfo>
                <RowInfo>Custo indireto:<b>{formatMoney(info?.indirect_cost)} </b> </RowInfo> */}
                {/* <RowInfo></RowInfo> */}
            </Info>
            <Info>
                <RowInfo>Custo Direto: <b>{formatMoney(info?.production_summary?.direct_cost_per_kg)}/kg</b> - <b>({((info?.saidas.direct_cost_percentage)*100).toFixed(2)}%)</b></RowInfo>
                <RowInfo>Custo Indireto: <b>{formatMoney(info?.production_summary?.indirect_cost_per_kg)}/kg</b> - <b>({((info?.saidas.indirect_cost_percentage)*100).toFixed(2)}%)</b></RowInfo>
            </Info>
            <Info>
                <RowInfo>Preço Médio: <b>{formatMoney(info?.production_summary?.price_per_kg)}/kg</b></RowInfo>
                <RowInfo>Custo Médio: <b>{formatMoney(info?.production_summary?.cost_per_kg)}/kg</b></RowInfo>
                <RowInfo>Saldo Médio: <b style={{color: ((info?.production_summary?.price_per_kg - info?.production_summary?.cost_per_kg)) > 0 ? '#03A03E' : '#FF4D5E',}}>{formatMoney((info?.production_summary?.price_per_kg - info?.production_summary?.cost_per_kg))} kg</b></RowInfo>
            </Info>
            </Content>
        </Container>
    )
}
import React, {useEffect, useState, useContext} from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
   Container,Content,Row
  } from './styles';
import { UseUsers } from "../../../hooks/useUsers"
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import ModalAddUser from './ModalAddUser/index';
import AppContext from "../../../state/App.context";
import LocalStorageService from '../../../services/storage';

  const columns = [
    // {
    //   name: 'Id',
    //   key:'id_fishingtype',
    //   type: 'number', 
    //   unit: '',
    // },
    {
      name: 'Email',
      key:'e_mail',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Perfil',
      key:'user_role',
      type: 'string', 
      unit:'',
    },
    {
      name: 'Ativo',
      key:'isactive',
      type: 'boolean', 
      unit:'',
    },

  ]

  const rolesOptions = [
    {id:'adm', label:'Administrador'},
    {id: 'write', label:'Visualização/Criação/Edição'},
    {id:'view', label:'Visualização'}
  ]

function Users(){
  const { users } = UseUsers();
  const [search, setSearch] = useState('');
  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState({open: false, mode: '', e_mail: '', id_user: null});
  const [, setSnack] = useContext(AppContext).snackState;
  const localStorageService = LocalStorageService();
  const userInfo = localStorageService.getUserInfo();
  const fk_companies_id_company = userInfo?.fk_companies_id_company;

  let usersToShow = users;
  if (search.length > 0) {
    usersToShow = users.filter(a => a.e_mail.toLowerCase().includes(search.toLowerCase()));
  }
  usersToShow && !usersToShow.error && usersToShow.filter(user => user.fk_companies_id_company === fk_companies_id_company);

  function enhanceUsers() {
    if (!Array.isArray(usersToShow)) {
      return [];
    }
    return usersToShow.map(user => {
      if (typeof user !== 'object' || typeof user.user_role !== 'string') {
        return user;
      }
      const { label: user_role } = rolesOptions.find(({ id }) => id === user?.user_role) || {};
      return {
        ...user,
        user_role,
      };
    });
  };
  usersToShow = enhanceUsers();

  useEffect(()=>{
    if(users.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:users.error,
      })
    }
  },[users])


    return (
      <Container>
        <Header title="Usuários" subtitle="Gerencie os usuários cadastrados" />
        <Content>
          {/* <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer> */}
          <Row>
            <Button
              label="Adicionar usuário" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddUser(true)}
             ></Button>
            <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar usuário..." fontSize={'0.7rem'} ></Searchbar>
          </Row>
          <Table 
            columns={columns} 
            rows={usersToShow || []} 
            hasEditing={false} 
            hasRemoving={false}
            setSelectedItem={setSelectedUser} 
          ></Table>
        </Content>
        <ModalAddUser 
         open={openModalAddUser} 
         handleOpen={setOpenModalAddUser} 
         width={700} 
         height={340} 
         ></ModalAddUser>
      </Container>
    );
  };

  export default React.createElement(Users);
import {Container, Header, Row, Content, ListTitle, Divider, Print, PrintHeader} from './styles';
import { formatMoney } from '../../../services/functions';
import moment from 'moment';
import Table from '../../../components/Table';
import { columnsApportionment, columnsParcels } from './options';


const columnsTransactions = [
    {
      name: 'Produto',
      key:'product_name',
      type: 'string', 
      unit: '',
    },  
   {
    name: 'Quantidade',
    key:'product_amount',
    type: 'number', 
    unit: '',
  },
    {
      name: 'Preço unitário',
      key:'unity_price',
      type: 'money', 
      unit: '',
    },

    {
      name: 'Valor total',
      key:'total_value',
      type: 'money', 
      unit: '',
    }, 
  ]




export default function OrderToPrint({order, transactions, apportionment, parcels}){
  function renderTripInfo(){
    if(!order?.isapportionment){
      return (
        <Row style={{width:'100%'}}> 
        <h4><b>Barco:</b> {order?.boats_name}</h4>
        <h4><b>Viagem:</b> {order?.trips_name}</h4>
        <h4><b>Safra:</b> {order?.harvest}</h4>
      </Row> 
      )
    } else {
      return (
        <></>
      )
    }
  }

  function renderParcels(){
    if(order && order.amount_parcels){
      return (
        <>
        <Row style={{width:'100%', marginTop: 24}}>
          <ListTitle>Parcelas:</ListTitle>
        </Row>
        <Divider></Divider>
              <Table 
                columns={columnsParcels} 
                rows={parcels || []} 
                hasEditing={false} 
                hasRemoving={false}
                setSelectedItem={()=>{}} 
                height="160px"
                actionsInFirstLine={false}
                fitWidth={false}
              ></Table>
              <br></br>
              <br></br>
              </>
      )
    } else {
      return (
        <Row style={{width:'100%', marginBottom: 36}}>
        <h4><b>Tipo de pagamento:</b> {order?.paymenttype_name}</h4>
        <h4><b>Vencimento:</b> {moment(order?.expiration_date_name).add(3, 'hours').format('DD/MM/YYYY')}</h4>
        <h4><b>Status:</b> {order?.status_name}</h4>
        </Row> 
      )
    }
  }
  
  function renderApportionment(){
    return (
      <>
        <Row style={{width:'100%', marginTop: 0}}>
          <ListTitle>Rateio:</ListTitle>
        </Row>
        <Divider></Divider>
          <Table 
            columns={columnsApportionment} 
            rows={apportionment || []} 
            hasEditing={false} 
            hasRemoving={false}
            setSelectedItem={()=>{}} 
            height="auto"
            actionsInFirstLine={false}
            fitWidth={false}
          ></Table>
      </>
    )

  }

    return (
        <Print id="report">
            <PrintHeader>
                <h4>Detalhes do Pedido:</h4>
                <h3>Data de criação: {moment(order?.created_at).format('DD/MM/YYYY')}</h3>
                </PrintHeader>
              <Divider></Divider>
              <Row style={{width:'100%', marginTop:8, justifyContent: 'flex-start'}}>
                  <h4 style={{width:'100%'}}><b>Id:</b> {order?.id_order}</h4>
                </Row>
              <Row style={{width:'100%',justifyContent: 'flex-start'}}> 
                  <h4 ><b>Criado por:</b> {order?.created_by}</h4>
                  <h4 ><b>Categoria:</b> {order?.category}</h4>
                  <h4><b>{order?.type === 'Entrada' ? 'Comprador' : 'Fornecedor'}</b>: {order?.providers_name}</h4>
                </Row> 
                {renderTripInfo()}
                { renderParcels()}
                { order?.category === 'Rateio' && renderApportionment()}
                <Row style={{width:'100%',  marginTop: 0}}>
                  <ListTitle>Produtos:</ListTitle>
                </Row>
              <Divider></Divider>
              <Table 
                  columns={columnsTransactions} 
                  rows={transactions || []} 
                  hasEditing={false} 
                  hasRemoving={false}
                  setSelectedItem={()=>{}} 
                  actionsInFirstLine={false}
                  height="auto"
                  fitWidth={false}
                ></Table>  
                <Row style={{width:'95%', borderTop:'1px solid var(--blue)', paddingTop: 16 }}>
                    <h3>Valor Total:</h3>
                    <h3> {formatMoney(order?.total_order_value) }</h3>
                </Row> 
                <Row style={{width:'95%', justifyContent:'flex-end'}}>
                    <h3>Desconto:</h3>
                    <h3> - {formatMoney(order?.discount)}</h3>
                </Row> 
                <Row style={{width:'95%',  justifyContent:'flex-end'}}>
                    <h3>A pagar:</h3>
                    <h3><b>{formatMoney(order?.total_order_value - order?.discount)}</b></h3>
                </Row> 
                {/* <Row  style={{width:'100%',  justifyContent:'flex-end'}}>
                    <h5> Gerado em {moment().format('DD/MM/YYYY HH:mm')}</h5>
                </Row> */}
        </Print>
    )

}

import  React, { useState, useContext, useEffect} from "react";
import Header from '../../components/Header';
import BoxButton
 from '../../components/BoxButton';
import {
   Container,Content
  } from './styles';
import {Ships, CostCategory, CostCenter, Products, Providers, Target, PaymentType, Subsidiary, Users, Trips, LandEmployees, CrewEmployees, Accounts} from '../../assets/icons/index';
import LocalStorageService from '../../services/storage';

const buttons = [
  {
    icon: Ships,
    title: "Barcos",
    explanation: "Gerencie os barcos cadastrados",
    path:"/records/boats",
    isAdmin: false,
  },
  {
    icon: Trips,
    title: "Viagens",
    explanation: "Gerencie as viagens cadastradas",
    path:"/records/trips",
    isAdmin: false,
  },
  {
    icon: CostCategory,
    title: "Categorias de Custo",
    explanation: "Gerencie as categorias de custos cadastrados",
    path:"/records/costCategories",
    isAdmin: false,
  },
  {
    icon: CostCenter,
    title: "Centros de Custo",
    explanation: "Gerencie os centros de custos cadastrados",
    path:"/records/costCenters",
    isAdmin: false,
  },
  {
    icon: Providers,
    title: "Fornecedores",
    explanation: "Gerencie os fornecedores cadastrados",
    path:"/records/providers",
    isAdmin: false,
  },
  {
    icon: Providers,
    title: "Compradores",
    explanation: "Gerencie os compradores cadastrados",
    path:"/records/buyers",
    isAdmin: false,
  },
  {
    icon: Products,
    title: "Produtos",
    explanation: "Gerencie os produtos cadastrados",
    path:"/records/products",
    isAdmin: false,
  },
  {
    icon: Products,
    title: "Insumos",
    explanation: "Gerencie os insumos cadastrados",
    path:"/records/supplies",
    isAdmin: false,
  },
  {
    icon: Target,
    title: "Espécies Alvo",
    explanation: "Gerencie as espécies alvo cadastradas",
    path:"/records/targetSpecies",
    isAdmin: false,
  },
  {
    icon: PaymentType,
    title: "Tipos de Pagamento",
    explanation: "Gerencie os tipos de pagamento cadastrados",
    path:"/records/paymentTypes",
    isAdmin: false,
  },
  {
    icon: Subsidiary,
    title: "Subsidiárias",
    explanation: "Gerencie as subsidiárias cadastradas",
    path:"/records/subsidiaries",
    isAdmin: false,
  },
  {
    icon: Users,
    title: "Usuários",
    explanation: "Gerencie os usuários do sistema",
    path:"/records/users",
    isAdmin: true,
  },
  {
    icon: LandEmployees,
    title: "Funcionários de Terra",
    explanation: "Gerencie os funcionários de terra",
    path:"/records/landEmployees",
    isAdmin: true,
  },
  {
    icon: CrewEmployees,
    title: "Tripulação",
    explanation: "Gerencie os membros de tripulação",
    path:"/records/crewEmployees",
    isAdmin: true,
  },
  {
    icon: CrewEmployees,
    title: "Sócios",
    explanation: "Gerencie os sócios da empresa",
    path:"/records/partners",
    isAdmin: true,
  },
  {
    icon: Accounts,
    title: "Contas bancárias",
    explanation: "Gerencie as contas das subsidiárias",
    path:"/records/accounts",
    isAdmin: true,
  },
]

function Records(){
  const localStorageService = LocalStorageService();
  const userInfo = localStorageService.getUserInfo();
  const isAdmin = userInfo?.user_role === 'adm';
    return (
      <Container>
        <Header title="Cadastros" subtitle="Cadastre barcos, centros de custo, categorias, produtos, fornecedores, etc.... " />
        <Content>
          {buttons.map((button, index) => (
            <BoxButton key={index} icon={button.icon} title={button.title} explanation={button.explanation} path={button.path} show={(button.isAdmin && isAdmin) || !button.isAdmin }></BoxButton>
          ))}
        </Content>
      </Container>
    );
  };

  export default React.createElement(Records);
import {Container, Header, Row, Content, ListTitle, Divider, Print, PrintHeader, Section, RowInfo, SectionTitle} from './styles';
import { formatMoney } from '../../../services/functions';
import moment from 'moment';
import Table from '../../../components/Table';
import { columns_inputs, columns_outputs } from './options';
import Subsidiary from '../../../components/SubsidiaryReport';

const columns = [
    {
        name: 'Subsidiária',
        key: 'subsidiary_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Tipo',
        key: 'type',
        type: 'string',
        unit: '',
    },
    {
        name: 'Comp./Forn.',
        key: 'providers_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'C.C',
        key: 'costs_center_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Pagamento',
        key: 'paymenttype_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Parcela',
        key: 'current_parcel_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Valor (R$)',
        key: 'current_parcel_value',
        type: 'money',
        unit: '',
    },
    {
        name: 'Vencimento',
        key: 'current_parcel_expiration_date',
        type: 'date',
        unit: '',
    },
    {
        name: 'Pagamento',
        key: 'current_parcel_payment_date',
        type: 'date',
        unit: '',
    },
]

export default function ReportToPrintAddPage({allOrders, startDate, endDate, index}){
    return (
        <Print id={"report_" + index} style={{marginTop: 16}}>
            <PrintHeader>
                <h4>Programação financeira</h4>
                <h3>Periodo: {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')} </h3>
                </PrintHeader>
              <Divider></Divider>
              <br></br>
              <Table 
                  columns={columns} 
                  rows={allOrders} 
                  hasEditing={false} 
                  hasRemoving={false}
                  setSelectedItem={()=>{}} 
                  actionsInFirstLine={false}
                  height="auto"
                  fitWidth={false}
                  maxRowHeight={60}
                ></Table>  
        </Print>
    )

}

import React, { useState } from "react";
import {
  Container,
  Title,
  TitleRow,
  Row,
  Subtitle,
  SubtitleRow,
  Content,
  Values,
  RowContent,
  Tabs,
} from "./styles";
import { ArrowUp, ArrowDown } from "../../assets/icons";
import moment from "moment";
import {
  formatMoney,
  formatNumber,
  getRandomColor,
  adjustCloseToZero,
} from "../../services/functions";
import Table from "../TableSubisidiary";
import ModalVisualizeOrder from "./ModalVisualizeOrder";
import ModalEditOrder from "./ModalEditOrder";
import ModalEditOrderApportionment from "./ModalEditOrderApportionment";
import ModalEditOrderStock from "./ModalEditOrderStock";
import ModalEditOrderSalary from "./ModalEditOrderSalary";
import ModalVisualizeOrderSalary from "./ModalVisualizeOrderSalary";
import ModalChangeExpirationDate from "./ModalChangeExpirationDate";
import ModalChangeMultiplesExpirationDates from "./ModalChangeMultiplesExpirationDates";
import ModalChangePaymentDate from "./ModalChangePaymentDate";
import ModalChangeMultiplesPaymentDates from "./ModalChangeMultiplesPaymentDates";
import { sortByProperty } from "../../services/functions";

const columns_inputs = [
  {
    name: "Comprador",
    key: "providers_name",
    type: "string",
    unit: "",
  },
  {
    name: "Centro de custo",
    key: "costs_center_name",
    type: "string",
    unit: "",
  },
  {
    name: "Categoria",
    key: "costs_categories_name",
    type: "string",
    unit: "",
  },
  {
    name: "Tipo de Pagamento",
    key: "paymenttype_name",
    type: "string",
    unit: "",
  },
  {
    name: "Parcela",
    key: "current_parcel_name",
    type: "string",
    unit: "",
  },
  {
    name: "Valor",
    key: "current_parcel_value",
    type: "money",
    unit: "",
  },
  {
    name: "Data de vencimento",
    key: "current_parcel_expiration_date",
    type: "date",
    unit: "",
  },
  {
    name: "Data de vencimento Original",
    key: "original_parcel_expiration_date",
    type: "date",
    unit: "",
  },
  {
    name: "Data de recebimento",
    key: "current_parcel_payment_date",
    type: "date",
    unit: "",
  },
];

const columns_outputs = [
  {
    name: "Fornecedor",
    key: "providers_name",
    type: "string",
    unit: "",
  },
  {
    name: "Centro de custo",
    key: "costs_center_name",
    type: "string",
    unit: "",
  },
  {
    name: "Categoria",
    key: "costs_categories_name",
    type: "string",
    unit: "",
  },
  {
    name: "Tipo de Pagamento",
    key: "paymenttype_name",
    type: "string",
    unit: "",
  },
  {
    name: "Parcela",
    key: "current_parcel_name",
    type: "string",
    unit: "",
  },
  {
    name: "Valor",
    key: "current_parcel_value",
    type: "money",
    unit: "",
  },
  {
    name: "Data de vencimento",
    key: "current_parcel_expiration_date",
    type: "date",
    unit: "",
  },
  {
    name: "Data de vencimento Original",
    key: "original_parcel_expiration_date",
    type: "date",
    unit: "",
  },
  {
    name: "Data de pagamento",
    key: "current_parcel_payment_date",
    type: "date",
    unit: "",
  },
];

const tabsStates = [
  {
    id: "pending_outputs",
    label: "Pagamentos Pendentes",
  },
  {
    id: "resolved_outputs",
    label: "Pagamentos Executados",
  },
  {
    id: "pending_inputs",
    label: "Recebimentos Pendentes",
  },
  {
    id: "resolved_inputs",
    label: "Recebimentos Executados",
  },
];

export default function Subsidiary({ subsidiary, setRefreshData }) {
  const [expanded, setExpanded] = useState(false);
  const [tab, setTab] = useState(tabsStates[0].id);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [modalEditOrder, setModalEditOrder] = useState(false);
  const [modalVisualizeOrder, setModalVisualizeOrder] = useState(false);
  const [modalChangeExpirationDate, setModalChangeExpirationDate] =
    useState(false);
  const [modalChangePaymentDate, setModalChangePaymentDate] = useState(false);
  const [
    modalChangeMultiplesExpirationDates,
    setModalChangeMultipleExpirationDates,
  ] = useState(false);
  const [
    modalChangeMultiplesPaymentDates,
    setModalChangeMultiplesPaymentDates,
  ] = useState(false);
  const icon = expanded ? ArrowUp : ArrowDown;
  const result =
    subsidiary?.pending_inputs_total - subsidiary?.pending_outputs_total;
  let pending_outputs = [];
  let pending_inputs = [];
  let resolved_inputs = [];
  let resolved_outputs = [];

  subsidiary.pending_outputs &&
    subsidiary.pending_outputs.map((output, index) => {
      let current_parcel_name = "1/1";
      let current_parcel_value =
        parseFloat(output?.total_order_value) - parseFloat(output?.discount);
      let current_parcel_expiration_date = output?.expiration_date
        ? output?.expiration_date[0]
        : new Date();
      let original_parcel_expiration_date = output?.original_expiration_date
        ? output?.original_expiration_date[0]
        : new Date();
      let providers_name = output.issalary
        ? output?.full_name
        : output?.providers_name;
      delete output?.current_parcel_payment_date;
      let costs_center_name = output.costs_center_name;
      let costs_categories_name = output.costs_categories_name;
      if (output.issalary && output.fk_costs_center_id_cost_array.length > 1) {
        costs_center_name = "Rateado";
      }
      if (output?.current_parcel_name) {
        pending_outputs.push({
          ...output,
          providers_name,
          checked: false,
          costs_center_name,
          costs_categories_name,
        });
      } else {
        pending_outputs.push({
          ...output,
          current_parcel_name,
          current_parcel_value,
          current_parcel_expiration_date,
          original_parcel_expiration_date,
          providers_name,
          checked: false,
          costs_center_name,
          costs_categories_name,
        });
      }
    });

  subsidiary.resolved_outputs &&
    subsidiary.resolved_outputs.map((output, index) => {
      let current_parcel_name = "1/1";
      let current_parcel_value =
        parseFloat(output?.total_order_value) - parseFloat(output?.discount);
      let current_parcel_expiration_date = output?.expiration_date
        ? output?.expiration_date[0]
        : new Date();
      let original_parcel_expiration_date = output?.original_expiration_date
        ? output?.original_expiration_date[0]
        : new Date();
      let current_parcel_payment_date = output?.payment_date[0];
      let providers_name = output.issalary
        ? output?.full_name
        : output?.providers_name;
      let costs_center_name = output.costs_center_name;
      let costs_categories_name = output.costs_categories_name;
      if (output.issalary && output.fk_costs_center_id_cost_array.length > 1) {
        costs_center_name = "Rateado";
      }
      if (output?.current_parcel_name) {
        resolved_outputs.push({
          ...output,
          providers_name,
          checked: false,
          costs_center_name,
          costs_categories_name,
        });
      } else {
        resolved_outputs.push({
          ...output,
          current_parcel_name,
          current_parcel_value,
          current_parcel_expiration_date,
          original_parcel_expiration_date,
          providers_name,
          current_parcel_payment_date,
          checked: false,
          costs_center_name,
          costs_categories_name,
        });
      }
    });

  subsidiary.pending_inputs &&
    subsidiary.pending_inputs.map((input, index) => {
      let current_parcel_name = "1/1";
      let current_parcel_value =
        parseFloat(input?.total_order_value) - parseFloat(input?.discount);
      let current_parcel_expiration_date = input?.expiration_date
        ? input?.expiration_date[0]
        : new Date();
      let original_parcel_expiration_date = input?.original_expiration_date
        ? input?.original_expiration_date[0]
        : new Date();
      delete input?.current_parcel_payment_date;
      if (input?.current_parcel_name) {
        pending_inputs.push({
          ...input,
          checked: false,
        });
      } else {
        pending_inputs.push({
          ...input,
          current_parcel_name,
          current_parcel_value,
          current_parcel_expiration_date,
          original_parcel_expiration_date,
          checked: false,
        });
      }
    });
  subsidiary.resolved_inputs &&
    subsidiary.resolved_inputs.map((input, index) => {
      let current_parcel_name = "1/1";
      let current_parcel_value =
        parseFloat(input?.total_order_value) - parseFloat(input?.discount);
      let current_parcel_expiration_date = input?.expiration_date
        ? input?.expiration_date[0]
        : new Date();
      let original_parcel_expiration_date = input?.original_expiration_date
        ? input?.original_expiration_date[0]
        : new Date();
      let current_parcel_payment_date = input?.payment_date[0];
      if (input?.current_parcel_name) {
        resolved_inputs.push({
          ...input,
          checked: false,
        });
      } else {
        resolved_inputs.push({
          ...input,
          current_parcel_name,
          current_parcel_value,
          current_parcel_expiration_date,
          original_parcel_expiration_date,
          current_parcel_payment_date,
          checked: false,
        });
      }
    });

  pending_inputs = sortByProperty(pending_inputs, "providers_name");
  pending_outputs = sortByProperty(pending_outputs, "providers_name");
  resolved_inputs = sortByProperty(resolved_inputs, "providers_name");
  resolved_outputs = sortByProperty(resolved_outputs, "providers_name");

  function renderTable() {
    if (tab.includes("inputs")) {
      return (
        <Table
          columns={columns_inputs}
          rows={tab.includes("pending") ? pending_inputs : resolved_inputs}
          hasEditing={false}
          hasRemoving={false}
          setSelectedItem={() => {}}
          height="100%"
          actionsInFirstLine={false}
          fitWidth={false}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          setSelectedOrders={setSelectedOrders}
          setModalEditOrder={setModalEditOrder}
          setModalVisualizeOrder={setModalVisualizeOrder}
          setModalChangeExpirationDate={setModalChangeExpirationDate}
          setModalChangePaymentDate={setModalChangePaymentDate}
          setModalChangeMultipleExpirationDates={
            setModalChangeMultipleExpirationDates
          }
          setModalChangeMultiplesPaymentDates={
            setModalChangeMultiplesPaymentDates
          }
        ></Table>
      );
    } else {
      return (
        <Table
          columns={columns_outputs}
          rows={tab.includes("pending") ? pending_outputs : resolved_outputs}
          hasEditing={false}
          hasRemoving={false}
          setSelectedItem={() => {}}
          height="100%"
          actionsInFirstLine={false}
          fitWidth={false}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          setSelectedOrders={setSelectedOrders}
          setModalEditOrder={setModalEditOrder}
          setModalVisualizeOrder={setModalVisualizeOrder}
          setModalChangeExpirationDate={setModalChangeExpirationDate}
          setModalChangePaymentDate={setModalChangePaymentDate}
          setModalChangeMultipleExpirationDates={
            setModalChangeMultipleExpirationDates
          }
          setModalChangeMultiplesPaymentDates={
            setModalChangeMultiplesPaymentDates
          }
        ></Table>
      );
    }
  }

  return (
    <Container
      style={{
        height: "auto",
        justifyContent: expanded ? "flex-start" : "center",
      }}
    >
      <Row>
        <TitleRow>
          <img
            src={icon}
            alt={"Expandir"}
            style={{ cursor: "pointer" }}
            onClick={() => setExpanded(!expanded)}
          ></img>
          <Title>{subsidiary?.subsidiary_name}</Title>
        </TitleRow>
        <SubtitleRow>
          <Subtitle>
            Pago: {formatMoney(subsidiary?.resolved_outputs_total)}
          </Subtitle>
          <Subtitle>
            Pendente à pagar: {formatMoney(subsidiary?.pending_outputs_total)}
          </Subtitle>
          <Subtitle>
            Recebido: {formatMoney(subsidiary?.resolved_inputs_total)}
          </Subtitle>
          <Subtitle>
            Pendente à receber: {formatMoney(subsidiary?.pending_inputs_total)}
          </Subtitle>
          {/* <Subtitle >Saldo Pendente: {formatMoney(result)}</Subtitle> */}
        </SubtitleRow>
      </Row>
      <Row
        style={{
          display: expanded ? "flex" : "none",
          marginTop: 0,
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Row>
            <Tabs>
              {tabsStates.map((state) => {
                if (state.id === tab) {
                  return (
                    <h4
                      style={{
                        color: "#256CE1",
                        borderBottom: "1px solid #256CE1",
                        fontWeight: 600,
                      }}
                      onClick={() => setTab(state.id)}
                      key={state.id}
                    >
                      {state?.label}
                    </h4>
                  );
                }
                return (
                  <h4 onClick={() => setTab(state.id)} key={state.id}>
                    {state?.label}
                  </h4>
                );
              })}
            </Tabs>
          </Row>
          <Row style={{ marginTop: 8 }}>{renderTable()}</Row>
        </div>
      </Row>
      {selectedOrder !== "" &&
        modalVisualizeOrder &&
        selectedOrder?.id_order &&
        !selectedOrder.issalary && (
          <ModalVisualizeOrder
            open={
              selectedOrder !== "" &&
              modalVisualizeOrder &&
              selectedOrder?.id_order &&
              !selectedOrder.issalary
            }
            setSelectedItem={setSelectedOrder}
            selectedItem={selectedOrder}
            width={1100}
            height={900}
          ></ModalVisualizeOrder>
        )}
      {selectedOrder !== "" &&
        selectedOrder.id_order &&
        modalEditOrder &&
        !selectedOrder.isapportionment &&
        !selectedOrder.original_order &&
        !selectedOrder.isstocked && (
          <ModalEditOrder
            open={
              selectedOrder !== "" &&
              selectedOrder.id_order &&
              modalEditOrder &&
              !selectedOrder.isapportionment &&
              !selectedOrder.original_order &&
              !selectedOrder.isstocked
            }
            setSelectedItem={setSelectedOrder}
            selectedItem={selectedOrder}
            modalEditOrder={modalEditOrder}
            width={1000}
            height={900}
            setRefreshData={setRefreshData}
          ></ModalEditOrder>
        )}
      {selectedOrder !== "" &&
        selectedOrder.id_order &&
        modalEditOrder &&
        selectedOrder.isapportionment && (
          <ModalEditOrderApportionment
            open={
              selectedOrder !== "" &&
              selectedOrder.id_order &&
              modalEditOrder &&
              selectedOrder.isapportionment
            }
            setSelectedItem={setSelectedOrder}
            selectedItem={selectedOrder}
            width={1000}
            height={900}
            modalEditOrder
            setRefreshData={setRefreshData}
          ></ModalEditOrderApportionment>
        )}
      {selectedOrder !== "" &&
        selectedOrder.id_order &&
        modalEditOrder &&
        !selectedOrder.isapportionment &&
        selectedOrder.isstocked && (
          <ModalEditOrderStock
            open={
              selectedOrder !== "" &&
              selectedOrder.id_order &&
              modalEditOrder &&
              !selectedOrder.isapportionment &&
              selectedOrder.isstocked
            }
            setSelectedItem={setSelectedOrder}
            selectedItem={selectedOrder}
            width={1000}
            height={900}
            modalEditOrder={modalEditOrder}
            setRefreshData={setRefreshData}
          ></ModalEditOrderStock>
        )}
      {selectedOrder !== "" &&
        selectedOrder.id_order &&
        modalEditOrder &&
        selectedOrder.issalary && (
          <ModalEditOrderSalary
            open={
              selectedOrder !== "" &&
              selectedOrder.id_order &&
              modalEditOrder &&
              selectedOrder.issalary
            }
            setSelectedItem={setSelectedOrder}
            selectedItem={selectedOrder}
            width={1000}
            height={900}
            setRefreshData={setRefreshData}
          ></ModalEditOrderSalary>
        )}
      {selectedOrder !== "" &&
        selectedOrder.id_order &&
        modalVisualizeOrder &&
        selectedOrder.issalary && (
          <ModalVisualizeOrderSalary
            open={
              selectedOrder !== "" &&
              selectedOrder.id_order &&
              selectedOrder.issalary
            }
            setSelectedItem={setSelectedOrder}
            selectedItem={selectedOrder}
            width={1100}
            height={900}
          ></ModalVisualizeOrderSalary>
        )}
      {selectedOrder !== "" &&
        selectedOrder.id_order &&
        modalChangeExpirationDate && (
          <ModalChangeExpirationDate
            open={
              selectedOrder !== "" &&
              selectedOrder.id_order &&
              modalChangeExpirationDate
            }
            setSelectedItem={setSelectedOrder}
            selectedItem={selectedOrder}
            width={500}
            height={"auto"}
            modalChangeExpirationDate={modalChangeExpirationDate}
            setRefreshData={setRefreshData}
          ></ModalChangeExpirationDate>
        )}
      {selectedOrders.length > 0 && modalChangeMultiplesExpirationDates && (
        <ModalChangeMultiplesExpirationDates
          open={
            selectedOrders.length > 0 && modalChangeMultiplesExpirationDates
          }
          selectedItems={selectedOrders}
          setSelectedItems={setSelectedOrders}
          width={500}
          height={"auto"}
          modalChangeMultiplesExpirationDates={
            modalChangeMultiplesExpirationDates
          }
          setRefreshData={setRefreshData}
        ></ModalChangeMultiplesExpirationDates>
      )}
      {selectedOrders.length > 0 && modalChangeMultiplesPaymentDates && (
        <ModalChangeMultiplesPaymentDates
          open={selectedOrders.length > 0 && modalChangeMultiplesPaymentDates}
          selectedItems={selectedOrders}
          setSelectedItems={setSelectedOrders}
          width={600}
          height={"auto"}
          modalChangeMultiplesPaymentDates={modalChangeMultiplesPaymentDates}
          setRefreshData={setRefreshData}
        ></ModalChangeMultiplesPaymentDates>
      )}
      {selectedOrder !== "" &&
        selectedOrder.id_order &&
        modalChangePaymentDate && (
          <ModalChangePaymentDate
            open={
              selectedOrder !== "" &&
              selectedOrder.id_order &&
              modalChangePaymentDate
            }
            setSelectedItem={setSelectedOrder}
            selectedItem={selectedOrder}
            width={500}
            height={"auto"}
            modalChangePaymentDate={modalChangePaymentDate}
            setRefreshData={setRefreshData}
          ></ModalChangePaymentDate>
        )}
    </Container>
  );
}

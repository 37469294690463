import Button from "../../../components/Button";
import Autocomplete from "../../../components/Autocomplete";
import { UseBoats } from "../../../hooks/useBoats";
import { UseFishingTypes } from "../../../hooks/useFishingTypes";
import { UseProducts } from "../../../hooks/useProducts";
import { harvestOptions, tripsOptions } from "../options";
import { UseEmployees } from "../../../hooks/useEmployees";
import { UseCostCategories } from "../../../hooks/useCostCategories";
import { UseProviders } from "../../../hooks/useProviders";
import MultipleSelect from "../../../components/MultipleSelect";

export default function OverviewFilters({
  filters,
  setFilters,
  defaultFilters,
}) {
  const { boats } = UseBoats();
  const { fishingTypes } = UseFishingTypes();
  const { products } = UseProducts();
  const { employees } = UseEmployees();
  const { costCategories } = UseCostCategories();
  const { providers } = UseProviders();

  let productsOptions = [];
  products &&
    !products.error &&
    products.map((element) => {
      if (element.isactive) {
        productsOptions.push({
          id: element?.id_product,
          label: element?.product_name,
        });
      }
    });

  let boatsOptions = [];
  boatsOptions &&
    !boatsOptions.error &&
    boats &&
    boats.map((element) => {
      if (element.isactive) {
        boatsOptions.push({
          value: element?.boats_name,
          label: element?.boats_name,
        });
      }
    });

  let fishingTypesOptions = [];
  fishingTypes &&
    !fishingTypes.error &&
    fishingTypes.map((element) => {
      if (element.isactive) {
        fishingTypesOptions.push({
          id: element?.id_fishingtype,
          label: element?.fishingtypes_name,
        });
      }
    });

  let employeesOptions = [];
  employees &&
    !employees.error &&
    employees.map((element) => {
      if (element.isactive) {
        employeesOptions.push({
          id: element?.id_employee,
          label: element?.full_name,
        });
      }
    });

  let costCategoriesOptions = [];
  costCategories &&
    !costCategories.error &&
    costCategories.map((element) => {
      if (element.isactive) {
        costCategoriesOptions.push({
          id: element?.costs_categories_name,
          label: element?.costs_categories_name,
        });
      }
    });

  let providersOptions = [];
  providers &&
    !providers.error &&
    providers.map((element) => {
      if (element.isactive) {
        providersOptions.push({
          id: element?.providers_name,
          label: element?.providers_name,
        });
      }
    });

  function cleanFilters() {
    setFilters({ ...defaultFilters });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Button
        label="Limpar filtros"
        background="transparent"
        color="#54577A"
        borderColor="#54577A"
        disabled={false}
        onClick={() => {
          cleanFilters();
        }}
      ></Button>
      <br></br>
      <Autocomplete
        value={filters?.harvest}
        width={"100%"}
        disabled={false}
        options={[{ id: "''", label: "Todas" }, ...harvestOptions]}
        fontSize={"0.9rem"}
        label="Safra"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.harvest = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
      <Autocomplete
        value={filters?.trip}
        width={"100%"}
        disabled={false}
        options={[{ id: "''", label: "Todas" }, ...tripsOptions]}
        fontSize={"0.9rem"}
        label="Viagem"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.trip = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
      <MultipleSelect
        selectOptions={[{ value: "Todos", label: "Todos" }, ...boatsOptions]}
        width={"100%"}
        defaultValue={"Todos"}
        fontSize={"0.9rem"}
        label="Barcos"
        handleChange={(value) => {
          let filtersCopy = { ...filters };
          filtersCopy.boat = value;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></MultipleSelect>
      <br></br>
      <Autocomplete
        value={filters?.fishingType}
        width={"100%"}
        disabled={false}
        options={[{ id: "''", label: "Todas" }, ...fishingTypesOptions]}
        fontSize={"0.9rem"}
        label="Espécie"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.fishingType = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
      <Autocomplete
        value={filters?.product}
        width={"100%"}
        disabled={false}
        options={[{ id: "''", label: "Todos" }, ...productsOptions]}
        fontSize={"0.9rem"}
        label="Produto/Insumo"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.product = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
      <Autocomplete
        value={filters?.employee}
        width={"100%"}
        disabled={false}
        options={[{ id: "''", label: "Todos" }, ...employeesOptions]}
        fontSize={"0.9rem"}
        label="Colaborador"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.employee = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
      <Autocomplete
        value={filters?.costCategory}
        width={"100%"}
        disabled={false}
        options={[{ id: "''", label: "Todas" }, ...costCategoriesOptions]}
        fontSize={"0.9rem"}
        label="Categoria de Custo"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.costCategory = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
      <Autocomplete
        value={filters?.provider}
        width={"100%"}
        disabled={false}
        options={[{ id: "''", label: "Todos" }, ...providersOptions]}
        fontSize={"0.9rem"}
        label="Fornecedor/Comprador"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.provider = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
    </div>
  );
}
